import { FC } from "react";
import { IconButton, IconButtonTypes } from "../../IconButton";

interface MenuItemProps {
  label: string;
  action: VoidFunction;
}

const MenuItem: FC<MenuItemProps> = ({ label, action }) => {
  return (
    <div
      className="flex items-center justify-between text-primary font-conversation py-6 px-6 text-2xl border-b cursor-pointer"
      onClick={action}
    >
      {label}
      <IconButton isDark icon={IconButtonTypes.CARET} onClick={() => null} />
    </div>
  );
};

export { MenuItem };
