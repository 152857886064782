import { FC } from "react";
import { useAddListener } from "./useAddListener";
import { FormControl } from "../../Forms/FormControl";
import { Button } from "../../Button";
import { ButtonTypes } from "../../../typings/components";
import { Spinner } from "../../Spinner";

interface AddListenerProps {}

const AddListener: FC<AddListenerProps> = () => {
  const { isLoading, formikObject } = useAddListener();
  const { submitForm, touched, errors, values, handleChange, handleBlur } =
    formikObject;

  return (
    <div className="">
      <h3 className="text-tertiary text-2xl font-thin text-center font-conversation mb-6">
        Add a new listener
      </h3>
      <Spinner isLoading={isLoading} withBackdrop />
      <div className="bg-tertiary py-8 px-4 mx-2 rounded-t-2xl">
        <p className="mb-6">Enter the email of the listener to invite them.</p>
        <p className="mb-6 font-bold">Your stories are private by default.</p>
        <p className="mb-6">
          Listeners will only see stories you share with &quot;My
          Listeners&quot; after recording.
        </p>
        <form
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            submitForm();
          }}
        >
          <FormControl
            styleType="SECONDARY"
            type="email"
            name="email"
            placeholder="Email"
            value={values.email}
            touched={touched.email}
            error={errors.email}
            {...{ handleChange, handleBlur }}
          />
          <Button
            label="Invite"
            type="submit"
            style={ButtonTypes.PRIMARY}
            isFullWidth
            action={() => null}
          />
        </form>
      </div>
    </div>
  );
};

export { AddListener };
