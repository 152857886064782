import { FC } from "react";
import { useMainModal } from "./useMainModal";
import classNames from "classnames";
import { MAIN_MODAL_ACTIONS } from "../../constants/services";
import { ChangePassword } from "../MainModalActions/ChangePassword";
import { CustomMainModal } from "../MainModalActions/CustomMainModal";

interface MainModalProps {}

const MainModal: FC<MainModalProps> = () => {
  const {
    isOpen,
    customMainModalProps,
    activeMainModalAction,
    closeMainModalAction,
  } = useMainModal();
  const menuStyles = classNames(
    "w-[calc(100%-2rem)] max-w-md bg-white fixed rounded-2xl transition-all overflow-hidden",
    isOpen ? "translate-y-0 pt-6" : "translate-y-full h-0"
  );
  const collapseMenuBackdrop = classNames(
    isOpen ? "h-full" : "h-0",
    "fixed left-0 bottom-0 w-full bg-black opacity-60"
  );

  const wrapperStyles = isOpen ? "h-full" : "h-0";

  const getMainModalActionToShow = () => {
    switch (activeMainModalAction) {
      case MAIN_MODAL_ACTIONS.CHANGE_PASSWORD:
        return <ChangePassword />;
      case MAIN_MODAL_ACTIONS.CUSTOM_MODAL:
        return customMainModalProps ? (
          <CustomMainModal {...customMainModalProps} />
        ) : null;
    }
    return null;
  };

  return (
    <div
      className={`fixed z-40 flex flex-col w-full ${wrapperStyles} top-0 items-center justify-center px-4`}
    >
      <div
        className={collapseMenuBackdrop}
        onClick={closeMainModalAction}
      ></div>
      <div className={menuStyles}>{getMainModalActionToShow()}</div>
    </div>
  );
};

export { MainModal };
