import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { OptionItem, ListItem } from ".";

interface OptionedItemProps<T> {
  options: Array<OptionItem<T>>;
  item: T;
  index: number;
  displayItems: (data: T, index: number) => React.ReactNode;
  openedOptions?: string | null;
  openOptions: (o: string) => void;
  closeOptions: () => void;
}

const OptionedListItem = <T extends ListItem>(props: OptionedItemProps<T>) => {
  const {
    item,
    displayItems,
    index,
    options,
    openedOptions,
    openOptions,
    closeOptions,
  } = props;
  const filteredOptions = item.optionsType
    ? options.filter((o) => o.types?.indexOf(item.optionsType || "") !== -1)
    : options;
  return (
    <div className="relative border-light-borders border-t border-border px-1 py-2 pr-12">
      {filteredOptions && filteredOptions.length > 0 && (
        <>
          <FontAwesomeIcon
            className="absolute right-6 top-[50%] -mt-2 cursor-pointer"
            icon={faChevronDown}
            onClick={() => openOptions(`options-for-${index}`)}
          />
          <div className="absolute right-6 mt-5 bg-dark-bold rounded-xl overflow-hidden w-40 z-20">
            {filteredOptions &&
              openedOptions === `options-for-${index}` &&
              filteredOptions.map((a, i) => (
                <div
                  key={`option-${i}-for-${item}`}
                  className="border-t border-stone-600 text-tertiary text-xs font-light px-3 py-2 cursor-pointer"
                  onClick={() => {
                    closeOptions();
                    a.action(item);
                  }}
                >
                  {a.label}
                </div>
              ))}
          </div>
        </>
      )}
      {displayItems(item, index)}
    </div>
  );
};

export { OptionedListItem };
