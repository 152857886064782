import { useContext, useEffect, useState } from "react";
import { MLStateContext } from "../../services/stateProvider";
import { useUserService } from "../../services/useUserService";
import { AiPrompt } from "../../typings/Prompts";
import { useNavigate } from "react-router-dom";
import { useStoriesService } from "../../services/useStoriesService";
import { Story } from "../../typings/Story";
import { useFollowupQuestionsService } from "../../services/useFollowupQuestionsService";
import { FollowUpQuestionDetails } from "../../typings/FollowUpQuestion";
import { NotificationTypes } from "../../typings/Notifications";
import { usePromptsService } from "../../services/usePromptsService";

const useHome = () => {
  const navigate = useNavigate();
  const { getAll } = useStoriesService();
  const { getAll: getAllPrompts } = usePromptsService();
  const [isLoading, setIsLoading] = useState(false);
  const [pendingFollowupQuestions, setPendingFollowupQuestions] = useState<
    FollowUpQuestionDetails[]
  >([]);
  const [storiesToListen, setStoriesToListen] = useState<Array<Story>>([]);
  const {
    state: {
      stories,
      prompts,
      followers,
      following,
      userDetails,
      aiPrompts = [],
      activeMode,
      notifications: originalNotifications = [],
    },
    updateStateItem,
  } = useContext(MLStateContext);
  const { refreshUserDetails } = useUserService();
  const { getPendingFollowupQuestions } = useFollowupQuestionsService();
  const notifications = originalNotifications.filter(
    (n) => n.type !== NotificationTypes.FOLLOW_UP_QUESTION
  );

  useEffect(() => {
    setIsLoading(true);
    updateStateItem({ currentAiPrompt: undefined });
    loadStoriesToListen();
    Promise.all([refreshUserDetails(), getAllPrompts()]).finally(() =>
      setIsLoading(false)
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadStoriesToListen = () => {
    setIsLoading(true);
    getAll()
      .then((l) => {
        setIsLoading(false);
        setStoriesToListen(l.filter((s) => s.author.id !== userDetails?.id));
        setPendingFollowupQuestions(getPendingFollowupQuestions());
      })
      .finally(() => setIsLoading(false));
  };

  const startRecordingAiPrompt = (aiPrompt: AiPrompt) => {
    updateStateItem({ currentAiPrompt: aiPrompt });
    navigate("/record");
  };

  return {
    prompts,
    stories,
    isLoading,
    followers,
    aiPrompts,
    following,
    activeMode,
    userDetails,
    notifications,
    storiesToListen,
    pendingFollowupQuestions,
    startRecordingAiPrompt,
  };
};

export { useHome };
