import { CustomMainModalProps } from "../components/MainModalActions/CustomMainModal";
import { MAIN_MODAL_ACTIONS } from "../constants/services";
import { MLStateContext } from "./stateProvider";
import { useContext } from "react";

const useMainModalActionService = () => {
  const {
    state: { activeMainModalAction, customMainModalProps },
    updateStateItem,
  } = useContext(MLStateContext);
  const openMainModalAction = (
    activeMainModalAction: MAIN_MODAL_ACTIONS,
    customMainModalProps?: CustomMainModalProps
  ) => {
    updateStateItem({ activeMainModalAction, customMainModalProps });
  };

  const closeMainModalAction = () => {
    updateStateItem({
      activeMainModalAction: null,
      customMainModalProps: null,
    });
  };

  return {
    openMainModalAction,
    closeMainModalAction,
    activeMainModalAction,
    customMainModalProps,
  };
};

export { useMainModalActionService };
