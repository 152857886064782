import { convertUTCDateToLocalDate } from "../utils/dates";
import { getNotificationType } from "../utils/invitations-helper";
import { LoginResponse, UserDetailInfo, UserDetails } from "./User";

export enum ResponseNotificationTypes {
  TEXT = "News",
  INVITATION = "Invitation",
  NEW_STORY = "NewStory",
  FOLLOW_UP_QUESTION = "FollowUpQuestion",
  INVITATION_RESPONDED = "InvitationResponded",
}

export enum NotificationTypes {
  INVITATION_REQUEST = "INVITATION_REQUEST",
  INVITATION_RESPONDED = "INVITATION_RESPONDED",
  FOLLOW_UP_QUESTION = "FOLLOW_UP_QUESTION",
  NEW_STORY = "NEW_STORY",
  TEXT = "TEXT",
}

type StoryBasic = {
  id: number;
  name: string;
  description: string;
};

export type NotificationResponse = {
  user_from: LoginResponse;
  created_at: string;
  id: number;
  name: string;
  resource_uri: string;
  story: StoryBasic;
  is_active: boolean;
  type: ResponseNotificationTypes;
  user_to_email: string;
  user_to_phonenumber: string;
  user_to_is_listener: boolean;
};

export type Notification = {
  id: number;
  name: string;
  author: UserDetails;
  createdAt: Date;
  resourceUri: string;
  story?: StoryBasic;
  isActive: boolean;
  type: string;
  userToEmail: string;
  userToPhoneNumber: string;
  userToIsListener: boolean;
};

export class NotificationDetail implements Notification {
  id: number;
  name: string;
  type: string;
  story?: StoryBasic;
  isActive: boolean;
  createdAt: Date;
  author: UserDetails;
  resourceUri: string;
  userToEmail: string;
  userToPhoneNumber: string;
  userToIsListener: boolean;

  constructor(n: NotificationResponse) {
    this.id = n.id;
    this.name = n.name;
    this.type = getNotificationType(n);
    this.story = n.story;
    this.author = new UserDetailInfo(n.user_from);
    this.isActive = n.is_active;
    this.createdAt = convertUTCDateToLocalDate(new Date(n.created_at));
    this.resourceUri = n.resource_uri;
    this.userToEmail = n.user_to_email;
    this.userToPhoneNumber = n.user_to_phonenumber;
    this.userToIsListener = n.user_to_is_listener;
  }
}
