import {
  FC,
  createRef,
  FocusEvent,
  ChangeEvent,
  HTMLInputTypeAttribute,
  // useState,
} from "react";
import { Cropper, ReactCropperElement } from "react-cropper";
// import { fileToBase64 } from "../../../utils/imagesHandling";

interface FormControlProps {
  styleType?: "PRIMARY" | "SECONDARY";
  type?: HTMLInputTypeAttribute;
  name: string;
  value: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: FocusEvent) => void;
  placeholder: string;
  error?: string;
  touched?: boolean;
  accept?: string;
  nonMargins?: boolean;
  autocomplete?: "on" | "off";
  isSmall?: boolean;
  isDisabled?: boolean;
}

const FormControl: FC<FormControlProps> = (props: FormControlProps) => {
  const {
    styleType = "PRIMARY",
    type = "text",
    accept,
    name,
    placeholder,
    error,
    touched,
    value,
    handleChange,
    handleBlur,
    autocomplete = "on",
    nonMargins = false,
    isSmall = false,
    isDisabled = false
  } = props;
  // const [imageUploaded, setImageUploaded] = useState<string>();
  const getBorderStyleType = () => {
    if (styleType === "PRIMARY") return "border-transparent";
    if (styleType === "SECONDARY") return "border-normal";
    return "border-transparent";
  };
  const inputRef = createRef<HTMLInputElement>();
  const isFile = type === "file";
  const errorStyles = error ? "border-red-900" : getBorderStyleType();
  const isHidden = isFile ? "" : "";
  // const isHidden = isFile ? "hidden" : "";
  const marginsStyles = nonMargins ? "mb-1" : "mb-10";
  const heightSize = isSmall ? "h-[30px]" : "h-[50px]";
  const styles = `w-full px-4 rounded-full placeholder:text-normal border ${heightSize} ${marginsStyles} ${errorStyles} ${isHidden}`;
  const cropperRef = createRef<ReactCropperElement>();
  const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
  };
  // const openInput = () => {
  //   console.log("openInput");
  //   inputRef.current?.click();
  // };
  return (
    <>
      {isFile && (
        <>
          <div
            className="mb-5 ml-4 cursor-pointer"
            onClick={() => inputRef.current?.click()}
          >
            SELECT PICTURE
          </div>
          {value && <p>{value}</p>}

          <Cropper
            // src={imageUploaded}
            style={{ height: 300, width: "100%" }}
            autoCropArea={1}
            aspectRatio={1}
            viewMode={3}
            guides={true}
            ref={cropperRef}
          />
        </>
      )}
      <input
        disabled={isDisabled}
        autoComplete={autocomplete}
        className={styles}
        ref={inputRef}
        type={type}
        id={name}
        name={name}
        placeholder={placeholder}
        onChange={onChangeValue}
        onBlur={handleBlur}
        accept={accept}
        value={value}
      />
      {error && touched && (
        <p className={`text-red-900 ml-4 mb-3 ${nonMargins ? '' : '-mt-9'}`}>{error}</p>
      )}
    </>
  );
};

export { FormControl };
