import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAudioRecorder } from "../../../../hooks/useAudioRecorder";
import { MLStateContext } from "../../../../services/stateProvider";
import { useStoriesService } from "../../../../services/useStoriesService";
import { useTellStoryService } from "../../../../services/useTellStoryService";
import { FollowUpQuestionType } from "../../../../typings/FollowUpQuestion";
import { Story } from "../../../../typings/Story";

const useStep3 = () => {
  const {
    stepBackNavigation,
    setDisplayHelp,
    resetStep,
    tellStory,
    goToStepNumber,
  } = useTellStoryService();
  const navigate = useNavigate();
  const {
    state: { currentStory, preSelectedQuestion },
    updateStateItem,
  } = useContext(MLStateContext);
  const { getById } = useStoriesService();
  const [isBuilding, setIsBuilding] = useState<boolean>(false);
  const [isAddingMore, setIsAddingMore] = useState<boolean>(false);
  const [isRecordedCompleted, setIsRecordedCompleted] =
    useState<boolean>(false);
  const [currentQuestion, setCurrentQuestion] =
    useState<FollowUpQuestionType>();
  const [transcriptText, setTranscriptText] = useState<string>("");
  const [story, setStory] = useState<Story>();
  const [questions, setQuestions] = useState<Array<FollowUpQuestionType>>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFlowCompleted, setIsFlowCompleted] = useState(false);

  const handleTranscriptUpdate = (t: string) => {
    setTranscriptText((prev) => `${prev} ${t}`);
  };

  useEffect(() => {
    if (story) {
      reloadNonAnsweredQuestions();
    }
  }, [story]);

  useEffect(() => {
    if (currentStory) {
      setStory(currentStory);
    }
  }, [currentStory]);

  const {
    changeStateToRecording,
    startRecording,
    recorderState,
    saveRecording,
    cancelRecording,
  } = useAudioRecorder({
    handleTranscriptUpdate,
  });
  const { isRecording } = recorderState;

  const recordItem = useState<boolean>(false);

  useEffect(() => {
    if (currentStory && isRecording) {
      startRecording();
    }
  }, [currentStory, isRecording]);

  useEffect(() => {
    return () => {
      updateStateItem({
        currentFollowupQuestion: undefined,
      });
    };
  }, []);

  const reloadNonAnsweredQuestions = () => {
    if (story) {
      const nonAnswered = story.followupQuestions
        .filter((a) => a.status === "active")
        .filter((a) => !a.answers || a.answers.length === 0);
      let current = nonAnswered[0];
      setQuestions([...nonAnswered]);

      if (preSelectedQuestion) {
        current = preSelectedQuestion;
      }

      setCurrentQuestion(current);
      updateStateItem({
        currentFollowupQuestion: current,
        currentStory: story,
      });
    }
  };

  const toggleRecording = () => {
    if (isRecording) pauseRecording();
    else changeStateToRecording();
  };

  const goBack = () => {
    stepBackNavigation();
  };

  const continueRecording = () => {
    updateStateItem({
      currentFollowupQuestion: undefined,
    });
    setIsAddingMore(true);
    toggleRecording();
  };

  const changeTopic = () => {
    updateStateItem({
      currentFollowupQuestion: undefined,
      currentStory: undefined,
    });
    goToStepNumber(0);
  };

  const sliderUpdateSelectedQuestion = (
    current: FollowUpQuestionType | undefined
  ) => {
    if (current?.id === -1) {
      setCurrentQuestion(undefined);
      updateStateItem({
        currentFollowupQuestion: undefined,
      });
    } else {
      setCurrentQuestion(current);
      updateStateItem({
        currentFollowupQuestion: current,
      });
    }
  };

  const pauseRecording = () => {
    if (transcriptText.length > 0 && story) {
      saveRecording();
      setIsBuilding(true);
      updateStateItem({
        currentStory: story,
      });
      setTimeout(refreshStory, 8000);
    } else {
      cancelRecording();
      setIsRecordedCompleted(false);
      setIsBuilding(false);
      setDisplayHelp(true);
    }
  };

  const refreshStory = () => {
    if (isAddingMore) {
      setIsAddingMore(false);
      if (story?.id) {
        getById(story.id).then((story) => {
          setIsBuilding(false);
          setStory(story);
          updateStateItem({ currentStory: story, isNewStory: false });
        });
      }
    } else if (story) {
      updateStateItem({
        currentFollowupQuestion: undefined,
      });
      updateStateItem({ currentStory: story, isNewStory: false });
      setIsFlowCompleted(true);
      navigate(`/record/${story?.id}/questions`);
    }
  };

  const reviewMyBook = () => {
    resetStep();
    navigate(`/mybook`);
  };

  const reviewStory = () => {
    resetStep();
    navigate(`/stories/${story?.id}`);
  };
  return {
    isFlowCompleted,
    isAddingMore,
    isRecordedCompleted,
    goBack,
    reloadNonAnsweredQuestions,
    questions,
    currentQuestion,
    setCurrentQuestion: sliderUpdateSelectedQuestion,
    continueRecording,
    changeTopic,
    reviewStory,
    reviewMyBook,
    displayHelp: tellStory?.displayHelp,
    transcriptText,
    isRecording,
    recordItem,
    story,
    isBuilding,
    isLoading,
    toggleRecording,
    setIsLoading,
  };
};

export { useStep3 };
