import { TARGET_WORDS_PER_CHAPTER } from "../constants/services";
import { Chapter } from "../typings/Chapter";
import { Story } from "../typings/Story";

export const getOrderedChapters = (list: Chapter[], separatorIndex?: number) =>
  list
    .map((c, i) => ({
      id: c?.id,
      isActive: separatorIndex ? i < separatorIndex : c.isActive,
    }))
    .filter((c) => c.id)
    .map((c, i) => ({ ...c, order: i + 1 } as Chapter));

export const getIncompleteChapters = (chapters: Chapter[]) => {
  return chapters
    .filter((c) => c.isActive)
    .filter((c) => c.wordsCount < TARGET_WORDS_PER_CHAPTER);
};

export const areChaptersEquals = (
  originalList: Chapter[],
  dndList: Chapter[],
  separatorIndex: number
) => {
  const orderedOriginal = getOrderedChapters(originalList);
  const orderedDnd = getOrderedChapters(dndList, separatorIndex);
  return JSON.stringify(orderedOriginal) === JSON.stringify(orderedDnd);
};

export const getOrderedStorys = (list: Story[], separatorIndex?: number) =>
  list
    .map((c, i) => ({
      id: c.id,
      isActive: separatorIndex ? i < separatorIndex : c.isActive,
    }))
    .filter((c) => c.id)
    .map((c, i) => ({ ...c, orderInChapter: i + 1 } as Story));

export const areStorysEquals = (
  originalList: Story[],
  dndList: Story[],
  separatorIndex: number
) => {
  const orderedOriginal = getOrderedStorys(originalList);
  const orderedDnd = getOrderedStorys(dndList, separatorIndex);
  return JSON.stringify(orderedOriginal) === JSON.stringify(orderedDnd);
};
