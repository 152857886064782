/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { Story } from "../../typings/Story";
import { useParams } from "react-router-dom";
import { UserDetails } from "../../typings/User";
import { MLStateContext } from "../../services/stateProvider";
import { useUserService } from "../../services/useUserService";
import { useStoriesService } from "../../services/useStoriesService";

const useStoryTellerProfile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserDetails>();
  const { refreshUserDetails } = useUserService();
  const { getByIdFiltered } = useStoriesService();
  const [allStories, setAllStories] = useState<Array<Story>>([]);
  const {
    state: { userDetails, following },
  } = useContext(MLStateContext);
  const { id = userDetails?.email } = useParams();

  useEffect(() => {
    loadInfo();
  }, [id]);

  useEffect(() => {
    setSelectedUser(following?.find((f) => f.id === Number(id)));
    setIsLoading(false);
  }, [following]);

  useEffect(() => {
    if(selectedUser?.id){
      getByIdFiltered(selectedUser?.id, true).then(list =>{
        setAllStories(list || []);
      })
    }
  }, [selectedUser]);

  const loadInfo = () => {
    setIsLoading(true);
    if (id) {
      refreshUserDetails();
    }
  };

  return { allStories, isLoading, selectedUser };
};

export { useStoryTellerProfile };
