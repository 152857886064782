import { MyBook } from "../pages/MyBook";
import { Home } from "../pages/Home";
import { RouteItem } from "../typings/routes";
import { Story } from "../pages/Story";
import { Record } from "../pages/Record";
import { Stories } from "../pages/Stories";
import { Lobby } from "../pages/Lobby";
import { Waitlist } from "../pages/Waitlist";
import { Login } from "../pages/Login";
import { Navigate } from "react-router-dom";
import { Register } from "../pages/Register";
import { CheckYourEmail } from "../pages/CheckYourEmail";
import { EmailConfirmation } from "../pages/EmailConfirmation";
import { StorySharing } from "../pages/StorySharing";
import { AnswerFollowUpQuestions } from "../pages/AnswerFollowUpQuestions";
import { StoryTellerProfile } from "../pages/StoryTellerProfile";
import { CurrentUserProfile } from "../pages/CurrentUserProfile";
import { ForgotPassword } from "../pages/ForgotPassword";
import { ResetPassword } from "../pages/ResetPassword";
import { EditStory } from "../pages/EditStory";
import { Onboarding } from "../pages/Onboarding";
import { HomeWelcome } from "../pages/HomeWelcome";
import { TellStory } from "../pages/TellStory";
import { ChapterDetail } from "../pages/ChapterDetail";
import { MitchellMartin } from "../pages/mitchell-martin";

const RESTRICTED_ROUTES: Array<RouteItem> = [];
const BACK_BTN_ROUTE_BLACKLIST: Array<string> = [
  "record",
  "notification",
  "register",
  "check-your-email",
  "mitchell-martin",
];
const COMMON_ROUTES: Array<RouteItem> = [
  {
    id: "1",
    label: "Login",
    path: "/login",
    element: <Login />,
  },
  {
    id: "2",
    label: "Register",
    path: "/register",
    element: <Register />,
  },
  {
    id: "3",
    label: "Register",
    path: "/register/:invitation",
    element: <Register />,
  },
  {
    id: "4",
    label: "Check your email",
    path: "/check-your-email",
    element: <CheckYourEmail />,
  },
  {
    id: "5",
    label: "Email Confirmation",
    path: "/confirm/:activationKey",
    element: <EmailConfirmation />,
  },
  {
    id: "6",
    label: "Forgot password",
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    id: "7",
    label: "Reset password",
    path: "/reset_password/:userId/:token",
    element: <ResetPassword />,
  },
  {
    id: "0",
    label: "Lobby",
    path: "/mitchell-martin",
    element: <MitchellMartin />,
  },
];
const PUBLIC_ROUTES: Array<RouteItem> = [
  {
    id: "0",
    label: "Lobby",
    path: "/",
    element: <Lobby />,
  },
  ...COMMON_ROUTES,
  {
    id: "6",
    label: "",
    path: "*",
    element: <Navigate to="/" />,
  },
];

const WAITLIST_ROUTES: Array<RouteItem> = [
  {
    id: "0",
    label: "",
    path: "*",
    element: <Navigate to="/" />,
  },
  {
    id: "1",
    label: "Waitlist",
    isInNav: true,
    path: "/",
    element: <Waitlist />,
  },
  {
    id: "2",
    label: "Register",
    path: "/register",
    element: <Register />,
  },
];

const AUTHENTICATED_ROUTES: Array<RouteItem> = [
  { id: "0", label: "Home", path: "/", element: <HomeWelcome /> },
  {
    id: "1",
    label: "Onboarding",
    path: "/onboarding",
    element: <Onboarding />,
  },
  {
    id: "2",
    label: "Listen",
    path: "/listen",
    element: <Home />,
  },
  {
    id: "2",
    label: "Chapter",
    path: "/chapter/:id",
    element: <ChapterDetail />,
  },
  {
    id: "3",
    label: "MyBook",
    isInNav: true,
    path: "/mybook",
    element: <MyBook />,
  },
  { id: "4", label: "Settings", isInNav: true, path: "/", element: <Home /> },
  { id: "5", label: "Legal", isInNav: true, path: "/", element: <Home /> },
  { id: "6", label: "Story", path: "/stories/:id", element: <Story /> },
  {
    id: "7",
    label: "Story",
    path: "/stories/:id/edit",
    element: <EditStory />,
  },
  {
    id: "8",
    label: "Stories",
    path: "/stories",
    element: <Stories />,
  },
  { id: "9", label: "Record", path: "/record", element: <Record /> },
  { id: "10", label: "Record", path: "/record/:id", element: <Record /> },
  {
    id: "11",
    label: "Answer Follow up Questions",
    path: "/record/:id/questions",
    element: <AnswerFollowUpQuestions />,
  },
  {
    id: "12",
    label: "Story Sharing",
    path: "/record/:id/share",
    element: <StorySharing />,
  },
  {
    id: "13",
    label: "Storyteller",
    path: "/storyteller/:id",
    element: <StoryTellerProfile />,
  },
  {
    id: "14",
    label: "Profile",
    path: "/profile",
    element: <CurrentUserProfile isProfile={true} />,
  },
  {
    id: "15",
    label: "TellStory",
    path: "/tellstory",
    element: <TellStory />,
  },
  ...COMMON_ROUTES,
  {
    id: "9999",
    label: "",
    path: "*",
    element: <Navigate to="/" />,
  },
];

const NAV_ROUTES = AUTHENTICATED_ROUTES.filter((r) => r.isInNav);

export {
  PUBLIC_ROUTES,
  WAITLIST_ROUTES,
  AUTHENTICATED_ROUTES,
  RESTRICTED_ROUTES,
  NAV_ROUTES,
  BACK_BTN_ROUTE_BLACKLIST,
};
