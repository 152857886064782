import { STORY_COMPLETITION_LEVEL } from "../constants/services";
import { Story } from "../typings/Story";

export const getStoryLength = (story: Story) =>
  (story.shortText || "").split(" ").length || 0;

export const getStoryCompletitionLevel = (story: Story) => {
  const storyLength = getStoryLength(story);
  switch (true) {
    case storyLength > STORY_COMPLETITION_LEVEL.DETAILED.minLength:
      return STORY_COMPLETITION_LEVEL.DETAILED;
      break;
    case storyLength > STORY_COMPLETITION_LEVEL.LONG.minLength:
      return STORY_COMPLETITION_LEVEL.LONG;
      break;
    case storyLength > STORY_COMPLETITION_LEVEL.GOOD.minLength:
      return STORY_COMPLETITION_LEVEL.GOOD;
      break;
    case storyLength > STORY_COMPLETITION_LEVEL.SHORT.minLength:
      return STORY_COMPLETITION_LEVEL.SHORT;
      break;
    case storyLength > STORY_COMPLETITION_LEVEL.OVERVIEW.minLength:
      return STORY_COMPLETITION_LEVEL.OVERVIEW;
      break;

    default:
      break;
  }

  return STORY_COMPLETITION_LEVEL.OVERVIEW;
};
