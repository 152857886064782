import { FC } from "react";
import { useNotifications } from "./useNotifications";
import { MaxWidthContainer } from "../../../components/MaxWidthContainer";
import { Statement } from "../../../components/Statement";
import { OptionedItemsList } from "../../../components/OptionedItemsList";
import { UserAvatar } from "../../../components/UserAvatar";
import {
  NotificationDetail,
  NotificationTypes,
} from "../../../typings/Notifications";
import { UserDetails } from "../../../typings/User";

interface NotificationsProps {
  closeMenu: VoidFunction;
}

const Notifications: FC<NotificationsProps> = ({ closeMenu }) => {
  const { notifications, options } = useNotifications({ closeMenu });
  const getAuthorAvatar = (author: UserDetails) => (
    <UserAvatar
      src={
        author.profile.photo ||
        `https://picsum.photos/seed/listener${author.id}/200`
      }
      alt={author.email}
      extraStyles={`mr-4`}
    />
  );
  const getNotificationFormat = (notification: NotificationDetail) => {
    if (notification.type === NotificationTypes.INVITATION_REQUEST)
      return notification.userToIsListener ? (
        <>
          {getAuthorAvatar(notification.author)}
          <Statement
            text={`${notification.author.firstName} has invited you to listen to their stories.`}
            extraStyles="text-normal text-lg font-light mr-2"
          />
        </>
      ) : (
        <>
          {getAuthorAvatar(notification.author)}
          <Statement
            text={`${notification.author.firstName} has requested to listen to your stories. Click here to accept/decline.`}
            extraStyles="text-normal text-lg font-light mr-2"
          />
        </>
      );
    if (notification.type === NotificationTypes.NEW_STORY)
      return (
        <>
          {getAuthorAvatar(notification.author)}
          <Statement
            text={`${notification.author.firstName} has invited you to listen to a story called: ${notification.story?.name}`}
            extraStyles="text-normal text-lg font-light mr-2"
          />
        </>
      );
    if (notification.type === NotificationTypes.FOLLOW_UP_QUESTION)
      return (
        <>
          {getAuthorAvatar(notification.author)}
          <div>
            <Statement
              text={`${notification.author.firstName} asked you a question.`}
              extraStyles="text-normal text-lg font-light mr-2"
            />
            <p className="text-sm">{notification.name}</p>
          </div>
        </>
      );
  };

  return (
    <div className="flex flex-col h-full">
      <Statement
        text="Notifications"
        extraStyles="pt-4 text-primary text-center text-3xl mb-16"
      />
      <MaxWidthContainer isMenuPageLayout>
        <OptionedItemsList
          items={notifications}
          displayItems={(n) => (
            <div className="flex items-center px-3 py-4">
              {getNotificationFormat(n)}
            </div>
          )}
          options={options}
          noItemsLabel="There are no notifications to show."
        />
      </MaxWidthContainer>
    </div>
  );
};

export { Notifications };
