import { FC, ReactNode } from "react";
import { UserAvatar } from "../UserAvatar";

interface CardProps {
  isRowDisplay?: boolean;
  legend?: string;
  children: ReactNode | Array<ReactNode>;
  action?: VoidFunction;
  actionLabel?: string;
  actionIcon?: ReactNode;
  author?: string;
  topRightImage?: ReactNode;
  legendImage?: string;
}

const Card: FC<CardProps> = ({
  isRowDisplay = false,
  legend,
  children,
  action,
  actionLabel = "Open",
  actionIcon,
  author,
  topRightImage,
  legendImage,
}) => {
  if (isRowDisplay) {
    return (
      <section
        className={`rounded-lg bg-tertiary px-3 py-4 flex justify-between w-full overflow-hidden ${
          action ? "cursor-pointer" : ""
        }`}
        onClick={action}
      >
        {legend && (
          <legend className="text-left text-sm w-2/12 mr-2">{legend}</legend>
        )}
        {children}
      </section>
    );
  }
  return (
    <section
      className={`flex flex-col rounded-lg bg-tertiary p-5 pb-12 relative w-full ${
        action ? "cursor-pointer" : ""
      }`}
      onClick={action}
    >
      {legend && (
        <legend className="flex items-center text-left text-md mb-6 mr-10">
          {legendImage ? (
            <UserAvatar
              extraStyles="mr-2"
              src={legendImage}
              alt={`${author}`}
              small
            />
          ) : null}
          {legend}
        </legend>
      )}
      <div className="flex absolute top-0 right-0 items-center mt-3 mr-3">
        {topRightImage ? topRightImage : null}
      </div>
      <div className="text-body flex-1 h-[calc(100%-30px)] overflow-y-auto">
        {children}
      </div>
      {action && (
        <div
          className="absolute bottom-0 left-0 px-3 py-2 h-10 border-t w-full flex items-center justify-between cursor-pointer"
          onClick={action}
        >
          <span className="text-md">{actionLabel}</span>
          {actionIcon && (
            <span className="w-8 flex justify-around">{actionIcon}</span>
          )}
        </div>
      )}
    </section>
  );
};

export { Card };
