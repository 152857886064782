import { FC, ReactNode } from "react";
import { IconButton, IconButtonTypes } from "../../IconButton";
import classNames from "classnames";

interface MenuPageProps {
  isOpen: boolean;
  pageComponent?: ReactNode;
  close: VoidFunction;
}

const MenuPage: FC<MenuPageProps> = ({ isOpen, pageComponent, close }) => {
  const menuPageStyles = classNames(
    isOpen ? "left-0 md:left-[calc(100%-468px)] md:w-[468px] md:right-auto" : "left-full ",
    "fixed top-0 w-full h-full bg-tertiary transition-all"
  );

  return (
    <div className={menuPageStyles}>
      <div className="absolute top-16 left-5">
        <IconButton
          extraStyles="w-6 h-6"
          isDark
          icon={IconButtonTypes.BACK}
          onClick={close}
        />
      </div>
      {pageComponent}
    </div>
  );
};

export { MenuPage };
