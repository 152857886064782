import { useMainModalActionService } from "../../services/useMainModalActionService";

const useMainModal = () => {
  const { closeMainModalAction, activeMainModalAction, customMainModalProps } =
    useMainModalActionService();
  return {
    closeMainModalAction,
    customMainModalProps,
    activeMainModalAction,
    isOpen: Boolean(activeMainModalAction),
  };
};

export { useMainModal };
