import { FC } from "react";
import { MaxWidthContainer } from "../MaxWidthContainer";
import { useLocation, useNavigate } from "react-router-dom";
import { useHeader } from "./useHeader";
import { IconButton, IconButtonTypes } from "../IconButton";
import { PAGE_STYLES } from "../../typings/state";
import { CollapsableMenu } from "../CollapsableMenu";
import { LogoNavigation } from "../LogoNavigation";
import { TabMenu } from "../TabMenu";
import { PillProps } from "../Pill";
import { useIntercom } from "react-use-intercom";
import { Button } from "../Button";
import { ButtonTypes } from "../../typings/components";

interface HeaderProps {
  isDisabled?: boolean;
}

const Header: FC<HeaderProps> = () => {
  const {
    noHeader,
    isMenuOpen,
    userDetails,
    isStoryTeller,
    pageStyle = PAGE_STYLES.PRIMARY,
    toggleMenu,
    toggleMode,
  } = useHeader();
  const { pathname: activeRoute } = useLocation();
  const navigate = useNavigate();
  const { boot } = useIntercom();
  const tabs: PillProps[] = [
    { label: "Tell story", action: toggleMode, isActive: isStoryTeller },
    { label: "Listen", action: toggleMode, isActive: !isStoryTeller },
  ];
  const isMainLayout = activeRoute === "/";

  // Set intercom Settings
  if (userDetails?.username) {
    boot({
      name: userDetails?.fullName,
      userId: userDetails?.username,
      email: userDetails?.email,
      createdAt: Date.now(),
    });
  } else {
    boot({
      name: "",
      createdAt: Date.now(),
    });
  }

  const headerColorStyle =
    pageStyle === PAGE_STYLES.PRIMARY ? "bg-quaternary" : "bg-white";
  return (
    <header
      className={`overflow-hidden ${headerColorStyle} fixed top-0 w-full z-20 transition-colors min-h-[130px] md:min-h-[65px]`}
    >
      <MaxWidthContainer>
        <LogoNavigation isMenuOpen={isMenuOpen} noHeader={noHeader}>
          {isMainLayout && (
            <TabMenu
              extraStyles="hidden md:flex py-3 mt-1 md:mt-0"
              tabs={tabs}
            />
          )}
          <IconButton
            isDark={pageStyle !== PAGE_STYLES.PRIMARY}
            icon={IconButtonTypes.SEARCH}
            onClick={toggleMenu}
          />
          <img
            onClick={toggleMenu}
            className="cursor-pointer rounded-full w-[50px] h-[50px] ml-4"
            src={
              userDetails?.profile.photo ||
              `https://picsum.photos/seed/${userDetails?.id}/200`
            }
            alt="avatar"
          />
        </LogoNavigation>
        {!noHeader && (
          <>
            {isMainLayout && (
              <Button
                extraStyles="-mt-[70px] underline -ml-8"
                style={ButtonTypes.LINK}
                label="My Stories"
                action={() => {
                  navigate("/stories");
                }}
              />
            )}
            {isMainLayout && (
              <TabMenu extraStyles="md:hidden py-3 md:mt-0" tabs={tabs} />
            )}
            <CollapsableMenu isOpen={isMenuOpen} toggleMenu={toggleMenu} />
          </>
        )}
      </MaxWidthContainer>
    </header>
  );
};

export { Header };
