import { useContext, useEffect, useState } from "react";
import { useAudioRecorder } from "../../../../hooks/useAudioRecorder";
import { MLStateContext } from "../../../../services/stateProvider";
import { useOnboardingService } from "../../../../services/useOnboardingService";
import { usePromptsService } from "../../../../services/usePromptsService";
import { useStoriesService } from "../../../../services/useStoriesService";
import { Prompt } from "../../../../typings/Prompts";
import { Story } from "../../../../typings/Story";
const DEFAULT_INITIAL_PROMPTS: Array<any> = [
  {
    author: null,
    category: {
      author: null,
      created_at: "2023-12-01T12:41:22.050000",
      description: "Wisdom: Values/Beliefs",
      id: 50,
      is_active: true,
      name: "Values/Beliefs",
      resource_uri: "",
      subcategories: [],
      updated_at: "2023-12-01T12:41:22.050000",
    },
    description: "Can you describe what you like to do for fun and why?",
    id: 0,
    is_active: true,
    lang: "en",
    mediafile: null,
    name: "Can you describe what you like to do for fun and why?",
    resource_uri: "",
    status: "active",
    tags: ["wisdom"],
    updated_at: "2023-12-19T12:26:40.148241",
    user_to: null,
  },
  {
    author: null,
    category: {
      author: null,
      created_at: "2023-12-01T12:41:22.050000",
      description: "Wisdom: Values/Beliefs",
      id: 50,
      is_active: true,
      name: "Values/Beliefs",
      resource_uri: "",
      subcategories: [],
      updated_at: "2023-12-01T12:41:22.050000",
    },
    description: "Can you describe where you grew up?",
    id: 0,
    is_active: true,
    lang: "en",
    mediafile: null,
    name: "Can you describe where you grew up?",
    resource_uri: "",
    status: "active",
    tags: ["wisdom"],
    updated_at: "2023-12-19T12:26:40.148241",
    user_to: null,
  },
  {
    author: null,
    category: {
      author: null,
      created_at: "2023-12-01T12:41:21.799000",
      description: "Family: Parents",
      id: 40,
      is_active: true,
      name: "Parents",
      resource_uri: "/api/v1/category/40/",
      subcategories: [],
      updated_at: "2023-12-01T12:41:21.799000",
    },
    description: "What did your parents do for work?",
    id: 0,
    is_active: true,
    lang: "en",
    mediafile: null,
    name: "What did your parents do for work?",
    resource_uri: "",
    status: "active",
    tags: ["wisdom"],
    updated_at: "2023-12-19T12:26:40.148241",
    user_to: null,
  },
  {
    author: null,
    category: {
      author: null,
      created_at: "2023-12-01T12:41:22.050000",
      description: "Wisdom: Values/Beliefs",
      id: 50,
      is_active: true,
      name: "Values/Beliefs",
      resource_uri: "",
      subcategories: [],
      updated_at: "2023-12-01T12:41:22.050000",
    },
    description: "What kind of work did you do in your life?",
    id: 0,
    is_active: true,
    lang: "en",
    mediafile: null,
    name: "what kind of work did you do in your life?",
    resource_uri: "",
    status: "active",
    tags: ["wisdom"],
    updated_at: "2023-12-19T12:26:40.148241",
    user_to: null,
  },
];
const useStep4 = () => {
  const {
    stepNextNavigation,
    stepBackNavigation,
    setDisplayHelp,
    displayHelp,
  } = useOnboardingService();
  const { createNew, getById } = useStoriesService();
  const { getInitial } = usePromptsService();
  const {
    state: { currentStory },
    updateStateItem,
  } = useContext(MLStateContext);
  const [readyToStart, setReadyToStart] = useState<boolean>(false);
  const [isBuilding, setIsBuilding] = useState<boolean>(false);
  const [selectedQuestion, setSelectedQuestion] = useState<Prompt>();
  const [recordAttempts, setRecordAttempts] = useState<number>(0);
  const [transcriptText, setTranscriptText] = useState<string>("");
  const [story, setStory] = useState<Story>();
  const [isLoading, setIsLoading] = useState(false);
  const handleTranscriptUpdate = (t: string) => {
    setTranscriptText((prev) => `${prev} ${t}`);
  };

  const {
    changeStateToRecording,
    startRecording,
    recorderState,
    saveRecording,
    cancelRecording,
  } = useAudioRecorder({
    handleTranscriptUpdate,
  });

  const { isRecording } = recorderState;
  const recordItem = useState<boolean>(false);
  const initialPrompts = DEFAULT_INITIAL_PROMPTS;
  useEffect(() => {
    if (isRecording) {
      if (!story && !isLoading) {
        createNew((newStory) => {
          setStory(newStory);
          updateStateItem({ currentStory: newStory, isNewStory: true });
        });
      }
    }
  }, [isRecording]);

  useEffect(() => {
    if (initialPrompts?.length === 0) {
      getInitial();
    }
  }, [initialPrompts]);

  useEffect(() => {
    if (currentStory && isRecording) {
      startRecording();
    }
  }, [currentStory, isRecording]);

  const toggleRecording = () => {
    if (isRecording) pauseRecording();
    else changeStateToRecording();
  };

  const goBack = () => {
    stepBackNavigation();
  };
  const setCurrentQuestion = (question: Prompt) => {
    setSelectedQuestion(question);
  };

  const buildingQuestion = () => {
    setIsBuilding(true);
    if (story?.id) {
      setTimeout(() => {
        getById(story?.id).then(
          (story) => {
            setIsBuilding(false);
            console.log("story with follow questions", story);
            setStory(story);
            updateStateItem({ currentStory: story, isNewStory: true });
            goToFollowUpQuestions();
          },
          (e) => {
            console.log("ERROR loading FollowUp questions", e);
          }
        );
      }, 13000);
    }
  };

  const goToFollowUpQuestions = () => {
    goForward();
  };

  const pauseRecording = () => {
    setRecordAttempts((prev) => prev + 1);
    if (transcriptText.length > 0 && story) {
      saveRecording();
      setReadyToStart(true);
      console.log(story);
      buildingQuestion();
    } else {
      cancelRecording();
      setReadyToStart(false);
      setRecordAttempts(0);
      setDisplayHelp(true);
    }
  };

  const goForward = () => {
    stepNextNavigation();
  };
  return {
    goBack,
    goForward,
    selectedQuestion,
    initialPrompts: initialPrompts || [],
    setCurrentQuestion,
    isBuilding,
    displayHelp,
    transcriptText,
    isRecording,
    recordItem,
    story,
    recordAttempts,
    readyToStart,
    isLoading,
    toggleRecording,
    setIsLoading,
  };
};

export { useStep4 };
