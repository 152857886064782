import { FC, FormEvent, useState } from "react";
import { UserAvatar, UserAvatarProps } from "../UserAvatar";
import classNames from "classnames";
// import { useCommentOrQuestionInput } from "./useCommentOrQuestionInput";

export interface CommentOrQuestionInputProps {
  author: UserAvatarProps;
  callback?: (value: string) => void;
  extraStyles?: string;
}

const CommentOrQuestionInput: FC<CommentOrQuestionInputProps> = ({
  author,
  callback,
  extraStyles = "",
}) => {
  const [questionValue, setQuestionValue] = useState("");
  const submit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (callback) callback(questionValue);
    setQuestionValue("");
  };
  const styles = classNames("block w-full mb-4", extraStyles);

  return (
    <div className={styles}>
      <form
        onSubmit={submit}
        className="flex items-center py-0 px-0.5 bg-stone-100 rounded-full"
      >
        <UserAvatar {...author} small />
        <input
          type="text"
          value={questionValue}
          onChange={(e) => setQuestionValue(e.target.value)}
          placeholder="Question or comment"
          className="bg-transparent block text-sm h-11 w-full py-0 px-3 font-light placeholder:text-stone-400 outline-none"
        />
        {questionValue && (
          <button className="px-2 font-normal" type="submit">
            Send
          </button>
        )}
      </form>
    </div>
  );
};

export { CommentOrQuestionInput };
