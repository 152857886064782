import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MLStateContext } from "../../services/stateProvider";
import { USER_MODES } from "../../typings/state";
import { IconButtonProps, IconButtonTypes } from "../IconButton";
import { useQuickActionService } from "../../services/useQuickActionService";
import { QUICK_ACTIONS } from "../../constants/services";

const useActionCenter = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isListener, setIsListener] = useState(true);
  const { state } = useContext(MLStateContext);
  const {
    activeMode,
    noActionCenter,
    API_KEY,
    currentStory,
    userDetails,
    isEditingStory,
  } = state;
  const { openQuickAction } = useQuickActionService();
  const isAuthenticated = Boolean(API_KEY && API_KEY !== "");
  const basicStorytellerActions: IconButtonProps[] = [
    {
      icon: IconButtonTypes.RECORD,
      onClick: () => {
        setIsOpen(false);
        navigate("/record");
      },
      label: "Record new",
    },
    {
      icon: IconButtonTypes.USER,
      onClick: () => {
        setIsOpen(false);
        openQuickAction(QUICK_ACTIONS.ADD_NEW_LISTENER);
      },
      label: "Add listener",
    },
  ];
  const listenerActions: IconButtonProps[] = [
    {
      icon: IconButtonTypes.RECORD,
      onClick: () => {
        setIsOpen(false);
        openQuickAction(QUICK_ACTIONS.ASK_QUESTION);
      },
      label: "Ask a question",
    },
    {
      icon: IconButtonTypes.USER,
      onClick: () => {
        setIsOpen(false);
        openQuickAction(QUICK_ACTIONS.ADD_NEW_STORYTELLER);
      },
      label: "Invite storyteller",
    },
  ];

  const storyRelatedActions =
    !isListener && !isEditingStory
      ? [
          {
            icon: IconButtonTypes.RECORD,
            onClick: () => {
              setIsOpen(false);
              navigate(`/record/${currentStory?.id}`);
            },
            label: "Add more",
          },
          {
            icon: IconButtonTypes.EDIT,
            onClick: () => {
              setIsOpen(false);
              navigate(`/stories/${currentStory?.id}/edit`);
            },
            label: "Edit",
          },
        ]
      : [];

  const storytellerActions = currentStory?.id
    ? [...basicStorytellerActions, ...storyRelatedActions]
    : basicStorytellerActions;

  const actions: IconButtonProps[] =
    activeMode === USER_MODES.STORYTELLER
      ? storytellerActions
      : listenerActions;

  useEffect(() => {
    if (currentStory && userDetails && currentStory.author) {
      setIsListener(userDetails.id !== currentStory.author.id);
    }
  }, [currentStory, userDetails]);

  return {
    isOpen,
    actions,
    noActionCenter: !isAuthenticated || noActionCenter,
    activeMode: activeMode?.toLowerCase(),
    recordButtonAction: () => setIsOpen(!isOpen),
    toggleMenu: () => setIsOpen(!isOpen),
  };
};

export { useActionCenter };
