import classNames from "classnames";
import { FC } from "react";

export enum StatementSizes {
  XS = "text-xs",
  SM = "text-sm",
  MD = "text-md",
  LG = "text-lg",
  XL = "text-xl",
  XL2 = "text-2xl",
  XL3 = "text-3xl",
  XL4 = "text-4xl",
}
interface StatementProps {
  text?: string;
  size?: StatementSizes;
  extraStyles?: string;
}

const Statement: FC<StatementProps> = ({
  text,
  size = StatementSizes.XL3,
  extraStyles = "mb-8",
}) => {
  const styles = classNames("text-left font-conversation", size, extraStyles);
  return <h2 className={styles}>{text}</h2>;
};

export { Statement };
