import { useState } from "react";
import { useFormik } from "formik";
import { passwordChange } from "../../../utils/form-validation/yup-schemas";
import { useInfoToastService } from "../../../services/useInfoToastService";
import { useMainModalActionService } from "../../../services/useMainModalActionService";
import { useAuthService } from "../../../services/useAuthService";
import { useNavigate } from "react-router-dom";

const useChangePassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { updatePassword } = useAuthService();
  const { closeMainModalAction } = useMainModalActionService();
  const { createErrorMsg, createSuccessMsg } = useInfoToastService();
  const navigate = useNavigate();

  const onSubmit = () => {
    setIsLoading(true);
    updatePassword(values)
      .then(() => {
        createSuccessMsg({
          title: "Password changed.",
          message: `Your password has been changed successfully.`,
        });
        closeMainModalAction();
      })
      .catch((e) =>
        createErrorMsg({
          title: "Password updates was not changed.",
          message: e.error,
        })
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  const formikObject = useFormik({
    initialValues: { current_password: "", password: "", password_confirm: "" },
    validationSchema: passwordChange,
    onSubmit,
  });
  const { values } = formikObject;

  const goToForgotPassword = () => {
    closeMainModalAction();
    navigate("/forgot-password");
  };

  return { isLoading, formikObject, goToForgotPassword };
};

export { useChangePassword };
