import { FC, ReactNode } from "react";
import { useCustomMainModal } from "./useCustomMainModal";
import { Spinner } from "../../Spinner";

export interface CustomMainModalProps {
  isLoading?: boolean;
  content: ReactNode;
}

const CustomMainModal: FC<CustomMainModalProps> = ({
  content,
  isLoading = false,
}) => {
  const {} = useCustomMainModal();

  return (
    <>
      <Spinner isLoading={isLoading} withBackdrop />
      {content}
    </>
  );
};

export { CustomMainModal };
