import { FC } from "react";
import { useListeners } from "./useListeners";
import { MaxWidthContainer } from "../../../components/MaxWidthContainer";
import { Statement } from "../../../components/Statement";
import {
  ListItem,
  OptionedItemsList,
} from "../../../components/OptionedItemsList";
import { UserAvatar } from "../../../components/UserAvatar";
import { IconButton, IconButtonTypes } from "../../../components/IconButton";
import { Spinner } from "../../../components/Spinner";
import { UserDetails } from "../../../typings/User";

interface ListenersProps {}

const Listeners: FC<ListenersProps> = () => {
  const { listeners, options, openAddListenerQA } = useListeners();

  return (
    <div className="flex flex-col h-full">
      <Spinner isLoading={false} withBackdrop />
      <Statement
        text="Listeners"
        extraStyles="pt-4 text-primary text-center text-3xl mb-4"
      />
      <IconButton
        extraStyles="mb-10"
        icon={IconButtonTypes.USER}
        onClick={openAddListenerQA}
        label="Add listener"
      />
      <MaxWidthContainer isMenuPageLayout>
        <OptionedItemsList<UserDetails & ListItem>
          items={listeners}
          displayItems={(listener) => (
            <div className="flex items-center px-3 py-4" onClick={() => options[0].action(listener)}>
              <UserAvatar
                src={
                  listener.profile.photo ||
                  `https://picsum.photos/seed/listener${listener.id}/200`
                }
                alt={listener.email}
                extraStyles={`mr-4 border-2 ${
                  !listener.isActive ? "border-secondary" : "border-transparent"
                }`}
              />
              <Statement
                text={listener.firstName}
                extraStyles="text-normal text-xl font-light"
              />
            </div>
          )}
          options={options}
          noItemsLabel="There are no listeners to show."
        />
      </MaxWidthContainer>
    </div>
  );
};

export { Listeners };
