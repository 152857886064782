import { FC } from "react";
import { Statement, StatementSizes } from "../../components/Statement";
import { MaxWidthContainer } from "../../components/MaxWidthContainer";
import { useMitchellMartin } from "./useMitchellMartin";

interface MitchellMartinProps {}

const MitchellMartin: FC<MitchellMartinProps> = () => {
  const {} = useMitchellMartin();
  return (
    <>
      <MaxWidthContainer>
        <Statement size={StatementSizes.XL3} text="Mitchell Martin" />
      </MaxWidthContainer>
    </>
  );
};

export { MitchellMartin };
