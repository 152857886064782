import { FC } from "react";
import { useLogin } from "./useLogin";
import { Statement } from "../../components/Statement";
import { MaxWidthContainer } from "../../components/MaxWidthContainer";
import { Button } from "../../components/Button";
import { ButtonTypes } from "../../typings/components";
import { FormControl } from "../../components/Forms/FormControl";
import { Spinner } from "../../components/Spinner";

interface LoginProps {}

const Login: FC<LoginProps> = () => {
  const { formikObject, isLoading, errorMessage, navigate } = useLogin();
  const { submitForm, touched, errors, values, handleChange, handleBlur } =
    formikObject;

  return (
    <div>
      {isLoading && <Spinner isLoading withBackdrop />}
      <MaxWidthContainer isPageLayout extraStyles="pb-12 md:pb-4">
        <div className="flex flex-col h-full content-between">
          <div className="mr-[10%] mb-14">
            <Statement
              text="Enter your email"
              extraStyles="text-primary text-4xl"
            />
          </div>
          {errorMessage && (
            <p className="text-red-900 ml-4 -mt-9 mb-3">{errorMessage}</p>
          )}
          <form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              submitForm();
            }}
          >
            <FormControl
              type="email"
              name="username"
              placeholder="Email"
              touched={touched.username}
              error={errors.username}
              value={values.username}
              {...{ handleChange, handleBlur }}
            />
            <FormControl
              type="password"
              name="password"
              placeholder="Password"
              touched={touched.password}
              error={errors.password}
              value={values.password}
              {...{ handleChange, handleBlur }}
            />
            <Button
              label="Continue"
              type="submit"
              style={ButtonTypes.PRIMARY}
              isFullWidth
              action={() => null}
            />
            <Button
              isFullWidth
              extraStyles="underline"
              style={ButtonTypes.LINK}
              label="Forgot password?"
              action={() => navigate("/forgot-password")}
            />
          </form>
        </div>
      </MaxWidthContainer>
    </div>
  );
};

export { Login };
