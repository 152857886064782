/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { MLStateContext } from "../../services/stateProvider";
import { useUserService } from "../../services/useUserService";
import { UserDetails } from "../../typings/User";
import { useInvitationsService } from "../../services/useInvitationsService";
import { useInfoToastService } from "../../services/useInfoToastService";
import { useStoriesService } from "../../services/useStoriesService";
import { StoryDetail } from "../../typings/Story";

const useStorySharing = () => {
  const { id = "" } = useParams();
  const [additionalListeners, setAdditionalListeners] = useState<string[]>([]);
  const [currentStory, setCurrentStory] = useState<StoryDetail>();
  const [selectedTab, setSelectedTab] = useState("ALL_LISTENERS");
  const [isLoading, setIsLoading] = useState(false);
  const {
    state: { userDetails, followers = [] },
  } = useContext(MLStateContext);
  const { refreshUserDetails } = useUserService();
  const { inviteListenersToStory } = useInvitationsService();
  const [filteredListeners, setFilteredListeners] = useState<
    Array<UserDetails>
  >([]);
  const navigate = useNavigate();
  const { createSuccessMsg, createErrorMsg } = useInfoToastService();
  const { getById } = useStoriesService();

  useEffect(() => {
    setIsLoading(true);
    getById(Number(id)).then((s) => setCurrentStory(s));
    refreshUserDetails()
      .then(() => filterListeners())
      .finally(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    filterListeners();
    if (selectedTab === "PRIVATE") {
      setAdditionalListeners([]);
    }
  }, [selectedTab]);

  const filterListeners = () => {
    if (selectedTab === "PRIVATE") {
      setFilteredListeners([]);
      // } else if (selectedTab === "FAVORITES") {
      //   setFilteredListeners(followers);
    } else if (selectedTab === "ALL_LISTENERS") {
      setFilteredListeners(followers);
    }
  };

  const tabs = [
    {
      label: "Just me",
      action: () => setSelectedTab("PRIVATE"),
      isActive: selectedTab === "PRIVATE",
    },
    // {
    //   label: "Favorites",
    //   action: () => setSelectedTab("FAVORITES"),
    //   isActive: selectedTab === "FAVORITES",
    // },
    {
      label: "My listeners",
      action: () => setSelectedTab("ALL_LISTENERS"),
      isActive: selectedTab === "ALL_LISTENERS",
    },
  ];

  const shareStoryWithListeners = () => {
    setIsLoading(true);
    if (currentStory && filteredListeners.length > 0) {
      inviteListenersToStory(currentStory.resourceUri, [
        ...filteredListeners.map((f) => f.email),
        ...additionalListeners,
      ])
        .then(() => {
          const firstListener = filteredListeners[0];
          const message =
            filteredListeners.length > 1
              ? {
                  title: "Invitations were sent!",
                  message:
                    "All the selected listeners were invited to listen to this story. They will receive a notification to see it.",
                }
              : {
                  title: "Invitation was sent!",
                  message: `${firstListener.firstName} was invited to listen to this story.`,
                };
          createSuccessMsg(message);
        })
        .finally(() => {
          setIsLoading(false);
          navigate("/stories/" + id);
        });
    } else {
      createSuccessMsg({
        title: "Private story!",
        message: "Only you will be able to see this story.",
      });
      navigate("/stories/" + id);
    }
  };

  const addAdditionalListener = (email: string) => {
    const isAlreadyAdded =
      additionalListeners.includes(email) ||
      Boolean(filteredListeners.find((l) => l.email === email));
    if (!isAlreadyAdded) {
      setAdditionalListeners([...additionalListeners, email]);
    } else {
      createErrorMsg({
        title: "Already in the list",
        message: "This email or phone is already in the list.",
      });
    }
  };

  const removeAdditionalListener = (email: string) => {
    setAdditionalListeners([...additionalListeners.filter((l) => email !== l)]);
  };

  return {
    tabs,
    isLoading,
    selectedTab,
    userDetails,
    addAdditionalListener,
    removeAdditionalListener,
    additionalListeners,
    filteredListeners,
    shareStoryWithListeners,
    navigate,
  };
};

export { useStorySharing };
