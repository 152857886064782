import { FC } from "react";
import { useActionCenter } from "./useActionCenter";
import { IconButton } from "../IconButton";
import { IconButtonTypes } from "../IconButton";
import classNames from "classnames";

interface ActionCenterProps {}

const ActionCenter: FC<ActionCenterProps> = () => {
  const {
    isOpen,
    actions,
    toggleMenu,
    recordButtonAction,
    activeMode,
    noActionCenter,
  } = useActionCenter();
  if (noActionCenter) return null;
  const menuStyles = classNames(
    "bg-primary fixed rounded-t-2xl w-full pt-10 bottom-0 transition-all",
    isOpen ? "translate-y-[0]" : "-translate-y-[-100%]"
  );
  const collapseMenuBackdrop = classNames(
    isOpen ? "h-full" : "h-0",
    "fixed left-0 bottom-0 w-full"
  );

  return (
    <div className="">
      <div className={collapseMenuBackdrop} onClick={toggleMenu}></div>
      <div className="fixed bottom-20 w-1/3 right-0 drop-shadow-xl md:mb-4">
        <IconButton
          extraStyles="text-tertiary"
          icon={IconButtonTypes.PLUS}
          onClick={recordButtonAction}
        />
      </div>
      <div className={menuStyles}>
        <h3 className="text-tertiary text-2xl font-thin text-center font-conversation capitalize">
          {activeMode} Actions
        </h3>
        <div className="flex flex-wrap justify-evenly pt-6 text-tertiary tracking-tighter text-sm md:mb-4">
          {actions &&
            actions.map((a, i) => (
              <div key={`action-item-${i}`} className="w-1/3 mb-6">
                <IconButton {...a} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export { ActionCenter };
