import { ChangeEvent, useContext, useEffect, useState } from "react";
import { MLStateContext } from "../../../services/stateProvider";
import { useFormik } from "formik";
import { profileSchema } from "../../../utils/form-validation/yup-schemas";
import { SelectFormValue } from "../../../typings/form-schemas";
import { useAPI } from "../../../utils/api-connection";
import { UserInfoEditRequest } from "../../../typings/User";
import { useUserService} from "../../../services/useUserService"

interface UseMyProfileProps {
  closeMenu: VoidFunction;
}

const GENDER_LIST: Array<SelectFormValue> = [
  { id: 0,
    value: "male",
    name: "Male"
  },
  { id: 1,
    value: "female",
    name: "Female"
  },
  { id: 2,
    value: "ns",
    name: "Custom"
  },
];

const useMyProfile = ({ closeMenu }: UseMyProfileProps) => {
  const {
    state: { userDetails },
  } = useContext(MLStateContext);
  const { setUserDetails, setUserProfilePhoto } = useAPI();
  const {refreshUserDetails} = useUserService();
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedFile, setSelectedFile] = useState<any>();
  const [profileImgSrc, setProfileImgSrc] = useState<string>('');

  const loadUserDetails = () => {
    refreshUserDetails()
  };

  const onSubmit = () => {
    setIsEditing(false);
    if (!formikObject.isValid) return;
    setIsLoading(true);
    saveProfile();
  };

  const saveProfile = () => {
      setUserDetails(new UserInfoEditRequest(formikObject.values)).then(
        (info) =>{
          setIsLoading(false);
          loadUserDetails();
        }
      )
  }

  const saveProfilePhoto = () => {
    setUserProfilePhoto(`${userDetails?.id}`, selectedFile[0]).then(
      () =>{
        loadUserDetails();
      }
    )
  }

  const handleProfileImgChange = (e:ChangeEvent<HTMLInputElement>) => {
    if(e && e.target && e.target.files && e.target.files.length) {
      setSelectedFile(e.target.files);
      setIsLoading(true);
      setProfileImgSrc(URL.createObjectURL(e.target.files[0]));
      setUserProfilePhoto(`${userDetails?.id}`, e.target.files[0]).then(
        (e) =>{
          loadUserDetails();
          setIsLoading(false);
        }
      )
    } else {
      setSelectedFile(null);
      setProfileImgSrc('');
    }
  };

  const getDefaultProfile = () => {
    return {
      id: 0,
      firstName: '',
      lastName: '',
      fullName: '',
      email: '',
      isActive: false,
      isStaff: false,
      isSuperuser: false,
      profile: {
        emailConfirmed: false,
        langs: [],
        dateOfBirth: '',
        photo: '',
        photoSm: '',
        sex: '',
        waitlisted: false,
      },
      username: '',
    }
  }
  const formikObject = useFormik({
    initialValues: userDetails || getDefaultProfile(),
    validationSchema: profileSchema,
    onSubmit,
  });
  useEffect(() => {
    formikObject.resetForm({ values: userDetails || getDefaultProfile()  });
  }, [userDetails]);
  return {isLoading, userDetails, saveProfilePhoto, setIsEditing, handleProfileImgChange,profileImgSrc,  isEditing,  selectedFile, formikObject, GENDER_LIST};
};

export { useMyProfile }; 
export type { UseMyProfileProps };

