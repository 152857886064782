import { FC } from "react";
import { RecordingButton } from "../../../../components/RecordingButton";
import { Statement, StatementSizes } from "../../../../components/Statement";
import { StepProps } from "../../useOnboarding";
import { useStep4 } from "./useStep4";
import { Carousel, IconButton, Spinner } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronCircleLeft, faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";

const Step4: FC<StepProps>  = () => {
  const {isRecording, toggleRecording, setCurrentQuestion,
    initialPrompts, selectedQuestion, readyToStart, story, isBuilding, displayHelp} = useStep4();

  return (
    <>
        {!displayHelp && <div className="text-center">
          {!isRecording && !readyToStart && <>
            <Statement
              text="Just speak - we do the rest"
              size={StatementSizes.XL3}
              extraStyles="text-center"
            />
            <div className="relative mt-10">
              <Statement
                text="Now it’s time to capture your first story. Let’s start with an easy one."
                size={StatementSizes.XL2}
                extraStyles="text-center pt-6"
              />
              <h2 className="text-center pt-6 font-conversation text-2xl align-text-bottom">
              Choose a prompt using the arrow buttons <span className="w-[50px] h-[24px]">
                <img className="inline" width={24} height={24} src="/assets/images/left-check-circle.svg" alt="Left Carousel Action" />
                <img className="ml-1 inline" width={24} height={24} src="/assets/images/rigth-check-circle.svg" alt="Right Carousel Action" />
                </span><br/>
              and click “Record” when you’re ready.
              </h2>
            </div>
            <div className="max-w-[800px] mx-auto">
              <Carousel
                className="h-min-[50px] py-3"
                navigation={({ setActiveIndex, activeIndex, length }) => (
                  <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                    {new Array(length).fill("").map((_, i) => (
                      <span
                        key={i}
                        className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                          activeIndex === i ? "w-3 bg-white" : "w-1 bg-white/50"
                        }`}
                        onClick={() => {
                          setCurrentQuestion(initialPrompts[i]);
                          setActiveIndex(i);
                        }}
                      >
                      </span>
                    ))}
                  </div>
                )}
                prevArrow={({ handlePrev, activeIndex, firstIndex, loop }) => (
                  <IconButton
                    disabled={!loop && firstIndex}
                    variant="text"
                    color="white"
                    onClick={() => {
                      handlePrev();
                      if (!firstIndex) setCurrentQuestion(initialPrompts[activeIndex - 1]);
                    }}
                    className="!absolute top-1/2 left-0 -translate-y-2/4"
                  >
                    <FontAwesomeIcon size="3x" icon={faChevronCircleLeft} />
                  </IconButton>
                )}
                nextArrow={({ handleNext, activeIndex, lastIndex ,loop}) => (
                  <IconButton
                    disabled={!loop && lastIndex}
                    variant="text"
                    color="white"
                    onClick={() => {
                      handleNext();
                      if (!lastIndex) setCurrentQuestion(initialPrompts[activeIndex + 1]);
                    }}
                    className="!absolute top-1/2 !right-0 -translate-y-2/4 "
                  > 
                    <FontAwesomeIcon size="3x" icon={faChevronCircleRight} />
                  </IconButton>
                )}
              >
                {initialPrompts.map((q, i) => (
                  <div
                    key={`peding-followup-questions-${q.id}`}
                    className={`mt-6 mb-6 px-10`}
                  >
                  <Statement
                    text={q.description}
                    size={StatementSizes.XL2}
                    extraStyles="text-center"
                  />
                  </div>
                ))}
              </Carousel>
            </div>
          </>}
          {isRecording && !readyToStart && <>
            <Statement
              text={selectedQuestion?.description}
              size={StatementSizes.XL2}
              extraStyles="text-center"
            />
            <Statement
              text="We're recording!"
              size={StatementSizes.XL}
              extraStyles="text-center mt-6"
            />
            <Statement
              text="Just speak normally, as you would to a friend."
              size={StatementSizes.XL}
              extraStyles="text-center"
            />
            <Statement
              text="Press “Stop” when you're finished."
              size={StatementSizes.XL}
              extraStyles="text-center"
            />
          </>}
          {readyToStart && story && <>
            <Statement
              text="You recorded your first story!"
              size={StatementSizes.XL2}
              extraStyles="text-center"
            />
            <Statement
              text="Great job!"
              size={StatementSizes.XL2}
              extraStyles="text-center"
            />
            <Statement
              text="Now, we’re building some follow-up questions for you."
              size={StatementSizes.MD}
              extraStyles="text-center mt-6"
            />
            {
              isBuilding &&
              <div className="flex items-center justify-center mt-6">
              <Spinner className="h-16 w-16" color="purple" />
              </div>
            }
          </>}
          {
            !readyToStart && <div className=" max-w-[400px] mx-auto mt-[100px]">
            <div className="flex items-center justify-center">
              <RecordingButton
                isActive={isRecording}
                action={toggleRecording}
                inActiveLabel="Record"
              />
            </div>
          </div>
          }
        </div>}
    </>

  );
};

export { Step4 };
