import { QUICK_ACTIONS } from "../constants/services";
import { MLStateContext } from "./stateProvider";
import { useContext } from "react";

const useQuickActionService = () => {
  const {
    state: { activeQuickAction },
    updateStateItem,
  } = useContext(MLStateContext);
  const openQuickAction = (activeQuickAction: QUICK_ACTIONS) => {
    updateStateItem({ activeQuickAction });
  };

  const closeQuickAction = () => {
    updateStateItem({ activeQuickAction: null });
  };

  return { openQuickAction, closeQuickAction, activeQuickAction };
};

export { useQuickActionService };
