import { FC } from "react";
import { useStorytellers } from "./useStorytellers";
import { MaxWidthContainer } from "../../../components/MaxWidthContainer";
import { Statement } from "../../../components/Statement";
import {
  ListItem,
  OptionedItemsList,
} from "../../../components/OptionedItemsList";
import { UserAvatar } from "../../../components/UserAvatar";
import { UserDetails } from "../../../typings/User";
import { IconButton, IconButtonTypes } from "../../../components/IconButton";

interface StorytellersProps {
  closeMenu: VoidFunction;
}

const Storytellers: FC<StorytellersProps> = ({ closeMenu }) => {
  const { storytellers, options, openAddStoryteller } = useStorytellers({
    closeMenu,
  });

  return (
    <div className="flex flex-col h-full">
      <Statement
        text="Storytellers"
        extraStyles="pt-4 text-primary text-center text-3xl mb-14"
      />
      <IconButton
        extraStyles="mb-10"
        icon={IconButtonTypes.USER}
        onClick={openAddStoryteller}
        label="Add storyteller"
      />
      <MaxWidthContainer isMenuPageLayout>
        <OptionedItemsList<UserDetails & ListItem>
          items={storytellers}
          displayItems={(storyteller) => (
            <div
              className="flex items-center px-3 py-4 cursor-pointer"
              onClick={() => options[0].action(storyteller)}
            >
              <UserAvatar
                src={
                  storyteller.profile.photo ||
                  `https://picsum.photos/seed/${storyteller.id}/200`
                }
                alt={storyteller.fullName}
                extraStyles="mr-4"
              />
              <Statement
                text={storyteller.firstName}
                extraStyles="text-normal text-xl font-light"
              />
            </div>
          )}
          options={options}
          noItemsLabel="There are no storytellers to show."
        />
      </MaxWidthContainer>
    </div>
  );
};

export { Storytellers };
