import { AiPrompt } from "../typings/Prompts";

export const AI_PROMPTS: AiPrompt[] = [
  { id: 0, text: `Who are/were you closest to in your immediate family?` },
  { id: 1, text: `How was family conflict resolved?` },
  { id: 2, text: `How is/was love expressed in your family?` },
  { id: 3, text: `Who's the funniest member in your family?` },
  { id: 4, text: `Who's the most competitive member in your family?` },
  { id: 5, text: `Do/did you have any family pets?  Let's hear about them.` },
  { id: 6, text: `What was your favorite family activity to do?` },
  { id: 7, text: `What is your wish for your family?` },
  { id: 8, text: `What are your top three family memories?` },
  { id: 9, text: `How would you describe your family?` },
  { id: 10, text: `What do you think makes your family unique?` },
  { id: 11, text: `What did you love and hate about being a parent?` },
  { id: 12, text: `What was one of your proudest moments as a parent?` },
  { id: 13, text: `What is/was your goal as a parent?` },
  { id: 14, text: `What does the word "family" mean to you?` },
  { id: 15, text: `How would your child/children describe you as a parent?` },
  { id: 16, text: `How would you describe yourself as a parent?` },
  {
    id: 17,
    text: `What was a difficult situation that your family overcame together?`,
  },
  {
    id: 18,
    text: `Is there anything about your family that you'd like following generations to know?`,
  },
  {
    id: 19,
    text: `To which continents and/or countries does your family trace its lineage?`,
  },
  { id: 20, text: `What are your grandparents' names?` },
  { id: 21, text: `What did your paternal grandparents do for work?` },
  {
    id: 22,
    text: `What was your relationship like with your paternal grandparents?`,
  },
  {
    id: 23,
    text: `Did you know your parent(s) growing up? Let's hear about them.`,
  },
  { id: 24, text: `What were your parents like when you were growing up?` },
  { id: 25, text: `What are your parents' names?` },
  { id: 26, text: `What did your parents do for work?` },
  { id: 27, text: `What is a piece of advice from your parents?` },
  {
    id: 28,
    text: `When you think about your father/mother, what do you remember most?`,
  },
  {
    id: 29,
    text: `How did your relationship with your parents change over time?`,
  },
  {
    id: 30,
    text: `Tell me about your parents marriage?  Are/were they happy?`,
  },
  { id: 31, text: `Can you tell me a little about your siblings?` },
  { id: 32, text: `Do you have siblings?  If so, what are their names?` },
  { id: 33, text: `Are your sibling(s) older or younger?` },
  { id: 34, text: `What was your wedding(s) like?` },
  { id: 35, text: `How did you meet your spouse?` },
  {
    id: 36,
    text: `What is one of your favorite memories or stories your spouse/partner?`,
  },
  { id: 37, text: `Have you ever been married? To whom and for how long?` },
  { id: 38, text: `Tell me about your proposal/acceptance to get married.` },
  { id: 39, text: `Describe your wedding if you had one.` },
  { id: 40, text: `Describe your honeymoon if you had one.` },
  {
    id: 41,
    text: `Have you ever been divorced? If so, what was your perspective on it?`,
  },
  { id: 42, text: `What were your nicknames growing up?` },
  { id: 43, text: `Where were you born?` },
  { id: 44, text: `What have your parents told you about your birth?` },
  { id: 45, text: `Which birthday was your favorite, and why?` },
  { id: 46, text: `Did you have a favorite family trip?` },
  {
    id: 47,
    text: `Is there anything you wish you could have done differently?`,
  },
  { id: 48, text: `What was the best date you've ever been on?` },
  { id: 49, text: `Do you have any children? If so, what are their names?` },
  { id: 50, text: `How many times have you been in love and with whom?` },
  { id: 51, text: `How did you overcome challenges as an adult?` },
  { id: 52, text: `What was a difficult moment you overcame?` },
  {
    id: 53,
    text: `Were there any moments that changed your life significantly?`,
  },
  { id: 54, text: `When did you know you liked your spouse?` },
  { id: 55, text: `Do you have any regrets?` },
  { id: 56, text: `What feels like your biggest accomplishment?` },
  { id: 57, text: `What book do you recommend everyone reads, and why?` },
  { id: 58, text: `What's one secret you never told anyone?` },
  { id: 59, text: `Who were your closest friends as an adult?` },
  { id: 60, text: `How did you handle stress as an adult?` },
  {
    id: 61,
    text: `What's one of the most special experiences you went through as an adult?`,
  },
  { id: 62, text: `What's your favorite family activity to do?` },
  { id: 63, text: `What did your maternal grandparents do for work?` },
  {
    id: 64,
    text: `What was your relationship like with your maternal grandparents?`,
  },
  {
    id: 65,
    text: `What's one thing you're really proud of that you've never told anyone about?`,
  },
  { id: 66, text: `What are you most grateful for?` },
  { id: 67, text: `How do you want to be remembered?` },
  { id: 68, text: `What do you want to be known for?` },
  {
    id: 69,
    text: `What could your family do to honor you after you've passed away?`,
  },
  { id: 70, text: `What do you think is your best trait?` },
  { id: 71, text: `What do you think is your worst trait?` },
  { id: 72, text: `How have you grown as a person?` },
  {
    id: 73,
    text: `Was there a moment in your life that has stuck with you throughout the years?`,
  },
  { id: 74, text: `What do you think you are known for?` },
  { id: 75, text: `Who was the first person you fell in love with?` },
  { id: 76, text: `How many times have you been in love?` },
  { id: 77, text: `What have your most significant relationships taught you?` },
  {
    id: 78,
    text: `Is there anything you wish you had done differently in your love life?`,
  },
  { id: 79, text: `What were some of the happiest times of your life?` },
  { id: 80, text: `What were some of the most difficult times of your life?` },
  {
    id: 81,
    text: `What helps you overcome challenges or tough times in life?`,
  },
  {
    id: 82,
    text: `What is one of the hardest choices you've ever had to make?`,
  },
  { id: 83, text: `Have you ever broken the law? What happened?` },
  {
    id: 84,
    text: `Name a book that changed or impacted you. What about it was so impactful?`,
  },
  {
    id: 85,
    text: `Name a movie that made you cry. What about it made you cry?`,
  },
  { id: 86, text: `What is a song that always makes you want to dance?` },
  {
    id: 87,
    text: `If you were stranded on a desert island, what music would you like to have with you?`,
  },
  { id: 88, text: `What keeps you awake at night?` },
  { id: 89, text: `What is something you're proud of yourself for?` },
  {
    id: 90,
    text: `What have been the best and worst parts about getting older?`,
  },
  {
    id: 91,
    text: `If you could go back and live one year of your life over without changing anything, which one would you pick?`,
  },
  { id: 92, text: `What makes you feel at peace?` },
  { id: 93, text: `How would you describe your spiritual beliefs?` },
  { id: 94, text: `When in your life have you felt most alive?` },
  { id: 95, text: `When in your life have you felt most yourself?` },
  { id: 96, text: `What is your definition of “happiness”?` },
  { id: 97, text: `What was your worst injury? What happened?` },
  { id: 98, text: `What celebrity would you most want to meet? Why?` },
  {
    id: 99,
    text: `What do you consider to be the most significant world event that has occurred during your life?`,
  },
  {
    id: 100,
    text: `What do you consider to be the most significant world event that has occurred in all of human history?`,
  },
  {
    id: 101,
    text: `Other than the present, which historical era would you like to have lived in?`,
  },
  {
    id: 102,
    text: `If you could meet any historical figure of the past, who would it be and why?`,
  },
  { id: 103, text: `What is the funniest thing that’s ever happened to you?` },
  { id: 104, text: `What was your most rebellious moment?` },
  { id: 105, text: `What do you like to do in your spare time?` },
  { id: 106, text: `What is your happiest memory?` },
  { id: 107, text: `What is your saddest memory?` },
  {
    id: 108,
    text: `When asked, what’s the one question you always answer with a lie?`,
  },
  { id: 109, text: `Who is your biggest fan?` },
  { id: 110, text: `What was your most embarrassing moment?` },
  {
    id: 111,
    text: `If you could possess one super-human power, what would it be?`,
  },
  {
    id: 112,
    text: `What are you really bad at that you would love to be great at?`,
  },
  { id: 113, text: `What do you think happens after death?` },
  { id: 114, text: `If there is a heaven, what do you imagine it to be like?` },
  {
    id: 115,
    text: `If you were re-born as an animal, what would you want to be?`,
  },
  { id: 116, text: `Do you have any phobias?` },
  { id: 117, text: `What's the best joke you've heard?` },
  { id: 118, text: `Have you had your heart broken?` },
  { id: 119, text: `Have you broken any hearts?` },
  { id: 120, text: `Do you believe in love at first sight?` },
  { id: 121, text: `Describe your worst break-up.` },
  { id: 122, text: `Who was your biggest crush?` },
  {
    id: 123,
    text: `What was the most special way you’ve shown someone that you loved them?`,
  },
  { id: 124, text: `What celebrity have you been told you resemble?` },
  { id: 125, text: `Who were your best friends growing up?` },
  { id: 126, text: `Did you collect anything?` },
  { id: 127, text: `What hobbies did you enjoy in your childhood?` },
  { id: 128, text: `What was your favorite childhood book?` },
  { id: 129, text: `What subject was your favorite in school?` },
  { id: 130, text: `What was your favorite toy?` },
  { id: 131, text: `What was your favorite game to play?` },
  { id: 132, text: `What were your dreams or hopes as a child?` },
  {
    id: 133,
    text: `Who in your family did you feel most attached to and why do you think that was?`,
  },
  { id: 134, text: `What did you want to be when you grew up?` },
  { id: 135, text: `What did you love and hate about your childhood?` },
  { id: 136, text: `Did you have a role model when you were growing up?` },
  { id: 137, text: `What was your first car?` },
  {
    id: 138,
    text: `Where did your family go on vacation when you were growing up?`,
  },
  { id: 139, text: `What chores did you have to do when you were growing up?` },
  {
    id: 140,
    text: `Did you receive an allowance growing up?  Do you remember how much it was?`,
  },
  { id: 141, text: `What did you spend your money on growing up?` },
  {
    id: 142,
    text: `What phrases did you use growing up that aren't used much today?`,
  },
  {
    id: 143,
    text: `What is something you did growing up that you're glad you did?`,
  },
  { id: 144, text: `As a child, who was an influential person in your life?` },
  { id: 145, text: `Did you share a room with anyone growing up?` },
  { id: 146, text: `Where did you live as a young child?` },
  {
    id: 147,
    text: `What's one of the best childhood memories you can recall?`,
  },
  { id: 148, text: `What was a difficult childhood moment for you?` },
  { id: 149, text: `What were you like as a child?` },
  { id: 150, text: `How would you describe yourself when you were a child?` },
  { id: 151, text: `What is your earliest memory?` },
  { id: 152, text: `What were you most afraid of as a child?` },
  { id: 153, text: `Who was your closest friend as a child?` },
  {
    id: 154,
    text: `What do you recall doing with your best friend as a child?`,
  },
  {
    id: 155,
    text: `How did you spend your summers when you were in high school?`,
  },
  { id: 156, text: `What was your high school experience like?` },
  { id: 157, text: `What were your plans after you finished high school?` },
  { id: 158, text: `What were you like in high school?` },
  { id: 159, text: `How and when did you learn to drive?` },
  {
    id: 160,
    text: `What was your favorite music when you were in high school?`,
  },
  { id: 161, text: `How would you describe yourself as a teenager?` },
  { id: 162, text: `How did you dress as a teenager?` },
  { id: 163, text: `How did you wear your hair as a teenager?` },
  { id: 164, text: `Where did you live when you were in high school?` },
  { id: 165, text: `Who was your closest friend in high school?` },
  {
    id: 166,
    text: `What do you recall doing with your best friend in high school?`,
  },
  { id: 167, text: `What was your friend group like in high school?` },
  {
    id: 168,
    text: `What sports did you enjoy in high school?  Which was your favorite?`,
  },
  { id: 169, text: `Which high school clubs were you involved in?` },
  { id: 170, text: `In high school, is there a teacher you remember fondly?` },
  { id: 171, text: `In high school, is there a teacher you disliked?` },
  { id: 172, text: `What did you like most about your high school?` },
  { id: 173, text: `Did you complete high school?` },
  { id: 174, text: `How did you spend your summers when you were a kid?` },
  { id: 175, text: `Where did you live in elementary school?` },
  { id: 176, text: `What was your elementary school experience like?` },
  { id: 177, text: `What was your middle school experience like?` },
  { id: 178, text: `What were you like in elementary school?` },
  {
    id: 179,
    text: `What was your favorite music when you were in primary school?`,
  },
  { id: 180, text: `Who was your closest friend in elementary school?` },
  {
    id: 181,
    text: `What do you recall doing with your best friend in elementary school?`,
  },
  { id: 182, text: `What was your friend group like in elementary school?` },
  {
    id: 183,
    text: `What sports did you enjoy in elementary school?  Which was your favorite?`,
  },
  {
    id: 184,
    text: `In elementary school, is there a teacher you remember fondly?`,
  },
  { id: 185, text: `In elementary school, is there a teacher you disliked?` },
  { id: 186, text: `What did you like most about your elementary school?` },
  { id: 187, text: `What do you feel passionate about?` },
  {
    id: 188,
    text: `Are there any recipes or meals you hope your family continues making?`,
  },
  { id: 189, text: `What's one of the best meals you've ever had?` },
  {
    id: 190,
    text: `Are there any cultural/historical events that really impacted or shaped you?`,
  },
  { id: 191, text: `What is the most beautiful place you've ever been to?` },
  { id: 192, text: `What’s the farthest from home you’ve ever been?` },
  {
    id: 193,
    text: `What was the most special way someone showed you that you are loved?`,
  },
  { id: 194, text: `What's your favorite quote or saying?` },
  { id: 195, text: `What do you think is the meaning of life?` },
  { id: 196, text: `What do you think happens after someone passes away?` },
  { id: 197, text: `What are your thoughts on making relationships work?` },
  { id: 198, text: `What do you think of the world we live in right now?` },
  { id: 199, text: `Is there anything you regret not doing or learning?` },
  {
    id: 200,
    text: `What is something you wish you hadn't worried so much about?`,
  },
  { id: 201, text: `What lessons were stressed in your household?` },
  { id: 202, text: `What memory exemplifies your family's values?` },
  { id: 203, text: `What's one of the best decisions you ever made, and why?` },
  {
    id: 204,
    text: `What's the best advice you've ever received and who gave it to you?`,
  },
  {
    id: 205,
    text: `What did you learn from your parent(s) or caregiver(s) that you passed along?`,
  },
  {
    id: 206,
    text: `What advice would you like to pass along to your family's youngest generation?`,
  },
  { id: 207, text: `What words have you lived by?` },
  {
    id: 208,
    text: `What lessons do you hope are passed along to your family's future generations?`,
  },
];

export const MITCHELL_MARTIN_AI_PROMPTS: AiPrompt[] = [
  {
    id: 0,
    text: "What do you remember about your first day at Mitchell Martin?",
    description:
      "What do you remember about your first day at Mitchell Martin?",
  },
  {
    id: 1,
    text: "Can you share a memorable story from your early days with the company?",
    description:
      "Can you share a memorable story from your early days with the company?",
  },
  {
    id: 2,
    text: "What was the company like when you first started?",
    description: "What was the company like when you first started?",
  },
  {
    id: 3,
    text: "How have you seen the early vision for the company play out over time?",
    description:
      "How have you seen the early vision for the company play out over time?",
  },
  {
    id: 4,
    text: "What if anything do you remember about the 80 Wall Street office? How did working there shape the company’s early culture?",
    description:
      "What if anything do you remember about the 80 Wall Street office? How did working there shape the company’s early culture?",
  },
  {
    id: 5,
    text: "What stands out in your memory about September 11 and the weeks following? How did it impact the company?",
    description:
      "What stands out in your memory about September 11 and the weeks following? How did it impact the company?",
  },
  {
    id: 6,
    text: "What do you remember about the move to the 307 38th Street office?",
    description:
      "What do you remember about the move to the 307 38th Street office?",
  },
  {
    id: 7,
    text: "Can you share any memorable experiences from the 307 38th Street office?",
    description:
      "Can you share any memorable experiences from the 307 38th Street office?",
  },
  {
    id: 8,
    text: "What do you remember about the financial crisis and how it affected Mitchell Martin? What helped Mitchell Martin get through that challenging period?",
    description:
      "What do you remember about the financial crisis and how it affected Mitchell Martin? What helped Mitchell Martin get through that challenging period?",
  },
  {
    id: 9,
    text: "Which office/branch do you work in and what is your best office story?",
    description:
      "Which office/branch do you work in and what is your best office story?",
  },
  {
    id: 10,
    text: "What were some key challenges and successes during the Covid pandemic and how did it change the way MMI operates?",
    description:
      "What were some key challenges and successes during the Covid pandemic and how did it change the way MMI operates?",
  },
  {
    id: 11,
    text: "What does becoming an Employee Stock Ownership Plan (ESOP) mean to you?",
    description:
      "What does becoming an Employee Stock Ownership Plan (ESOP) mean to you?",
  },
  {
    id: 12,
    text: "How has becoming an ESOP impacted the company culture and your role?",
    description:
      "How has becoming an ESOP impacted the company culture and your role?",
  },
  {
    id: 13,
    text: "How would you describe Mitchell Martin today and what makes us different?",
    description:
      "How would you describe Mitchell Martin today and what makes us different?",
  },
  {
    id: 14,
    text: "What motivates you to do what you do at MMI?",
    description: "What motivates you to do what you do at MMI?",
  },
  {
    id: 15,
    text: "What recent achievements are you most proud of?",
    description: "What recent achievements are you most proud of?",
  },
  {
    id: 16,
    text: "What are your hopes and aspirations for the future of the company?",
    description:
      "What are your hopes and aspirations for the future of the company?",
  },
  {
    id: 17,
    text: "How do you see Mitchell Martin evolving in the coming years?",
    description: "How do you see Mitchell Martin evolving in the coming years?",
  },
  {
    id: 18,
    text: "How would you describe the company culture?",
    description: "How would you describe the company culture?",
  },
  {
    id: 19,
    text: "Can you share a story about a memorable colleague or team experience?",
    description:
      "Can you share a story about a memorable colleague or team experience?",
  },
  {
    id: 20,
    text: "Do you have a funny or heartwarming client or candidate story? (no names required)",
    description:
      "Do you have a funny or heartwarming client or candidate story? (no names required)",
  },
  {
    id: 21,
    text: "What makes Mitchell Martin a special place to work?",
    description: "What makes Mitchell Martin a special place to work?",
  },
  {
    id: 22,
    text: "What challenges have you faced during your time here, and how did you overcome them?",
    description:
      "What challenges have you faced during your time here, and how did you overcome them?",
  },
  {
    id: 23,
    text: "Can you recount a difficult project or period and how the team pulled through?",
    description:
      "Can you recount a difficult project or period and how the team pulled through?",
  },
  {
    id: 24,
    text: "What lessons have you learned from your experiences here?",
    description: "What lessons have you learned from your experiences here?",
  },
  {
    id: 25,
    text: "How has the company adapted to changes in the industry over the years?",
    description:
      "How has the company adapted to changes in the industry over the years?",
  },
  {
    id: 26,
    text: "Can you talk about any innovative projects or initiatives you’ve been involved in?",
    description:
      "Can you talk about any innovative projects or initiatives you’ve been involved in?",
  },
  {
    id: 27,
    text: "How has digital transformation impacted your work?",
    description: "How has digital transformation impacted your work?",
  },
  {
    id: 28,
    text: "How have you grown personally and professionally during your tenure?",
    description:
      "How have you grown personally and professionally during your tenure?",
  },
  {
    id: 29,
    text: "Can you share an example of a skill or lesson you’ve learned here?",
    description:
      "Can you share an example of a skill or lesson you’ve learned here?",
  },
  {
    id: 30,
    text: "What advice would you give to new employees joining the company?",
    description:
      "What advice would you give to new employees joining the company?",
  },
  {
    id: 31,
    text: "What is one of your fondest memories at a company event or celebration?",
    description:
      "What is one of your fondest memories at a company event or celebration?",
  },
  {
    id: 32,
    text: "Can you describe a moment that made you feel especially connected to your colleagues?",
    description:
      "Can you describe a moment that made you feel especially connected to your colleagues?",
  },
  {
    id: 33,
    text: "Give us your funniest or most heartwarming moment!",
    description: "Give us your funniest or most heartwarming moment!",
  },
  {
    id: 34,
    text: "What is your favorite Gene memory?",
    description: "What is your favorite Gene memory?",
  },
  {
    id: 35,
    text: "Who do you know that would have a good MMI story (current/former employees, clients or consultants?)",
    description:
      "Who do you know that would have a good MMI story (current/former employees, clients or consultants?)",
  },
];
