import { FC } from "react";
import { useSettings } from "./useSettings";
import { MaxWidthContainer } from "../../../components/MaxWidthContainer";
import { Statement } from "../../../components/Statement";
import { MAIN_MODAL_ACTIONS } from "../../../constants/services";
// import { useNavigate } from "react-router-dom";

interface SettingsProps {
  closeMenu: VoidFunction;
}

const Settings: FC<SettingsProps> = (props) => {
  const { openMainModalAction } = useSettings(props);
  // const navigate = useNavigate();

  return (
    <div className="flex flex-col h-full">
      <Statement
        text="Settings"
        extraStyles="pt-4 text-primary text-center text-3xl mb-16"
      />
      <MaxWidthContainer isMenuPageLayout extraStyles="px-4">
        <div className="flex flex-col h-full">
          <div
            className="py-6 px-6 border-b border-t -mx-4 cursor-pointer"
            onClick={() =>
              openMainModalAction(MAIN_MODAL_ACTIONS.CHANGE_PASSWORD)
            }
          >
            <Statement
              text="Change password"
              extraStyles="text-primary text-xl"
            />
          </div>
          <div
            className="py-6 px-6 border-b -mx-4 cursor-pointer"
            // onClick={() => navigate("https://intercom.help/memory-lane/en/")}
          >
            <a
              target="blank"
              className="text-primary font-conversation underline text-xl"
              href="https://intercom.help/memory-lane/en/"
            >
              FAQ & Help
            </a>
            {/* <Statement text="FAQ & Help" extraStyles="text-primary text-xl" /> */}
          </div>
          <div className="py-6 px-6 border-b -mx-4">
            <Statement
              text="Contact Memory Lane at: "
              extraStyles="text-primary text-xl"
            />
            <a className="underline" href="mailto:support@memory-lane.ai">
              support@memory-lane.ai
            </a>
          </div>

          <div className="mt-auto">
            <div className="py-4 px-6 border-t -mx-4 cursor-pointer">
              <a
                target="blank"
                className="text-normal font-conversation underline text-md"
                href="https://intercom.help/memory-lane/en/"
              >
                Privacy Policy
              </a>
            </div>
            <div className="py-4 px-6 border-t -mx-4 cursor-pointer">
              <a
                target="blank"
                className="text-normal font-conversation underline text-md"
                href="https://intercom.help/memory-lane/en/"
              >
                Terms and Conditions
              </a>
            </div>
            <div className="py-4 px-6 border-t -mx-4 cursor-pointer">
              <a
                target="blank"
                className="text-normal font-conversation underline text-md"
                href="https://intercom.help/memory-lane/en/"
              >
                AI Ethics Policy
              </a>
            </div>
          </div>
        </div>
      </MaxWidthContainer>
    </div>
  );
};

export { Settings };
