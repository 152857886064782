/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { MLStateContext } from "../../services/stateProvider";
import { PARTNERSHIPS } from "../../constants/services";

const useMitchellMartin = () => {
  const { updateStoredState } = useContext(MLStateContext);
  const navigate = useNavigate();

  useEffect(() => {
    updateStoredState({ partnership: PARTNERSHIPS.MITCHELL_MARTIN });
    navigate("/home");
  }, []);

  return {};
};

export { useMitchellMartin };
