// import { useContext } from "react";
import { MediaFileDetail } from "../typings/MediaLibrary";
import { Story } from "../typings/Story";
import { useAPI } from "../utils/api-connection";
// import { MLStateContext } from "./stateProvider";

const useMediaService = () => {
  const { getAllMediaLibFiles, createFile, deleteFile } = useAPI();
  // const {
  //   state: {},
  //   updateStateItem,
  // } = useContext(MLStateContext);

  const getAllFiles = () => {
    return getAllMediaLibFiles().then((files) =>
      files.map((f) => new MediaFileDetail(f))
    );
  };

  const addMediaFile = (file: File, description: string, story?: Story) => {
    return createFile(file, description, story);
  };

  const removeFile = (id: number) => deleteFile(id);

  return {
    removeFile,
    getAllFiles,
    addMediaFile,
  };
};

export { useMediaService };
