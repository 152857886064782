import { useContext, useEffect, useState } from "react";
import { useStoriesService } from "../../../services/useStoriesService";
import { Story } from "../../../typings/Story";
import { trimText } from "../../../utils/text-format";
import { useInfoToastService } from "../../../services/useInfoToastService";
import { OptionItem } from "../../../components/OptionedItemsList";
import { useNavigate } from "react-router-dom";
import { MLStateContext } from "../../../services/stateProvider";

interface UseMyStoriesProps {
  closeMenu: VoidFunction;
  stories: Array<Story>;
}

const useMyStories = ({ closeMenu, stories }: UseMyStoriesProps) => {
  const navigate = useNavigate();
  const {
    state: { userDetails },
  } = useContext(MLStateContext);
  const [isLoading, setIsLoading] = useState(false);
  const { removeStory, getByIdFiltered } = useStoriesService();
  const { createSuccessMsg } = useInfoToastService();
  const [ allStories, setAllStories] = useState<Array<Story>>([]);
  const options: Array<OptionItem<Story>> = [
    {
      label: "Open Story",
      action: (item) => {
        navigate(`/stories/${item.id}`);
        closeMenu();
      },
    },
    {
      label: "Edit Story",
      action: (item) => {
        navigate(`/stories/${item.id}/edit`);
        closeMenu();
      },
    },
    {
      label: "Delete Story",
      action: (item) =>
        window.confirm("Are you sure you want to delete this story?")
          ? deleteStory(item.id)
          : null,
    },
  ];

  const loadStories = () => {
    if(!stories?.length) {
      if(userDetails?.id) {
        getByIdFiltered(userDetails?.id, true).then(r => {
          setAllStories(
            r.map((s) => ({ ...s, shortText: trimText(s.shortText || "") })) || []
            );
          setIsLoading(false);
        })
      }
    } else {
      setAllStories(
        stories.map((s) => ({ ...s, shortText: trimText(s.shortText || "") })) || []
        );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    loadStories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteStory = (id: number) => {
    setIsLoading(true);
    removeStory(id)
      .then(() => {
        createSuccessMsg({
          title: "Story is deleted.",
          message: "Story was deleted correctly.",
        });
        loadStories();
      })
      .catch(() =>
        createSuccessMsg({
          title: "Story was not deleted.",
          message: "There was an error deleting the story.",
        })
      )
      .finally(() => setIsLoading(false));
  };

  return { deleteStory, allStories, isLoading, options };
};

export { useMyStories };
