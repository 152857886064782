import { useAPI } from "../utils/api-connection";
import { Book, BookDetails, BookRequest } from "../typings/Book";
import { Chapter } from "../typings/Chapter";

const useBookService = () => {
  const { getBook, updateBook } = useAPI();

  const getBookDetail = () => {
    return getBook().then((book) => new BookDetails(book));
  };

  const orderBookChapters = (chapters: Chapter[]) => {
    const book = { chapters } as Book;
    return updateBook(new BookRequest(book)).then(
      (book) => new BookDetails(book)
    );
  };

  return {
    getBookDetail,
    orderBookChapters,
  };
};

export { useBookService };
