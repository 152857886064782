import {
  NotificationResponse,
  NotificationTypes,
  ResponseNotificationTypes,
} from "../typings/Notifications";

export const getNotificationType = (notification: NotificationResponse) => {
  switch (notification.type) {
    case ResponseNotificationTypes.INVITATION:
      if (notification.story) return NotificationTypes.NEW_STORY;
      return NotificationTypes.INVITATION_REQUEST;
    case ResponseNotificationTypes.INVITATION_RESPONDED:
      return NotificationTypes.INVITATION_RESPONDED;
    case ResponseNotificationTypes.FOLLOW_UP_QUESTION:
      return NotificationTypes.FOLLOW_UP_QUESTION;
  }
  return NotificationTypes.TEXT;
};
