import { useAPI } from "../utils/api-connection";
import { Chapter, ChapterDetail, ChapterRequest } from "../typings/Chapter";
import { Story } from "../typings/Story";

const useChapterService = () => {
  const { getChapter, updateChapter } = useAPI();

  const getChapterDetail = (id: string) => {
    return getChapter(id).then((chapter) => new ChapterDetail(chapter));
  };

  const orderChapterStories = (id: string, stories: Story[]) => {
    const chapter = { stories } as Chapter;
    return updateChapter(id, new ChapterRequest(chapter)).then(
      (c) => new ChapterDetail(c)
    );
  };

  return {
    getChapterDetail,
    orderChapterStories,
  };
};

export { useChapterService };
