import { useQuickActionService } from "../../services/useQuickActionService";

const useQuickActionPrompt = () => {
  const { closeQuickAction, activeQuickAction } = useQuickActionService();
  return {
    closeQuickAction,
    activeQuickAction,
    isOpen: Boolean(activeQuickAction),
  };
};

export { useQuickActionPrompt };
