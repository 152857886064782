import { useFormik } from "formik";
import { inviteUserByEmailSchema } from "../../../utils/form-validation/yup-schemas";

interface AddListenersProps {
  addListener: (email: string) => void;
}

const useAddListeners = ({ addListener }: AddListenersProps) => {
  const initialValues = { email: "" };

  const onSubmit = () => {
    if (!formikObject.isValid) return;
    addListener(formikObject.values.email);
    formikObject.resetForm();
  };

  const formikObject = useFormik({
    initialValues,
    validationSchema: inviteUserByEmailSchema,
    onSubmit,
    validateOnBlur: true,
  });

  return { formikObject };
};

export { useAddListeners };
