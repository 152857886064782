import { ReactNode, useContext, useEffect, useState } from "react";
import { useAuthService } from "../../services/useAuthService";
import { useUserService } from "../../services/useUserService";
import { MLStateContext } from "../../services/stateProvider";
import { useStoriesService } from "../../services/useStoriesService";
import { Story } from "../../typings/Story";

const useCollapsableMenu = () => {
  const {
    state: { userDetails, notifications = [], following = [], followers = [] },
  } = useContext(MLStateContext);
  const [isLoading, setIsLoading] = useState(false);
  const [allStories, setAllStories] = useState<Array<Story>>([]);
  const { logout } = useAuthService();
  const { refreshUserDetails } = useUserService();
  const { getByIdFiltered } = useStoriesService();
  const [activeMenuPage, setActiveMenuPage] = useState<ReactNode | null>();

  useEffect(() => {
    if (userDetails?.id && activeMenuPage) {
      getByIdFiltered(userDetails?.id, true).then((r) => {
        setAllStories(r || []);
      });
    }
  }, [activeMenuPage]);

  const reloadUserData = () => {
    setIsLoading(true);
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    refreshUserDetails(() => {})
      .catch((e) => console.log("Error", e.error_message))
      .finally(() => setIsLoading(false));
  };

  const closeMenuPage = () => setActiveMenuPage(() => null);
  const openMenuPage = (page: ReactNode) => setActiveMenuPage(page);

  return {
    allStories,
    followers,
    following,
    isLoading,
    userDetails,
    notifications,
    activeMenuPage,
    reloadUserData,
    closeMenuPage,
    openMenuPage,
    logout,
  };
};

export { useCollapsableMenu };
