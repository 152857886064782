import { useContext } from "react";
import { MLStateContext } from "../../services/stateProvider";

const useInfoToast = () => {
  const {
    state: { toasts },
  } = useContext(MLStateContext);
  const isOpen = Boolean(toasts && toasts?.length > 0);

  return {
    isOpen,
    toasts,
  };
};

export { useInfoToast };
