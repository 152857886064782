import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthService } from "../../services/useAuthService";
import { passwordAndConfirmation } from "../../utils/form-validation/yup-schemas";
import { useFormik } from "formik";
import { useAPI } from "../../utils/api-connection";

const useResetPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>();

  const navigate = useNavigate();
  const { userId, token = "" } = useParams();
  const { setNewPassword, logout } = useAuthService();
  const { closeSession } = useAPI();

  useEffect(() => {
    logout(false);
  }, []);

  const onSubmit = () => {
    if (!formikObject.isValid) return;
    setIsLoading(true);
    setErrorMessage(null);
    updatePassword();
  };

  useEffect(() => {
    closeSession();
  }, []);

  const formikObject = useFormik({
    initialValues: { password: "", password_confirm: "" },
    validationSchema: passwordAndConfirmation,
    onSubmit,
    validateOnBlur: true,
  });

  const updatePassword = () => {
    setNewPassword({
      ...formikObject.values,
      user_id: Number(userId),
      token: token,
    })
      .then(() => navigate("/"))
      .catch((e) => setErrorMessage(e.error))
      .finally(() => setIsLoading(false));
  };

  return { isLoading, errorMessage, formikObject, navigate };
};

export { useResetPassword };
