import { FC } from "react";
import { useEmailConfirmation } from "./useEmailConfirmation";
import { MaxWidthContainer } from "../../components/MaxWidthContainer";
import { Statement } from "../../components/Statement";
import { Spinner } from "../../components/Spinner";

interface EmailConfirmationProps {}

const EmailConfirmation: FC<EmailConfirmationProps> = () => {
  const { isLoading, errorMessage } = useEmailConfirmation();

  return (
    <div>
      <MaxWidthContainer isPageLayout extraStyles="pb-12 md:pb-4">
        <div className="flex flex-col h-full content-between">
          <div className="mr-[10%] mb-14 flex items-center">
            <Spinner isLoading={isLoading} />
            <Statement
              text="Confirming your email..."
              extraStyles="text-primary text-3xl"
            />
          </div>
          {errorMessage && (
            <p className="text-red-900 ml-4 -mt-9 mb-3">{errorMessage}</p>
          )}
        </div>
      </MaxWidthContainer>
    </div>
  );
};

export { EmailConfirmation };
