import { FC } from "react";
import { useMediaLibrary } from "./useMediaLibrary";
import { MaxWidthContainer } from "../../../components/MaxWidthContainer";
import { Statement } from "../../../components/Statement";
import { IconButton, IconButtonTypes } from "../../../components/IconButton";
import { MediaLibraryModal } from "../../../components/MediaLibraryModal";
import { MediaFileDetail } from "../../../typings/MediaLibrary";
import {
  ListItem,
  OptionedItemsList,
} from "../../../components/OptionedItemsList";
import { formatTime } from "../../../utils/number-format";
import { Spinner } from "../../../components/Spinner";

interface MediaLibraryProps {
  closeMenu: VoidFunction;
}

const MediaLibrary: FC<MediaLibraryProps> = ({ closeMenu }) => {
  const { addMediaFiles, isLoading, options, mediaFiles } = useMediaLibrary({
    closeMenu,
  });

  return (
    <div className="flex flex-col h-full">
      <Spinner withBackdrop isLoading={isLoading} />
      <Statement
        text="My Photos"
        extraStyles="pt-4 text-primary text-center text-3xl mb-4"
      />
      <MediaLibraryModal
        triggerNode={
          <IconButton
            extraStyles="mb-10"
            icon={IconButtonTypes.PHOTO}
            onClick={() => null}
            label="Add Photos"
          />
        }
        submitFiles={addMediaFiles}
      />
      <MaxWidthContainer isMenuPageLayout>
        <OptionedItemsList<MediaFileDetail & ListItem>
          items={mediaFiles}
          displayItems={(file) => (
            <div className="flex items-center px-3 py-4">
              <img
                className="w-24 h-20 object-cover object-left-top rounded-md"
                alt={file.description}
                src={file.fileURL}
              />
              <div className="flex-1 ml-4">
                <p className="text-ellipsis">{file.description}</p>
                <p>{formatTime(file.createdAt?.getTime())}</p>
              </div>
            </div>
          )}
          options={options}
          noItemsLabel="There are no files to show."
        />
      </MaxWidthContainer>
    </div>
  );
};

export { MediaLibrary };
