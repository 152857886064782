import { useEffect, useState } from "react";
import { useInfoToastService } from "../../services/useInfoToastService";
import { ToastMessage } from "../../typings/InfoToast";

const useInfoToastMessage = (t: ToastMessage) => {
  const [time, setTime] = useState(10);
  const [timerInterval, setTimerInterval] = useState<NodeJS.Timer>();
  const { clearMessage } = useInfoToastService();
  const closeToast = () => setTime(1);

  useEffect(() => {
    const timerIntv = setInterval(() => {
      setTime((prevTime) => prevTime - 1);
    }, 1000);
    setTimerInterval(timerIntv);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (time === 0) {
      clearInterval(timerInterval);
      clearMessage(t.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time]);

  return {
    closeToast,
    time,
  };
};

export { useInfoToastMessage };
