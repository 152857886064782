import { MLStateContext } from "./stateProvider";
import { useContext } from "react";
import { INITIAL_STATE } from "../constants/state";
import { useNavigationService } from "./useNavigationService";
import { PARTNERSHIPS } from "../constants/services";

const useOnboardingService = () => {
  const navigationService = useNavigationService();
  const {
    state: { onBoarding, partnership },
    updateStateItem,
  } = useContext(MLStateContext);

  const setIsVisible = (isVisibleP = false) => {
    updateStateItem({
      onBoarding: { ...onBoarding, isVisible: isVisibleP },
    });
  };

  const stepNextNavigation = () => {
    setStateWithAnimation(() => {
      if (onBoarding?.currentStep !== undefined) {
        updateStateItem({
          onBoarding: {
            ...onBoarding,
            currentStep: onBoarding.currentStep + 1,
          },
        });
      }
    });
  };

  const stepBackNavigation = () => {
    setStateWithAnimation(() => {
      if (!onBoarding?.displayHelp) {
        if (onBoarding?.currentStep && onBoarding.currentStep > 0) {
          updateStateItem({
            onBoarding: {
              ...onBoarding,
              currentStep: onBoarding.currentStep - 1,
            },
          });
        } else {
          updateStateItem({
            onBoarding: { ...onBoarding, currentStep: 0, isStepActive: false },
          });
        }
      } else {
        setDisplayHelp(false);
      }
    });
  };

  const resetOnboarding = () => {
    navigationService.resetHistory();
    updateStateItem({
      onBoarding: { ...INITIAL_STATE.state.onBoarding },
    });
  };
  const setStepActive = () => {
    setStateWithAnimation(() =>
      updateStateItem({
        onBoarding: { ...onBoarding, isStepActive: true },
      })
    );
  };

  const setIsLoading = (state: boolean) => {
    updateStateItem({
      onBoarding: { ...onBoarding, isLoading: state },
    });
  };

  const setDisplayHelp = (state: boolean) => {
    setStateWithAnimation(() =>
      updateStateItem({
        onBoarding: { ...onBoarding, displayHelp: state },
      })
    );
  };

  const setStateWithAnimation = (func: () => void) => {
    setIsVisible();
    setTimeout(func);
  };

  return {
    isMitchellMartin: partnership === PARTNERSHIPS.MITCHELL_MARTIN,
    resetOnboarding,
    stepBackNavigation,
    stepNextNavigation,
    setDisplayHelp,
    setIsLoading,
    setStepActive,
    setIsVisible,
    ...onBoarding,
  };
};

export { useOnboardingService };
