import { FC } from "react";
import classNames from "classnames";
// import { usePill } from "./usePill";

export interface PillProps {
  isDark?: boolean;
  label: string;
  action?: VoidFunction;
  extraStyles?: string;
  size?: "small" | "medium";
  isActive?: boolean;
  activeStyles?: string;
  inActiveStyles?: string;
}

const Pill: FC<PillProps> = ({
  // isDark = false,
  label,
  action,
  extraStyles = "",
  size = "small",
  isActive = false,
  activeStyles = "border-normal text-white bg-normal",
  inActiveStyles = "border-transparent text-normal",
}) => {
  const styles = classNames(
    `inline-block rounded-full border transition-colors cursor-pointer`,
    extraStyles,
    // isDark ? "border-normal text-normal" : "border-secondary text-secondary",
    size === "small" ? "px-2 text-xs" : "",
    size === "medium" ? "px-4 text-md font-regular" : "",
    isActive ? activeStyles : inActiveStyles
  );
  return (
    <span className={styles} onClick={action}>
      {label}
    </span>
  );
};

export { Pill };
