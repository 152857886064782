import { FC } from "react";
import { UserAvatar } from "../UserAvatar";
import { FollowUpQuestionDetails } from "../../typings/FollowUpQuestion";
import { Statement, StatementSizes } from "../Statement";
import { AudioAndTranscriptionItem } from "../AudioAndTranscriptionItem";

export interface FollowUpQuestionProps {
  question: FollowUpQuestionDetails;
  extraStyles?: string;
  isLarge?: boolean;
  hideAuthor?: boolean;
  commentary?: string;
}

const FollowUpQuestion: FC<FollowUpQuestionProps> = ({
  question,
  isLarge = false,
  extraStyles = "mt-10",
  hideAuthor = false,
  commentary

}) => {
  const wrapperStyles = isLarge ? "mb-4" : "mb-2";
  return (
    <div key={question.id} className={extraStyles}>
      {!hideAuthor && <div className={`flex items-center ${wrapperStyles}`}>
        <UserAvatar
          src={
            question.author.profile.photo ||
            `https://picsum.photos/seed/${question.author?.id}/200`
          }
          alt={question.author.firstName}
          small
        />
        <Statement
          text={`${question.author.firstName} ${commentary ? '': 'asked'}`}
          size={isLarge ? StatementSizes.XL3 : StatementSizes.LG}
          extraStyles="mb-0 ml-4"
        />
      </div>
      }
      {
      commentary && !hideAuthor && <div className="border rounded-md bg-quaternary px-3 py-2 mb-2">
        <p className="text-black">{commentary}</p>
      </div>
      }
      <Statement
        extraStyles="mb-2"
        text={`"${question.description}"`}
        size={isLarge ? StatementSizes.XL2 : StatementSizes.MD}
      />
      {question.answers &&
        question.answers.map((a) => (
            <AudioAndTranscriptionItem
              hideAudio={true}
              key={`question_answer_${a.id}`}
              storyChunk={a}
            />
        ))}
    </div>
  );
};

export { FollowUpQuestion };
