/* eslint-disable react-hooks/exhaustive-deps */
import { createRef, useContext, useEffect, useState } from "react";
import { MLStateContext } from "../../services/stateProvider";
import { PAGE_STYLES, USER_MODES } from "../../typings/state";
import { useParams } from "react-router-dom";
import { StoryChunk, StoryDetail } from "../../typings/Story";
import { useStoriesService } from "../../services/useStoriesService";
import { useFollowupQuestionsService } from "../../services/useFollowupQuestionsService";
import { useInfoToastService } from "../../services/useInfoToastService";
import { CommentOrQuestionProps } from "../../components/CommentOrQuestion";
import { MediaFile, MediaFileDetail } from "../../typings/MediaLibrary";
import { useMediaService } from "../../services/useMediaService";
import { MAX_FILE_PER_STORY } from "../../constants/services";
import { useNavigate } from "react-router-dom";

const useStory = () => {
  const { getById, updateById, updateMediaFiles } = useStoriesService();
  const { createNewQuestion } = useFollowupQuestionsService();
  const { createSuccessMsg } = useInfoToastService();
  const [selectedImages, setSelectedImages] = useState<MediaFile[]>([]);
  const { getAllFiles, addMediaFile } = useMediaService();
  const [mediaFiles, setMediaFiles] = useState<Array<MediaFile>>([]);
  const [recentAddedFiles, setRecentAddedFiles] = useState<Array<MediaFile>>(
    []
  );
  const navigate = useNavigate();

  useEffect(() => {
    reloadFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadFiles = () => {
    setIsLoading(true);
    getAllFiles().then((files) => {
      setMediaFiles(
        files.sort((a, b) => {
          if (isItemSelected(a) && isItemSelected(b)) {
            return b.id - a.id;
          }
          if (isItemSelected(a)) {
            return -1;
          } else {
            return b.id - a.id;
          }
        })
      );
      setIsLoading(false);
    });
  };

  const isItemSelected = (image: MediaFile) => {
    return selectedImages.findIndex((i) => image.id === i.id) !== -1;
  };

  const addMediaFiles = (files: File[]) => {
    setIsLoading(true);

    files.forEach((f) =>
      addMediaFile(f, f.name).then((res) => {
        setRecentAddedFiles((prev) => {
          return [...prev, new MediaFileDetail(res)];
        });
        createSuccessMsg({
          title: "Image uploaded.",
          message: "Image was succesfully uploaded.",
        });
      })
    );
    setTimeout(reloadFiles, 3000);
  };

  const audioRef = createRef<HTMLAudioElement>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [isListener, setIsListener] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentAudio, setCurrentAudio] = useState<StoryChunk>();
  const {
    state: { userDetails },
    updateStateItem,
  } = useContext(MLStateContext);
  const [story, setStory] = useState<StoryDetail>();
  const { id } = useParams();

  useEffect(() => {
    if (recentAddedFiles && recentAddedFiles.length) {
      if (selectedImages.length < MAX_FILE_PER_STORY) {
        setSelectedImages((prev) => {
          return [...prev, recentAddedFiles[recentAddedFiles.length - 1]];
        });
      }
    }
  }, [recentAddedFiles]);

  useEffect(() => {
    updateStateItem({
      pageStyle: PAGE_STYLES.SECONDARY,
      currentStory: story,
    });
    return () => {
      updateStateItem({
        pageStyle: PAGE_STYLES.PRIMARY,
        currentStory: undefined,
      });
    };
  }, []);

  useEffect(() => {
    if (id) reloadStory();
  }, [id]);

  useEffect(() => {
    if (mediaFiles.length && story) {
      setMediaFiles(
        mediaFiles.sort((a, b) => {
          if (isItemSelected(a) && isItemSelected(b)) {
            return b.id - a.id;
          }
          if (isItemSelected(a)) {
            return -1;
          } else {
            return b.id - a.id;
          }
        })
      );
    }
  }, [mediaFiles, story]);

  const userAvatar = {
    src:
      userDetails?.profile.photo ||
      `https://picsum.photos/seed/${userDetails?.id}/200`,
    alt: userDetails?.fullName || "",
  };

  const reloadStory = () => {
    setIsLoading(true);
    getById(Number(id))
      .then((s) => {
        setStory({
          ...s,
          media: s.media.sort(
            (a, b) => b.createdAt.getDate() - a.createdAt.getDate()
          ),
        });
        if (s.fullText && s.fullText[0]) setCurrentAudio(s.fullText[0]);
        setIsListener(userDetails?.id !== s.author.id);
        setSelectedImages(s.media);
        updateStateItem({
          currentStory: s,
          activeMode:
            s.author.id === userDetails?.id
              ? USER_MODES.STORYTELLER
              : USER_MODES.LISTENER,
        });
      })
      .finally(() => setIsLoading(false));
  };

  const makeAFollowUpQuestion = (description: string) => {
    if (id) {
      setIsLoading(true);
      createNewQuestion({ story: story?.resourceUri, description })
        .then(() => {
          createSuccessMsg({
            title: "Question on it's way!",
            message: `Your question to ${story?.author.firstName} has been sent. He will get a notification.`,
          });
        })
        .catch((e) => null)
        .finally(() => {
          reloadStory();
        });
    }
  };

  const updateStory = (st: StoryDetail) => {
    setIsLoading(true);
    if (st)
      updateById(st)
        .then(() => {
          createSuccessMsg({
            title: "Story is updated",
            message: `Your story was updated succesfully.`,
          });
        })
        .catch((e) => null)
        .finally(() => {
          reloadStory();
        });
  };

  const saveSelectedImages = () => {
    setIsLoading(true);
    if (story)
      updateMediaFiles(story.id, selectedImages)
        .then(() => {
          createSuccessMsg({
            title: "Images were updated",
            message: `Your story images were updated succesfully.`,
          });
        })
        .catch(() => null)
        .finally(() => {
          reloadStory();
        });
  };

  const questions: CommentOrQuestionProps[] = story
    ? [
        ...story.followupQuestions
          .filter((q) => !q.answers || q.answers.length === 0)
          .map((q) => ({
            author: {
              src:
                q.author.profile.photo ||
                `https://picsum.photos/seed/${q.author.id}/200`,
              alt: q.author.fullName,
            },
            comment: q,
            date: q.createdAt,
          })),
      ]
    : [];

  // const pills = [{ label: "New" }, { label: "Sensitive" }];
  // const tags = [
  //   { label: "1970's" },
  //   { label: "Brooklyn" },
  //   { label: "Parents" },
  // ];

  const toggleAudio = () => {
    if (isPlaying) {
      audioRef.current?.pause();
      setIsPlaying(false);
    } else {
      if (audioRef.current) {
        audioRef.current.muted = false;
        audioRef.current.play();
        setIsPlaying(true);
      }
    }
  };

  const onAudioEnded = () => {
    playNextAudio();
  };

  const playNextAudio = async () => {
    if (story && story.fullText && currentAudio) {
      const currentAudioIndex = story.fullText.indexOf(currentAudio);
      if (story.fullText.length - 1 > currentAudioIndex) {
        setCurrentAudio(story.fullText[currentAudioIndex + 1]);
      } else {
        setIsPlaying(false);
      }
    }
  };

  useEffect(() => {
    if (currentAudio) {
      audioRef.current?.setAttribute("src", currentAudio?.audio);
      if (isPlaying) audioRef.current?.play();
    }
  }, [currentAudio]);

  return {
    story,
    audioRef,
    isLoading,
    questions,
    isPlaying,
    isListener,
    userAvatar,
    mediaFiles,
    userDetails,
    currentAudio,
    selectedImages,
    recentAddedFiles,
    makeAFollowUpQuestion,
    saveSelectedImages,
    setSelectedImages,
    addMediaFiles,
    onAudioEnded,
    toggleAudio,
    updateStory,
    navigate,
  };
};

export { useStory };
