import { FC } from "react";
import { useAnswerFollowUpQuestions } from "./useAnswerFollowUpQuestions";
import { MaxWidthContainer } from "../../components/MaxWidthContainer";
import { Button } from "../../components/Button";
import { ButtonTypes } from "../../typings/components";
import { IconButton, IconButtonTypes } from "../../components/IconButton";
import { LogoNavigation } from "../../components/LogoNavigation";
import { Statement, StatementSizes } from "../../components/Statement";
import { RecordingButton } from "../../components/RecordingButton";
import { Spinner } from "../../components/Spinner";
import { FollowUpQuestion } from "../../components/FollowUpQuestion";
import { QuestionsCarrousel } from "../../components/QuestionsCarrousel";

interface AnswerFollowUpQuestionsProps {
  isValid?: boolean;
}

const AnswerFollowUpQuestions: FC<AnswerFollowUpQuestionsProps> = () => {
  const {
    story,
    questions,
    isLoading,
    isRecording,
    messagesBoxRef,
    currentQuestion,
    answeredQuestions,
    preSelectedQuestion,
    hasRecordPengindToSave,
    setCurrentQuestion,
    toggleRecording,
    saveStory,
    navigate,
  } = useAnswerFollowUpQuestions();

  return (
    <div className="fixed z-30 pt-8 bg-primary h-full w-full text-tertiary">
      <MaxWidthContainer extraStyles="h-full">
        {isLoading && (
          <Spinner
            withBackdrop
            isLoading={true}
            label="I'm loading your follow-up questions."
          />
        )}
        <LogoNavigation isLightLogo extraStyles={"mb-5"}>
          {!isRecording && !hasRecordPengindToSave && preSelectedQuestion && (
            <IconButton
              icon={IconButtonTypes.CLOSE}
              onClick={() => navigate("/")}
            />
          )}
        </LogoNavigation>
        <div className="flex flex-col h-[calc(100vh-107px)] max-w-[800px] mx-auto overflow-y-auto">
          {story && (
            <div>
              <Statement
                extraStyles="ml-0"
                text={story.name}
                size={StatementSizes.XL2}
              />
            </div>
          )}

          {answeredQuestions.length > 0 && (
            <div
              className="flex-1 overflow-y-scroll md:mt-10"
              ref={messagesBoxRef}
            >
              <span className="h-20 w-full left-0 absolute bg-gradient-to-b from-primary z-10 pointer-events-none" />
              <div className="h-8" />
              {answeredQuestions &&
                answeredQuestions.map((q) => (
                  <FollowUpQuestion
                    extraStyles="mt-2 mx-10"
                    key={`answered_question_${q.id}`}
                    question={q}
                  />
                ))}
            </div>
          )}

          {preSelectedQuestion ? (
            <FollowUpQuestion
              extraStyles="flex-1 my-6 mx-10"
              question={preSelectedQuestion}
            />
          ) : (
            !isLoading &&
            questions.length > 0 && (
              <QuestionsCarrousel
                  questions={questions}
                  setCurrentQuestion={setCurrentQuestion}
                  questionTextExtraStyle="text-lg"
                  story={story}/>
            )
          )}

          {!questions.length && !isLoading && (
            <p>There are no questions to answer for this story.</p>
          )}

          <div className="flex flex-col flex-1 mx-2">
            <div className="flex justify-center mt-auto">
              {currentQuestion && (
                <RecordingButton
                  isActive={isRecording}
                  action={toggleRecording}
                  inActiveLabel={story ? "Record" : "Start"}
                />
              )}
            </div>
            {!isRecording && story && (
              <Button
                isFullWidth
                extraStyles="mb-2 z-10"
                style={ButtonTypes.TERTIARY}
                label="Review my story"
                action={saveStory}
              />
            )}
          </div>
        </div>
      </MaxWidthContainer>
    </div>
  );
};

export { AnswerFollowUpQuestions };
