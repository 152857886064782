import { FC } from "react";
import { MaxWidthContainer } from "../MaxWidthContainer";
import { NavLink } from "react-router-dom";

interface FooterProps {}

const Footer: FC<FooterProps> = () => {
  return (
    <footer className="bg-tertiary text-normal w-full md:bg-primary md:text-tertiary border-t-2 border-gray-200
    w-full bg-primary py-2 md:py-10 transition-all translate-y-full">
      <MaxWidthContainer extraStyles="h-full">
        <div className="justify-evenly items-center h-full font-light text-sm hidden md:flex">
          <NavLink to="/" className="mr-auto">
            <img
              src="/assets/images/ml-logo-white.svg"
              alt="Memory Lane Logo"
            />
          </NavLink>
          <nav className="flex justify-between w-full max-w-[50%] mr-auto">
            <NavLink to="/">Terms & Conditions</NavLink>
            <NavLink to="/">Privacy Policy</NavLink>
            <NavLink to="/">FAQ&apos;s</NavLink>
            <NavLink to="/">About Us</NavLink>
          </nav>
        </div>
      </MaxWidthContainer>
      <MaxWidthContainer extraStyles="md:hidden">
        <div className="flex flex-row justify-center items-center h-14">
          <p className="text-center text-sm">
            memory-lane.ai
            <br />
            All Rights Reserved {new Date().getFullYear()}
          </p>
        </div>
      </MaxWidthContainer>
    </footer>
  );
};

export { Footer };
