import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ENDPOINTS } from "../constants/endpoints";
import { MLStateContext } from "../services/stateProvider";
import { INITIAL_STATE } from "../constants/state";
import { Invitation, SignupInvitationResponse } from "../typings/Invitations";
import { Connection } from "../typings/Connections";
import { LoginValues, RegisterValues } from "../typings/form-schemas";
import {
  LoginResponse,
  PasswordChangeRequest,
  PasswordReset,
  UserInfoEditRequest,
} from "../typings/User";
import {
  AddChunkToStoryProps,
  StoriesFilteredOptions,
  StoriesFilteredOptionsRequest,
  Story,
  StoryChunk,
  StoryRequest,
  StoryResponse,
} from "../typings/Story";
import { useInfoToastService } from "../services/useInfoToastService";
import {
  FollowUpQuestion,
  FollowUpQuestionChunk,
} from "../typings/FollowUpQuestion";
import { MediaFileResponse } from "../typings/MediaLibrary";
import { Prompt, PromptsFilterAttributes } from "../typings/Prompts";
import { BookRequest, BookResponse } from "../typings/Book";
import { ChapterRequest, ChapterResponse } from "../typings/Chapter";

export const useAPI = () => {
  const { state, updateStoredState, updateState } = useContext(MLStateContext);
  const { createErrorMsg } = useInfoToastService();
  const navigate = useNavigate();
  const { API_KEY = "", userDetails } = state;
  // const { API_KEY = "", apiKeyExpDate } = state;
  const isAuthenticated = API_KEY && API_KEY !== "";
  // const todayTime = new Date().getTime();
  // const expDate = apiKeyExpDate ? new Date(apiKeyExpDate).getTime() : 0;
  // const isSessionExpired = todayTime > expDate;

  const clearSession = (redirect = false) => {
    updateState({ ...state, ...INITIAL_STATE.state });
    updateStoredState({ ...state, ...INITIAL_STATE.state });
    if (redirect) navigate("/");
  };

  const validateSession = () => {
    // if (!isAuthenticated || isSessionExpired) clearSession();
    // Expiration date is not working well
    if (!isAuthenticated) clearSession();
  };

  const getApiKey = () => API_KEY || "";
  const getAuthRequest = () => ({ Authorization: `apiKey ${getApiKey()}` });

  const BASE_OPTIONS: RequestInit = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      ...getAuthRequest(),
    },
  };

  const NON_AUTH_POST_OPTIONS = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "POST",
  };

  const NON_AUTH_GET_OPTIONS = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    method: "GET",
  };

  const POST_OPTIONS = {
    ...BASE_OPTIONS,
    method: "POST",
  };

  const GET_OPTIONS = {
    ...BASE_OPTIONS,
    method: "GET",
  };

  const PATCH_OPTIONS = {
    ...BASE_OPTIONS,
    method: "PATCH",
  };

  const DELETE_OPTIONS = {
    ...BASE_OPTIONS,
    method: "DELETE",
  };

  interface Response<T> {
    meta: unknown;
    objects: T;
  }

  const api = <T>(
    url: string,
    options: RequestInit,
    nonAuth = false,
    skipError = false
  ): Promise<T> => {
    if (!nonAuth) validateSession();
    return fetch(url, options).then((r) => {
      if (r.status === 204 || r.status === 202) return { success: true };
      if (r.ok)
        return r.json().catch(() => {
          throw r;
        });
      r.text().then((t) =>
        !skipError ? createErrorMsg({ title: "Error:", message: t }) : null
      );
      throw r;
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const buildBody = (obj: { [key: string]: any }) => ({
    body: JSON.stringify(obj),
  });

  // Authentication related Endpoints
  const loginViaUserAndPassword = (cred: LoginValues) => {
    return api<LoginResponse>(
      ENDPOINTS.USER + "login/",
      {
        ...NON_AUTH_POST_OPTIONS,
        ...buildBody(cred),
      },
      true
    );
  };

  const registerNewUser = (newUser: RegisterValues, reference?: string) => {
    const registerValues = {
      ...newUser,
      profile: {
        sex: "ns",
      },
    };
    const data = reference
      ? { ...registerValues, ref_number: reference }
      : registerValues;
    return api<LoginResponse>(
      ENDPOINTS.USER,
      {
        ...NON_AUTH_POST_OPTIONS,
        ...buildBody(data),
      },
      true
    );
  };

  const changePassword = (requestInfo: PasswordChangeRequest) => {
    return api<LoginResponse>(
      ENDPOINTS.USER + "change_password/",
      {
        ...POST_OPTIONS,
        ...buildBody({ ...requestInfo, user_id: userDetails?.id }),
      },
      true
    );
  };

  const resetPassword = (requestInfo: PasswordReset) => {
    return api<LoginResponse>(
      ENDPOINTS.USER + "set_password/",
      {
        ...POST_OPTIONS,
        ...buildBody({ ...requestInfo }),
      },
      true
    );
  };

  const resetPasswordAndDeactivateUser = (email: string) => {
    return api(
      ENDPOINTS.USER + "reset_password/",
      {
        ...POST_OPTIONS,
        ...buildBody({ email }),
      },
      true
    );
  };

  // const logout = (username: string) => {
  //   return api(
  //     ENDPOINTS.USER + "logout/"
  //   );
  // };

  // User related Endpoints
  const getUserDetails = (username: string) => {
    return api<LoginResponse>(ENDPOINTS.USER + username + "/", {
      ...GET_OPTIONS,
    });
  };

  const setUserDetails = (userDetails: UserInfoEditRequest) => {
    return api<LoginResponse>(ENDPOINTS.USER + userDetails.email + "/", {
      ...PATCH_OPTIONS,
      ...buildBody({
        ...userDetails,
      }),
    });
  };

  const setUserProfilePhoto = (user_id: string, file: File) => {
    const form = new FormData();
    const mediaFile = new File([file], `${file.name}`, { type: file.type });

    form.append("photo", mediaFile);
    form.append("user_id", user_id);

    return api<any>(ENDPOINTS.PROFILE, {
      ...POST_OPTIONS,
      headers: { ...getAuthRequest() },
      body: form,
    });
  };

  const emailConfirmation = (activation_key: string) => {
    return api<LoginResponse>(
      ENDPOINTS.USER + "confirm/",
      {
        ...NON_AUTH_POST_OPTIONS,
        ...buildBody({ activation_key }),
      },
      true,
      // This needs to be uncommented when the confirmation error gets fixed
      true
    );
  };

  const getStorytellerDetails = (username: string) => {
    return api<LoginResponse>(ENDPOINTS.USER + username + "/", {
      ...GET_OPTIONS,
    });
  };

  // Invitations related Endpoints
  const acceptDenyInvitation = (id: number, action: boolean) => {
    return api(ENDPOINTS.INVITATION + `${id}/`, {
      ...PATCH_OPTIONS,
      ...buildBody({ action: action ? "accept" : "reject" }),
    });
  };

  const sendInvitationToListener = (user_to_email: string) => {
    return api(ENDPOINTS.INVITATION, {
      ...POST_OPTIONS,
      ...buildBody({ user_to_email }),
    });
  };

  const sendInvitationToStoryteller = (user_to_email: string) => {
    return api(ENDPOINTS.INVITATION, {
      ...POST_OPTIONS,
      ...buildBody({ user_to_email, user_to_is_listener: false }),
    });
  };

  const getAllInvitations = () => {
    return api<Response<Array<Invitation>>>(ENDPOINTS.INVITATION, {
      ...GET_OPTIONS,
    }).then((r) => r.objects);
  };

  const getInvitationInfo = (refNumber: string) => {
    return api<SignupInvitationResponse>(
      ENDPOINTS.INVITATION + refNumber,
      { ...NON_AUTH_GET_OPTIONS },
      true
    );
  };

  const sendInvitationsToStory = (
    invitations: Array<{ user_to_email: string; story: string }>
  ) => {
    return api(ENDPOINTS.INVITATION, {
      ...PATCH_OPTIONS,
      ...buildBody({ objects: invitations }),
    });
  };

  // Connections related Endpoints
  const sendConnectionToListener = (user: string) => {
    return api(ENDPOINTS.CONNECTION, {
      ...POST_OPTIONS,
    });
  };

  const getAllConnections = () => {
    return api<Response<Array<Connection>>>(ENDPOINTS.CONNECTION, {
      ...GET_OPTIONS,
    }).then((r) => r.objects);
  };

  // Follow Up Questions related Endpoints
  const createFollowUpQuestion = (question: FollowUpQuestion) => {
    return api<FollowUpQuestion>(ENDPOINTS.FOLLOW_UP_Q, {
      ...POST_OPTIONS,
      ...buildBody(question),
    });
  };

  const postChunkToFollowUpQuestion = (chunk: FollowUpQuestionChunk) => {
    const { id, text, audio, storyId } = chunk;
    const form = new FormData();
    const audioFile = new File([audio], "followUpQuestionChunk.mp4", {
      type: "audio/mp4",
    });
    form.append("audio", audioFile);
    form.append("story", `${storyId}`);
    form.append("followup_question", `${id}`);
    form.append("description", text);

    return api<FollowUpQuestionChunk>(ENDPOINTS.CHUNK, {
      ...POST_OPTIONS,
      headers: { ...getAuthRequest() },
      body: form,
    });
  };

  // Story related Endpoints
  const createStory = () => {
    return api<Story>(ENDPOINTS.STORY, {
      ...POST_OPTIONS,
      ...buildBody({ full_text: [] }),
    }).then((s) => s);
  };

  const getAllStories = (fields: string[]) => {
    return api<Response<Array<StoryResponse>>>(
      ENDPOINTS.STORY + "?limit=9999&fields=" + fields.join(","),
      {
        ...GET_OPTIONS,
      }
    ).then((r) => r.objects);
  };

  /**
   * @param id userID
   * @param fields options that we want to be retur by api
   * @param ownStories flag to identify your stories or from other users
   * @param orderBy set the order of the list by default is accent by created_at
   * @returns
   */
  // StoriesFilteredOptions

  const getStoriesFiltered = (id: number, optionsT: StoriesFilteredOptions) => {
    const options = new StoriesFilteredOptionsRequest(optionsT);
    return api<Response<Array<StoryResponse>>>(
      ENDPOINTS.STORY +
        `?limit=${options.limit}&${
          options.ownStories ? "storyteller" : "storyteller__ne"
        }=${id}&orderBy=${options.orderBy}${
          options.fields?.length ? `&fields=${options.fields?.join(",")}` : ""
        }`,
      {
        ...GET_OPTIONS,
      }
    ).then((r) => r.objects);
  };

  const getStory = (id: number, fields: string[]) => {
    return api<StoryResponse>(
      ENDPOINTS.STORY + `${id}/?fields=${fields.join(",")}`,
      {
        ...GET_OPTIONS,
      }
    );
  };

  const deleteStory = (id: number) => {
    return api(ENDPOINTS.STORY + `${id}/`, {
      ...DELETE_OPTIONS,
      headers: {
        ...getAuthRequest(),
      },
    });
  };

  const updateStory = (s: Story) => {
    const storyRequest = new StoryRequest(s);
    return api<StoryResponse>(ENDPOINTS.STORY + `${s.id}/`, {
      ...PATCH_OPTIONS,
      ...buildBody({ ...storyRequest }),
    });
  };

  const updateStoryMediaFiles = (id: number, files: string[]) => {
    return api<StoryResponse>(ENDPOINTS.STORY + `${id}/`, {
      ...PATCH_OPTIONS,
      ...buildBody({ media: files.map((f) => ({ resource_uri: f })) }),
    });
  };

  const postChunkToStory = ({
    id,
    transcription,
    followupQuestion,
  }: AddChunkToStoryProps) => {
    return api<StoryChunk>(ENDPOINTS.CHUNK, {
      ...POST_OPTIONS,
      ...buildBody({
        story: Number(id),
        text: transcription,
        followup_question: `/api/v1/followup_question/${followupQuestion}/`,
      }),
    });
  };

  // Media Library related Endpoints
  const createFile = (file: File, description: string, story?: Story) => {
    const form = new FormData();
    const mediaFile = new File([file], `${file.name}`, { type: file.type });

    form.append("media_file", mediaFile);
    form.append("description", description);
    if (story) form.append("story", `${story.id}`);

    return api<MediaFileResponse>(ENDPOINTS.MEDIA, {
      ...POST_OPTIONS,
      headers: { ...getAuthRequest() },
      body: form,
    }).then((s) => s);
  };

  const getAllMediaLibFiles = () => {
    return api<Response<Array<MediaFileResponse>>>(
      `${ENDPOINTS.MEDIA}?limit=9999`,
      {
        ...GET_OPTIONS,
      }
    ).then((r) => r.objects);
  };

  const deleteFile = (id: number) => {
    return api(ENDPOINTS.MEDIA + `${id}/`, {
      ...DELETE_OPTIONS,
      headers: { ...getAuthRequest() },
    });
  };

  // Prompts related Endpoints
  const getInitialPrompt = () => {
    return api<Response<Array<Prompt>>>(
      ENDPOINTS.PROMPT +
        `?author__isnull=true&user_to__isnull=true&mediafile__isnull=true&shuffle&limit=20`,
      {
        ...GET_OPTIONS,
      }
    ).then((r) => r.objects);
  };

  const getAllPrompts = () => {
    return api<Response<Array<Prompt>>>(ENDPOINTS.PROMPT, {
      ...GET_OPTIONS,
    }).then((r) => r.objects);
  };

  const getFilteredPrompts = ({
    limit,
    categoryName,
    justWithPhotos,
  }: PromptsFilterAttributes) => {
    let params: string[] = [];

    if (limit) params = [...params, `limit=${limit}`];
    if (categoryName) params = [...params, `category__name=${categoryName}`];
    if (justWithPhotos) params = [...params, `mediafile__isnull=false`];

    return api<Response<Array<Prompt>>>(
      ENDPOINTS.PROMPT + `?${params.join("&")}`,
      {
        ...GET_OPTIONS,
      }
    ).then((r) => r.objects);
  };

  const closeSession = (redirect = false) => {
    clearSession(redirect);
  };

  // Book related Endpoints
  const getBook = () => {
    return api<BookResponse>(ENDPOINTS.BOOK + userDetails?.email + "/", {
      ...GET_OPTIONS,
    });
  };

  const updateBook = (book: BookRequest) => {
    return api<BookResponse>(ENDPOINTS.BOOK + userDetails?.email + "/", {
      ...PATCH_OPTIONS,
      ...buildBody(book),
    });
  };

  // Chapter related Endpoints
  const getChapter = (id: string) => {
    return api<ChapterResponse>(ENDPOINTS.CHAPTER + id + "/", {
      ...GET_OPTIONS,
    });
  };

  const updateChapter = (id: string, chapter: ChapterRequest) => {
    return api<ChapterResponse>(ENDPOINTS.CHAPTER + id + "/", {
      ...PATCH_OPTIONS,
      ...buildBody(chapter),
    });
  };

  return {
    // Authentication related Endpoints
    loginViaUserAndPassword,
    registerNewUser,
    closeSession,
    changePassword,
    resetPassword,
    resetPasswordAndDeactivateUser,

    // User related Endpoints
    getStorytellerDetails,
    emailConfirmation,
    getUserDetails,
    setUserDetails,
    setUserProfilePhoto,

    // Invitation related Endpoints
    sendInvitationToListener,
    sendInvitationToStoryteller,
    sendInvitationsToStory,
    acceptDenyInvitation,
    getAllInvitations,
    getInvitationInfo,

    // Connection related Endpoints
    sendConnectionToListener,
    getAllConnections,

    // FollowUpQuestion related Endpoints
    postChunkToFollowUpQuestion,
    createFollowUpQuestion,

    // Media Library related Endpoints
    createFile,
    deleteFile,
    getAllMediaLibFiles,

    // Story related Endpoints
    updateStoryMediaFiles,
    postChunkToStory,
    getAllStories,
    getStoriesFiltered,
    createStory,
    updateStory,
    deleteStory,
    getStory,

    // Prompt related Endpoints
    getAllPrompts,
    getInitialPrompt,
    getFilteredPrompts,

    // Book related Endpoints
    getBook,
    updateBook,

    // Chapter related Endpoints
    getChapter,
    updateChapter,
  };
};
