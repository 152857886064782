import { useContext } from "react";
import { useAPI } from "../utils/api-connection";
import { MLStateContext } from "./stateProvider";

const usePromptsService = () => {
  const { getAllPrompts, getInitialPrompt, getFilteredPrompts } = useAPI();
  const { updateStateItem } = useContext(MLStateContext);

  const getAll = () => {
    return getAllPrompts()
      .then((prompts) => updateStateItem({ prompts }))
      .catch((e) => console.log(e));
  };

  const getAllByCategory = (categoryName: string) => {
    return getFilteredPrompts({ categoryName })
      .then((prompts) => updateStateItem({ prompts }))
      .catch((e) => console.log(e));
  };

  const getAllPhotoOnes = () => {
    return getFilteredPrompts({ limit: 9999, justWithPhotos: true })
      .then((prompts) =>
        updateStateItem({ prompts: prompts.sort((a, b) => b.id - a.id) })
      )
      .catch((e) => console.log(e));
  };

  const getInitial = () => {
    return getInitialPrompt()
      .then((prompts) => updateStateItem({ initialPrompts: prompts }))
      .catch((e) => console.log(e));
  };

  return {
    getAll,
    getInitial,
    getAllPhotoOnes,
    getAllByCategory,
  };
};

export { usePromptsService };
