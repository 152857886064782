import { FC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWarning as iconWarning,
  faCircleCheck as iconSuccess,
  faCircleExclamation as iconError,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { TOAST_TYPES, ToastMessage } from "../../typings/InfoToast";
import { useInfoToastMessage } from "./useInfoToastMessage";

interface InfoToastMessageProps {
  toastMessage: ToastMessage;
}

const InfoToastMessage: FC<InfoToastMessageProps> = ({ toastMessage }) => {
  const { time, closeToast } = useInfoToastMessage(toastMessage);
  const { type, title, message } = toastMessage;

  const toastMessageToProps = () => {
    switch (type) {
      case TOAST_TYPES.SUCCESS:
        return {
          timerBgColor: "bg-success-500",
          iconColor: "text-success-500",
          icon: iconSuccess,
        };
      case TOAST_TYPES.ERROR:
        return {
          timerBgColor: "bg-error-500",
          iconColor: "text-error-500",
          icon: iconError,
        };
      case TOAST_TYPES.WARNING:
        return {
          timerBgColor: "bg-warning-500",
          iconColor: "text-warning-500",
          icon: iconWarning,
        };
    }
    return {
      timerBgColor: "bg-success-500",
      iconColor: "text-success-500",
      icon: iconSuccess,
    };
  };
  const messageProps = toastMessageToProps();
  const visibilityStyle = time > 1 ? "h-[100px]" : "h-[0px]";

  return (
    <div
      className={`flex items-center overflow-hidden relative bg-tertiary w-full max-h-30 rounded-md px-4 mb-4 shadow-md transition-all ${visibilityStyle}`}
    >
      <div className="flex items-center flex-1">
        <div
          className={`absolute rounded-sm left-0 top-0 w-0 h-1 animate-reduce-slow ${messageProps.timerBgColor}`}
        ></div>
        <div className="mr-4">
          <FontAwesomeIcon
            className={messageProps.iconColor}
            icon={messageProps.icon}
          />
        </div>
        <div className="w-full">
          <h3 className="text-md font-medium">{title}</h3>
          {message && <p className="text-sm">{message}</p>}
        </div>
        <div className="ml-4">
          <FontAwesomeIcon icon={faTimes} onClick={() => closeToast()} />
        </div>
      </div>
    </div>
  );
};

export { InfoToastMessage };
