import { FC, ReactNode, useContext } from "react";
import { useLocation } from "react-router-dom";
import { IconButton, IconButtonTypes } from "../IconButton";
import { BACK_BTN_ROUTE_BLACKLIST } from "../../constants/routesList";
import { useNavigationService } from "../../services/useNavigationService";
import { MLStateContext } from "../../services/stateProvider";
import { PARTNERSHIPS } from "../../constants/services";

interface LogoNavigationProps {
  isLightLogo?: boolean;
  children?: ReactNode | Array<ReactNode>;
  noHeader?: boolean;
  isMenuOpen?: boolean;
  isModalMenu?: boolean;
  noBackButton?: boolean;
  extraStyles?: string;
}

const LogoNavigation: FC<LogoNavigationProps> = ({
  isLightLogo = false,
  noHeader = false,
  children = null,
  isMenuOpen,
  isModalMenu = false,
  noBackButton = false,
  extraStyles,
}) => {
  const location = useLocation();
  const { goBack, goHome } = useNavigationService();
  const { state } = useContext(MLStateContext);
  const { partnership } = state;
  const logoPath = isLightLogo
    ? "/assets/images/ml-logo-white.svg"
    : "/assets/images/ml-logo-beta.svg";
  const mitchellMartinLogoPath = "/assets/images/mitchell_martin_logo.png";

  return (
    <div className="flex flex-col justify-center">
      <nav
        className={`flex justify-between items-center pb-4 mt-2 ${extraStyles}`}
      >
        {!noBackButton && (
          <div className="mr-auto flex p-2" onClick={() => goBack()}>
            {location.pathname !== "/" &&
              !isMenuOpen &&
              !BACK_BTN_ROUTE_BLACKLIST.some((item) =>
                location.pathname.includes(item)
              ) && (
                <IconButton
                  isDark
                  icon={IconButtonTypes.BACK}
                  onClick={() => null}
                  extraStyles="w-5 h-5"
                />
              )}
          </div>
        )}
        <div
          className={`absolute top-0 mt-4 left-[50%] translate-x-[-50%] flex flex-row items-center`}
        >
          <a
            className={`${!isModalMenu && "cursor-pointer"}`}
            onClick={() => !isModalMenu && goHome()}
          >
            <img src={logoPath} alt="Memory Lane Logo" />
          </a>
          {partnership === PARTNERSHIPS.MITCHELL_MARTIN && (
            <>
              <span
                className={`h-12 border-l-2 ${
                  isLightLogo ? "border-secondary" : "border-primary"
                } mx-4`}
              ></span>
              <a
                className={`${!isModalMenu && "cursor-pointer"}`}
                onClick={() => !isModalMenu && goHome()}
              >
                <img
                  className="w-32"
                  src={mitchellMartinLogoPath}
                  alt="Mitchell Martin Logo"
                />
              </a>
            </>
          )}
        </div>
        {!noHeader && <div className={`ml-auto flex`}>{children}</div>}
      </nav>
    </div>
  );
};

export { LogoNavigation };
