/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStoriesService } from "../../services/useStoriesService";
import { Story, StoryChunk } from "../../typings/Story";
import { useAudioRecorder } from "../../hooks/useAudioRecorder";
import { MLStateContext } from "../../services/stateProvider";

const useRecord = () => {
  const params = useParams();
  const isNewUser = params.id && params.id === "new";
  const id = params.id && !isNewUser ? params.id : "";
  const {
    state: { currentAiPrompt, currentStory },
    updateStateItem,
  } = useContext(MLStateContext);
  const [storyID, setStoryID] = useState<number>(Number(id));
  const [storyChunks, setStoryChunks] = useState<Array<StoryChunk>>([]);
  const [story, setStory] = useState<Story>();
  const [isLoading, setIsLoading] = useState(false);
  const { getById, createNew } = useStoriesService();
  const [isBuilding, setIsBuilding] = useState(false);
  const navigate = useNavigate();
  const messagesBoxRef = useRef<HTMLDivElement>(null);
  const scrollHeight = messagesBoxRef.current?.scrollHeight || 0;
  const clientHeight = messagesBoxRef.current?.clientHeight || 0;
  const isScrollVisible = scrollHeight > clientHeight;

  const handleTranscriptUpdate = (t: string) => {};

  const {
    changeStateToRecording,
    startRecording,
    saveRecording,
    recorderState,
  } = useAudioRecorder({
    handleTranscriptUpdate,
  });
  const { isRecording } = recorderState;

  useEffect(() => {
    if (id && id !== "new" && !story && !isLoading) {
      setIsLoading(true);
      refreshStory();
    }
  }, [id]);

  useEffect(() => {
    if (messagesBoxRef) {
      messagesBoxRef.current?.addEventListener("DOMNodeInserted", (event) => {
        const { currentTarget } = event;
        const target = currentTarget as HTMLDivElement;
        target?.scroll({ top: target.scrollHeight, behavior: "smooth" });
      });
    }

    return () => {
      if (isRecording) pauseRecording();
    };
  }, []);

  useEffect(() => {
    if (isRecording) {
      if (!story && !isLoading) {
        createNew((newStory) => {
          setStoryID(newStory.id);
          setStory(newStory);
          updateStateItem({ currentStory: newStory, isNewStory: true });
        });
      }
    }
  }, [isRecording]);

  useEffect(() => {
    if (currentStory && isRecording) startRecording();
  }, [currentStory, isRecording]);

  const refreshStory = () => {
    getById(storyID, (s) => {
      updateStateItem({ currentStory: s });
      setIsLoading(false);
      setStory(s);
      if (s.fullText) setStoryChunks(s.fullText);
    });
  };

  const pauseRecording = () => {
    saveRecording();
    buildingQuestion();
    refreshStory();
  };

  const buildingQuestion = () => {
    setIsBuilding(true);
    setTimeout(() => {
      setIsBuilding(false);
      goToFollowUpQuestions();
    }, 10000);
  };

  const toggleRecording = () => {
    if (isRecording) pauseRecording();
    else changeStateToRecording();
  };

  const saveStory = () => {
    if (story) {
      navigate(`/record/${story.id}/share`);
    }
  };

  const goToFollowUpQuestions = () => {
    if (story) {
      navigate(`/record/${story.id}/questions`);
    }
  };

  return {
    story,
    isLoading,
    isBuilding,
    isRecording,
    isNewUser,
    storyChunks,
    messagesBoxRef,
    currentAiPrompt,
    isScrollVisible,
    isNewStoryView: !storyID,
    goToFollowUpQuestions,
    toggleRecording,
    pauseRecording,
    saveStory,
    navigate,
  };
};

export { useRecord };
