import { useEffect, useState } from "react";
import { useOnboardingService } from "../../services/useOnboardingService";

interface StepProps {}

const useOnboarding = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animationClass, setAnimationClass] = useState("opacity-0");

  const {
    currentStep,
    isLoading,
    isStepActive,
    displayHelp,
    isVisible,
    stepNextNavigation,
    stepBackNavigation,
    setStepActive,
    setIsLoading,
    setDisplayHelp,
    setIsVisible,
    isMitchellMartin,
  } = useOnboardingService();

  useEffect(() => {
    setActiveIndex(currentStep || 0);
  }, [currentStep]);

  useEffect(() => {
    if (isVisible) {
      setAnimationClass("animate-fade-in opacity-100");
    } else {
      setAnimationClass("");
    }
  }, [isVisible]);

  useEffect(() => {
    setIsVisible(true);
  }, [displayHelp, activeIndex, isStepActive]);

  return {
    isLoading,
    activeIndex,
    setIsLoading,
    currentStep,
    isStepActive,
    displayHelp,
    setStepActive,
    setDisplayHelp,
    stepNextNavigation,
    stepBackNavigation,
    animationClass,
    isMitchellMartin,
  };
};

export { useOnboarding };
export type { StepProps };
