import { useAPI } from "../utils/api-connection";
import { LoginValues, RegisterValues } from "../typings/form-schemas";
import {
  LoginResponse,
  PasswordChangeRequest,
  PasswordReset,
  UserDetailInfo,
} from "../typings/User";
import { MLStateContext } from "./stateProvider";
import { useContext } from "react";
import { NotificationDetail } from "../typings/Notifications";
import { SignupInvitationDetails } from "../typings/Invitations";
import { USER_AI } from "../constants/services";

const useAuthService = () => {
  const {
    closeSession,
    resetPassword,
    changePassword,
    registerNewUser,
    getInvitationInfo,
    emailConfirmation,
    loginViaUserAndPassword,
    resetPasswordAndDeactivateUser,
  } = useAPI();
  const { updateStoredState, updateStateItem } = useContext(MLStateContext);

  const storeApiKey = (loginResponse: LoginResponse) => {
    const { api_key } = loginResponse;
    const userDetails = new UserDetailInfo(loginResponse);
    const { followers, following, notifications, stories } = loginResponse;

    updateStoredState({
      API_KEY: api_key.key,
      apiKeyExpDate: api_key.expiration_date,
      userDetails,
    });

    updateStateItem({
      stories: stories ? userDetails.stories : [],
      following: following ? following.map((f) => new UserDetailInfo(f)) : [],
      followers: followers ? followers.map((f) => new UserDetailInfo(f)) : [],
      notifications: notifications
        ? notifications
            .map((n) => new NotificationDetail(n))
            // prevent the display of AI questions under the notification section
            .filter((n) => n.author.id !== USER_AI.ID)
        : [],
    });
  };

  const loginWithPassword = (c: LoginValues, callback?: () => void) => {
    return loginViaUserAndPassword(c).then((r) => {
      storeApiKey(r);
      if (callback) callback();
    });
  };

  const registerUser = (user: RegisterValues, reference?: string) => {
    return registerNewUser(user, reference).then((r) => {
      if (r && r.is_active) storeApiKey(r);
    });
  };

  const loadInvitationInfo = (invitation: string) => {
    return getInvitationInfo(invitation).then(
      (inv) => new SignupInvitationDetails(inv)
    );
  };

  const confirmEmail = (confirmationKey: string) => {
    return emailConfirmation(confirmationKey).then((r) => {
      if (r && r.is_active) storeApiKey(r);
    });
  };

  const updatePassword = (passDetail: PasswordChangeRequest) => {
    return changePassword(passDetail).then((r) => {
      if (r && r.is_active) storeApiKey(r);
    });
  };

  const setNewPassword = (resetPasswordData: PasswordReset) => {
    return resetPassword(resetPasswordData).then((r) => {
      if (r && r.is_active) storeApiKey(r);
    });
  };

  const requestResetPassword = (email: string) => {
    return resetPasswordAndDeactivateUser(email).then(() => {});
  };

  const logout = (redirect = true) => {
    closeSession(redirect);
  };

  return {
    logout,
    registerUser,
    confirmEmail,
    setNewPassword,
    updatePassword,
    loginWithPassword,
    loadInvitationInfo,
    requestResetPassword,
  };
};

export { useAuthService };
