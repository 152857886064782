import { FC, useRef } from "react";
import { useMyProfile, UseMyProfileProps } from "./useMyProfile";
import { Spinner } from "../../../components/Spinner";
import { UserAvatar } from "../../../components/UserAvatar";
import { Statement } from "../../../components/Statement";
import { Button } from "../../../components/Button";
import { ButtonTypes } from "../../../typings/components";
import { FormControl } from "../../../components/Forms/FormControl";
import { MaxWidthContainer } from "../../../components/MaxWidthContainer";
import { formatDate } from "../../../utils/dates";


const MyProfile: FC<UseMyProfileProps> = ({ closeMenu }) => {
  const { isLoading, userDetails, isEditing, setIsEditing, formikObject, GENDER_LIST, handleProfileImgChange, profileImgSrc } = useMyProfile({ closeMenu });
  const {
    submitForm,
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    isValid,
    setFieldValue
  } = formikObject;
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  return (
    <div className="flex flex-col h-full bg-quaternary ">
      <Spinner isLoading={isLoading} withBackdrop />
      <div className="absolute right-0">
      <UserAvatar
          src={
            profileImgSrc || userDetails?.profile.photo ||
            `https://picsum.photos/seed/${userDetails?.id}/200`
          }
          medium
          alt="Avatar"
          avatarExtraStyles="relative"
          extraStyles="ml-auto mr-10 mb-4 mt-8 w-[92px] h-[92px]"
          action={() => {
            if(hiddenFileInput && hiddenFileInput.current){
              hiddenFileInput.current.click();
            }
          }}>
            <div
              className={`cursor-pointer flex justify-center content-center border-2 border-white rounded-full bg-secondary absolute w-[23px] h-[23px] bottom-[20px] right-[40px] overflow-hidden`}
            >
            <div className="w-[23px] h-[23px] absolute right-[-99999px]">
              <input
                ref={hiddenFileInput}
                type="file"
                onChange={(e) => handleProfileImgChange(e)}
              />
              </div>
              <img 
              onClick={(e) => {
                if(hiddenFileInput && hiddenFileInput.current){
                  hiddenFileInput.current.click();
                }
              }} src="/assets/images/plus-white.svg" alt="Add More Icon" />
            </div>
        </UserAvatar>
      </div>
        <Statement
          text="My Profile"
          extraStyles="text-pansy-purple text-4xl mb-4 mx-5 pt-[120px]"
        />
      <MaxWidthContainer isMenuPageLayout>
      <form
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            submitForm();
          }}
        >
            <div className="border-light-borders border-b py-4 px-5">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="relative">
                  <label className="text-2xl">First Name:</label>
                </div>
                <div className="text-pansy-purple">
                {!isEditing ?
                    <p className="text-2xl">{userDetails?.firstName}</p>:
                    <FormControl
                        nonMargins
                        styleType="SECONDARY"
                        type="text"
                        name="firstName"
                        placeholder="firstName"
                        value={values.firstName}
                        touched={touched.firstName}
                        error={errors.firstName}
                        {...{ handleChange, handleBlur }}
                      />
                  }
                </div>
              </div>
            </div>
            <div className="border-light-borders border-b py-4 px-5">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="relative">
                  <label className="text-2xl">Last Name:</label>
                </div>
                <div className="text-pansy-purple">
                  {!isEditing ?
                    <p className="text-2xl">{userDetails?.lastName}</p>:
                    <FormControl
                        nonMargins
                        styleType="SECONDARY"
                        type="text"
                        name="lastName"
                        placeholder="LastName"
                        value={values.lastName}
                        touched={touched.lastName}
                        error={errors.lastName}
                        {...{ handleChange, handleBlur }}
                      />
                  }
                </div>
              </div>
            </div>
            <div className="border-light-borders border-b py-4 px-5">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="relative">
                  <label className="text-2xl">E-mail:</label>
                </div>
                <div className="text-pansy-purple">
                  {!isEditing ?
                    <p className="text-xl overflow-hidden text-ellipsis" title={userDetails?.email}>{userDetails?.email}</p>:
                    <FormControl
                        isDisabled
                        nonMargins
                        styleType="SECONDARY"
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={values.email}
                        touched={touched.email}
                        error={errors.email}
                        {...{ handleChange, handleBlur }}
                      />
                  }
                </div>
              </div>
              {isEditing &&
              <p className="mt-3 text-sm italic ">In order to modify your email, please contact support.</p>
              }    
            </div>
            <div className="border-light-borders border-b py-4 px-5">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="relative">
                  <label className="text-2xl">Mobile Phone:</label>
                </div>
                <div className="text-pansy-purple">
                {!isEditing ?
                  <p className="text-2xl">{userDetails?.username}</p>:
                  <FormControl
                      nonMargins
                      styleType="SECONDARY"
                      type="text"
                      name="username"
                      placeholder="Phone"
                      value={values.username}
                      touched={touched.username}
                      error={errors.username}
                      {...{ handleChange, handleBlur }}
                    />
                  }
                </div>
              </div>
            </div>
            <div className="border-light-borders border-b py-4 px-5">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="relative">
                  <label className="text-2xl">Date of Birth:</label>
                </div>
                <div className="text-pansy-purple">
                  {!isEditing ?
                  <p className="text-2xl">
                    { formatDate(userDetails?.profile.dateOfBirth ? userDetails?.profile.dateOfBirth : new Date())}</p>:
                  <>
                    <FormControl
                        nonMargins
                        styleType="SECONDARY"
                        type="date"
                        name="dateOfBirth"
                        placeholder="Date Of Birth"
                        value={values.profile?.dateOfBirth}
                        touched={touched.profile?.dateOfBirth}
                        error={errors.profile?.dateOfBirth}
                        handleChange={(selectedValue) =>
                          setFieldValue('profile.dateOfBirth', selectedValue.target.value)}
                        {...{ handleBlur }}
                      />
                      {errors.profile?.dateOfBirth && (
                        <p className="text-red-900 ml-4 mb-3">{errors.profile?.dateOfBirth}</p>
                      )}
                  </>
                  }
                </div>
              </div>
            </div>
            <div className="border-light-borders border-b py-4 px-5">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                <div className="relative">
                  <label className="text-2xl">Gender:</label>
                </div>
                <div className="text-pansy-purple">
                  {!isEditing ?
                    <p className="text-2xl">{userDetails?.profile.sex || ''}</p>:
                    <>
                      <select name="sex" value={values.profile.sex}
                        className="w-full px-4 rounded-full placeholder:text-normal border h-[50px] mb-1 border-normal"
                        onChange={(selectedValue) =>
                          setFieldValue('profile.sex', selectedValue.target.value)}
                        onBlur={(e) => handleBlur(e)}
                     >
                        <option value="" disabled>Select a Gender</option>
                        {GENDER_LIST.map((gender) => (
                          <option key={gender.id} value={gender.value}>
                            {gender.name}
                          </option>
                        ))}
                      </select>
                      {errors.profile?.sex && (
                        <p className="text-red-900 ml-4 mb-3">{errors.profile?.sex}</p>
                      )}
                    </>
                  }
                </div>
              </div>
            </div>
            <div className="pb-10">
            { isEditing && <>
              <Button
                  type="submit"
                  style={ButtonTypes.PRIMARY}
                  extraStyles={`text-black border border-black mx-auto mt-[60px] w-[200px]`}
                  label={'Save'}
                  disabled={!isValid}
                  action={() => null }
                />
              <Button
                  type="button"
                  style={ButtonTypes.TRANSPARENT}
                  extraStyles="text-black border-black mx-auto mt-3 w-[200px]"
                  label={'Cancel'}
                  action={() => setIsEditing(false) }
                />
            </>
            }
            <Button
                type="button"
                style={ButtonTypes.TERTIARY}
                extraStyles={`text-black border border-black mx-auto mt-[60px] md:max-w-[100px] ${isEditing && 'hidden'}`}
                label={'Edit'}
                action={() => setIsEditing(!isEditing) }
              />

            </div>
        </form>
      </MaxWidthContainer>
    </div>
  );
};

export { MyProfile };
