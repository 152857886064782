import { FC } from "react";
import { useStory } from "./useStory";
import { Statement, StatementSizes } from "../../components/Statement";
import { IconButton, IconButtonTypes } from "../../components/IconButton";
import { MaxWidthContainer } from "../../components/MaxWidthContainer";
import { Spinner } from "../../components/Spinner";
import { CommentOrQuestionInput } from "../../components/CommentOrQuestionInput";
import { CommentOrQuestionList } from "../../components/CommentOrQuestionList";
import { AudioAndTranscriptionItem } from "../../components/AudioAndTranscriptionItem";
import { CommentOrQuestion } from "../../components/CommentOrQuestion";
import { sortQuestionsByAnswer } from "../../utils/sort-n-filters";
import { ImageSelector } from "../../components/ImageSelector";
import { formatTime } from "../../utils/number-format";
import { UserAvatar } from "../../components/UserAvatar";
import { convertUTCDateToLocalDate } from "../../utils/dates";

interface StoryProps {
  isDisabled?: boolean;
}

const Story: FC<StoryProps> = () => {
  const {
    story,
    audioRef,
    questions,
    isLoading,
    isPlaying,
    isListener,
    userAvatar,
    mediaFiles,
    selectedImages,
    makeAFollowUpQuestion,
    saveSelectedImages,
    setSelectedImages,
    addMediaFiles,
    onAudioEnded,
    toggleAudio,
    navigate,
  } = useStory();

  return (
    <>
      <div className="bg-tertiary">
        <Spinner isLoading={isLoading} withBackdrop />
        {story && (
          <MaxWidthContainer isPageLayout extraStyles="pb-8">
            {/* <PillList pills={pills} /> */}
            {story.name && (
              <Statement text={story.name} size={StatementSizes.XL4} />
            )}
            <div className="mb-6 flex">
              <IconButton
                extraStyles={`transition-all mr-4 ${
                  isPlaying ? "animate-pulse" : ""
                }`}
                icon={isPlaying ? IconButtonTypes.PAUSE : IconButtonTypes.PLAY}
                onClick={toggleAudio}
                label={isPlaying ? "Pause" : "Play Memory"}
              />
              {!isListener && (
                <>
                  <ImageSelector
                    submitFiles={addMediaFiles}
                    selectedFiles={selectedImages}
                    setSelectedFiles={setSelectedImages}
                    libraryFiles={mediaFiles}
                    triggerNode={
                      <IconButton
                        extraStyles={`transition-all mr-4`}
                        icon={IconButtonTypes.PHOTO}
                        onClick={() => null}
                        label={"Add photos"}
                      />
                    }
                    saveSelection={saveSelectedImages}
                  />
                  <IconButton
                    label="Share"
                    icon={IconButtonTypes.SHARE}
                    onClick={() => navigate(`/record/${story.id}/share`)}
                  />
                </>
              )}
            </div>
            <audio
              className=""
              controls={false}
              ref={audioRef}
              onEnded={onAudioEnded}
            />

            <p className="whitespace-pre-line">
              {story.summary || story.description}
            </p>

            <div className="grid grid-cols-3 gap-4 auto-rows-fr lg:grid-cols-6 overflow-hidden mt-4">
              {story.media &&
                story.media.map((file) => (
                  <div
                    className="rounded-md overflow-hidden"
                    key={`image-selector-item-${file.id}`}
                  >
                    <img
                      className={`w-full h-full object-cover`}
                      alt={file.name}
                      src={file.fileURL}
                    />
                  </div>
                ))}
            </div>
            {isListener && (
              <>
                <Statement
                  text="Ask a follow-up question"
                  size={StatementSizes.XL4}
                  extraStyles="my-10"
                />
                <CommentOrQuestionInput
                  author={userAvatar}
                  callback={makeAFollowUpQuestion}
                  extraStyles="mb-11"
                />
              </>
            )}

            {/* 
              <Statement
                text="Would you tell me more about how that made you feel?"
                size={StatementSizes.XL2}
                extraStyles="my-10"
              /> 

              <Statement
                text="Tags"
                size={StatementSizes.XL3}
                extraStyles="mt-10 mb-8"
              />
              <PillList isDark pills={tags} />
            */}
            <Statement
              text="Follow-up questions"
              size={StatementSizes.XL3}
              extraStyles="my-10"
            />
            <CommentOrQuestionList
              questions={questions}
              extraStyles="-mx-4 mb-8 mt-8"
              isStoryteller={!isListener}
            />
            <Statement
              text="Recordings"
              size={StatementSizes.XL3}
              extraStyles="my-10"
            />
            <div className="-mx-4">
            {story.fullText?.length &&
              story.fullText?.filter((a) => a.followup_question === null)
              .map((a) => (
                <div key={`answered-questions-original-${a.id}`}>
                  <div className="flex items-center border-t border-t-light-borders text-normal py-5 px-4">
                    <div className="flex">
                      <UserAvatar src={story.author.profile.photo}
                        alt={story.author.fullName} small />
                    </div>
                    <div className="ml-4">
                      <span className="block font-bold text-sm w-full">
                        {formatTime(convertUTCDateToLocalDate(new Date(a.created_at)).getTime())}
                      </span>
                    </div>
                  </div>
                  <AudioAndTranscriptionItem
                    key={`audio-n-transcript-original-${a.id}`}
                    storyChunk={a}
                  />
                </div>
              ))}
              {story.followupQuestions
                .filter((a) => a.answers && a.answers.length > 0)
                .sort(sortQuestionsByAnswer)
                .map((a) => (
                  <div key={`answered-questions-${a.id}`}>
                    <CommentOrQuestion
                      author={{
                        src: a.author.profile.photo,
                        alt: a.author.fullName,
                      }}
                      comment={a}
                      date={a.updatedAt}
                    />
                    {a.answers?.map((r) => (
                      <AudioAndTranscriptionItem
                        key={`audio-n-transcript-${r.id}`}
                        storyChunk={r}
                      />
                    ))}
                  </div>
                ))}
            </div>
          </MaxWidthContainer>
        )}
      </div>
      {/*
        <MaxWidthContainer extraStyles="mb-14 pb-12">
          <Statement
            text="Up next"
            size={StatementSizes.XL4}
            extraStyles="my-10"
          />

          <Grid>
            <Card>
              <Statement text="Our wedding day in 1968" />
              <PillList
                isDark
                pills={[{ label: "3 min" }, { label: "12 photos" }]}
              />
            </Card>

            <Card>
              <Statement text="Growing up in Brooklyn" />
              <PillList
                isDark
                pills={[{ label: "2 min" }, { label: "16 photos" }]}
              />
            </Card>

            <Card>
              <Statement text="Staying in love through the years" />
              <PillList
                isDark
                pills={[{ label: "8 min" }, { label: "7 photos" }]}
              />
            </Card>
          </Grid>
        </MaxWidthContainer>
      */}
    </>
  );
};

export { Story };
