import * as Yup from "yup";
const EMAIL_REGEX =
  /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
const PHONE_REGEX = /^(\+91-|\+91|0)?\d{10}$/;

const getRequiredText = (name: string) => `${name} is a required field.`;
const validateEmailPhone = (value?: string) => {
  const isValidEmail = value ? EMAIL_REGEX.test(value) : false;
  const isValidPhone = value ? PHONE_REGEX.test(value) : false;
  if (!isValidEmail && !isValidPhone) return false;
  return true;
};
const validatePhone = (value?: string) => {
  const isValidPhone = value ? PHONE_REGEX.test(value) : false;
  if (!isValidPhone) return false;
  return true;
};

const validateDateOfBirth = (value?: Date) => {
  if (!value) return false;
  const dob = new Date(value);
  const validDate = new Date();
  const valid = validDate.getFullYear() - dob.getFullYear() >= 13;
  return Boolean(valid);
};

// const validateGender = (gender?: string) => {
//   if (!gender) return false;
//   return gender === ("male" || "female" || "na" || "ns");
// };

const text = Yup.string();
// const gender = Yup.string().test(
//   "gender-validation",
//   "Selected gender is not valid.",
//   validateGender
// );
const email = Yup.string().email("Email format is not ok.");
const password = Yup.string().min(8, "Password must be at least 8 characters");

export const passwordAndConfirmation = Yup.object().shape({
  password: password.required(getRequiredText("Password")),
  password_confirm: password.oneOf(
    [Yup.ref("password")],
    "Passwords do not match."
  ),
});
const getDateOfBirth = Yup
  .date()
  .required('Date of birth cannot be empty')
  .max(new Date(), 'Should be less than or equal to today')
  .test(
    'age',
    'Should be greater than 13 age old',
    validateDateOfBirth
  ).typeError('Date of birth cannot be empty');

const emailOrPhone = Yup.string().test(
  "email-phone-validation",
  "Enter valid Phone or Email",
  validateEmailPhone
);
const phone = Yup.string().test(
  "phone-validation",
  "Enter valid phone",
  validatePhone
);
const getRequiredString = (name: string) =>
  text.required(getRequiredText(name));

export const passwordChange = Yup.object()
  .shape({
    current_password: password.required(getRequiredText("Current password")),
  })
  .concat(passwordAndConfirmation);

export const loginSchema = Yup.object().shape({
  username: email.required(getRequiredText("Email")),
  password: password.required(getRequiredText("Password")),
});

export const registerSchema = Yup.object()
  .shape({
    first_name: getRequiredString("First name"),
    last_name: getRequiredString("Last name"),
    username: phone.required(getRequiredText("Phone")),
    email: email.required(getRequiredText("Email")),
    profile: Yup.object({
      // dateOfBirth: getDateOfBirth,
      // photo: text,
      // sex: gender.required(getRequiredText("Gender")),
    }),
    acceptTC: getRequiredString(getRequiredText("Terms and Conditions")),
  })
  .concat(passwordAndConfirmation);

export const profileSchema = Yup.object()
  .shape({
    firstName: getRequiredString("First name"),
    lastName: getRequiredString("Last name"),
    username: phone.required(getRequiredText("Phone")),
    email: email.required(getRequiredText("Email")),
    profile: Yup.object({
    dateOfBirth: getDateOfBirth,
    photo: text,
    photoSM: text,
    sex: text,
    }),
  })

export const inviteUserSchema = Yup.object().shape({
  email: emailOrPhone.required(getRequiredText("Email or phone")),
});

export const inviteUserByEmailSchema = Yup.object().shape({
  email: emailOrPhone.required(getRequiredText("Email")),
});

export const passwordReset = Yup.object().shape({
  email: email.required(getRequiredText("Email")),
});

export const editStorySchema = Yup.object().shape({
  name: getRequiredString("Title"),
  summary: getRequiredString("Summary"),
});
