import { FC } from "react";
import { useForgotPassword } from "./useForgotPassword";
import { MaxWidthContainer } from "../../components/MaxWidthContainer";
import { Statement } from "../../components/Statement";
import { Spinner } from "../../components/Spinner";
import { FormControl } from "../../components/Forms/FormControl";
import { ButtonTypes } from "../../typings/components";
import { Button } from "../../components/Button";

interface ForgotPasswordProps {}

const ForgotPassword: FC<ForgotPasswordProps> = () => {
  const { isLoading, formikObject } = useForgotPassword();
  const {
    submitForm,
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    isValid,
  } = formikObject;

  return (
    <div>
      <MaxWidthContainer isPageLayout extraStyles="pb-12 md:pb-4">
        <div className="flex flex-col h-full content-between">
          <div className="mr-[10%] mb-14 flex items-center">
            <Spinner withBackdrop isLoading={isLoading} />
            <Statement
              text="Forgot password"
              extraStyles="text-primary text-3xl"
            />
          </div>
          <p className="mb-6">To reset your password please fill your email.</p>
          <form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              submitForm();
            }}
          >
            <FormControl
              styleType="SECONDARY"
              type="email"
              name="email"
              placeholder="Email"
              value={values.email}
              touched={touched.email}
              error={errors.email}
              {...{ handleChange, handleBlur }}
            />
            <Button
              label="Reset"
              type="submit"
              style={ButtonTypes.PRIMARY}
              isFullWidth
              action={() => null}
              disabled={!isValid}
            />
          </form>
        </div>
      </MaxWidthContainer>
    </div>
  );
};

export { ForgotPassword };
