import classNames from "classnames";
import { FC } from "react";
// import { useRoundedImage } from "./useRoundedImage";

export interface RoundedImageProps {
  src: string;
  alt: string;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  action?: () => void;
  extraStyles?: string;
}

const RoundedImage: FC<RoundedImageProps> = ({
  src,
  alt,
  small = false,
  medium = false,
  large = false,
  extraStyles,
  action,
}) => {
  const size = small
    ? "w-[40px] h-[40px]"
    : medium
    ? "w-[80px] h-[80px]"
    : large
    ? "w-[113px] h-[113px]"
    : "w-[50px] h-[50px]";
  const styles = classNames([
    extraStyles,
    size,
    "inline-block rounded-full overflow-hidden",
    action ? 'cursor-pointer':''
  ]);
  return (
    <div className={styles} onClick={action}>
      <img src={src} alt={alt} />
    </div>
  );
};

export { RoundedImage };
