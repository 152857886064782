import { Category, CategoryDetail, CategoryResponse } from "./Category";
import { Story, StoryDetail, StoryResponse } from "./Story";

export type ChapterResponse = {
  id: number;
  name: string;
  description: string;
  category: CategoryResponse;
  words_count: number;
  stories_count: number;
  stories: StoryResponse[];
  order: number;
  is_active: boolean;
  resource_uri: string;
  updated_at: string;
  created_at: string;
};

export type Chapter = {
  id: number;
  name: string;
  description: string;
  category?: Category;
  wordsCount: number;
  storiesCount: number;
  stories: Story[];
  order: number;
  isActive: boolean;
  resourceUri: string;
  updatedAt: string;
  createdAt: string;
};

export class ChapterDetail implements Chapter {
  id: number;
  name: string;
  description: string;
  category?: Category;
  wordsCount: number;
  storiesCount: number;
  stories: Story[];
  order: number;
  isActive: boolean;
  resourceUri: string;
  updatedAt: string;
  createdAt: string;

  constructor(r: ChapterResponse) {
    this.id = r.id;
    this.name = r.name || r.category.name;
    this.description = r.description;
    this.category = r.category ? new CategoryDetail(r.category) : undefined;
    this.wordsCount = r.words_count | 0;
    this.storiesCount = r.stories_count;
    this.stories = r.stories ? r.stories.map((s) => new StoryDetail(s)) : [];
    this.order = r.order;
    this.isActive = r.is_active;
    this.resourceUri = r.resource_uri;
    this.updatedAt = r.updated_at;
    this.createdAt = r.created_at;
  }
}

export class ChapterRequest {
  id?: number;
  name?: string;
  description?: string;
  order?: number;
  is_active?: boolean;
  resource_uri?: string;
  updated_at?: string;
  created_at?: string;
  stories?: Array<{ id: number; order_in_chapter: number }>;

  constructor(r: Chapter) {
    this.id = r.id;
    this.name = r.name;
    this.description = r.description;
    this.order = r.order;
    this.is_active = r.isActive;
    this.resource_uri = r.resourceUri;
    this.updated_at = r.updatedAt;
    this.created_at = r.createdAt;
    this.stories = r.stories
      ? r.stories.map(({ id, orderInChapter, isActive }) => ({
          id,
          order_in_chapter: orderInChapter,
          is_active: isActive,
        }))
      : undefined;
  }
}
