import { FC, useContext, useEffect } from "react";
import { Statement } from "../../components/Statement";
import { MaxWidthContainer } from "../../components/MaxWidthContainer";
import { MLStateContext } from "../../services/stateProvider";
import { Button } from "../../components/Button";
import { useAuthService } from "../../services/useAuthService";

const Waitlist: FC = () => {
  const { updateStateItem } = useContext(MLStateContext);
  const { logout } = useAuthService();
  useEffect(() => {
    updateStateItem({ noHeader: true, noActionCenter: true });
  }, []);

  return (
    <div>
      <MaxWidthContainer isPageLayout extraStyles="pb-32 md:pb-4">
        <div className="flex flex-col justify-center content-around ml-[8%] mr-[8%]">
          <Statement
            text="Waitlist"
            extraStyles="text-primary text-center text-5xl mb-8"
          />
          <p className="text-center mb-4 text-2xl">
            We know you&apos;re excited to get started with Memory Lane!
          </p>
          <p className="text-center mb-4 text-2xl">
            We&apos;re experiencing intense demand, so we have added you to a
            waitlist while we work to expand our capacity.
          </p>
          <p className="text-center mb-4 text-2xl">
            We will contact you when you can start capturing your stories!
          </p>
          <Button extraStyles="mt-4" action={logout} label="Log out" />
        </div>
      </MaxWidthContainer>
    </div>
  );
};

export { Waitlist };
