import { FC } from "react";
import { useMyStories } from "./useMyStories";
import { MaxWidthContainer } from "../../../components/MaxWidthContainer";
import { Statement } from "../../../components/Statement";
import { Spinner } from "../../../components/Spinner";
import {
  ListItem,
  OptionedItemsList,
} from "../../../components/OptionedItemsList";
import { Story } from "../../../typings/Story";

interface MyStoriesProps {
  closeMenu: VoidFunction;
  stories: Array<Story>;
}

const MyStories: FC<MyStoriesProps> = ({ closeMenu, stories}) => {
  const { allStories, isLoading, options } = useMyStories({ closeMenu , stories});

  return (
    <div className="flex flex-col h-full">
      <Spinner isLoading={isLoading} withBackdrop />
      <Statement
        text="My Stories"
        extraStyles="pt-4 text-primary text-center text-3xl mb-16"
      />
      <MaxWidthContainer isMenuPageLayout>
        <OptionedItemsList<Story & ListItem>
          items={allStories}
          displayItems={(item) => (
            <div className="px-3 py-4 cursor-pointer" onClick={() => {
              options[0].action(item);
            }}>
              <Statement
                text={item.name}
                extraStyles="text-normal text-3xl font-light"
              />
              <p>{item.shortText}</p>
            </div>
          )}
          options={options}
          noItemsLabel="There are no stories to show."
        />
      </MaxWidthContainer>
    </div>
  );
};

export { MyStories };
