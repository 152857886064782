import { FC } from "react";
import { useChangePassword } from "./useChangePassword";
import { FormControl } from "../../Forms/FormControl";
import { Button } from "../../Button";
import { ButtonTypes } from "../../../typings/components";
import { Spinner } from "../../Spinner";

interface ChangePasswordProps {}

const ChangePassword: FC<ChangePasswordProps> = () => {
  const { isLoading, formikObject, goToForgotPassword } = useChangePassword();
  const {
    submitForm,
    touched,
    errors,
    values,
    handleChange,
    handleBlur,
    isValid,
  } = formikObject;

  return (
    <div className="">
      <h3 className="text-normal text-2xl font-thin text-center font-conversation">
        Change password
      </h3>
      <Spinner isLoading={isLoading} withBackdrop />
      <div className="py-8 px-3">
        <p className="mb-6">
          To change your password please fill the password and confirmation
          fields.
        </p>
        <form
          noValidate
          onSubmit={(e) => {
            e.preventDefault();
            submitForm();
          }}
        >
          <FormControl
            styleType="SECONDARY"
            type="password"
            name="current_password"
            placeholder="Current password"
            value={values.current_password}
            touched={touched.current_password}
            error={errors.current_password}
            {...{ handleChange, handleBlur }}
          />
          <FormControl
            styleType="SECONDARY"
            type="password"
            name="password"
            placeholder="Password"
            value={values.password}
            touched={touched.password}
            error={errors.password}
            {...{ handleChange, handleBlur }}
          />
          <FormControl
            styleType="SECONDARY"
            type="password"
            name="password_confirm"
            placeholder="Confirmation"
            value={values.password_confirm}
            touched={touched.password_confirm}
            error={errors.password_confirm}
            {...{ handleChange, handleBlur }}
          />
          <Button
            label="Change"
            type="submit"
            style={ButtonTypes.PRIMARY}
            isFullWidth
            action={() => null}
            disabled={!isValid}
          />
          <Button
            isFullWidth
            extraStyles="underline"
            style={ButtonTypes.LINK}
            label="Forgot password?"
            action={goToForgotPassword}
          />
        </form>
      </div>
    </div>
  );
};

export { ChangePassword };
