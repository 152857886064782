import { AI_AUTHOR } from "../constants/services";
import { sortQuestionsByDate } from "../utils/dates";
import { Category, CategoryDetail, CategoryResponse } from "./Category";
import { Chapter, ChapterDetail, ChapterResponse } from "./Chapter";
import {
  FollowUpQuestionDetails,
  FollowUpQuestionResponse,
  FollowUpQuestionChunk,
  FollowUpQuestionType,
} from "./FollowUpQuestion";
import { MediaFile, MediaFileDetail, MediaFileResponse } from "./MediaLibrary";
import { LoginResponse, UserBase, UserBasicInfo, UserResponse } from "./User";

const getAnswers = (ans: StoryChunk[]) =>
  ans.map(
    ({ followup_question, ...answer }) => answer
  ) as FollowUpQuestionChunk[];
export interface AddChunkToStoryProps {
  id: string;
  followupQuestion?: number;
  author: string;
  chunk: Blob;
  transcription: string;
}

export type StoryChunkResponse = {
  id: number;
  audio: string;
  text: string;
  created_at: string;
  followup_question?: FollowUpQuestionResponse;
};

export type StoryResponse = {
  id: number;
  name?: string;
  resource_uri: string;
  description?: string;
  summary?: string;
  commentary?: string;
  short_text?: string;
  order_in_chapter: number;
  is_active: boolean;
  full_text?: Array<StoryChunk>;
  mainAudio?: string;
  story_id?: string;
  chapter: ChapterResponse;
  category: CategoryResponse;
  // chapter?: ChapterResponse;
  storyteller: UserResponse;
  media?: Array<MediaFileResponse>;
  followup_questions: Array<FollowUpQuestionResponse>;
  protected_summary: boolean;
  protected_name: boolean;
};

export type StoryChunk = {
  id: number;
  audio: string;
  text: string;
  created_at: string;
  updated_at?: string;
  followup_question?: FollowUpQuestionResponse;
};

export type Story = {
  id: number;
  name?: string;
  resourceUri: string;
  description?: string;
  summary?: string;
  shortText?: string;
  commentary?: string;
  orderInChapter: number;
  isActive: boolean;
  fullText?: Array<StoryChunk>;
  mainAudio?: string;
  category?: Category;
  chapter?: Chapter;
  author: UserBase;
  media: Array<MediaFile>;
  followupQuestions: Array<FollowUpQuestionType>;
  isProtectedSummary: boolean;
  isProtectedName: boolean;
};

export class StoryDetail implements Story {
  id: number;
  name?: string;
  resourceUri: string;
  commentary?: string;
  description?: string;
  summary?: string;
  shortText?: string;
  orderInChapter: number;
  isActive: boolean;
  fullText?: Array<StoryChunk>;
  mainAudio?: string;
  category?: Category;
  chapter?: Chapter;
  author: UserBase;
  media: Array<MediaFile>;
  followupQuestions: Array<FollowUpQuestionDetails>;
  isProtectedSummary: boolean;
  isProtectedName: boolean;

  constructor(r: StoryResponse) {
    this.id = r.id;
    this.name = r.name;
    this.resourceUri = r.resource_uri;
    this.description = r.description;
    this.commentary = r.commentary;
    this.summary = r.summary;
    this.shortText = r.short_text;
    this.orderInChapter = r.order_in_chapter;
    this.isActive = r.is_active;
    this.isProtectedSummary = r.protected_summary;
    this.isProtectedName = r.protected_name;
    this.fullText = r.full_text;
    this.mainAudio = r.mainAudio;
    this.chapter = r.chapter ? new ChapterDetail(r.chapter) : undefined;
    this.category = r.category ? new CategoryDetail(r.category) : undefined;
    this.media = r.media?.map((m) => new MediaFileDetail(m)) || [];
    this.author = r.storyteller
      ? new UserBasicInfo(r.storyteller as LoginResponse)
      : AI_AUTHOR;
    const answers =
      this.fullText?.filter((c) => Boolean(c.followup_question)) || [];
    const questionsWithAnswers = r.followup_questions
      ? r.followup_questions
          .map((q) => new FollowUpQuestionDetails(q))
          .map((f) => ({
            ...f,
            answers: getAnswers(
              answers.filter((a) => a.followup_question?.id === f.id) || []
            ),
            storyRef: r.id,
            storyName: r.name,
          }))
      : [];
    this.followupQuestions = sortQuestionsByDate(questionsWithAnswers);
  }
}

export class StoryRequest {
  name?: string;
  summary?: string;
  description?: string;
  chapter?: string;
  category?: string;
  protected_summary: boolean;
  protected_name: boolean;

  constructor(r: Story) {
    this.name = r.name;
    this.summary = r.summary;
    this.description = r.description;
    this.chapter = r.chapter as unknown as string;
    this.category = r.category as unknown as string;
    this.protected_summary = r.isProtectedSummary;
    this.protected_name = r.isProtectedName;
  }
}

export type StoriesFilteredOptions = {
  fields?: string[];
  ownStories?: boolean;
  orderBy?: string;
  limit?: number;
};

const DEFAULT_STORIES_LIMIT = 9999;
export class StoriesFilteredOptionsRequest {
  fields?: string[];
  ownStories?: boolean;
  orderBy?: string;
  limit: number = DEFAULT_STORIES_LIMIT;
  constructor(r: StoriesFilteredOptions) {
    this.fields = r.fields || [];
    this.ownStories = r.ownStories || false;
    this.orderBy = r.orderBy || "-created_at";
    this.limit = r.limit || DEFAULT_STORIES_LIMIT;
  }
}
