import { useContext, useState } from "react";
import { MLStateContext } from "../../../services/stateProvider";
import {
  Notification,
  NotificationTypes,
} from "../../../typings/Notifications";
import { ListItem } from "../../../components/OptionedItemsList";
import { useInvitationsService } from "../../../services/useInvitationsService";
import { useInfoToastService } from "../../../services/useInfoToastService";
import { useNavigate } from "react-router-dom";

interface UseNotificationsProps {
  closeMenu: VoidFunction;
}

const useNotifications = ({ closeMenu }: UseNotificationsProps) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { createErrorMsg, createSuccessMsg } = useInfoToastService();
  const { respondInvitation } = useInvitationsService();
  const {
    state: { notifications: news = [] },
  } = useContext(MLStateContext);
  const notifications: Array<Notification & ListItem> = news.map((n) => ({
    ...n,
    optionsType: n.type,
  }));

  const acceptInvitation = (n: Notification) => {
    setIsLoading(true);
    respondInvitation(n, true, () => {
      const userFirstName = n.author.firstName || n.author.email;
      createSuccessMsg(
        n.userToIsListener
          ? {
              title: `You became ${userFirstName}'s listener`,
              message: `Now you can see the stories ${userFirstName} share with you. Also you can ask questions.`,
            }
          : {
              title: `${userFirstName} is now one of your Listeners`,
              message: `${userFirstName} will only see stories you share with your Listeners. Stories remain private until you share them.`,
            }
      );
      closeMenu();
    })
      .catch((e) => console.log(e))
      .finally(() => setIsLoading(false));
  };

  const denyInvitation = (n: Notification) => {
    setIsLoading(true);
    respondInvitation(n, false, () => {
      const userFirstName = n.author.firstName || n.author.email;
      createErrorMsg({
        title: "Invitation rejected",
        message: `You rejected ${userFirstName}'s invitation.`,
      });
      closeMenu();
    })
      .catch((e) => console.log(e))
      .finally(() => setIsLoading(false));
  };

  const acceptAndOpenStory = (n: Notification) => {
    setIsLoading(true);
    respondInvitation(n, true)
      .then(() => {
        navigate(`stories/${n.story?.id}`);
        closeMenu();
      })
      .catch((e) => console.log(e))
      .finally(() => setIsLoading(false));
  };

  const options = [
    {
      types: [NotificationTypes.INVITATION_REQUEST],
      label: "Accept",
      action: (i: Notification) => acceptInvitation(i),
    },
    {
      types: [NotificationTypes.INVITATION_REQUEST],
      label: "Reject",
      action: (i: Notification) => denyInvitation(i),
    },
    {
      types: [NotificationTypes.NEW_STORY],
      label: "Open story",
      action: (n: Notification) => acceptAndOpenStory(n),
    },
    {
      types: [NotificationTypes.FOLLOW_UP_QUESTION],
      label: "Check question",
      action: (n: Notification) => {
        navigate(`stories/${n.story?.id}`);
        closeMenu();
      },
    },
  ];

  return {
    isLoading,
    notifications,
    options,
  };
};

export { useNotifications };
