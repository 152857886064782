import { MLStateContext } from "./stateProvider";
import { useContext } from "react";
import { INITIAL_STATE } from "../constants/state";
import { useNavigate } from "react-router-dom";

const useTellStoryService = () => {
  const {
    state: { prompts, tellStory, tellstory_selectedCategory },
    updateStateItem,
  } = useContext(MLStateContext);

  const navigate = useNavigate();
  const setIsVisible = (isVisible = false) => {
    updateStateItem({
      tellStory: { ...tellStory, isVisible },
    });
  };
  const stepNextNavigation = () => {
    setStateWithAnimation(() => {
      if (tellStory?.currentStep !== undefined) {
        updateStateItem({
          tellStory: { ...tellStory, currentStep: tellStory.currentStep + 1 },
        });
      }
    });
  };

  const stepBackNavigation = () => {
    if(tellStory?.currentStep == 1) {
      setCategory(undefined);
    }
    setStateWithAnimation(() => {
      if (!tellStory?.displayHelp) {
        if (tellStory?.currentStep && tellStory.currentStep >= 1) {
          updateStateItem({
            tellStory: { ...tellStory, currentStep: tellStory.currentStep - 1 },
          });
        } else {
          resetStep();
          navigate("/");
        }
      } else {
        setDisplayHelp(false);
      }
    });
  };

  const goToStepNumber = (number: number) => {
    setStateWithAnimation(() => {
      if (tellStory) {
        updateStateItem({
          tellStory: { ...tellStory, currentStep: number },
        });
      }
    });
  };

  const resetStep = () => {
    updateStateItem({
      tellStory: { ...INITIAL_STATE.state.tellStory },
    });
  };

  const setIsLoading = (state: boolean) => {
    updateStateItem({
      tellStory: { ...tellStory, isLoading: state },
    });
  };

  const setCategory = (category: string | undefined) => {
    updateStateItem({
      tellstory_selectedCategory: category,
    });
  };

  const setDisplayHelp = (state: boolean) => {
    setStateWithAnimation(() =>
      updateStateItem({
        tellStory: { ...tellStory, displayHelp: state },
      })
    );
  };

  const setStateWithAnimation = (func: () => void) => {
    setIsVisible();
    setTimeout(func);
  };

  const cleanPromts = () => updateStateItem({ prompts: [] });

  const getSelectedCategory = () => {
    return tellstory_selectedCategory;
  };
  return {
    cleanPromts,
    prompts,
    tellStory,
    goToStepNumber,
    resetStep,
    stepBackNavigation,
    setCategory,
    stepNextNavigation,
    setDisplayHelp,
    setIsLoading,
    setIsVisible,
    getSelectedCategory,
    selectedCategory: tellstory_selectedCategory,
  };
};

export { useTellStoryService };
