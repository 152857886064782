import { NotificationDetail, NotificationResponse } from "./Notifications";
import { Story, StoryDetail, StoryResponse } from "./Story";

export type UserProfile = {
  emailConfirmed: boolean;
  langs: Array<string>;
  dateOfBirth: string;
  photo: string;
  photoSm: string;
  sex: string;
  waitlisted: boolean;
};

export type UserBase = {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  isActive: boolean;
  isStaff: boolean;
  isSuperuser: boolean;
  profile: UserProfile;
  username: string;
};

export type UserDetails = UserBase & {
  following?: Array<UserDetails>;
  followers?: Array<UserDetails>;
  notifications?: Array<NotificationDetail>;
  stories?: Array<Story>;
};

export interface ProfileResponse {
  email_confirmed: boolean;
  langs: Array<string>;
  date_of_birth: string;
  photo: string;
  photo_sm: string;
  sex: string;
  waitlisted: boolean;
}

export interface ProfileEditRequest {
  date_of_birth: string;
  sex: string;
}

export interface UserResponse {
  id: number;
  date_joined: string;
  email: string;
  first_name: string;
  full_name: string;
  is_active: boolean;
  is_staff: boolean;
  is_superuser: boolean;
  last_login: string;
  last_name: string;
  profile: ProfileResponse;
  resource_uri: string;
  username: string;
}

export interface PasswordChangeRequest {
  current_password: string;
  password: string;
  password_confirm: string;
}

export interface PasswordReset {
  user_id: number;
  token: string;
  password: string;
  password_confirm: string;
}

export interface LoginResponse extends UserResponse {
  api_key: {
    expiration_date: string;
    key: string;
  };
  following?: Array<LoginResponse>;
  followers?: Array<LoginResponse>;
  notifications?: Array<NotificationResponse>;
  stories?: Array<StoryResponse>;
}

export class UserInfoEditRequest {
  email: string;
  first_name: string;
  last_name: string;
  username: string;
  profile: ProfileEditRequest;

  constructor(profile: Partial<UserBase>) {
    this.email = profile.email || '';
    this.first_name = profile.firstName || '';
    this.last_name = profile.lastName || '';
    this.username = profile.username || '';
    this.profile = {
      date_of_birth: profile?.profile?.dateOfBirth || '',
      sex: profile?.profile?.sex || ''
    }
  }
}

export class UserProfileInfo implements UserProfile {
  emailConfirmed: boolean;
  langs: Array<string>;
  dateOfBirth: string;
  photo: string;
  photoSm: string;
  sex: string;
  waitlisted: boolean;

  constructor(profile: ProfileResponse) {
    this.emailConfirmed = profile?.email_confirmed;
    this.langs = profile?.langs;
    this.dateOfBirth = profile?.date_of_birth;
    this.photo = profile?.photo;
    this.photoSm = profile?.photo_sm;
    this.sex = profile?.sex;
    this.waitlisted = profile?.waitlisted;
  }
}

export class UserBasicInfo implements UserBase {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  username: string;
  email: string;
  isActive: boolean;
  isStaff: boolean;
  isSuperuser: boolean;
  profile: UserProfile;

  constructor(user: LoginResponse) {
    this.id = user.id;
    this.firstName = user.first_name;
    this.lastName = user.last_name;
    this.fullName = user.full_name;
    this.username = user.username;
    this.email = user.email;
    this.isActive = user.is_active;
    this.isStaff = user.is_staff;
    this.isSuperuser = user.is_superuser;
    this.profile = new UserProfileInfo(user.profile);
  }
}

export class UserDetailInfo implements UserDetails {
  id: number;
  firstName: string;
  lastName: string;
  fullName: string;
  username: string;
  email: string;
  isActive: boolean;
  isStaff: boolean;
  isSuperuser: boolean;
  profile: UserProfile;
  following?: Array<UserBase>;
  followers?: Array<UserBase>;
  notifications?: Array<NotificationDetail>;
  stories?: Array<Story>;

  constructor(user: LoginResponse) {
    this.id = user.id;
    this.firstName = user.first_name;
    this.lastName = user.last_name;
    this.fullName = user.full_name;
    this.username = user.username;
    this.email = user.email;
    this.isActive = user.is_active;
    this.isStaff = user.is_staff;
    this.isSuperuser = user.is_superuser;
    this.profile = new UserProfileInfo(user.profile);
    this.following = user.following
      ? user.following.map((f) => new UserBasicInfo(f))
      : [];
    this.followers = user.followers
      ? user.followers.map((f) => new UserBasicInfo(f))
      : [];
    this.notifications = user.notifications
      ? user.notifications.map((f) => new NotificationDetail(f))
      : [];
    this.stories = user.stories
      ? user.stories.map((s) => new StoryDetail(s))
      : [];
  }
}
