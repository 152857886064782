import { FC } from "react";
import { useOnboarding } from "./useOnboarding";
import { MaxWidthContainer } from "../../components/MaxWidthContainer";
import { LogoNavigation } from "../../components/LogoNavigation";
import { Statement, StatementSizes } from "../../components/Statement";
import { Button } from "../../components/Button";
import "./styles.css";
import { Step1 } from "./Steps/Step1";
import { Step2 } from "./Steps/Step2";
import { ButtonTypes } from "../../typings/components";
import { Step3 } from "./Steps/Step3";
import { IconButton, IconButtonTypes } from "../../components/IconButton";
import { Step4 } from "./Steps/Step4";
import { Step5 } from "./Steps/Step5";
import { Step6 } from "./Steps/Step6";
import { useNavigate } from "react-router-dom";

interface OnboardingProps {}

const Onboarding: FC<OnboardingProps> = () => {
  const {
    setStepActive,
    stepBackNavigation,
    setDisplayHelp,
    animationClass,
    displayHelp,
    currentStep,
    isStepActive,
    isMitchellMartin,
  } = useOnboarding();
  const navigate = useNavigate();
  const steps = [
    {
      label: "How it works",
      component: <Step1 />,
    },
    {
      label: "Microphone Check",
      component: <Step2 />,
    },
    {
      label: "Microphone Check",
      component: <Step3 />,
    },
    {
      label: "Create Story",
      component: <Step4 />,
    },
    {
      label: "Review your story",
      component: <Step5 />,
    },
    {
      label: "Review your story",
      component: <Step6 />,
    },
  ];

  return (
    <div className="fixed z-30 pt-8 bg-primary h-full w-full text-tertiary overflow-y-auto">
      <MaxWidthContainer extraStyles="h-full">
        <LogoNavigation isLightLogo noBackButton></LogoNavigation>
        <div>
          {isStepActive && (
            <>
              <div className={`flex flex-row justify-between relative`}>
                {isStepActive && (
                  <>
                    <IconButton
                      icon={IconButtonTypes.BACK}
                      onClick={() => stepBackNavigation()}
                      extraStyles="w-5 h-5 mr-auto"
                    />
                    {/* <div className="absolute top-[2px] right-0">
                    <Statement
                      text={`${(currentStep||0)+1} of ${steps.length}`}
                      size={StatementSizes.SM}
                      extraStyles="text-right opacity-50"
                    />
                  </div> */}
                  </>
                )}
              </div>
            </>
          )}
          <div className={`mb-8 pt-4 opacity-0 ${animationClass}`}>
            {!displayHelp && !isStepActive ? (
              <div className="text-center">
                <Statement
                  text="Welcome to Memory Lane!"
                  size={StatementSizes.XL3}
                  extraStyles="text-center"
                />
                <Statement
                  text="Discover and share the stories that matter."
                  size={StatementSizes.LG}
                  extraStyles="text-center mt-10"
                />
                <Statement
                  text="What brings you here today?"
                  size={StatementSizes.LG}
                  extraStyles="text-center mt-5"
                />
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 max-w-[900px] mx-auto mt-[100px]">
                  <Button
                    isFullWidth
                    extraStyles="mb-2 z-10"
                    style={ButtonTypes.TERTIARY}
                    label="I have a story worth sharing"
                    action={() => setStepActive()}
                  />
                  <Button
                    isFullWidth
                    extraStyles="mb-2 z-10"
                    style={ButtonTypes.TERTIARY}
                    label={
                      isMitchellMartin
                        ? "I want to discover some stories"
                        : "I want to discover a loved one’s stories"
                    }
                    action={() => navigate("/listen")}
                  />
                </div>
              </div>
            ) : (
              steps.filter((a, index) => {
                if (index === currentStep) {
                  return a;
                }
              })[0].component
            )}
            {displayHelp && (
              <div>
                <Statement
                  text="Hmm, we're having trouble hearing you."
                  size={StatementSizes.XL3}
                  extraStyles="text-center"
                />
                <h2 className="text-center pt-6 font-conversation text-2xl align-text-bottom">
                  Need help? Just click the{" "}
                  <span className="-mt-8 bg-[#D24B34] inline-block rounded-full w-[44px] h-[44px]">
                    <img
                      className="inline mt-2"
                      width={16}
                      height={16}
                      src="/assets/images/help-assist-log.svg"
                      alt="Sound wave"
                    />
                  </span>
                  <br />
                  in the lower right of your screen
                  <br />
                  and our Support Team will help you get going.
                </h2>
                <h2 className="text-center pt-6 font-conversation text-2xl">
                  You can also view this{" "}
                  <a className="underline cursor-pointer underline-offset-2">
                    help article
                  </a>{" "}
                  on recording setup and tips.
                </h2>
                <div className="flex max-w-[600px] mx-auto mt-[100px]">
                  <Button
                    extraStyles="mb-2 z-10 px-6 w-full md:w-[240px] md:mr-6"
                    style={ButtonTypes.TERTIARY}
                    label="Try again"
                    action={() => setDisplayHelp(false)}
                  />
                  {!isMitchellMartin && (
                    <Button
                      isFullWidth
                      extraStyles="mb-2 z-10"
                      style={ButtonTypes.TERTIARY}
                      label="Let me take a look around"
                      action={() => navigate("/mybook/")}
                    />
                  )}
                </div>
                <div className="flex justify-center max-w-[600px] mx-auto mt-4">
                  <Button
                    extraStyles="mb-2 z-10 px-6 w-full md:w-[240px] hover:underline"
                    style={ButtonTypes.LINK}
                    label="Proceed anyway"
                    action={() => {
                      navigate("/tellstory");
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </MaxWidthContainer>
    </div>
  );
};

export { Onboarding };
