import {
  MLStateContextType,
  MLStateType,
  MLStoredState,
  MLVolatileState,
  PAGE_STYLES,
  USER_MODES,
} from "../typings/state";

export const STORED_STATE_NAME = "memory-lane-cookies";
export const INITIAL_STATE: MLStateContextType = {
  state: {
    API_KEY: null,
    apiKeyExpDate: null,
    userDetails: null,
    pageStyle: PAGE_STYLES.PRIMARY,
    activeMode: USER_MODES.LISTENER,
    noHeader: false,
    noActionCenter: false,
    toasts: [],
    historyNavigation: [],
    initialPrompts: [],
    onBoarding: {
      currentStep: 0,
      isStepActive: false,
      microphoneAccess: false,
      maxStepReached: 0,
      isVisible: true,
    },
    tellStory: {
      currentStep: 0,
      maxStepReached: 0,
      isVisible: true,
    },
    tellstory_selectedCategory: "",
  },
  updateState: (s: MLStateType) => null,
  updateStateItem: (s: MLVolatileState) => null,
  updateStoredState: (s: MLStoredState) => null,
};
