/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect, useState, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useStoriesService } from "../../services/useStoriesService";
import { Story } from "../../typings/Story";
import { useAudioRecorder } from "../../hooks/useAudioRecorder";
import { FollowUpQuestionType } from "../../typings/FollowUpQuestion";
import { MLStateContext } from "../../services/stateProvider";
import { AI_AUTHOR } from "../../constants/services";

const useAnswerFollowUpQuestions = () => {
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const messagesBoxRef = useRef<HTMLDivElement>(null);
  const {
    state: { preSelectedQuestion, isNewStory },
    updateStateItem,
  } = useContext(MLStateContext);

  const addMoreQuestionsDefault = {
    id: -1,
    name: "haveSomethingToAdd",
    description: "Have something else to add?",
    author: AI_AUTHOR,
    text: "Have something else to add?",
    status: "active",
    isActive: true,
    createdAt: new Date(),
    resourceUri: "",
    updatedAt: new Date(),
  };

  const scrollBottom = () => {
    messagesBoxRef.current?.scroll({ top: 9999, behavior: "smooth" });
  };

  useEffect(() => {
    if (messagesBoxRef)
      messagesBoxRef.current?.addEventListener("DOMNodeInserted", scrollBottom);
  }, []);

  const [story, setStory] = useState<Story>();
  const [currentQuestion, setCurrentQuestion] =
    useState<FollowUpQuestionType>();
  const [isLoading, setIsLoading] = useState(false);
  const [hasRecordPengindToSave, setHasRecordPengindToSave] = useState(false);
  const [questions, setQuestions] = useState<Array<FollowUpQuestionType>>([]);
  const [answeredQuestions, setAnsweredQuestions] = useState<
    Array<FollowUpQuestionType>
  >([]);
  const { getById } = useStoriesService();
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const handleTranscriptUpdate = (_: string) => {};
  const {
    changeStateToRecording,
    startRecording,
    saveRecording,
    recorderState,
  } = useAudioRecorder({ handleTranscriptUpdate });
  const { isRecording } = recorderState;

  useEffect(() => {
    return () => {
      if (isRecording) pauseRecording();
      updateStateItem({
        currentFollowupQuestion: undefined,
      });
    };
  }, []);

  useEffect(() => {
    if (isRecording) {
      setHasRecordPengindToSave(true);
      startRecording();
    } else {
      refreshStory();
    }
  }, [isRecording]);

  useEffect(() => {
    if (story) {
      reloadNonAnsweredQuestions();
      reloadAnsweredQuestions();
    }
  }, [story]);

  useEffect(() => {
    scrollBottom();
  }, [currentQuestion]);

  const refreshStory = () => {
    setIsLoading(true);
    setTimeout(() => {
      getById(Number(id), (s) => {
        setStory(s);
        setIsLoading(false);
      });
    }, 5000);
  };

  const reloadAnsweredQuestions = () => {
    if (story) {
      const answered = story.followupQuestions
        .filter((a) => a.answers && a.answers.length > 0)
        .sort(
          ({ answers: aAns = [] }, { answers: bAns = [] }) =>
            aAns[0].id - bAns[0].id
        );
      setAnsweredQuestions(answered);
    }
  };

  const reloadNonAnsweredQuestions = () => {
    if (story) {
      const nonAnswered = story.followupQuestions
        .filter((a) => a.status === "active")
        .filter((a) => !a.answers || a.answers.length === 0);
      let current = nonAnswered[0];
      setQuestions([...nonAnswered, addMoreQuestionsDefault]);

      if (preSelectedQuestion) {
        current = preSelectedQuestion;
      }

      setCurrentQuestion(current);
      updateStateItem({
        currentFollowupQuestion: current,
        currentStory: story,
      });
    }
  };

  const pauseRecording = () => {
    if (currentQuestion) {
      saveRecording();
      updateStateItem({
        preSelectedQuestion: undefined,
      });
    }
  };

  const toggleRecording = () =>
    isRecording ? pauseRecording() : changeStateToRecording();

  const saveStory = () => {
    if (isNewStory) {
      navigate(`/record/${story?.id}/share`);
    } else {
      navigate(`/stories/${story?.id}`);
    }
  };

  const sliderUpdateSelectedQuestion = (
    current: FollowUpQuestionType | undefined
  ) => {
    setCurrentQuestion(current);
    updateStateItem({
      currentFollowupQuestion: current,
    });
  };

  return {
    story,
    isLoading,
    questions,
    isRecording,
    hasRecordPengindToSave,
    messagesBoxRef,
    currentQuestion,
    preSelectedQuestion,
    answeredQuestions,
    setCurrentQuestion: sliderUpdateSelectedQuestion,
    toggleRecording,
    pauseRecording,
    saveStory,
    navigate,
  };
};

export { useAnswerFollowUpQuestions };
