import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { MLStateContext } from "../../services/stateProvider";

const useLobby = () => {
  const navigate = useNavigate();
  const { updateStateItem } = useContext(MLStateContext);

  useEffect(() => {
    updateStateItem({
      noHeader: true,
      noActionCenter: true,
    });
    return () => {
      updateStateItem({
        noHeader: false,
        noActionCenter: false,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { navigate };
};

export { useLobby };
