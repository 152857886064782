import { FC, useContext } from "react";
import { Statement, StatementSizes } from "../../../../components/Statement";
import { StepProps } from "../../useTellStory";
import { useStep1 } from "./useStep1";
import { MLStateContext } from "../../../../services/stateProvider";
import { PARTNERSHIPS } from "../../../../constants/services";
import { Button } from "../../../../components/Button";
import { ButtonTypes } from "../../../../typings/components";

const Step1: FC<StepProps> = () => {
  const {
    isRecording,
    setPromptCategory,
    readyToStart,
    displayHelp,
    chapters,
  } = useStep1();

  const {
    state: { partnership },
  } = useContext(MLStateContext);

  if (partnership === PARTNERSHIPS.MITCHELL_MARTIN) {
    return (
      <div className="text-center py-20">
        <Statement
          text="Happy anniversary, Mitchell Martin!"
          size={StatementSizes.XL4}
          extraStyles="text-center"
        />
        <Statement
          text="We'd love to hear your piece of the Mitchell Martin story."
          size={StatementSizes.XL2}
          extraStyles="text-center mt-8"
        />
        <div className="flex justify-center mt-8">
          <Button
            style={ButtonTypes.SECONDARY}
            label="Continue"
            action={() => setPromptCategory("")}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      {!displayHelp && (
        <div className="text-center">
          {!isRecording && !readyToStart && (
            <>
              <Statement
                text="Talk about anything you’d like."
                size={StatementSizes.XL4}
                extraStyles="text-center"
              />
              <Statement
                text="Everyone’s story is different, so you can always choose"
                size={StatementSizes.XL2}
                extraStyles="text-center mt-8"
              />
              <Statement
                text="which topic you’d like to talk about."
                size={StatementSizes.XL2}
                extraStyles="text-center"
              />
              <div className="max-w-[800px] mx-auto mt-10">
                <div className="flex flex-wrap justify-center">
                  {chapters?.map((c) => (
                    <span
                      className="border-2 px-2 mr-2 my-2 text-xl border-white rounded-xl cursor-pointer"
                      key={`chapter-item-${c.id}`}
                      onClick={() => setPromptCategory(c.category?.name || "")}
                    >
                      {c.category?.name || ""}
                    </span>
                  ))}
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export { Step1 };
