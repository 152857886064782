export enum AI_PROVIDER {
  DEEPGRAM = "DEEPGRAM",
  OPEN_AI = "OPEN_AI",
  EDEN_AI = "EDEN_AI",
}

export enum HOTJAR {
  ID = 3571029,
  VERSION = 6
}

export enum INTERCOM_APP {
  BASE_URL = 'https://api-iam.intercom.io',
  ID = 'q7qszjfj'
}
