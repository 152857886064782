import { FC } from "react";
import { useStorySharing } from "./useStorySharing";
import { MaxWidthContainer } from "../../components/MaxWidthContainer";
import { Button } from "../../components/Button";
import { ButtonTypes } from "../../typings/components";
import { IconButton, IconButtonTypes } from "../../components/IconButton";
import { LogoNavigation } from "../../components/LogoNavigation";
import { Statement } from "../../components/Statement";
import { Spinner } from "../../components/Spinner";
import {
  ListItem,
  OptionedItemsList,
} from "../../components/OptionedItemsList";
import { UserDetails } from "../../typings/User";
import { UserAvatar } from "../../components/UserAvatar";
import { TabMenu } from "../../components/TabMenu";
import { AddListeners } from "./AddListeners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface StorySharingProps {}

const StorySharing: FC<StorySharingProps> = () => {
  const {
    tabs,
    isLoading,
    userDetails,
    selectedTab,
    filteredListeners,
    additionalListeners,
    addAdditionalListener,
    removeAdditionalListener,
    shareStoryWithListeners,
    navigate,
  } = useStorySharing();

  return (
    <div className="fixed z-30 pt-8 bg-primary h-full w-full text-tertiary">
      <MaxWidthContainer extraStyles="h-full">
        <Spinner isLoading={isLoading} withBackdrop />
        <LogoNavigation isLightLogo>
          <IconButton
            icon={IconButtonTypes.CLOSE}
            onClick={() => navigate("/")}
          />
        </LogoNavigation>
        <div className="h-[90vh] overflow-y-auto">
          <Statement
            text={`Congrats ${userDetails?.firstName}! Do you want to share this story with your listeners?`}
            extraStyles="text-5xl text-center"
          />
          <div className="my-16">
            <TabMenu
              extraStyles="flex w-full justify-evenly mb-16"
              activeStyles="border-transparent text-white bg-secondary"
              inActiveStyles="border-transparent text-white"
              tabs={tabs}
            />
            <div className="">
              <OptionedItemsList<UserDetails & ListItem>
                items={filteredListeners}
                displayItems={(listener) => (
                  <div className="flex items-center my-1">
                    <UserAvatar
                      src={listener.profile.photo}
                      alt={listener.email}
                      extraStyles={`mr-4 w-[34px] h-[34px]`}
                    />
                    <p className="text-md">{listener.fullName}</p>
                    {/* <div className="flex-1 flex justify-end">
                    this will hold checkboxes for costume selection
                  </div> */}
                  </div>
                )}
                options={[]}
                noItemsLabel="This will be a private story."
              />
            </div>
            {additionalListeners && additionalListeners.length > 0 && (
              <>
                <Statement
                  text="Additional listeners:"
                  extraStyles="mt-4 text-xl"
                />
                <ul>
                  {additionalListeners.map((l) => (
                    <li
                      key={`additional-listener-${l}`}
                      className="ml-2 pr-4 flex justify-between"
                    >
                      {l}
                      <button onClick={() => removeAdditionalListener(l)}>
                        <FontAwesomeIcon
                          className="text-tertiary"
                          icon={faTimes}
                        />
                      </button>
                    </li>
                  ))}
                </ul>
              </>
            )}
            {selectedTab !== "PRIVATE" && (
              <AddListeners addListener={addAdditionalListener} />
            )}
          </div>
          <Button
            label="Continue"
            extraStyles="w-[90%] max-w-[450px] mx-auto"
            style={ButtonTypes.TERTIARY}
            action={shareStoryWithListeners}
          />
        </div>
      </MaxWidthContainer>
    </div>
  );
};

export { StorySharing };
