import { FC } from "react";
import { useQuickActionPrompt } from "./useQuickActionPrompt";
import classNames from "classnames";
import { AddListener } from "../QuickActions/AddListener";
import { QUICK_ACTIONS } from "../../constants/services";
import { AskQuestion } from "../QuickActions/AskQuestion";
import { AddStoryteller } from "../QuickActions/AddStoryteller";

interface QuickActionPromptProps {}

const QuickActionPrompt: FC<QuickActionPromptProps> = () => {
  const { isOpen, activeQuickAction, closeQuickAction } =
    useQuickActionPrompt();

  const menuStyles = classNames(
    "w-full bg-primary fixed rounded-t-2xl transition-all bottom-0 md:max-w-[454px] right-[50%] translate-x-[50%] shadow-2xl shadow-black",
    isOpen ? "translate-y-0 pt-10" : "translate-y-full h-0"
  );
  const collapseMenuBackdrop = classNames(
    isOpen ? "h-full" : "h-0",
    "fixed left-0 bottom-0 w-full"
  );

  const getQuickActionToShow = () => {
    switch (activeQuickAction) {
      case QUICK_ACTIONS.ADD_NEW_LISTENER:
        return <AddListener />;

      case QUICK_ACTIONS.ADD_NEW_STORYTELLER:
        return <AddStoryteller />;

      case QUICK_ACTIONS.ASK_QUESTION:
        return <AskQuestion />;
    }
    return null;
  };

  return (
    <div className="fixed z-20">
      <div className={collapseMenuBackdrop} onClick={closeQuickAction}></div>
      <div className={menuStyles}>{getQuickActionToShow()}</div>
    </div>
  );
};

export { QuickActionPrompt };
