import classNames from "classnames";
import { FC } from "react";
import { RoundedImage, RoundedImageProps } from "../RoundedImage";
// import { useUserAvatar } from "./useUserAvatar";

export interface UserAvatarProps extends RoundedImageProps {
  avatarExtraStyles?: string;
}

const UserAvatar: FC<React.PropsWithChildren<UserAvatarProps>> = (props) => {
  const styles = classNames("flex relative", [props.avatarExtraStyles]);
  return (
    <div className={styles}>
        <RoundedImage {...props}/>
        {props.children}
    </div>
  );
};

export { UserAvatar };
