import { useContext } from "react";
import { Invitation } from "../typings/Invitations";
import { useAPI } from "../utils/api-connection";
import { MLStateContext } from "./stateProvider";
import { NotificationDetail } from "../typings/Notifications";

const useInvitationsService = () => {
  const {
    sendInvitationToListener,
    sendInvitationToStoryteller,
    getAllInvitations,
    acceptDenyInvitation,
    sendInvitationsToStory,
  } = useAPI();
  const {
    state: { notifications = [] },
    updateStateItem,
  } = useContext(MLStateContext);

  const addNewListener = (c: string) => {
    return sendInvitationToListener(c);
  };

  const addNewStoryteller = (c: string) => {
    return sendInvitationToStoryteller(c);
  };

  const respondInvitation = (
    inv: NotificationDetail,
    response = true,
    callback?: () => void
  ) => {
    return acceptDenyInvitation(inv.id, response).then(() => {
      updateStateItem({
        notifications: notifications.filter(
          (n) => n.resourceUri !== inv.resourceUri
        ),
      });
      if (callback) callback();
    });
  };

  const getAll = (callback: (invitations: Invitation[]) => void) => {
    getAllInvitations()
      .then(callback)
      .catch((e) => console.log(e));
  };

  const inviteListenersToStory = (
    storyUid: string,
    listeners: Array<string>
  ) => {
    const invitations = listeners.map((l) => ({
      user_to_email: l,
      story: storyUid,
    }));
    return sendInvitationsToStory(invitations);
  };

  return {
    addNewListener,
    addNewStoryteller,
    getAll,
    respondInvitation,
    inviteListenersToStory,
  };
};

export { useInvitationsService };
