import { FC } from "react";
// import { useRecordingButton } from "./useRecordingButton";

interface RecordingButtonProps {
  action: VoidFunction;
  activeLabel?: string;
  inActiveLabel?: string;
  isActive?: boolean;
}

const RecordingButton: FC<RecordingButtonProps> = ({
  isActive = false,
  activeLabel = "Stop",
  inActiveLabel = "Start",
  action,
}) => {
  return (
    <div className="flex flex-col justify-center min-h-[220px]">
      <div
        onClick={action}
        className="relative flex justify-center items-center h-[110px] w-[110px] rounded-full cursor-pointer"
      >
        <span
          className={`${
            isActive && "animate-pulse"
          } relative z-20 text-tertiary flex justify-center items-center w-full h-full`}
        >
          {isActive && (
            <span className="absolute -top-[35px] text-secondary">
              Recording
            </span>
          )}
          {isActive ? activeLabel : inActiveLabel}
        </span>
        <span
          className={`absolute -z-10 w-[120%] h-[120%] rounded-full bg-[#331423]`}
        ></span>
        <span
          className={`${
            isActive
              ? "animate-pulse-slow w-[200%] h-[200%]"
              : "w-[100%] h-[100%]"
          } absolute -z-10 rounded-full bg-[#331423] transition-all duration-700`}
        ></span>
        <span
          className={`${
            isActive && "animate-ping-slow"
          } absolute z-0 w-full h-full rounded-full border-4 border-primary bg-[#331423]`}
        ></span>
        <span
          className={`${
            isActive ? "border-secondary bg-secondary/[.40]" : "border-tertiary"
          } absolute w-full h-full border-4 rounded-full transition-all duration-500`}
        ></span>
      </div>
    </div>
  );
};

export { RecordingButton };
