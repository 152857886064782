import { useContext, useEffect, useState } from "react";
import { useAudioRecorder } from "../../../../hooks/useAudioRecorder";
import { MLStateContext } from "../../../../services/stateProvider";
import { useOnboardingService } from "../../../../services/useOnboardingService";
import { Story } from "../../../../typings/Story";
import { UserBase } from "../../../../typings/User";
import { PARTNERSHIPS } from "../../../../constants/services";
import { useNavigate } from "react-router-dom";

const useStep3 = () => {
  const {
    stepNextNavigation,
    stepBackNavigation,
    setDisplayHelp,
    displayHelp,
  } = useOnboardingService();
  const {
    state: { currentStory, partnership },
    updateStateItem,
  } = useContext(MLStateContext);
  const [readyToStart, setReadyToStart] = useState<boolean>(false);
  const [transcriptText, setTranscriptText] = useState<string>("");
  const [story, setStory] = useState<Story>();
  const [isLoading, setIsLoading] = useState(false);
  const handleTranscriptUpdate = (t: string) => {
    setTranscriptText((prev) => `${prev} ${t}`);
  };
  const {
    changeStateToRecording,
    startRecording,
    cancelRecording,
    recorderState,
  } = useAudioRecorder({
    handleTranscriptUpdate,
  });
  const { isRecording } = recorderState;

  const recordItem = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (isRecording) {
      if (!story && !isLoading) {
        const mockStory = {
          id: 0,
          resourceUri: "",
          isActive: false,
          media: [],
          author: null as unknown as UserBase,
          followupQuestions: [],
          isProtectedSummary: false,
          isProtectedName: false,
        } as unknown as Story;
        setStory(mockStory);
        updateStateItem({ currentStory: mockStory, isNewStory: true });
      }
    }
  }, [isRecording]);

  useEffect(() => {
    if (currentStory && isRecording) {
      startRecording();
    }
  }, [currentStory, isRecording]);

  const toggleRecording = () => {
    if (isRecording) pauseRecording();
    else changeStateToRecording();
  };

  const goBack = () => {
    stepBackNavigation();
  };

  const pauseRecording = () => {
    cancelRecording();
    console.log("transcriptText 1", transcriptText);
    if (transcriptText.length > 0) {
      setStory(undefined);
      setReadyToStart(true);
      console.log("Great, we can hear you!");
    } else {
      setStory(undefined);
      setReadyToStart(false);
      setDisplayHelp(true);
    }
  };

  const goToRecordStores = () => {
    navigate("/tellstory");
  };

  const goForward = () => {
    if (partnership === PARTNERSHIPS.MITCHELL_MARTIN) {
      goToRecordStores();
    } else {
      stepNextNavigation();
    }
  };
  return {
    goBack,
    goForward,
    displayHelp,
    transcriptText,
    isRecording,
    recordItem,
    story,
    readyToStart,
    toggleRecording,
    setIsLoading,
    isMitchellMartin: partnership === PARTNERSHIPS.MITCHELL_MARTIN,
  };
};

export { useStep3 };
