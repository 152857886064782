import { CustomMainModalProps } from "../components/MainModalActions/CustomMainModal";
import { AiPrompt, Prompt } from "./Prompts";
import { FollowUpQuestionType } from "./FollowUpQuestion";
import { ToastMessage } from "./InfoToast";
import { Notification } from "./Notifications";
import { Story } from "./Story";
import { UserDetailInfo } from "./User";
import { UserTellStory, UserOnboarding } from "./StepSettings";

export enum PAGE_STYLES {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
}

export enum USER_MODES {
  STORYTELLER = "STORYTELLER",
  LISTENER = "LISTENER",
}

export type MLStoredState = {
  API_KEY?: string | null;
  activeMode?: USER_MODES;
  apiKeyExpDate?: string | null;
  userDetails?: UserDetailInfo | null;
  partnership?: string;
};

export type MLVolatileState = {
  aiPrompts?: Array<AiPrompt>;
  prompts?: Array<Prompt>;
  currentAiPrompt?: AiPrompt;
  isNewStory?: boolean;
  currentFollowupQuestion?: FollowUpQuestionType;
  preSelectedQuestion?: FollowUpQuestionType;
  pageStyle?: PAGE_STYLES;
  activeMode?: USER_MODES;
  currentStory?: Story;
  isEditingStory?: boolean;
  noHeader?: boolean;
  noActionCenter?: boolean;
  activeQuickAction?: string | null;
  activeMainModalAction?: string | null;
  customMainModalProps?: CustomMainModalProps | null;
  following?: Array<UserDetailInfo>;
  followers?: Array<UserDetailInfo>;
  notifications?: Array<Notification>;
  initialPrompts?: Array<Prompt>;
  stories?: Array<Story>;
  toasts?: Array<ToastMessage>;
  historyNavigation?: Array<string>;
  onBoarding?: UserOnboarding;
  tellStory?: UserTellStory;
  tellstory_selectedCategory?: string;
};

export type MLStateType = MLStoredState & MLVolatileState;

export type MLStateContextType = {
  state: MLStateType;
  updateState: (s: MLStateType) => void;
  updateStateItem: (s: MLStateType) => void;
  updateStoredState: (s: MLStoredState) => void;
};
