import { FC } from "react";
import { useChapterDetail } from "./useChapterDetail";
import { MaxWidthContainer } from "../../components/MaxWidthContainer";
import { Spinner } from "../../components/Spinner";
import { useLocation } from "react-router-dom";
import { OrderList } from "primereact/orderlist";
import { Statement, StatementSizes } from "../../components/Statement";
import { ProgressBar } from "primereact/progressbar";
import { TARGET_WORDS_PER_CHAPTER } from "../../constants/services";
import { StoryTile } from "../../components/StoryTile";
import { Story } from "../../typings/Story";

interface ChapterDetailProps {}
interface DraggableDivider {
  isDivider: true;
}

const ChapterDetail: FC<ChapterDetailProps> = () => {
  const { chapter, isLoading, activeStories, storiesTiles, setStoriesTiles } =
    useChapterDetail();
  const { pathname: activeRoute } = useLocation();
  const isMainLayout = activeRoute === "/";

  const dividerIndex = storiesTiles.findIndex(
    (i) => (i as DraggableDivider).isDivider
  );

  const excludedChaptersDivider = (
    <div
      draggable={false}
      className="bg-primary text-tertiary p-6 rounded-2xl text-xs md:text-base"
    >
      Stories moved below this mark will not be included in your printed
      chapter.
    </div>
  );

  const itemTemplate = (c: Story) => {
    const chapterIndex = storiesTiles.indexOf(c);
    return c.id !== undefined ? (
      <StoryTile story={c} isDisabled={chapterIndex > dividerIndex} />
    ) : (
      excludedChaptersDivider
    );
  };

  return (
    <div>
      <MaxWidthContainer
        isPageLayout
        extraStyles="pb-12 md:pb-4"
        isMainLayout={isMainLayout}
      >
        <Spinner isLoading={isLoading} withBackdrop />
        <div>
          <div className="flex flex-col w-full max-w-[600px] my-0 mx-auto mb-10 items-center">
            <Statement
              extraStyles="mb-4"
              size={StatementSizes.XL4}
              text={chapter?.name}
            />
            <div className="flex flex-col w-full">
              <ProgressBar
                value={
                  (100 / TARGET_WORDS_PER_CHAPTER) * (chapter?.wordsCount || 0)
                }
                color="#5DA4B0"
                displayValueTemplate={() => null}
                style={{ height: 14, border: "1px solid lightgray" }}
              ></ProgressBar>
              <p className="text-center text-xl mt-2">
                <span className="font-bold">
                  {activeStories.length} Stories
                </span>{" "}
                totaling{" "}
                <span className="font-bold text-chapters-progress">
                  {chapter?.wordsCount} Words
                </span>
              </p>
            </div>
          </div>
          <div className="ml-2 mr-2 bg-primary text-tertiary p-6 rounded-2xl">
            <p className="mb-4">Welcome to Chapter page!</p>
            <ul className="list-decimal ml-8 text-sm md:text-base">
              <li>
                Click on a story to view, edit, add more, or move it to another
                chapter
              </li>
              <li>Click and drag a story to change its order</li>
              <li>
                Remove a story from this chapter by dragging it to the bottom of
                the page
              </li>
            </ul>
          </div>
          <Statement
            size={StatementSizes.XL4}
            text="Stories"
            extraStyles="mt-14 ml-1 mb-1"
          />
          <OrderList
            dragdrop
            value={storiesTiles}
            onChange={(e) => setStoriesTiles(e.value)}
            itemTemplate={itemTemplate}
          ></OrderList>
        </div>
      </MaxWidthContainer>
    </div>
  );
};

export { ChapterDetail };
