import { FC } from "react";
import { MaxWidthContainer } from "../../components/MaxWidthContainer";
import { Statement } from "../../components/Statement";

interface CheckYourEmailProps {}

const CheckYourEmail: FC<CheckYourEmailProps> = () => {
  return (
    <div>
      <MaxWidthContainer isPageLayout extraStyles="pb-12 md:pb-4">
        <div className="flex flex-col h-full content-between">
          <div className="mr-[10%] mb-14">
            <Statement
              text="Check your email to confirm your account"
              extraStyles="text-primary text-4xl"
            />
            <Statement
              text='If you do not see an email from Memory Lane within a few minutes, please check your "Spam" or "Junk mail" folder.'
              extraStyles="mt-2 text-primary text-4xl"
            />
          </div>
        </div>
      </MaxWidthContainer>
    </div>
  );
};

export { CheckYourEmail };
