import { useOnboardingService } from "../../../../services/useOnboardingService";

const useStep1 = () => {
  const { stepNextNavigation, stepBackNavigation, isMitchellMartin } =
    useOnboardingService();
  const goBack = () => {
    stepBackNavigation();
  };
  const goForward = () => {
    stepNextNavigation();
  };
  return { goBack, goForward, isMitchellMartin };
};

export { useStep1 };
