import { FC, useEffect } from "react";
import classNames from "classnames";
import { Button } from "../Button";
import { UserAvatar } from "../UserAvatar";
import { ButtonTypes } from "../../typings/components";
import { useCollapsableMenu } from "./useCollapsableMenu";
import { IconButton, IconButtonTypes } from "../IconButton";
import { LogoNavigation } from "../LogoNavigation";
import { Spinner } from "../Spinner";
import { MenuItem } from "./MenuItem";
import { MenuPage } from "./MenuPage";
import { Settings } from "../../pages/MenuPages/Settings";
// import { Legal } from "../../pages/MenuPages/Legal";
import { Listeners } from "../../pages/MenuPages/Listeners";
import { Notifications } from "../../pages/MenuPages/Notifications";
import { MyStories } from "../../pages/MenuPages/MyStories";
import { Storytellers } from "../../pages/MenuPages/Storytellers";
import { useNavigate } from "react-router-dom";
import { MediaLibrary } from "../../pages/MenuPages/MediaLibrary";
import { MyProfile } from "../../pages/MenuPages/MyProfile";

interface CollapsableMenuProps {
  isOpen: boolean;
  toggleMenu: () => void;
}

const CollapsableMenu: FC<CollapsableMenuProps> = ({ isOpen, toggleMenu }) => {
  const {
    allStories,
    following,
    followers,
    isLoading,
    userDetails,
    notifications,
    activeMenuPage,
    reloadUserData,
    closeMenuPage,
    openMenuPage,
    logout,
  } = useCollapsableMenu();
  const navigate = useNavigate();

  const collapseMenuStyles = classNames(
    isOpen
      ? "left-0 md:left-[calc(100%-468px)] md:w-[468px] md:right-auto shadow-2xl shadow-black"
      : "left-full ",
    "bg-primary flex flex-col top-0 fixed w-full lg:flex h-full transition-all z-10"
  );

  useEffect(() => {
    if (isOpen) reloadUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const closeAllMenu = () => {
    toggleMenu();
    closeMenuPage();
  };

  return (
    <div className={collapseMenuStyles}>
      <Spinner isLoading={isLoading} withBackdrop />
      <div className="mx-4">
        <LogoNavigation isMenuOpen={isOpen} isLightLogo isModalMenu={true}>
          <IconButton icon={IconButtonTypes.CLOSE} onClick={toggleMenu} />
        </LogoNavigation>
      </div>
      <div className="flex flex-col items-center w-full flex-1">
        <UserAvatar
          src={
            userDetails?.profile.photo ||
            `https://picsum.photos/seed/${userDetails?.id}/200`
          }
          alt="Avatar"
          large
          extraStyles="mb-4"
          action={() => {
            navigate("profile");
            toggleMenu();
          }}
        />
        <Button
          style={ButtonTypes.TERTIARY}
          label="Edit Profile"
          action={() => 
            openMenuPage(<MyProfile closeMenu={closeAllMenu} />)}
        />
        <Button
          style={ButtonTypes.LINK}
          label="Logout"
          extraStyles="text-tertiary"
          action={() => {
            logout();
            toggleMenu();
          }}
        />
      </div>
      <div className="bg-white mt-auto flex flex-col overflow-auto">
        <MenuItem
          label={`Notifications (${notifications.length})`}
          action={() =>
            openMenuPage(<Notifications closeMenu={closeAllMenu} />)
          }
        />
        <MenuItem
          label={`My Stories (${allStories.length})`}
          action={() => openMenuPage(<MyStories closeMenu={closeAllMenu} stories={allStories} />)}
        />
        <MenuItem
          label={`Listeners (${followers.length})`}
          action={() => openMenuPage(<Listeners />)}
        />
        <MenuItem
          label={`Storytellers (${following.length})`}
          action={() => openMenuPage(<Storytellers closeMenu={closeAllMenu} />)}
        />
        <MenuItem
          label="My Photos"
          action={() => openMenuPage(<MediaLibrary closeMenu={closeAllMenu} />)}
        />
        <MenuItem
          label="Settings"
          action={() => openMenuPage(<Settings closeMenu={closeAllMenu} />)}
        />
        {/* <MenuItem label="Legal" action={() => openMenuPage(<Legal />)} /> */}
      </div>
      <MenuPage
        isOpen={Boolean(activeMenuPage)}
        close={closeMenuPage}
        pageComponent={activeMenuPage}
      />
    </div>
  );
};

export { CollapsableMenu };
