import { FC } from "react";
import classNames from "classnames";
import { Pill, PillProps } from "../Pill";

interface TabMenuProps {
  isDark?: boolean;
  tabs: PillProps[];
  extraStyles?: string;
  activeStyles?: string;
  inActiveStyles?: string;
}

const TabMenu: FC<TabMenuProps> = ({
  tabs,
  extraStyles = "",
  activeStyles,
  inActiveStyles,
}) => {
  const styles = classNames(`flex`, extraStyles);
  return (
    <div className={styles}>
      {tabs.map((p) => (
        <Pill
          key={`tab-${p.label}`}
          {...p}
          size="medium"
          extraStyles={p.extraStyles + " mr-1.5 cursor-pointer"}
          activeStyles={activeStyles}
          inActiveStyles={inActiveStyles}
        />
      ))}
    </div>
  );
};

export { TabMenu };
