import { useContext, useEffect, useState } from "react";
import { useAudioRecorder } from "../../../../hooks/useAudioRecorder";
import { MLStateContext } from "../../../../services/stateProvider";
import { useOnboardingService } from "../../../../services/useOnboardingService";
import { FollowUpQuestionType } from "../../../../typings/FollowUpQuestion";
import { Story } from "../../../../typings/Story";

const useStep5 = () => {
  const { stepNextNavigation, stepBackNavigation, setDisplayHelp , displayHelp} = useOnboardingService();
  const {
    state: { currentStory, preSelectedQuestion },
    updateStateItem,
  } = useContext(MLStateContext);
  const [readyToStart, setReadyToStart] = useState<boolean>(false);
  const [isRecordedCompleted, setIsRecordedCompleted] = useState<boolean>(false);
  const [currentQuestion, setCurrentQuestion] =
  useState<FollowUpQuestionType>();
  const [transcriptText, setTranscriptText] = useState<string>('');
  const [story, setStory] = useState<Story>();
  const [questions, setQuestions] = useState<Array<FollowUpQuestionType>>([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleTranscriptUpdate = (t: string) => {
    setTranscriptText((prev) => `${prev} ${t}`);
  };

  useEffect(() => {
    if (story) {
      reloadNonAnsweredQuestions();
    }
  }, [story]);

  useEffect(() => {
    if (currentStory) {
      setStory(currentStory);
    }
  }, [currentStory]);

  const {
    changeStateToRecording,
    startRecording,
    recorderState,
    saveRecording
  } = useAudioRecorder({
    handleTranscriptUpdate,
  });
  const { isRecording } = recorderState;
  
  const recordItem = useState<boolean>(false);

  useEffect(() => {
    if (currentStory && isRecording) {
      startRecording();
    }
  }, [currentStory, isRecording]);

  useEffect(() => {
    return () => {
      updateStateItem({
        currentFollowupQuestion: undefined,
      });
    };
  }, []);
  
  const reloadNonAnsweredQuestions = () => {
    if (story) {
      const nonAnswered = story.followupQuestions
        .filter((a) => a.status === "active")
        .filter((a) => !a.answers || a.answers.length === 0);
      let current = nonAnswered[0];
      setQuestions([...nonAnswered]);

      if (preSelectedQuestion) {
        current = preSelectedQuestion;
      }

      setCurrentQuestion(current);
      updateStateItem({
        currentFollowupQuestion: current,
        currentStory: story,
      });
    }
  };

  const toggleRecording = () => {
    if (isRecording) pauseRecording();
    else changeStateToRecording();
  };

  const recordFollowUpQuestion = () => {
    setReadyToStart(true);
  }

  const goBack = ()=>{
    stepBackNavigation();
  }
  const sliderUpdateSelectedQuestion = (current: FollowUpQuestionType | undefined) => {
    setCurrentQuestion(current);
    updateStateItem({
      currentFollowupQuestion: current,
    });
  };
  
  const pauseRecording = () => {
    if(transcriptText.length > 0 && story){
      saveRecording();
      setIsRecordedCompleted(true);
      updateStateItem({
        currentStory: story,
      });
    } else {
      setIsRecordedCompleted(false);
      setReadyToStart(false);
      setDisplayHelp(true);
    }
  };

  const goForward = ()=>{
    stepNextNavigation();
  }
  return {isRecordedCompleted, goBack,goForward, reloadNonAnsweredQuestions, recordFollowUpQuestion, questions, currentQuestion,
    setCurrentQuestion: sliderUpdateSelectedQuestion, displayHelp, transcriptText, isRecording, recordItem, story, readyToStart,isLoading, toggleRecording, setIsLoading};
};

export { useStep5 };
