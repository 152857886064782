import { Spinner } from "@material-tailwind/react";
import { FC } from "react";
import { Button } from "../../../../components/Button";
import { Statement, StatementSizes } from "../../../../components/Statement";
import { ButtonTypes } from "../../../../typings/components";
import { StepProps } from "../../useOnboarding";
import { useStep2 } from "./useStep2";

const Step2: FC<StepProps>  = () => {
  const {goForward, requestAccess , hasMicrophoneAccess, isLoading} = useStep2();

  return (
    <>
      <div className="text-center">
          <Statement
            text="You’ll be speaking to record your stories, so let’s make sure we can hear you"
            size={StatementSizes.XL3}
            extraStyles="text-center max-w-[600px] mx-auto"
          />
          <div className=" max-w-[500px] mx-auto mt-[100px]">
          {
              isLoading &&
              <div className="flex items-center justify-center my-4">
              <Spinner className="h-16 w-16" color="purple" />
              </div>
            }
            {!hasMicrophoneAccess ? 
            (!isLoading && <Button
                isFullWidth
                extraStyles="mb-2 z-10"
                style={ButtonTypes.TERTIARY}
                label="Request permission to use your microphone"
                action={() => requestAccess()}
              /> ):
              <>
                <Statement
                  text="Fantastic! Next, we'll test your microphone."
                  size={StatementSizes.XL}
                  extraStyles="text-center mb-5"
                />
                <Button
                  isFullWidth
                  extraStyles="mb-2 z-10"
                  style={ButtonTypes.TERTIARY}
                  label="Continue"
                  action={() => goForward()}
                />
              </>
              }
            </div>
      </div>
    </>
  );
};

export { Step2 };
