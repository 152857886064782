import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";

import { FC, ReactNode } from "react";
import { faPencil, faShare } from "@fortawesome/free-solid-svg-icons";

export enum IconButtonTypes {
  BACK = "BACK",
  CARET = "CARET",
  CLOSE = "CLOSE",
  PHOTO = "PHOTO",
  RECORD = "RECORD",
  SEARCH = "SEARCH",
  PLAY = "PLAY",
  PAUSE = "PAUSE",
  USER = "USER",
  PLUS = "PLUS",
  EDIT = "EDIT",
  SHARE = "SHARE",
}

export interface IconButtonProps {
  isSmall?: boolean;
  isDark?: boolean;
  label?: string;
  onClick: () => void;
  icon: IconButtonTypes;
  extraStyles?: string;
}

const IconButton: FC<IconButtonProps> = ({
  icon,
  label,
  onClick,
  isDark = false,
  isSmall = false,
  extraStyles = "",
}) => {
  const iconColor = isDark ? "bg-normal" : "bg-tertiary";
  const bgColor = isDark ? "bg-quaternary" : "bg-tertiary";
  const getButtonWithLabel = (children: ReactNode | Array<ReactNode>) => {
    return (
      <div className={`flex flex-col items-center ${extraStyles}`}>
        {children}
        {label && <p className="mt-2 tracking-tighter text-sm">{label}</p>}
      </div>
    );
  };

  if (icon === IconButtonTypes.PLUS) {
    const size = isSmall ? "w-[50px] h-[50px]" : "w-[75px] h-[75px]";
    return getButtonWithLabel(
      <div
        className={`cursor-pointer flex justify-center content-center rounded-full bg-secondary ${size}`}
        onClick={onClick}
      >
        <img src="/assets/images/plus-white.svg" alt="Add More Icon" />
      </div>
    );
  }

  if (icon === IconButtonTypes.RECORD) {
    const size = isSmall ? "w-[50px] h-[50px]" : "w-[75px] h-[75px]";
    return getButtonWithLabel(
      <div
        className={`cursor-pointer flex justify-center content-center rounded-full bg-secondary ${size}`}
        onClick={onClick}
      >
        <img
          className="w-[50%]"
          src="/assets/images/sound-wave-white.svg"
          alt="Record Icon"
        />
      </div>
    );
  }

  if (icon === IconButtonTypes.USER) {
    const size = isSmall ? "w-[50px] h-[50px]" : "w-[75px] h-[75px]";
    return getButtonWithLabel(
      <div
        className={`cursor-pointer flex justify-center items-center rounded-full text-tertiary bg-secondary ${size}`}
        onClick={onClick}
      >
        <FontAwesomeIcon size="2x" icon={faUser} />
      </div>
    );
  }

  if (icon === IconButtonTypes.EDIT) {
    const size = isSmall ? "w-[50px] h-[50px]" : "w-[75px] h-[75px]";
    return getButtonWithLabel(
      <div
        className={`cursor-pointer flex justify-center items-center rounded-full text-tertiary bg-secondary ${size}`}
        onClick={onClick}
      >
        <FontAwesomeIcon size="2x" icon={faPencil} />
      </div>
    );
  }

  if (icon === IconButtonTypes.SHARE) {
    const size = isSmall ? "w-[50px] h-[50px]" : "w-[75px] h-[75px]";
    return getButtonWithLabel(
      <div
        className={`cursor-pointer flex justify-center items-center rounded-full text-tertiary bg-secondary ${size}`}
        onClick={onClick}
      >
        <FontAwesomeIcon size="2x" icon={faShare} />
      </div>
    );
  }

  if (icon === IconButtonTypes.PLAY)
    return getButtonWithLabel(
      <div
        className="cursor-pointer flex justify-center content-center w-[75px] h-[75px] p-5 rounded-full bg-secondary"
        onClick={onClick}
      >
        <div className="cursor-pointer w-0 h-0 border-l-[15px] border-t-[26px] border-r-[15px] border-l-transparent border-t-white border-r-transparent -rotate-90 ml-2 mt-1"></div>
      </div>
    );

  if (icon === IconButtonTypes.PAUSE)
    return getButtonWithLabel(
      <div
        className="cursor-pointer flex justify-center content-center w-[75px] h-[75px] p-5 rounded-full bg-secondary"
        onClick={onClick}
      >
        <div className="w-7 h-8 border-l-8 border-r-8 border-white ml-0 mt-0.5"></div>
      </div>
    );

  if (icon === IconButtonTypes.PHOTO)
    return getButtonWithLabel(
      <div
        className="cursor-pointer flex justify-center content-center w-[75px] h-[75px] p-6 rounded-full bg-secondary"
        onClick={onClick}
      >
        <img src="/assets/images/image.png" alt="Media icon" />
      </div>
    );

  if (icon === IconButtonTypes.SEARCH) {
    return getButtonWithLabel(
      <div
        className={`${bgColor} cursor-pointer text-normal flex justify-center content-center w-[50px] h-[50px] rounded-full pt-4 pl-2`}
        onClick={onClick}
      >
        <span className="w-[16px] h-[16px] block border-normal border rounded-full"></span>
        <span className="w-[11px] h-[1px] block border-normal border-b rotate-45 translate-y-[17px] -translate-x-[5px] rounded-full"></span>
      </div>
    );
  }

  if (icon === IconButtonTypes.CLOSE)
    return (
      <div
        className={`cursor-pointer ${
          isSmall ? "w-6 h-6" : "w-10 h-10"
        } rotate-45 ${extraStyles}`}
        onClick={onClick}
      >
        <span
          className={`${iconColor} relative block ${
            isSmall ? "top-3" : "top-5"
          } w-full h-[1px]`}
        ></span>
        <span
          className={`${iconColor} relative block ${
            isSmall ? "left-3" : "left-5"
          } w-[1px] h-full`}
        ></span>
      </div>
    );

  if (icon === IconButtonTypes.CARET)
    return (
      <div
        className={`cursor-pointer relative w-3 h-3 -rotate-45 ${extraStyles}`}
        onClick={onClick}
      >
        <span
          className={`${iconColor} absolute block bottom-0 w-full h-[1px]`}
        ></span>
        <span
          className={`${iconColor} absolute block right-0 w-[1px] h-full`}
        ></span>
      </div>
    );

  return (
    <div
      className={`cursor-pointer relative w-3 h-3 rotate-45 translate-x-2 ${extraStyles}`}
      onClick={onClick}
    >
      <span
        className={`${iconColor} absolute block bottom-0 w-full h-[1px]`}
      ></span>
      <span
        className={`${iconColor} absolute block left-0 w-[1px] h-full`}
      ></span>
    </div>
  );
};

export { IconButton };
