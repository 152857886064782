import { FC, ReactNode, useEffect } from "react";
import { useImageSelector } from "./useImageSelector";
import { Story } from "../../typings/Story";
import { MediaFile } from "../../typings/MediaLibrary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../Button";
import { ButtonTypes } from "../../typings/components";
import { MediaLibraryModal } from "../MediaLibraryModal";
import { MAX_FILE_PER_STORY } from "../../constants/services";
import { Statement } from "../Statement";
import { IconButton, IconButtonTypes } from "../IconButton";

interface ImageSelectorProps {
  triggerNode: ReactNode;
  libraryFiles: MediaFile[];
  selectedFiles: MediaFile[];
  setSelectedFiles: (f: MediaFile[]) => void;
  submitFiles: (files: File[], story?: Story) => void;
  saveSelection: VoidFunction;
  extraStyles?: string;
}

const ImageSelector: FC<ImageSelectorProps> = ({
  triggerNode,
  libraryFiles,
  selectedFiles,
  setSelectedFiles,
  saveSelection,
  submitFiles,
  extraStyles = "",
}) => {
  const { isOpen, closeImageSelector, openImageSelector } = useImageSelector();
  const isItemSelected = (image: MediaFile) =>
    selectedFiles.findIndex((i) => image.id === i.id) !== -1;
  const toggleImage = (image: MediaFile) => {
    if (isItemSelected(image)) {
      setSelectedFiles([...selectedFiles.filter((i) => image.id !== i.id)]);
    } else {
      if (selectedFiles.length < MAX_FILE_PER_STORY) {
        setSelectedFiles([...selectedFiles, image]);
      }
    }
  };

  useEffect(() => {
    if(isOpen) {
      // TODO check if empty and open mediaLibrary
    }
  }, [isOpen,libraryFiles]);

  return (
    <>
      <div onClick={openImageSelector}>{triggerNode}</div>
      {isOpen && (
        <div className="fixed left-0 top-0 h-full w-full z-20 flex items-center justify-center">
          <span
            onClick={closeImageSelector}
            className="absolute left-0 top-0 h-full w-full bg-black opacity-50"
          />
          <div
            className={`relative flex flex-col items-center w-full h-[60vh] mx-4 p-4 rounded-md bg-tertiary z-10 ${extraStyles}`}
          >
            <IconButton
              isSmall
              extraStyles="absolute right-2 top-2"
              isDark
              icon={IconButtonTypes.CLOSE}
              onClick={closeImageSelector}
            />
            <Statement text={`My Photos (${libraryFiles.length})`} extraStyles="text-primary text-2xl" />
            <p className="mb-4 font-semibold">
              Select up to 6 photos to add to this story.
            </p>
            <div className="grid grid-cols-3 gap-4 auto-rows-min md:grid-cols-4 lg:grid-cols-5 overflow-y-scroll mb-4">
              {libraryFiles &&
                libraryFiles.map((file) => (
                  <div
                    className="relative cursor-pointer"
                    key={`image-selector-item-${file.id}`}
                    onClick={() => toggleImage(file)}
                  >
                    <img
                      className={`w-full h-full object-cover rounded-md`}
                      alt={file.name}
                      src={file.fileURL}
                    />
                    {isItemSelected(file) && (
                      <span className="absolute top-0 left-0 w-full h-full bg-success-600 flex rounded-md justify-center items-center opacity-50">
                        <FontAwesomeIcon
                          className="text-tertiary"
                          size={"3x"}
                          icon={faCheck}
                        />
                      </span>
                    )}
                  </div>
                ))}
            </div>
            <Button
              extraStyles="mt-auto md:max-w-[300px]"
              label="Save photos to story"
              style={ButtonTypes.PRIMARY}
              action={() => {
                saveSelection();
                closeImageSelector();
              }}
              isFullWidth
            />

            <MediaLibraryModal
              extraStyles="w-full"
              triggerNode={
                <Button
                  label="Upload More Photos"
                  style={ButtonTypes.TRANSPARENT}
                  extraStyles="text-black border-black mx-auto mt-3 md:max-w-[300px]"
                  // this action should remain like this, it is triggered in Media Library
                  action={() => null}
                  isFullWidth
                />
              }
              submitFiles={submitFiles}
            />
          </div>
        </div>
      )}
    </>
  );
};

export { ImageSelector };
