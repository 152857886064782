import { Children, FC, ReactNode } from "react";
import classNames from "classnames";
// import { useGrid } from "./useGrid";

interface GridProps {
  children: ReactNode | Array<ReactNode>;
  extraStyles?: string;
}

const Grid: FC<GridProps> = ({ children, extraStyles = "" }) => {
  const styles = classNames(`grid grid-cols-12 gap-4 md:gap-6`, extraStyles);
  return (
    <div className={styles}>
      {Children.toArray(children).map((c, i) => (
        <div
          key={`grid-item-${new Date().getTime() * i}`}
          className="col-span-12 md:col-span-6 lg:col-span-4 xl:col-span-3 md:h-[387px] md:flex md:flex-1"
        >
          {c}
        </div>
      ))}
    </div>
  );
};

export { Grid };
