import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../components/Button";
import { LogoNavigation } from "../../components/LogoNavigation";
import { MaxWidthContainer } from "../../components/MaxWidthContainer";
import { Spinner } from "../../components/Spinner";
import { Statement, StatementSizes } from "../../components/Statement";
import { ButtonTypes } from "../../typings/components";
import { useHomeWelcome } from "./useHomeWelcome";
interface HomeProps {}

const HomeWelcome: FC<HomeProps> = () => {
  const navigate = useNavigate();
  const { isNewUser, isLoading, isMitchellMartin } = useHomeWelcome();

  return (
    <div className="fixed z-30 pt-8 bg-primary h-full w-full text-tertiary overflow-y-auto">
      <MaxWidthContainer extraStyles="h-full">
        <LogoNavigation isLightLogo noBackButton></LogoNavigation>
        <Spinner isLoading={isLoading} withBackdrop />
        <div>
          {!isLoading && !isNewUser && (
            <div className="text-center">
              <Statement
                text={"Welcome back to Memory Lane!"}
                size={StatementSizes.XL3}
                extraStyles="text-center mt-10"
              />
              <Statement
                text={"Discover and share the stories that matter."}
                size={StatementSizes.LG}
                extraStyles="text-center mt-10"
              />

              <Statement
                text="What brings you here today?"
                size={StatementSizes.LG}
                extraStyles="text-center mt-5"
              />

              {/* <div className="grid grid-cols-1 sm:grid-cols-3 gap-4 max-w-[650px] mx-auto mt-[100px]"> */}
              <div className="flex justify-around w-full mt-[100px]">
                <Button
                  isFullWidth
                  extraStyles="mb-2 z-10 max-w-[200px]"
                  style={ButtonTypes.TERTIARY}
                  label="Tell a story"
                  action={() => navigate("/tellstory")}
                />
                {!isMitchellMartin && (
                  <Button
                    isFullWidth
                    extraStyles="mb-2 z-10 max-w-[200px]"
                    style={ButtonTypes.TERTIARY}
                    label="Work on my book"
                    action={() => navigate("/mybook")}
                  />
                )}
                {!isMitchellMartin && (
                  <Button
                    isFullWidth
                    extraStyles="mb-2 z-10 max-w-[200px]"
                    style={ButtonTypes.TERTIARY}
                    label="Listen to stories"
                    action={() => navigate("/listen")}
                  />
                )}
              </div>
            </div>
          )}
        </div>
      </MaxWidthContainer>
    </div>
  );
};

export { HomeWelcome };
