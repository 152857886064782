import { FC } from "react";
import classNames from "classnames";
import {
  CommentOrQuestion,
  CommentOrQuestionProps,
} from "../CommentOrQuestion";

interface CommentOrQuestionListProps {
  questions: CommentOrQuestionProps[];
  extraStyles?: string;
  isStoryteller?: boolean;
}

const CommentOrQuestionList: FC<CommentOrQuestionListProps> = ({
  questions,
  extraStyles = "",
  isStoryteller = false,
}) => {
  const styles = classNames(
    `flex flex-col mb-4 border-b border-b-light-borders`,
    extraStyles
  );
  return (
    <span className={styles}>
      {questions.map((q) => (
        <CommentOrQuestion
          key={`question-${q.date?.getTime()}`}
          {...q}
          isStoryteller={isStoryteller}
        />
      ))}
    </span>
  );
};

export { CommentOrQuestionList };
