import { useState } from "react";
import { getTranscriptQueryParams } from "../utils/deepgram";
import { AI_PROVIDER } from "../constants/third-party";
import { Story } from "../typings/Story";

interface OpenAICompletitionResponse {
  id: string;
  object: string;
  model: string;
  choices: Array<{
    message: {
      content: string;
      role: string;
    };
  }>;
}

const transcribeFromDeepgram = (
  file: Blob,
  callback: (text: string) => void
) => {
  const options = {
    method: "POST",
    headers: {
      Authorization: "Token 7d72629f95cb0f4cbad9615597622a0b7b6bbe49",
    },
    body: file,
  };

  fetch(
    `https://api.deepgram.com/v1/listen?${getTranscriptQueryParams()}`,
    options
  )
    .then((response) => response.json())
    .then((response) =>
      callback(response.results.channels[0].alternatives[0].transcript)
    )
    .catch((err) => console.error(err));
};

const transcribeFromOpenAI = (file: Blob, callback: (text: string) => void) => {
  const formData = new FormData();
  const myFile = new File([file], "audio.mp4", { type: "audio/mp4" });
  formData.append("file", myFile);
  formData.append("model", "whisper-1");
  formData.append("language", "en");
  formData.append("response_format", "json");

  const options = {
    method: "POST",
    headers: {
      Authorization:
        "Bearer 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",
    },
    body: formData,
  };

  fetch(`https://api.openai.com/v1/audio/transcriptions`, options)
    .then((response) => response.json())
    .then((response) => callback(response.text))
    .catch((err) => console.error(err));
};

const textHelpFromOpenAI = (
  instruction: string,
  callback: (response: OpenAICompletitionResponse) => void
) => {
  const params = {
    model: "gpt-3.5-turbo",
    messages: [
      {
        role: "user",
        content: instruction,
      },
    ],
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization:
        "Bearer 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",
    },
    body: JSON.stringify(params),
  };

  fetch(`https://api.openai.com/v1/chat/completions`, options)
    .then((response) => response.json())
    .then((response: OpenAICompletitionResponse) => callback(response))
    .catch((err) => console.error(err));
};

const blobToBase64 = (blob: Blob) => {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
};

const transcribeFromEdenAI = async (
  file: Blob,
  callback: (text: string) => void
) => {
  // const formData = new FormData();
  // // const myFile = new File([file], "audio.mp3", { type: "audio/mp3" });
  // formData.append("file", file);
  // formData.append("convert_to_wav", "true");
  // formData.append("speakers", "1");
  // formData.append(
  //   "providers",
  //   "amazon,microsoft,google,openai,assembly,symbl,neuralspace,deepgram"
  // );
  const base64 = await blobToBase64(file);
  const params = {
    show_original_response: false,
    speakers: 1,
    profanity_filter: false,
    convert_to_wav: true,
    providers: "google",
    // "amazon,microsoft,google,openai,assembly,symbl,neuralspace,deepgram",
    file: base64,
  };

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization:
        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiYzFiNmQ3YjYtOTAxYS00NDEwLWIxODktODVlZWRlYTkwM2NjIiwidHlwZSI6ImFwaV90b2tlbiJ9.nJXh4n6X54dj0ivd1DtUvj4e3j_3cgtQdlTpqYAErj0",
    },
    body: JSON.stringify(params),
  };

  fetch(`https://api.edenai.run/v2/audio/speech_to_text_async`, options)
    .then((response) => response.json())
    .then((response) => callback(response.text))
    .catch((err) => console.error(err));
};

const useAudioTranscriptor = (provider: AI_PROVIDER) => {
  const [isLoading, setIsLoading] = useState(false);
  const [transcript, setTranscript] = useState("");
  const transcriptionSucceed = (text: string) => {
    setIsLoading(false);
    setTranscript(text);
  };
  const transcribeAudio = (file: Blob) => {
    setIsLoading(true);
    if (provider === AI_PROVIDER.DEEPGRAM)
      transcribeFromDeepgram(file, transcriptionSucceed);
    if (provider === AI_PROVIDER.OPEN_AI)
      transcribeFromOpenAI(file, transcriptionSucceed);
    if (provider === AI_PROVIDER.EDEN_AI)
      transcribeFromEdenAI(file, transcriptionSucceed);
  };

  const consolidateTextArrayAndGetTitle = (
    story: Story,
    callback: (title: string, text: string) => void
  ) => {
    const allTexts = story.fullText?.map((t) => t.text).join("-") || "";
    textHelpFromOpenAI(
      `Return a JSON object with fixed punctuation and a title for this text: "${allTexts}". Translate all to English.`,
      (r) => {
        const { title, text } = JSON.parse(r.choices[0].message.content);
        callback(title, text);
      }
    );
  };

  return {
    isLoading,
    transcript,
    transcribeAudio,
    consolidateTextArrayAndGetTitle,
  };
};

export { useAudioTranscriptor };
