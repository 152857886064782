import { FC } from "react";
import { Statement } from "../Statement";
import { ProgressBar } from "primereact/progressbar";
import { Link } from "react-router-dom";
// import { TARGET_WORDS_PER_CHAPTER } from "../../constants/services";
import { Story } from "../../typings/Story";
import {
  getStoryCompletitionLevel,
  getStoryLength,
} from "../../utils/story-helper";

interface StoryTileProps {
  story: Story;
  isDisabled?: boolean;
}

const StoryTile: FC<StoryTileProps> = ({ story, isDisabled = false }) => {
  const { id, name } = story;
  const wordsCount = getStoryLength(story);
  const isTileDisabled = isDisabled ? "opacity-60" : "";
  const completitionLevel = getStoryCompletitionLevel(story);

  return (
    <section
      className={`bg-tertiary px-4 py-4 rounded-lg flex flex-col md:flex-row items-stretch ${isTileDisabled}`}
    >
      <div className="flex-1">
        <Link to={`/stories/${id}`}>
          <Statement
            text={name}
            extraStyles="mb-1 md:mb-3 text-base md:text-3xl font-bold md:font-normal"
          />
        </Link>
      </div>
      <div className="flex flex-col md:max-w-[400px] w-full">
        <ProgressBar
          value={(100 / 100) * wordsCount}
          color="#5DA4B0"
          displayValueTemplate={() => null}
          style={{ height: 14 }}
        ></ProgressBar>
        <p className="text-center text-xs md:text-base mt-2 md:mt-0">
          {completitionLevel.label} -{" "}
          <span className="font-bold text-chapters-progress">
            {wordsCount} words
          </span>
        </p>
        <div className="hidden md:flex justify-center md:flex-row-reverse mt-4 md:min-h-[61px]">
          {story.media.map((p) => (
            <img
              key={`story-image-${p.id}`}
              className="h-14 w-16 rounded-lg mr-2"
              alt={p.description}
              src={p.thumbnail}
            />
          ))}
        </div>
      </div>
      <div className="ml-4 justify-center hidden md:flex ">
        <img
          className="pointer-events-none"
          alt="ordering handle"
          src="/assets/images/sorting-icon.svg"
        />
      </div>
    </section>
  );
};

export { StoryTile };
