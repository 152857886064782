import { FC, ReactNode } from "react";
import { FollowUpQuestionType } from "../../typings/FollowUpQuestion";
import { Carousel, IconButton } from "@material-tailwind/react";
import { FollowUpQuestion } from "../FollowUpQuestion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleRight,
  faChevronCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { UserAvatar } from "../UserAvatar";
import { Statement, StatementSizes } from "../Statement";
import { AI_AUTHOR, CUSTOM_QUESTION } from "../../constants/services";
import { Button } from "../Button";
import { Story } from "../../typings/Story";
import { useNavigate } from "react-router-dom";
import { ButtonTypes } from "../../typings/components";
// import { useQuestionsCarrousel } from "./useQuestionsCarrousel";

export interface QuestionsCarrouselProps {
  questions: FollowUpQuestionType[];
  setCurrentQuestion: (q: FollowUpQuestionType | undefined) => void;
  questionTextExtraStyle?: string;
  story?: Story | undefined;
  hideCommentary?: boolean;
  displayAddMore?: boolean;
  children?: ReactNode | Array<ReactNode>;
}
export type slideRef = React.Ref<HTMLDivElement>;
const QuestionsCarrousel: FC<QuestionsCarrouselProps> = ({
  setCurrentQuestion,
  questions,
  questionTextExtraStyle,
  story,
  hideCommentary = false,
  displayAddMore = true,
  children
}) => {
  const questionsCarrousel = children ?
  [...questions, CUSTOM_QUESTION] : questions;

  const navigate = useNavigate();
  return (
    <div className="flex flex-col">
      {!hideCommentary && story && story.commentary && (
        <div className="px-10">
          <div className={`flex items-center`}>
            <UserAvatar
              alt={AI_AUTHOR.fullName}
              src={AI_AUTHOR.profile.photo}
              small
            />
            <Statement
              text="Memory Lane"
              size={StatementSizes.LG}
              extraStyles="mb-0 ml-4"
            />
          </div>
          <div className="border rounded-md bg-quaternary px-3 py-2 mb-2">
            <p className="text-black">{story.commentary}</p>
          </div>
        </div>
      )}
      <Carousel
        className="h-min-[50px] py-3"
        navigation={({ setActiveIndex, activeIndex, length }) => (
          <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
            {new Array(length).fill("").map((_, i) => (
              <span
                key={i}
                className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                  activeIndex === i ? "w-3 bg-white" : "w-1 bg-white/50"
                }`}
                onClick={() => {
                  setCurrentQuestion(i+1 !== length ? questionsCarrousel[i] : undefined);
                  setActiveIndex(i);
                }}
              >
              </span>
            ))}
          </div>
        )}
        prevArrow={({ handlePrev, activeIndex, firstIndex }) => (
          <IconButton
            variant="text"
            color="white"
            disabled={firstIndex}
            onClick={() => {
              handlePrev();
              if (!firstIndex) setCurrentQuestion(questionsCarrousel[activeIndex - 1]);
            }}
            className="!absolute top-1/2 left-0 -translate-y-2/4"
          >
            <FontAwesomeIcon size="3x" icon={faChevronCircleLeft} />
          </IconButton>
        )}
        nextArrow={({ handleNext, activeIndex, lastIndex }) => (
          <IconButton
          disabled={lastIndex}
            variant="text"
            color="white"
            onClick={() => {
              handleNext();
              if (!lastIndex) setCurrentQuestion(questionsCarrousel[activeIndex + 1]);
            }}
            className="!absolute top-1/2 !right-0 -translate-y-2/4 "
          > 
            <FontAwesomeIcon size="3x" icon={faChevronCircleRight} />
          </IconButton>
        )}
      >
        {questionsCarrousel.map((q, i) => (
          <div
            key={`peding-followup-questions-${q.id}`}
            className={`mt-6 mb-6 px-10 ${questionTextExtraStyle}`}
          >
            { !children ? <FollowUpQuestion
              extraStyles="mt-0 px-2"
              hideAuthor={true}
              question={q}
            /> :
              i !== questions.length && <FollowUpQuestion
              extraStyles="mt-0 px-2"
              hideAuthor={true}
              question={q}
            /> 
            }
            {displayAddMore && i+1 === questions.length && 
              <Button
                extraStyles="mx-auto mt-4 text-tertiary border-tertiary"
                style={ButtonTypes.TRANSPARENT}
                label="Add something else to my story"
                action={() => navigate(`/record/${story?.id}/`)}
              />
            }
            {children && i === questions.length && <div>
              {children}
              </div>}
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export { QuestionsCarrousel };
