import { FC } from "react";
import { useRecord } from "./useRecord";
import { MaxWidthContainer } from "../../components/MaxWidthContainer";
import { Button } from "../../components/Button";
import { ButtonTypes } from "../../typings/components";
import { IconButton, IconButtonTypes } from "../../components/IconButton";
import { LogoNavigation } from "../../components/LogoNavigation";
import { Statement, StatementSizes } from "../../components/Statement";
import { RecordingButton } from "../../components/RecordingButton";
import { AudioAndTranscriptionItem } from "../../components/AudioAndTranscriptionItem";
import { Spinner } from "../../components/Spinner";
import { UserAvatar } from "../../components/UserAvatar";
import { AI_AUTHOR } from "../../constants/services";

interface RecordProps {}

const Record: FC<RecordProps> = () => {
  const {
    story,
    isLoading,
    isBuilding,
    isNewUser,
    storyChunks,
    isRecording,
    messagesBoxRef,
    isNewStoryView,
    currentAiPrompt,
    goToFollowUpQuestions,
    toggleRecording,
    saveStory,
    navigate,
  } = useRecord();

  return (
    <div className="fixed z-30 pt-8 bg-primary h-full w-full text-tertiary overflow-y-auto">
      <MaxWidthContainer extraStyles="h-full">
        {isLoading && (
          <div className="absolute top-28 left-4 mt-2 z-10">
            <Spinner isLoading={true} />
          </div>
        )}
        <Spinner isLoading={isLoading} withBackdrop />
        <LogoNavigation isLightLogo isModalMenu={true}>
          {!isRecording && !story && (
            <IconButton
              icon={IconButtonTypes.CLOSE}
              onClick={() => navigate("/")}
            />
          )}
        </LogoNavigation>

        <div className="flex flex-col h-[calc(100%-107px)] max-w-[800px] mx-auto pt-6">
          {isNewUser && (
            <>
              <Statement
                text="Welcome to Memory Lane! Click start to record your first story."
                extraStyles="mb-8 md:text-2xl"
                size={StatementSizes.XL}
              />
              <Statement
                text="The longer you talk, the more we’ll have to work with – so try to speak for about a minute to get some great follow-up questions and begin writing your story."
                extraStyles="mb-8 md:text-2xl"
                size={StatementSizes.XL}
              />
              <Statement
                text="What do you like to do for fun and why?"
                extraStyles="mb-8 md:text-4xl font-semibold"
                size={StatementSizes.XL2}
              />
            </>
          )}
          {(isNewStoryView || !isLoading) &&
            (currentAiPrompt ? (
              <>
                <Statement
                  text={currentAiPrompt.text || currentAiPrompt.description}
                  size={StatementSizes.XL3}
                />
                {currentAiPrompt.mediafile && (
                  <img
                    className="rounded-lg overflow-hidded object-center object-cover"
                    src={currentAiPrompt.mediafile.media_file}
                    alt={currentAiPrompt.text}
                  />
                )}
              </>
            ) : (
              <Statement
                text={
                  isRecording
                    ? "Hit Stop to finish recording!"
                    : isNewStoryView
                    ? !isNewUser
                      ? "Hit Start to record your story!"
                      : ""
                    : !isNewUser
                    ? "Hit Record to continue your story!"
                    : "Hit Answer follow-up questions to continue your story!"
                }
                size={isNewStoryView ? StatementSizes.XL4 : StatementSizes.XL}
              />
            ))}
          {story && <Statement text={story.name} size={StatementSizes.XL3} />}
          <div
            className="flex-1 overflow-y-scroll md:mt-10 min-h-[100px]"
            ref={messagesBoxRef}
          >
            <span className="h-20 w-full left-0 absolute bg-gradient-to-b from-primary z-10 pointer-events-none " />
            {story &&
              storyChunks &&
              storyChunks.map((a, index) => (
                <>
                  {a.followup_question && (
                    <>
                      <div className="flex items-center">
                        <UserAvatar
                          src={
                            (a.followup_question.author || AI_AUTHOR).profile
                              .photo ||
                            `https://picsum.photos/seed/${
                              (a.followup_question.author || AI_AUTHOR).id
                            }/200`
                          }
                          alt={
                            a.followup_question.author?.first_name ||
                            AI_AUTHOR.firstName
                          }
                          small
                        />
                        <Statement
                          text={`${
                            a.followup_question.author?.first_name ||
                            AI_AUTHOR.firstName
                          } asked:`}
                          size={StatementSizes.LG}
                          extraStyles="mb-0 ml-4"
                        />
                      </div>
                      <Statement
                        extraStyles="mb-2"
                        text={`${a.followup_question.description}`}
                        size={StatementSizes.MD}
                      />
                    </>
                  )}
                  <AudioAndTranscriptionItem
                    hideAudio={true}
                    key={`story-chunk-${a.id}`}
                    customLabel={
                      !a.followup_question
                        ? index === 0
                          ? {
                              on: "You told a story: Hide",
                              off: "You told a story: View",
                            }
                          : {
                              on: "You added  to your story: Hide",
                              off: "You added  to your story: View",
                            }
                        : undefined
                    }
                    storyChunk={a}
                  />
                </>
              ))}
          </div>
          <div className="flex-1 mb-2 mx-2 pt-6 ">
            <div className="flex justify-center mb-10">
              <RecordingButton
                isActive={isRecording}
                action={toggleRecording}
                inActiveLabel={story ? "Record" : "Start"}
              />
            </div>
            {!isRecording && story && (
              <Button
                isFullWidth
                extraStyles={`mb-2 z-10 ${
                  isBuilding ? "" : "text-tertiary border-tertiary"
                }`}
                style={
                  isBuilding ? ButtonTypes.DISABLED : ButtonTypes.TRANSPARENT
                }
                disabled={isBuilding}
                isLoading={isBuilding}
                label={
                  isBuilding
                    ? "Building follow-up questions"
                    : "Answer follow-up questions"
                }
                action={goToFollowUpQuestions}
              />
            )}
            {/* {!isRecording && (
              <Button
                isFullWidth
                extraStyles="mb-2 z-10"
                style={ButtonTypes.TRANSPARENT}
                label="Want to type instead?"
                action={() => null}
              />
            )} */}
            {!isRecording && story && (
              <Button
                isFullWidth
                disabled={isRecording}
                extraStyles="mb-2 z-10"
                style={ButtonTypes.TERTIARY}
                label="Review my story"
                action={saveStory}
              />
            )}
          </div>
        </div>
      </MaxWidthContainer>
    </div>
  );
};

export { Record };
