import { AI_AUTHOR } from "../constants/services";
import { convertUTCDateToLocalDate } from "../utils/dates";
import { LoginResponse, UserBase, UserBasicInfo } from "./User";

export type FollowUpQuestionChunk = {
  id: number;
  storyId: number;
  audio: string;
  text: string;
  created_at: string;
};

export type FollowUpQuestionResponse = {
  id: number;
  name: string;
  text: string;
  description: string;
  status: string;
  is_active: boolean;
  created_at: string;
  updated_at: string;
  resource_uri: string;
  author: LoginResponse;
  answers?: FollowUpQuestionChunk[];
};

export type FollowUpQuestionType = {
  id: number;
  name: string;
  text: string;
  description: string;
  status: string;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
  resourceUri: string;
  author: UserBasicInfo;
  storyRef?: number;
  storyName?: string;
  answers?: FollowUpQuestionChunk[];
};

export type FollowUpQuestion = {
  id?: number;
  description?: string;
  story?: string;
  author?: UserBase;
};

export class FollowUpQuestionDetails implements FollowUpQuestionType {
  id: number;
  name: string;
  text: string;
  description: string;
  status: string;
  isActive: boolean;
  createdAt: Date;
  updatedAt: Date;
  resourceUri: string;
  author: UserBase;
  storyRef?: number;
  storyName?: string;
  answers?: FollowUpQuestionChunk[];

  constructor(fq: FollowUpQuestionResponse) {
    this.id = fq.id;
    this.name = fq.name;
    this.text = fq.text;
    this.description = fq.description;
    this.status = fq.status;
    this.isActive = fq.is_active;
    this.createdAt = convertUTCDateToLocalDate(new Date(fq.created_at));
    this.updatedAt = convertUTCDateToLocalDate(new Date(fq.updated_at));
    this.resourceUri = fq.resource_uri;
    this.author = fq.author ? new UserBasicInfo(fq.author) : AI_AUTHOR;
    this.answers = fq.answers;
  }
}
