import { FC, ReactNode, createContext, useState } from "react";
import { INITIAL_STATE, STORED_STATE_NAME } from "../constants/state";
import {
  MLStateType,
  MLStateContextType,
  MLStoredState,
} from "../typings/state";

const getStoredState = (): MLStateType => {
  const storedState = localStorage.getItem(STORED_STATE_NAME);
  if (!storedState) return INITIAL_STATE.state;
  return JSON.parse(storedState);
};

const setStateToStore = (newState: MLStoredState) => {
  localStorage.setItem(STORED_STATE_NAME, JSON.stringify(newState));
};

export const MLStateContext = createContext<MLStateContextType>(INITIAL_STATE);

interface MLStateProviderProps {
  children: ReactNode | Array<ReactNode>;
}

export const MLStateProvider: FC<MLStateProviderProps> = ({ children }) => {
  const [state, setState] = useState<MLStateType>({
    ...INITIAL_STATE.state,
    ...getStoredState(),
  });
  const updateState = (s: MLStateType) => setState(s);
  const updateStateItem = (vs: MLStateType) => {
    setState((prev) => ({ ...prev, ...vs }));
  };
  const updateStoredState = (ss: MLStoredState) => {
    setState((prev) => {
      setStateToStore({ ...getStoredState(), ...ss });
      return { ...prev, ...ss };
    });
  };

  return (
    <MLStateContext.Provider
      value={{
        state,
        updateState,
        updateStateItem,
        updateStoredState,
      }}
    >
      {children}
    </MLStateContext.Provider>
  );
};
