import { FC } from "react";
import { Button } from "../../../../components/Button";
import { RecordingButton } from "../../../../components/RecordingButton";
import { Statement, StatementSizes } from "../../../../components/Statement";
import { ButtonTypes } from "../../../../typings/components";
import { StepProps } from "../../useOnboarding";
import { useStep3 } from "./useStep3";

const Step3: FC<StepProps> = () => {
  const {
    goForward,
    isRecording,
    toggleRecording,
    displayHelp,
    readyToStart,
    isMitchellMartin,
  } = useStep3();

  return (
    <>
      <div className="text-center">
        {!displayHelp && !readyToStart && (
          <>
            <Statement
              text="Great!  Now let’s make sure your microphone is working"
              size={StatementSizes.XL3}
              extraStyles="text-center max-w-[650px] mx-auto"
            />
            <div className="relative mt-10">
              <Statement
                text="Press record and read the following:"
                size={StatementSizes.MD}
                extraStyles="text-center pt-6"
              />
              <h2 className="text-center pt-6 tracking-tighter italic font-conversation text-xl max-w-[400px] mx-auto">
                “My name is
                <span className="inline-block border-b-2 mx-2 w-[160px]"></span>
                {isMitchellMartin
                  ? "I have worked at MMI for ___ years.”"
                  : "and I’m about to record the story of my life.”"}
              </h2>
            </div>
            {isRecording && (
              <Statement
                text="Press “Stop” when you’re finished."
                size={StatementSizes.MD}
                extraStyles="text-center pt-6"
              />
            )}
            <div className=" max-w-[400px] mx-auto mt-[100px]">
              <div className="flex items-center justify-center">
                <RecordingButton
                  isActive={isRecording}
                  action={toggleRecording}
                  inActiveLabel="Record"
                />
              </div>
            </div>
          </>
        )}
        {readyToStart && (
          <>
            <Statement
              text="Nice work!"
              size={StatementSizes.XL2}
              extraStyles="text-center"
            />
            <Statement
              text="You sound great!"
              size={StatementSizes.XL2}
              extraStyles="text-center"
            />
            <Statement
              text="Ready to get started?"
              size={StatementSizes.XL2}
              extraStyles="text-center mt-6"
            />
            <div className=" max-w-[400px] mx-auto mt-[100px]">
              <Button
                isFullWidth
                extraStyles="mb-2 z-10"
                style={ButtonTypes.TERTIARY}
                label="Continue to your first story"
                action={() => goForward()}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export { Step3 };
