/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";

import { MLStateContext } from "../../services/stateProvider";
import { PAGE_STYLES } from "../../typings/state";
import { useNavigate, useParams } from "react-router-dom";
import { Story, StoryDetail } from "../../typings/Story";
import { useStoriesService } from "../../services/useStoriesService";
import { useInfoToastService } from "../../services/useInfoToastService";
import { editStorySchema } from "../../utils/form-validation/yup-schemas";
import { EditStoryValues } from "../../typings/form-schemas";
import { useBookService } from "../../services/useBookService";
import { BookDetails } from "../../typings/Book";
import { Category } from "../../typings/Category";

const useEditStory = () => {
  const { getById, updateById } = useStoriesService();
  const { createSuccessMsg } = useInfoToastService();
  const [isAuthor, setIsAuthor] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { getBookDetail } = useBookService();
  const [book, setBook] = useState<BookDetails>();
  const navigate = useNavigate();
  const {
    state: { userDetails },
    updateStateItem,
  } = useContext(MLStateContext);
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [story, setStory] = useState<StoryDetail>();
  const { id } = useParams();
  const [initialValues, setInitialValues] = useState<EditStoryValues>({
    name: "",
    summary: "",
    chapter: "",
    category: "",
    isProtectedName: false,
    isProtectedSummary: false,
  });
  const [categories, setCategories] = useState<Category[]>([]);

  useEffect(() => {
    Promise.all([getBookDetail(), getById(Number(id))])
      .then((results) => {
        const bookResult = results[0];
        const storyResult = results[1];

        setCategories(
          bookResult.chapters.find(
            (c) => c.resourceUri === storyResult.chapter?.resourceUri
          )?.category?.subcategories || []
        );

        setBook(bookResult);
        resetStory(storyResult);
      })
      .finally(() => setIsLoading(false));

    updateStateItem({
      pageStyle: PAGE_STYLES.SECONDARY,
      isEditingStory: true,
      currentStory: story,
    });

    return () => {
      updateStateItem({
        pageStyle: PAGE_STYLES.PRIMARY,
        currentStory: undefined,
        isEditingStory: false,
      });
    };
  }, []);

  const resetStory = (s: Story) => {
    setStory(s);
    setInitialValues({
      name: `${s.name}`,
      summary: `${s.summary}`,
      chapter: `${s.chapter?.resourceUri}`,
      category: `${s.category?.resourceUri}`,
      isProtectedSummary: s.isProtectedSummary,
      isProtectedName: s.isProtectedName,
    });
    setIsAuthor(userDetails?.id === s.author.id);
    updateStateItem({ currentStory: s });
  };

  const editStory = () => {
    // This is harcoded intentionally. Business rules links title to be same value as summary
    const isProtectedName = formikObject.values.isProtectedSummary;

    const storyToBe = {
      ...formikObject.values,
      isProtectedName,
      id: Number(id),
    };
    updateById(storyToBe as unknown as StoryDetail)
      .then(() => {
        createSuccessMsg({
          title: "Story is updated",
          message: `Your story was updated succesfully.`,
        });
        navigate(`/stories/${id}`);
      })
      .catch((e) => setErrorMessage(e.error))
      .finally(() => setIsLoading(false));
  };

  const onSubmit = () => {
    if (!formikObject.isValid) return;
    setIsLoading(true);
    editStory();
  };

  const setProtectedSummary = () =>
    formikObject.setFieldValue("isProtectedSummary", true);

  const formikObject = useFormik({
    initialValues,
    validationSchema: editStorySchema,
    validateOnChange: true,
    onSubmit,
  });

  useEffect(() => {
    formikObject.resetForm({ values: initialValues });
  }, [initialValues]);

  useEffect(() => {
    if (formikObject.values.chapter !== story?.chapter?.resourceUri) {
      setCategories(
        book?.chapters.find(
          (c) => c.resourceUri === formikObject.values.chapter
        )?.category?.subcategories || []
      );
    }
  }, [formikObject.values]);

  useEffect(() => {
    if (initialValues.chapter) {
      formikObject.setFieldValue("category", story?.category?.resourceUri);
    } else {
      formikObject.setFieldValue("category", "");
    }
  }, [categories]);

  return {
    story,
    chapters: book?.chapters || [],
    categories,
    isAuthor,
    isLoading,
    userDetails,
    errorMessage,
    formikObject,
    setProtectedSummary,
  };
};

export { useEditStory };
