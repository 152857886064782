import { FC } from "react";
import { useMyBook } from "./useMyBook";
import { MaxWidthContainer } from "../../components/MaxWidthContainer";
import { Spinner } from "../../components/Spinner";
import { useLocation } from "react-router-dom";
import { ChapterTile } from "../../components/ChapterTile";
import { OrderList } from "primereact/orderlist";
import { Chapter } from "../../typings/Chapter";
import { Statement } from "../../components/Statement";
import { UserAvatar } from "../../components/UserAvatar";
import { MediaLibraryModal } from "../../components/MediaLibraryModal";

interface MyBookProps {}

const MyBook: FC<MyBookProps> = () => {
  const {
    chapters,
    isLoading,
    followers,
    following,
    userDetails,
    dividerIndex,
    storiesCount,
    bookProgress,
    pendingChapters,
    categoryToRecord,
    recordCategory,
    onChaptersDragged,
    addMediaFiles,
  } = useMyBook();
  const { pathname: activeRoute } = useLocation();
  const isMainLayout = activeRoute === "/";

  const excludedChaptersDivider = (
    <div
      draggable={false}
      className="bg-primary text-tertiary p-4 md:p-6 rounded-lg text-xs md:text-xl"
    >
      Chapters moved below this mark will not be included in your printed book.
    </div>
  );

  const itemTemplate = (c: Chapter) => {
    const chapterIndex = chapters.indexOf(c);
    return c?.id !== undefined ? (
      <ChapterTile chapter={c} isDisabled={chapterIndex > dividerIndex} />
    ) : (
      excludedChaptersDivider
    );
  };

  return (
    <div>
      <MaxWidthContainer
        isPageLayout
        extraStyles="pb-12 md:pb-4"
        isMainLayout={isMainLayout}
      >
        <Spinner isLoading={isLoading} withBackdrop />
        <div>
          <div className="flex flex-row w-full max-w-[500px] my-0 mx-auto mb-10 items-center">
            <div
              className="w-[130px] h-[130px] px-10 rounded-full flex justify-center items-center transition-all"
              style={{
                background: `radial-gradient(closest-side, transparent 79%, transparent 80% 100%), conic-gradient(#D24B34 ${bookProgress}%, lightgray 0)`,
              }}
            >
              <UserAvatar
                src={userDetails?.profile.photo || ""}
                alt={`${userDetails?.fullName} avatar picture`}
                large
              />
              <progress
                className="visibility-hidden h-0 w-0"
                value={bookProgress}
                max="100"
              >
                {bookProgress}%
              </progress>
            </div>
            <div>
              <p className="ml-4 text-2xl md:text-3xl">
                {userDetails?.firstName}, just {pendingChapters.length} more
                chapters to go!
              </p>
              <p className="ml-4 mt-2">
                {storiesCount} Stories {followers.length || 0} Listeners{" "}
                {following.length || 0} Storytellers
              </p>
            </div>
          </div>
          <div className="ml-2 mr-2 bg-primary text-tertiary p-6 rounded-lg text-xs md:text-base">
            <p className="mb-4">Welcome to your book of life stories!</p>
            <ul className="list-decimal ml-8">
              <li>Click on a chapter to get started</li>
              <li>Click and drag a chapter to change its order</li>
              <li>
                Remove a chapter from your book by dragging it to the bottom of
                the page
              </li>
            </ul>
          </div>
          <div className="flex">
            <div className="bg-secondary rounded-xl text-tertiary flex flex-col cursor-pointer w-[60%] mt-5"
                onClick={() => {
                  recordCategory(categoryToRecord)
                }}>
              <Statement
                text={`Click to record more about ${categoryToRecord}`}
                extraStyles="text-2xl font-medium md:4xl p-4"
              />
              <div className="border-t border-tertiary px-4 py-1 mt-auto">
                <p className="flex justify-end">
                  Start recording
                  <img
                    className="ml-2 w-6"
                    src="/assets/images/sound-wave-white.svg"
                    alt="Record Icon"
                  />
                </p>
              </div>
            </div>
            <MediaLibraryModal
              extraStyles="bg-secondary rounded-xl text-tertiary flex flex-col cursor-pointer w-[40%] mt-5 ml-4"
              triggerNode={
                <>
                  <Statement
                    text="Add a photo to talk about"
                    extraStyles="text-2xl font-medium md:4xl p-4"
                  />
                  <div className="border-t border-tertiary px-4 py-1 mt-auto">
                    <p className="flex justify-end">
                      Upload photos
                      <img
                        className="ml-2 w-6 h-auto object-contain"
                        src="/assets/images/camera.png"
                        alt="Record Icon"
                      />
                    </p>
                  </div>
                </>
              }
              submitFiles={addMediaFiles}
            />
          </div>
          <Statement
            text="Chapters"
            extraStyles="mt-8 md:mt-10 ml-1 mb-1 text-2xl md:4xl"
          />
          <OrderList
            dragdrop
            value={chapters}
            onChange={(e) => {
              onChaptersDragged(e.value);
            }}
            itemTemplate={itemTemplate}
          ></OrderList>
        </div>
      </MaxWidthContainer>
    </div>
  );
};

export { MyBook };
