import { useState } from "react";
import { useInvitationsService } from "../../../services/useInvitationsService";
import { useQuickActionService } from "../../../services/useQuickActionService";
import { useFormik } from "formik";
import { inviteUserSchema } from "../../../utils/form-validation/yup-schemas";
import { useInfoToastService } from "../../../services/useInfoToastService";

const useAddStoryteller = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { addNewStoryteller } = useInvitationsService();
  const { closeQuickAction } = useQuickActionService();
  const { createErrorMsg, createSuccessMsg } = useInfoToastService();

  const onSubmit = () => {
    setIsLoading(true);
    addNewStoryteller(values.email)
      .then(() => {
        createSuccessMsg({
          title: "Storyteller invitation sent.",
          message: `Your invitation was sent to ${values.email}.`,
        });
        closeQuickAction();
      })
      .catch((e) =>
        createErrorMsg({
          title: "Storyteller invitation not sent.",
          message: e.error,
        })
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  const formikObject = useFormik({
    initialValues: { email: "" },
    validationSchema: inviteUserSchema,
    onSubmit,
  });
  const { values } = formikObject;

  return { isLoading, formikObject };
};

export { useAddStoryteller };
