import { useState } from "react";

const useAudioAndTranscriptionItem = () => {
  const [isTranscriptionVisible, setIsTranscriptionVisible] = useState(false);
  return {
    isTranscriptionVisible,
    toggleTranscription: () =>
      setIsTranscriptionVisible(!isTranscriptionVisible),
  };
};

export { useAudioAndTranscriptionItem };
