import { FC } from "react";
import { useHome } from "./useHome";
import { Statement } from "../../components/Statement";
import { MaxWidthContainer } from "../../components/MaxWidthContainer";
import { Spinner } from "../../components/Spinner";
import { ListenerFeed } from "./ListenerFeed";
import { useLocation } from "react-router-dom";

interface HomeProps {}

const Home: FC<HomeProps> = () => {
  const {
    isLoading,
    userDetails,
  } = useHome();
  const { pathname: activeRoute } = useLocation();
  const isMainLayout = activeRoute === "/";
  const userName = userDetails?.firstName || userDetails?.username;

  return (
    <div>
      <MaxWidthContainer
        isPageLayout
        extraStyles="pb-12 md:pb-4"
        isMainLayout={isMainLayout}
      >
        <Spinner isLoading={isLoading} withBackdrop />
        <div className="mb-10 hidden md:block">
          <Statement text={`Hi ${userName}`} extraStyles="mb-0 text-7xl" />
          <p className="text-md">
            Let&apos;s keep capturing more of your memories.
          </p>
        </div>
        {/* {userDetails &&
          (activeMode === USER_MODES.LISTENER ? (
            <ListenerFeed currentUserID={userDetails.id} />
          ) : (
            <StorytellerFeed
              aiPrompts={aiPrompts}
              prompts={prompts}
              pendingFollowupQuestions={pendingFollowupQuestions}
              startRecordingAiPrompt={startRecordingAiPrompt}
            />
          ))} */}
          {userDetails && <ListenerFeed
              currentUserID={userDetails.id}
            />}
      </MaxWidthContainer>
    </div>
  );
};

export { Home };
