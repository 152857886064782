import { format } from "timeago.js";

export const formatSeconds = (time: number) => {
  if (time < 10) {
    return `00:0${time}`;
  } else if (time < 60) {
    return `00:${time}`;
  } else {
    const minutes = Math.floor(time / 60);
    const seconds = time - minutes * 60;
    const minutesLabel = minutes < 10 ? `0${minutes}` : minutes;
    const secondsLabel = seconds < 10 ? `0${seconds}` : seconds;
    return `${minutesLabel}:${secondsLabel}`;
  }
};

export const formatTime = (ts: number) => {
  return format(ts);
};
