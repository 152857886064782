import { useContext, useEffect, useState } from "react";
import { MLStateContext } from "../../services/stateProvider";
import { useNavigate } from "react-router-dom";
import { useStoriesService } from "../../services/useStoriesService";
import { PARTNERSHIPS } from "../../constants/services";

const useHomeWelcome = () => {
  const navigate = useNavigate();
  const { getByIdFiltered } = useStoriesService();
  const [isLoading, setIsLoading] = useState(false);
  const [isNewUser, setIsNewUser] = useState(true);
  const {
    state: { userDetails, partnership },
  } = useContext(MLStateContext);

  useEffect(() => {
    setIsLoading(true);
    if (userDetails) {
      getByIdFiltered(userDetails.id, true).then((r) => {
        setIsLoading(false);
        if (r.length === 0) {
          navigate("/onboarding");
        } else {
          setIsNewUser(false);
        }
      });
    }
  }, []);

  return {
    isNewUser,
    isLoading,
    isMitchellMartin: partnership === PARTNERSHIPS.MITCHELL_MARTIN,
  };
};

export { useHomeWelcome };
