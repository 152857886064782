import { useContext } from "react";
import { MLStateContext } from "../../../services/stateProvider";
import { useQuickActionService } from "../../../services/useQuickActionService";
import { QUICK_ACTIONS } from "../../../constants/services";

const useListeners = () => {
  const {
    state: { followers },
  } = useContext(MLStateContext);
  const { openQuickAction } = useQuickActionService();
  const openAddListenerQA = () =>
    openQuickAction(QUICK_ACTIONS.ADD_NEW_LISTENER);

  const options = [
    {
      label: "Add to favorites",
      action: (i: unknown) => console.log(i),
    },
    {
      label: "Nominate as Steward",
      action: (i: unknown) => console.log(i),
    },
    {
      label: "Remove",
      action: (i: unknown) => console.log(i),
    },
    {
      label: "Block",
      action: (i: unknown) => console.log(i),
    },
  ];
  const listeners = [...(followers || [])];
  return {
    // isLoading,
    listeners,
    options,
    openAddListenerQA,
  };
};

export { useListeners };
