import { FC } from "react";
import { useRegister } from "./useRegister";
import { Statement } from "../../components/Statement";
import { MaxWidthContainer } from "../../components/MaxWidthContainer";
import { Button } from "../../components/Button";
import { ButtonTypes } from "../../typings/components";
import { FormControl } from "../../components/Forms/FormControl";
import { Spinner } from "../../components/Spinner";
import { RegisterValues } from "../../typings/form-schemas";
import { TermsConditionFormControl } from "../../components/Forms/TermsConditionFormControl";

interface RegisterProps {}

const Register: FC<RegisterProps> = () => {
  const { isLoading, errorMessage, formikObject, invitationDetail } =
    useRegister();
  const { touched, errors, values, submitForm, handleChange, handleBlur } =
    formikObject;

  const getFieldProps = (
    fieldName: keyof RegisterValues,
    placeholder: string
  ) => {
    return {
      name: fieldName,
      placeholder,
      touched: touched[fieldName],
      error: errors[fieldName],
      handleChange,
      handleBlur,
    };
  };

  return (
    <div>
      {isLoading && <Spinner isLoading withBackdrop />}
      <MaxWidthContainer isPageLayout extraStyles="pb-12 md:pb-4">
        <div className="flex flex-col h-full content-between">
          <div className="mr-[10%] mb-14">
            <Statement text="Register" extraStyles="text-primary text-4xl" />
            {invitationDetail && (
              <p className="mt-2">
                Welcome to Memory-Lane, <br />
                you received this invitation from{" "}
                {invitationDetail.userFrom.fullName}. <br />
                Fill the form to listen to {invitationDetail.userFrom.firstName}
                &apos;s shared stories.
              </p>
            )}
          </div>
          {errorMessage && (
            <p className="text-red-900 ml-4 -mt-9 mb-3">{errorMessage}</p>
          )}
          <form
            noValidate
            onSubmit={(e) => {
              e.preventDefault();
              submitForm();
            }}
          >
            <FormControl
              type="text"
              {...getFieldProps("first_name", "First Name")}
              value={values.first_name}
            />
            <FormControl
              type="text"
              {...getFieldProps("last_name", "Last Name")}
              value={values.last_name}
            />
            <FormControl
              type="phone"
              autocomplete="off"
              {...getFieldProps("username", "Phone")}
              value={values.username}
            />
            <FormControl
              type="email"
              {...getFieldProps("email", "Email")}
              value={values.email}
            />
            {/* <FormControl
              type="text"
              {...getFieldProps("profile.sex", "profile.sex")}
            /> */}
            {/* <FormControl
              type="date"
              {...getFieldProps("profile.date_of_birth", "profile.date_of_birth")}
              placeholder="Date of birth"
              value={values.profile.date_of_birth || ""}
            /> */}
            {/* <FormControl
              type="file"
              placeholder="Picture"
              {...getFieldProps("profile.photo", "profile.photo")}
              value={values.profile.photo || ""}
              accept="image/png,image/jpeg,image/gif"
            /> */}
            <FormControl
              type="password"
              {...getFieldProps("password", "Password")}
              value={values.password}
            />
            <FormControl
              type="password"
              {...getFieldProps("password_confirm", "Confirmation")}
              value={values.password_confirm}
            />
            <TermsConditionFormControl
              {...getFieldProps("acceptTC", "Terms and Conditions")}
              value={values.acceptTC}
            />
            <Button
              label="Continue"
              type="submit"
              style={ButtonTypes.PRIMARY}
              isFullWidth
              action={() => null}
            />
          </form>
        </div>
      </MaxWidthContainer>
    </div>
  );
};

export { Register };
