import { Story, StoryDetail, StoryResponse } from "./Story";
import { LoginResponse, UserDetailInfo, UserDetails } from "./User";

export type Invitation = {
  author: UserDetails;
  created_at: string;
  id: number;
  resource_uri: string;
  story: string;
  user_to_email: string;
  user_to_phonenumber: string;
};

export type SignupInvitationResponse = {
  id: number;
  ref_number: string;
  resource_uri: string;
  status: string;
  story?: StoryResponse;
  created_at: string;
  updated_at: string;
  user_from: LoginResponse;
  user_to_email: string;
  user_to_first_name?: string;
  user_to_last_name?: string;
  user_to_phonenumber: string;
};

export type SignupInvitation = {
  id: number;
  refNumber: string;
  resourceUri: string;
  status: string;
  story?: Story;
  createdAt: string;
  updatedAt: string;
  userFrom: UserDetails;
  userToEmail: string;
  userToFirstName?: string;
  userToLastName?: string;
  userToPhonenumber: string;
};

export class SignupInvitationDetails {
  id: number;
  refNumber: string;
  resourceUri: string;
  status: string;
  story?: Story;
  createdAt: string;
  updatedAt: string;
  userFrom: UserDetails;
  userToEmail: string;
  userToFirstName?: string;
  userToLastName?: string;
  userToPhonenumber: string;

  constructor(inv: SignupInvitationResponse) {
    this.id = inv.id;
    this.refNumber = inv.ref_number;
    this.resourceUri = inv.resource_uri;
    this.status = inv.status;
    this.story = inv.story && new StoryDetail(inv.story);
    this.createdAt = inv.created_at;
    this.updatedAt = inv.updated_at;
    this.userFrom = new UserDetailInfo(inv.user_from);
    this.userToEmail = inv.user_to_email;
    this.userToFirstName = inv.user_to_first_name;
    this.userToLastName = inv.user_to_last_name;
    this.userToPhonenumber = inv.user_to_phonenumber;
  }
}
