// TODO: This has to be reviewed
/* eslint-disable react/prop-types */
import { FC, ReactNode } from "react";
import { useMediaLibraryModal } from "./useMediaLibraryModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import {
  FileUpload,
  FileUploadHeaderTemplateOptions,
  ItemTemplateOptions,
} from "primereact/fileupload";
import { FormControl } from "../Forms/FormControl";
import { Story } from "../../typings/Story";
import { Button } from "../Button";
import { IconButton, IconButtonTypes } from "../IconButton";
import { MAX_FILE_SIZE } from "../../constants/services";
import { ButtonTypes } from "../../typings/components";

interface MediaLibraryModalProps {
  triggerNode: ReactNode;
  submitFiles: (files: File[], story?: Story) => void;
  extraStyles?: string;
}

const MediaLibraryModal: FC<MediaLibraryModalProps> = ({
  triggerNode,
  submitFiles,
  extraStyles = "",
}) => {
  const {
    files,
    isOpen,
    fileUploadRef,
    setInputName,
    closeMediaLibrary,
    openMediaLibrary,
    onTemplateSelect,
    onTemplateRemove,
    onTemplateClear,
  } = useMediaLibraryModal();

  const headerTemplate = (options: FileUploadHeaderTemplateOptions) => {
    const { chooseButton, cancelButton } = options;

    return (
      <div className={`flex justify-between mx-4 my-2`}>
        <p
          className="cursor-pointer text-md"
          onClick={() => cancelButton.props.onClick()}
        >
          Clear
        </p>
        <IconButton
          isSmall
          isDark
          icon={IconButtonTypes.CLOSE}
          onClick={closeMediaLibrary}
        />
        <div className="hidden">
          {chooseButton}
          {cancelButton}
        </div>
      </div>
    );
  };

  const itemTemplate = (
    file: object & File & { objectURL: string },
    props: ItemTemplateOptions
  ) => {
    return (
      <div className="flex items-center -mx-6 -my-2">
        <div className="flex items-center flex-1 mr-2">
          <img
            alt={file.name}
            className="w-14 h-14 rounded-md"
            src={file.objectURL}
          />
          <div className="flex flex-col w-full text-left ml-3">
            <FormControl
              styleType="SECONDARY"
              name="file-name"
              value={file.name}
              handleChange={(e) => setInputName(file, e.target.value)}
              placeholder=""
              handleBlur={() => null}
              nonMargins
              isSmall
            />
            <p>{props.formatSize}</p>
          </div>
        </div>
        <IconButton
          isDark
          icon={IconButtonTypes.CLOSE}
          onClick={() =>
            onTemplateRemove(file, props.onRemove as (event?: unknown) => void)
          }
        />
      </div>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex flex-1 h-full items-center justify-center">
        <div className="flex flex-col justify-center items-center p-4 text-lg rounded-xl mb-6">
          <FontAwesomeIcon className="w-8 h-8" icon={faFileDownload} />
          <span className="mt-4">Drag and Drop Image Here</span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className={`w-full ${extraStyles}`} onClick={openMediaLibrary}>
        {triggerNode}
      </div>
      {isOpen && (
        <div className="fixed left-0 top-0 h-full w-full z-20 flex items-center justify-center">
          <span
            onClick={closeMediaLibrary}
            className="absolute left-0 top-0 h-full w-full bg-black opacity-50"
          />
          <div className={`w-full bg-tertiary rounded-md mx-4 z-10 pb-3`}>
            <FileUpload
              ref={fileUploadRef}
              name="mediaLibrary"
              multiple
              accept="image/*"
              maxFileSize={MAX_FILE_SIZE}
              invalidFileSizeMessageSummary="File exceeds the size limit."
              invalidFileSizeMessageDetail="File exceeds the size limit."
              onSelect={onTemplateSelect}
              onError={onTemplateClear}
              onClear={onTemplateClear}
              headerTemplate={headerTemplate}
              itemTemplate={itemTemplate as unknown as ReactNode}
              emptyTemplate={emptyTemplate}
              contentStyle={{
                height: "55vh",
                border: "none",
                overflowY: "scroll",
                overflowX: "hidden",
                marginBottom: "12px",
              }}
              chooseLabel="Select files"
              chooseOptions={{
                className: "bg-transparent text-normal border-none p-0",
              }}
              cancelOptions={{
                className:
                  "bg-transparent text-normal border-none p-0 active:bg-transparent",
              }}
              cancelLabel="Clear"
            />
            <div className="w-full px-3">
              <Button
                extraStyles="mt-auto md:mx-auto md:max-w-[300px]"
                style={ButtonTypes.PRIMARY}
                action={() => {
                  fileUploadRef.current?.getInput().click();
                }}
                isFullWidth
                label="Select Files"
              />
              <Button
                style={ButtonTypes.TRANSPARENT}
                extraStyles="text-black border-black mx-auto mt-3 md:max-w-[300px]"
                action={() => {
                  submitFiles(files);
                  closeMediaLibrary();
                }}
                isFullWidth
                label="Upload images"
                disabled={!files || files.length === 0}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { MediaLibraryModal };
