import { FC } from "react";
// import { useAskQuestion } from "./useAskQuestion";

interface AskQuestionProps {}

const AskQuestion: FC<AskQuestionProps> = () => {
  // const { } = useAskQuestion();
  return (
    <div className="">
      <h3 className="text-tertiary text-2xl font-thin text-center font-conversation mb-6">
        Ask a question
      </h3>
      <div className="bg-tertiary py-8 px-3 mx-2 rounded-t-2xl">
        <p className="mb-6">Ask a question to one of your storytellers</p>
        <p className="mb-6">This function will be ready for you soon...</p>
      </div>
    </div>
  );
};

export { AskQuestion };
