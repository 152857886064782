import classNames from "classnames";
import { FC, ReactNode } from "react";

interface MaxWidthContainerProps {
  extraStyles?: string;
  isPageLayout?: boolean;
  isMenuPageLayout?: boolean;
  isMainLayout?: boolean;
  children: ReactNode | Array<ReactNode>;
}

const MaxWidthContainer: FC<MaxWidthContainerProps> = ({
  children,
  isPageLayout = false,
  isMenuPageLayout = false,
  isMainLayout = false,
  extraStyles = "",
}) => {
  const pageLayout = isPageLayout
    ? `min-h-[calc(100dvh-218px)] ${isMainLayout ? 'pt-40 md:pt-36' : 'pt-36'} pb-15`
    : "";
  const maxWidth = !isMenuPageLayout
    ? "container mx-auto px-4 max-w-[1440px]"
    : "";
  const menuPageLayout = isMenuPageLayout
    ? "flex-1 overflow-y-auto"
    : "";
  const styles = classNames([
    maxWidth,
    pageLayout,
    menuPageLayout,
    extraStyles,
  ]);
  return <div className={styles}>{children}</div>;
};

export { MaxWidthContainer };
