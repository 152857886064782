export enum TOAST_TYPES {
  INFO = "INFO",
  ERROR = "ERROR",
  SUCCESS = "SUCCESS",
  WARNING = "WARNING",
}

export type GenericToastMessage = {
  title: string;
  message?: string;
};

export type ToastMessageType = GenericToastMessage & {
  type: TOAST_TYPES;
};

export class ToastMessage implements ToastMessageType {
  id: string;
  title: string;
  message?: string;
  type: TOAST_TYPES;

  constructor(n: ToastMessageType) {
    this.id = `toast_msg_${Date.now()}`;
    this.title = n.title;
    this.message = n.message;
    this.type = n.type;
  }
}

export interface ToastAlertsService {
  createMessage: (m: ToastMessageType) => void;
  createSuccessMsg: (m: GenericToastMessage) => void;
  createWarningMsg: (m: GenericToastMessage) => void;
  createErrorMsg: (m: GenericToastMessage) => void;
  clearMessage: (id: string) => void;
}
