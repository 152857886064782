import { UserBasicInfo, UserResponse } from "./User";

export type CategoryResponse = {
  id: number;
  name: string;
  description: string;
  is_active: boolean;
  subcategories: CategoryResponse[];
  resource_uri: string;
  created_at: string;
  author?: UserResponse;
};

export type Category = {
  id: number;
  name: string;
  description: string;
  subcategories: Category[];
  isActive: boolean;
  resourceUri: string;
  createdAt: string;
  author?: UserBasicInfo;
};

export class CategoryDetail implements Category {
  id: number;
  name: string;
  description: string;
  subcategories: Category[];
  isActive: boolean;
  resourceUri: string;
  createdAt: string;

  constructor(r: CategoryResponse) {
    this.id = r.id;
    this.name = r.name;
    this.description = r.description;
    this.subcategories = r.subcategories
      ? r.subcategories.map((sc) => new CategoryDetail(sc))
      : [];
    this.isActive = r.is_active;
    this.resourceUri = r.resource_uri;
    this.createdAt = r.created_at;
  }
}
