import { useContext } from "react";
import { MLStateContext } from "../../../../services/stateProvider";
import { useOnboardingService } from "../../../../services/useOnboardingService";

const useStep6 = () => {
  const { stepNextNavigation, stepBackNavigation, resetOnboarding} = useOnboardingService();
  const {
    state: { currentStory },
  } = useContext(MLStateContext);

  const goBack = ()=>{
    stepBackNavigation();
  }
  const goForward = ()=>{
    stepNextNavigation();
  }

  return {goBack,goForward,resetOnboarding, currentStory};
};

export { useStep6 };
