import { useAPI } from "../utils/api-connection";
import {
  AddChunkToStoryProps,
  Story,
  StoryChunk,
  StoryDetail,
} from "../typings/Story";
import { useAudioTranscriptor } from "./useAudioTranscriptor";
import { AI_PROVIDER } from "../constants/third-party";
import { sortItemsByDate } from "../utils/dates";
import { MediaFile } from "../typings/MediaLibrary";
import { ALL_DEFAULT_STORY_FIELDS, BASIC_STORY_FIELDS } from "../constants/endpoints";

const useStoriesService = () => {
  const {
    createStory,
    getAllStories,
    getStoriesFiltered,
    getStory,
    postChunkToStory,
    updateStory,
    deleteStory,
    updateStoryMediaFiles,
  } = useAPI();
  const { consolidateTextArrayAndGetTitle } = useAudioTranscriptor(
    AI_PROVIDER.OPEN_AI
  );
  const createNew = (callback: (s: Story) => void) => {
    createStory()
      .then(callback)
      .catch((e) => console.log(e));
  };

  const getById = (
    id: number,
    callback?: (s: Story) => void,
    fields = ALL_DEFAULT_STORY_FIELDS
  ) => {
    return getStory(id, fields).then((story) => {
      const newStory = sortItemsByDate(new StoryDetail(story));
      if (callback) callback(sortItemsByDate(newStory));
      return newStory;
    });
  };

  const getByIdFiltered = (
    id: number,
    ownStories: boolean = false,
    fields = BASIC_STORY_FIELDS
  ) => {
    return getStoriesFiltered(id, {
      fields,
      ownStories
    }).then((list) =>
      list.map((l) => new StoryDetail(l))
    );
  };

  const updateById = (story: Story, callback?: (s: Story) => void) => {
    return updateStory(story)
      .then((s) => {
        const newStory = new StoryDetail(s);
        if (callback) callback(newStory);
        return newStory;
      })
      .catch((e) => console.log(e));
  };

  const removeStory = (id: number, callback?: () => void) => {
    return deleteStory(id)
      .then(() => (callback ? callback() : null))
      .catch((e) => console.log(e));
  };

  const getAll = (fields = ALL_DEFAULT_STORY_FIELDS) => {
    return getAllStories(fields).then((list) =>
      list.map((l) => new StoryDetail(l))
    );
  };

  const addChunkToStory = (
    {
      id,
      author,
      chunk,
      transcription,
      followupQuestion,
    }: AddChunkToStoryProps,
    callback: (s: StoryChunk) => void
  ) => {
    postChunkToStory({ id, chunk, author, transcription, followupQuestion })
      .then(callback)
      .catch((e) => console.log(e));
  };

  const consolidateStory = (id: number, callback: (s: Story) => void) => {
    getById(id, (s) =>
      consolidateTextArrayAndGetTitle(s, (title, text) =>
        updateById({ ...s, name: title, description: text }, callback)
      )
    );
  };

  const updateMediaFiles = (id: number, files: MediaFile[]) => {
    return updateStoryMediaFiles(
      id,
      files.map((f) => f.resourceUri)
    );
  };

  return {
    getAll,
    getById,
    getByIdFiltered,
    createNew,
    updateById,
    removeStory,
    addChunkToStory,
    updateMediaFiles,
    consolidateStory,
  };
};

export { useStoriesService };
