import { useState } from "react";

const useImageSelector = () => {
  const [isOpen, setIsOpen] = useState(false);

  const closeImageSelector = () => {
    setIsOpen(false);
  };
  const openImageSelector = () => setIsOpen(true);

  return {
    isOpen,
    closeImageSelector,
    openImageSelector,
  };
};

export { useImageSelector };
