import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Routes } from "./routes/routes";
import { ActionCenter } from "./components/ActionCenter";
import { MLStateProvider } from "./services/stateProvider";
import { QuickActionPrompt } from "./components/QuickActionPrompt";
import { InfoToast } from "./components/InfoToast";
import { PrimeReactProvider } from "primereact/api";
import { hotjar } from "react-hotjar";
import { HOTJAR, INTERCOM_APP } from "./constants/third-party";
import { IntercomProvider } from "react-use-intercom";
import { MainModal } from "./components/MainModal";

// Hotjar initialization
if(process.env.REACT_APP_ENV === 'production'){
  hotjar.initialize(HOTJAR.ID, HOTJAR.VERSION);
}


function App() {
  return (
    <div className="bg-quaternary font-body">
      <MLStateProvider>
        <IntercomProvider appId={INTERCOM_APP.ID}>
          <PrimeReactProvider>
            <Router>
              <Header />
              <Routes />
              <Footer />
              <InfoToast />
              <ActionCenter />
              <QuickActionPrompt />
              <MainModal />
            </Router>
          </PrimeReactProvider>
        </IntercomProvider>
      </MLStateProvider>
    </div>
  );
}

export default App;
