import { FC, useContext } from "react";
import classNames from "classnames";
import { UserAvatar, UserAvatarProps } from "../UserAvatar";
import { formatTime } from "../../utils/number-format";
import { Button } from "../Button";
import { ButtonTypes } from "../../typings/components";
import { MLStateContext } from "../../services/stateProvider";
import { useNavigate } from "react-router-dom";
import { FollowUpQuestionType } from "../../typings/FollowUpQuestion";

export interface CommentOrQuestionProps {
  author: UserAvatarProps;
  comment: FollowUpQuestionType;
  date: Date;
  action?: VoidFunction;
  isStoryteller?: boolean;
}

const CommentOrQuestion: FC<CommentOrQuestionProps> = ({
  comment,
  date,
  author,
  action,
  isStoryteller = false,
}) => {
  const styles = classNames(
    `flex items-center border-t border-t-light-borders text-normal py-5 px-4`,
    action ? 'cursor-pointer':''
  );
  const {
    state: { currentStory },
    updateStateItem,
  } = useContext(MLStateContext);
  const navigate = useNavigate();
  const answerQuestion = () => {
    if (currentStory) {
      updateStateItem({ preSelectedQuestion: comment });
      navigate(`/record/${currentStory.id}/questions`);
    }
  };

  return (
    <div className={styles} onClick={action}>
      <div className="flex">
        <UserAvatar {...author} small />
      </div>
      <div className="ml-4">
        <span className="block font-bold text-sm w-full">
          {formatTime(date.getTime())}
        </span>
        <p className="text-sm">{comment.description}</p>
      </div>
      {isStoryteller && (
        <Button
          extraStyles="ml-auto"
          style={ButtonTypes.LINK}
          label="Answer"
          action={answerQuestion}
        />
      )}
    </div>
  );
};

export { CommentOrQuestion };
