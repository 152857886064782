/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuthService } from "../../services/useAuthService";
import { useInfoToastService } from "../../services/useInfoToastService";

const useEmailConfirmation = () => {
  const { activationKey = "" } = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  // const [errorMessage, setErrorMessage] = useState<string | null>();
  const { confirmEmail } = useAuthService();
  const { createSuccessMsg } = useInfoToastService();

  useEffect(() => {
    if (activationKey) confirmNewUserEmail();
  }, [activationKey]);

  const confirmNewUserEmail = () => {
    confirmEmail(activationKey)
      .then(() => {
        createSuccessMsg({
          title: "Account confirmed!",
          message:
            "Your account has been confirmed succesfully. Welcome to Memory Lane!",
        });
        navigate("/");
      })
      .catch((e) => {
        // This needs to be uncommented when the confirmation error gets fixed
        // setErrorMessage(e.error);
        navigate("/login");
      })
      .finally(() => setIsLoading(false));
  };

  return { isLoading, errorMessage: null };
};

export { useEmailConfirmation };
