import { FC, useContext } from "react";
import { useRoutes } from "react-router-dom";
import {
  PUBLIC_ROUTES,
  AUTHENTICATED_ROUTES,
  RESTRICTED_ROUTES,
  WAITLIST_ROUTES,
} from "../constants/routesList";
import { MLStateContext } from "../services/stateProvider";

const Routes: FC = () => {
  const { state } = useContext(MLStateContext);

  const { API_KEY, userDetails } = state;
  const isAuthenticated = API_KEY && API_KEY !== "";
  const isSuperuser = userDetails?.isSuperuser;

  let routes = isAuthenticated ? AUTHENTICATED_ROUTES : PUBLIC_ROUTES;
  if (userDetails && userDetails.profile.waitlisted) routes = WAITLIST_ROUTES;

  const superUserRoutes = isSuperuser ? RESTRICTED_ROUTES : [];
  const allRoutes = [...routes, ...superUserRoutes];

  return useRoutes(allRoutes);
};

export { Routes };
