import { useEffect, useState } from "react";
import { useTellStoryService } from "../../services/useTellStoryService";

interface StepProps {}

const useTellStory = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animationClass, setAnimationClass] = useState("opacity-0");

  const {
    prompts,
    tellStory,
    selectedCategory,
    stepNextNavigation,
    stepBackNavigation,
    setIsLoading,
    setDisplayHelp,
    setIsVisible,
    cleanPromts,
  } = useTellStoryService();

  useEffect(() => {
    if (prompts && prompts?.length > 0) stepNextNavigation();
    return () => cleanPromts();
  }, []);

  useEffect(() => {
    setActiveIndex(tellStory?.currentStep || 0);
  }, [tellStory?.currentStep]);

  useEffect(() => {
    if (tellStory?.isVisible) {
      setAnimationClass("animate-fade-in opacity-100");
    } else {
      setAnimationClass("");
    }
  }, [tellStory?.isVisible]);

  useEffect(() => {
    setIsVisible(true);
  }, [tellStory?.displayHelp, activeIndex]);

  useEffect(() => {
    console.log(selectedCategory);
  }, [selectedCategory]);

  return {
    isLoading: tellStory?.isLoading,
    activeIndex,
    setIsLoading,
    currentStep: tellStory?.currentStep,
    displayHelp: tellStory?.displayHelp,
    setDisplayHelp,
    stepNextNavigation,
    stepBackNavigation,
    animationClass,
    selectedCategory,
  };
};

export { useTellStory };
export type { StepProps };
