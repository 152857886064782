import { useContext } from "react";
import { MLStateContext } from "../../../services/stateProvider";
import { useQuickActionService } from "../../../services/useQuickActionService";
import { QUICK_ACTIONS } from "../../../constants/services";
import { UserDetails } from "../../../typings/User";
import { useNavigate } from "react-router-dom";

interface UseStorytellersProps {
  closeMenu: VoidFunction;
}

const useStorytellers = ({ closeMenu }: UseStorytellersProps) => {
  const {
    state: { following },
  } = useContext(MLStateContext);
  const { openQuickAction } = useQuickActionService();
  const openAddStoryteller = () =>
    openQuickAction(QUICK_ACTIONS.ADD_NEW_STORYTELLER);
  const navigate = useNavigate();
  const options = [
    {
      label: "Profile",
      action: (i: UserDetails) => {
        navigate(`storyteller/${i.id}`);
        closeMenu();
      },
    },
    { label: "Stop following", action: (i: UserDetails) => console.log(i) },
    { label: "Block", action: (i: UserDetails) => console.log(i) },
  ];
  return {
    storytellers: following || [],
    options,
    openAddStoryteller,
  };
};

export { useStorytellers };
