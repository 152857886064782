import { FC, useEffect, useState } from "react";
import { Card } from "../../components/Card";
import { Statement } from "../../components/Statement";
import { useNavigate } from "react-router";
import { Grid } from "../../components/Grid";
import { StoryDetail } from "../../typings/Story";

import { Spinner } from "../../components/Spinner";
import { useStoriesService } from "../../services/useStoriesService";
import { LISTEN_STORY_FIELDS } from "../../constants/endpoints";

interface ListenerFeedProps {
  currentUserID: number;
}

const ListenerFeed: FC<ListenerFeedProps> = ({ currentUserID }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { getByIdFiltered } = useStoriesService();
  const [allStories, setAllStories] = useState<Array<StoryDetail>>([]);

  useEffect(() => {
    if (currentUserID) {
      setIsLoading(true);
      getByIdFiltered(currentUserID, false, LISTEN_STORY_FIELDS).then((r) => {
        setAllStories(r || []);
        setIsLoading(false);
      });
    }
  }, [currentUserID]);

  return (
    <div>
      <Spinner withBackdrop isLoading={isLoading} />
      <Grid>
        {allStories.map((s) => (
          <Card
            key={`story-to-view-${s.id}`}
            legend={s.author.firstName}
            action={() => navigate(`/stories/${s.id}`)}
            actionIcon={<img src="/assets/images/story.svg" alt="Sound wave" />}
            actionLabel="View"
            legendImage={s.author.profile.photo}
          >
            <Statement text={s.name} />
            {s.media && s.media.length > 0 && (
              <img
                className="rounded-lg"
                src={s.media[0].fileURL}
                alt={s.media[0].name}
              />
            )}
          </Card>
        ))}
      </Grid>
    </div>
  );
};

export { ListenerFeed };
