import { convertUTCDateToLocalDate } from "../utils/dates";

export type MediaFileToUpload = {
  file: File;
  description: string;
};

export type MediaFileResponse = {
  id: number;
  media_file: string;
  name: string;
  story?: string;
  description: string;
  is_active: boolean;
  mime: string;
  owner: string;
  size: number;
  thumbnail: string;
  created_at: string;
  resource_uri: string;
  // size_hr
  // updated_at
};

export type MediaFile = {
  id: number;
  fileURL: string;
  name: string;
  story?: string;
  description: string;
  isActive: boolean;
  mimeType: string;
  owner: string;
  size: number;
  thumbnail: string;
  createdAt: Date;
  resourceUri: string;
};

export class MediaFileDetail implements MediaFile {
  id: number;
  fileURL: string;
  name: string;
  story?: string;
  description: string;
  isActive: boolean;
  mimeType: string;
  owner: string;
  size: number;
  thumbnail: string;
  createdAt: Date;
  resourceUri: string;

  constructor(n: MediaFileResponse) {
    this.id = n.id;
    this.fileURL = n.media_file;
    this.name = n.name;
    this.story = n.story;
    this.description = n.description;
    this.isActive = n.is_active;
    this.mimeType = n.mime;
    this.owner = n.owner;
    this.size = n.size;
    this.thumbnail = n.thumbnail;
    this.resourceUri = n.resource_uri;
    this.createdAt = convertUTCDateToLocalDate(new Date(n.created_at));
  }
}
