import { useMediaVerification } from "../../../../utils/microphone-access";
import { useEffect, useState } from "react";
import { useOnboardingService } from "../../../../services/useOnboardingService";

const useStep2 = () => {
  const [hasMicrophoneAccess, setHasMicrophoneAccess] = useState<boolean>(false);
  const {verifyMicrophoneStatus, requestMicrophoneAccess } = useMediaVerification();
  const { stepNextNavigation, stepBackNavigation, setIsLoading, isLoading} = useOnboardingService();
  const goBack = ()=>{
    stepBackNavigation();
  }
  const goForward = ()=>{
    stepNextNavigation();
  }

  const requestAccess = async () => {
    const isGranted = await requestMicrophoneAccess();
    if(isGranted){
      setHasMicrophoneAccess(true);
    } else {
      setTimeout(requestAccess,1000);
    }
  }

  useEffect(() => {
    setIsLoading(true);
    verifyMicrophoneStatus().then(e => {
      setTimeout(()=>{
        setIsLoading(false);
        setHasMicrophoneAccess(e === 'granted' ? true: false);
      },1000)
    })
  }, []);

  return {goBack,goForward, requestAccess, isLoading, hasMicrophoneAccess};
};

export { useStep2 };
