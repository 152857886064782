import { MLStateContext } from "./stateProvider";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

const useNavigationService = () => {
  const navigate = useNavigate();
  const {
    state: { historyNavigation = [] },
    updateStateItem,
  } = useContext(MLStateContext);

  const goHome = () => {
    updateStateItem({ historyNavigation: [] });
    navigate('/');
  };

  const goBack = () => {
    if (historyNavigation.length > 0){
      // remove current page nav
      historyNavigation.pop();
    }
    const backToNav = historyNavigation.pop() || '/';
    updateStateItem({ historyNavigation: [...historyNavigation] });
    navigate(backToNav);
  };

  const resetHistory = () => {
    updateStateItem({ historyNavigation: [] })
  }

  return {
    goBack,
    goHome,
    resetHistory
  };
};

export { useNavigationService };
