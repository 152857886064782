import { FC } from "react";
import { RecordingButton } from "../../../../components/RecordingButton";
import { Statement, StatementSizes } from "../../../../components/Statement";
import { StepProps } from "../../useTellStory";
import { useStep2 } from "./useStep2";
import { Carousel, IconButton, Spinner } from "@material-tailwind/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleLeft,
  faChevronCircleRight,
} from "@fortawesome/free-solid-svg-icons";

const Step2: FC<StepProps> = () => {
  const {
    isRecording,
    toggleRecording,
    setCurrentQuestion,
    initialPrompts,
    selectedQuestion,
    readyToStart,
    story,
    isBuilding,
    displayHelp,
    selectedCategory,
    isMitchellMartin,
  } = useStep2();

  return (
    <>
      {!displayHelp && (
        <div className="text-center">
          {!isRecording && !readyToStart && (
            <>
              <Statement
                text={selectedCategory}
                size={StatementSizes.XL4}
                extraStyles="text-center"
              />
              <Statement
                text={
                  isMitchellMartin
                    ? "Pick a question and record YOUR Mitchell Martin story:"
                    : "Below are some initial prompts to provide inspiration."
                }
                size={StatementSizes.XL2}
                extraStyles="text-center mt-8"
              />
              <div className="max-w-[800px] mx-auto mt-10">
                <Carousel
                  className="h-min-[50px] py-3 items-center"
                  navigation={({ setActiveIndex, activeIndex, length }) => (
                    <div className="absolute bottom-4 left-2/4 z-50 flex -translate-x-2/4 gap-2">
                      {new Array(length).fill("").map((_, i) => (
                        <span
                          key={i}
                          className={`block h-1 cursor-pointer rounded-2xl transition-all content-[''] ${
                            activeIndex === i
                              ? "w-3 bg-white"
                              : "w-1 bg-white/50"
                          }`}
                          onClick={() => {
                            setCurrentQuestion(initialPrompts[i]);
                            setActiveIndex(i);
                          }}
                        ></span>
                      ))}
                    </div>
                  )}
                  prevArrow={({
                    handlePrev,
                    activeIndex,
                    firstIndex,
                    loop,
                  }) => (
                    <IconButton
                      disabled={!loop && firstIndex}
                      variant="text"
                      color="white"
                      onClick={() => {
                        handlePrev();
                        if (!firstIndex)
                          setCurrentQuestion(initialPrompts[activeIndex - 1]);
                      }}
                      className="!absolute top-1/2 left-0 -translate-y-2/4"
                    >
                      <FontAwesomeIcon size="3x" icon={faChevronCircleLeft} />
                    </IconButton>
                  )}
                  nextArrow={({ handleNext, activeIndex, lastIndex, loop }) => (
                    <IconButton
                      disabled={!loop && lastIndex}
                      variant="text"
                      color="white"
                      onClick={() => {
                        handleNext();
                        if (!lastIndex)
                          setCurrentQuestion(initialPrompts[activeIndex + 1]);
                      }}
                      className="!absolute top-1/2 !right-0 -translate-y-2/4 "
                    >
                      <FontAwesomeIcon size="3x" icon={faChevronCircleRight} />
                    </IconButton>
                  )}
                >
                  {initialPrompts.map((q, i) => (
                    <div
                      key={`peding-followup-questions-${q.id}`}
                      className={`mt-6 mb-6 px-10`}
                    >
                      {q.mediafile && (
                        <div className="flex justify-center items-center">
                          <img
                            className="w-[200px] h-[200px] rounded-lg object-cover mb-4"
                            src={q.mediafile.media_file}
                            alt={q.mediafile.name}
                          />
                        </div>
                      )}
                      <Statement
                        text={q.description}
                        size={StatementSizes.XL2}
                        extraStyles="text-center"
                      />
                    </div>
                  ))}
                </Carousel>
              </div>
            </>
          )}
          {isRecording && !readyToStart && (
            <>
              {selectedQuestion?.mediafile && (
                <div className="flex justify-center items-center">
                  <img
                    className="w-[200px] h-[200px] rounded-lg object-cover mb-4"
                    src={selectedQuestion?.mediafile.media_file}
                    alt={selectedQuestion?.mediafile.name}
                  />
                </div>
              )}
              <Statement
                text={selectedQuestion?.description}
                size={StatementSizes.XL2}
                extraStyles="text-center"
              />
              <Statement
                text="We're recording!"
                size={StatementSizes.XL}
                extraStyles="text-center mt-6"
              />
              <Statement
                text="Just speak normally, as you would to a friend."
                size={StatementSizes.XL}
                extraStyles="text-center"
              />
              <Statement
                text="Press “Stop” when you're finished."
                size={StatementSizes.XL}
                extraStyles="text-center"
              />
            </>
          )}
          {readyToStart && story && (
            <>
              <Statement
                text="Great job!"
                size={StatementSizes.XL2}
                extraStyles="text-center"
              />
              <Statement
                text="Now, we’re building some follow-up questions."
                size={StatementSizes.MD}
                extraStyles="text-center mt-6"
              />
              {isBuilding && (
                <div className="flex items-center justify-center mt-6">
                  <Spinner className="h-16 w-16" color="purple" />
                </div>
              )}
            </>
          )}
          {!readyToStart && (
            <div className=" max-w-[400px] mx-auto mt-[100px]">
              <div className="flex items-center justify-center">
                <RecordingButton
                  isActive={isRecording}
                  action={toggleRecording}
                  inActiveLabel="Record"
                />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export { Step2 };
