import { FC, useRef } from "react";
import classNames from "classnames";
import { StoryChunk } from "../../typings/Story";
import { Button } from "../Button";
import { ButtonTypes } from "../../typings/components";
import { useAudioAndTranscriptionItem } from "./useAudioAndTranscriptionItem";

export interface AudioAndTranscriptionItemProps {
  storyChunk: StoryChunk;
  hideAudio?: boolean;
  customLabel?: {
    on: string;
    off: string;
  };
}

const AudioAndTranscriptionItem: FC<AudioAndTranscriptionItemProps> = ({
  storyChunk,
  hideAudio = false,
  customLabel,
}) => {
  const { isTranscriptionVisible, toggleTranscription } =
    useAudioAndTranscriptionItem();
  const refText = useRef<null | HTMLDivElement>(null);
  const styles = classNames(`py-2`);
  const { text, audio } = storyChunk;
  const labelActions = `${
    customLabel
      ? isTranscriptionVisible
        ? customLabel.on
        : customLabel.off
      : isTranscriptionVisible
      ? "Hide answer"
      : "View answer"
  }`;
  const autoScroll = () => {
    if (!isTranscriptionVisible) {
      setTimeout(() => {
        refText.current?.scrollIntoView({ behavior: "smooth" });
      });
    }
  };
  return (
    <div className={styles}>
      {audio && !hideAudio && (
        <audio controls className="w-full h-8">
          <source type="audio/mpeg" src={audio.replace(".wav", ".mp3")} />
          <source type="audio/wav" src={audio} />
        </audio>
      )}
      <Button
        style={ButtonTypes.LINK}
        action={() => {
          toggleTranscription();
          autoScroll();
        }}
        label={labelActions}
        extraStyles={`${hideAudio && "underline ml-auto pr-0"}`}
      />
      {isTranscriptionVisible && (
        <p ref={refText} className={`${hideAudio && "md:pl-[30%]"}`}>
          {text}
        </p>
      )}
    </div>
  );
};

export { AudioAndTranscriptionItem };
