export const useMediaVerification = () => {
  const verifyMicrophoneStatus = async () => {
    try {
      // @ts-expect-error this validations is
        const permiso = await navigator.permissions.query({ name: 'microphone' });
        return permiso.state; // 'granted', 'denied', 'prompt'
    } catch (error) {
        console.error("Error checkign microphone:", error);
        return null;
    }
  }
  
  const requestMicrophoneAccess = async () => {
    try {
        const {active} = await navigator.mediaDevices.getUserMedia({ audio: true });
        console.log("Access granted");
        return active;
    } catch (error) {
        console.error("Access denied");
        return false;
    }
  }

  return {
    verifyMicrophoneStatus,
    requestMicrophoneAccess
  };
};

