import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LoginValues } from "../../typings/form-schemas";
import { useAuthService } from "../../services/useAuthService";
import { loginSchema } from "../../utils/form-validation/yup-schemas";
import { useFormik } from "formik";
import { useAPI } from "../../utils/api-connection";

const useLogin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const initialValues: LoginValues = { username: "", password: "" };

  const navigate = useNavigate();
  const { loginWithPassword } = useAuthService();
  const { closeSession } = useAPI();
  const onSubmit = () => {
    if (!formikObject.isValid) return;
    setIsLoading(true);
    setErrorMessage(null);
    login();
  };

  useEffect(() => {
    closeSession();
  }, []);

  const formikObject = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit,
    validateOnBlur: true,
  });

  const login = () => {
    loginWithPassword(formikObject.values, () => navigate("/"))
      .catch((e) => setErrorMessage(e.error))
      .finally(() => setIsLoading(false));
  };

  return { isLoading, errorMessage, formikObject, navigate };
};

export { useLogin };
