import { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { BACK_BTN_ROUTE_BLACKLIST } from "../../constants/routesList";
import { MLStateContext } from "../../services/stateProvider";
import { USER_MODES } from "../../typings/state";

const useHeader = () => {
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { state, updateStateItem, updateStoredState } =
    useContext(MLStateContext);
  const { noHeader, pageStyle, activeMode, API_KEY, userDetails, partnership } =
    state;
  const isAuthenticated = Boolean(API_KEY && API_KEY !== "");
  const isStoryTeller = activeMode === USER_MODES.STORYTELLER;
  const toggleMode = () =>
    updateStoredState({
      activeMode: isStoryTeller ? USER_MODES.LISTENER : USER_MODES.STORYTELLER,
    });

  useEffect(() => {
    if (
      !BACK_BTN_ROUTE_BLACKLIST.some((item) =>
        location.pathname.includes(item)
      ) &&
      state.historyNavigation
    ) {
      location.pathname === "/"
        ? updateStateItem({ historyNavigation: [] })
        : updateStateItem({
            historyNavigation: [...state.historyNavigation, location.pathname],
          });
    }
  }, [location.pathname]);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);
  return {
    noHeader: !isAuthenticated || noHeader,
    pageStyle,
    isMenuOpen,
    userDetails,
    isStoryTeller,
    toggleMode,
    toggleMenu,
    partnership,
  };
};

export { useHeader };
