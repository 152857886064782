import { useState } from "react";

const useOptionedItemsList = () => {
  const [openedOptions, setOpenedOptions] = useState<string | null>();
  const openOptions = (options: string) => setOpenedOptions(options);
  const closeOptions = () => setOpenedOptions(null);
  return { openedOptions, openOptions, closeOptions };
};

export { useOptionedItemsList };
