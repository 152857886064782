import { FC, createRef, FocusEvent, ChangeEvent, RefObject } from "react";

interface EditFormControlProps {
  name: string;
  value: string;
  rows?: number;
  placeholder: string;
  error?: string;
  touched?: boolean;
  isMultiline?: boolean;
  handleChange: (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  handleBlur: (e: FocusEvent) => void;
  styles?: string;
}

const EditFormControl: FC<EditFormControlProps> = (
  props: EditFormControlProps
) => {
  const {
    name,
    rows = 1,
    value,
    placeholder,
    error,
    touched,
    handleChange,
    handleBlur,
    isMultiline = false,
    styles = "",
  } = props;
  const inputRef = isMultiline
    ? createRef<HTMLTextAreaElement>()
    : createRef<HTMLInputElement>();
  const onChangeValue = (e: ChangeEvent<HTMLTextAreaElement>) => {
    handleChange(e);
  };

  return (
    <div className="mb-4">
      {isMultiline ? (
        <textarea
          className={`flex mb-11 overflow-hidden w-full border-2 rounded-2xl px-6 resize-y py-4 flex-nowrap focus:outline-none ${styles}`}
          ref={inputRef as RefObject<HTMLTextAreaElement>}
          id={name}
          name={name}
          placeholder={placeholder}
          onChange={onChangeValue}
          onBlur={handleBlur}
          value={value}
          rows={rows}
        />
      ) : (
        <input
          className={`flex mb-11 overflow-hidden w-full border-2 rounded-2xl px-6 resize-y py-4 flex-nowrap focus:outline-none ${styles}`}
          type="text"
          ref={inputRef as RefObject<HTMLInputElement>}
          id={name}
          name={name}
          placeholder={placeholder}
          onChange={(e) => handleChange(e)}
          onBlur={handleBlur}
          value={value}
        />
      )}
      {error && touched && <p className="text-red-900 -mt-9 mb-3">{error}</p>}
    </div>
  );
};

export { EditFormControl };
