import { FC } from "react";
import { useLobby } from "./useLobby";
import { Statement } from "../../components/Statement";
import { MaxWidthContainer } from "../../components/MaxWidthContainer";
import { Button } from "../../components/Button";
import { ButtonTypes } from "../../typings/components";

interface LobbyProps {}

const Lobby: FC<LobbyProps> = () => {
  const { navigate } = useLobby();

  return (
    <div>
      <MaxWidthContainer isPageLayout extraStyles="pb-12 md:pb-4">
        <div className="ml-[8%] mr-[8%]">
          <Statement
            text="Welcome to Memory Lane"
            extraStyles="text-center text-primary text-5xl mb-8"
          />
          <p className="text-center mb-8">
            Your stories and wisdom, captured and saved forever.
          </p>
        </div>
        <Button
          label="Create an account"
          extraStyles="w-[90%] ml-[5%] mr-[5%] mb-2"
          action={() => navigate("/register")}
        />
        <Button
          label="Login"
          style={ButtonTypes.TERTIARY}
          extraStyles="w-[90%] ml-[5%] mr-[5%]"
          action={() => navigate("/login")}
        />
      </MaxWidthContainer>
    </div>
  );
};

export { Lobby };
