/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { Story } from "../../typings/Story";
import { useParams } from "react-router-dom";
import { trimText } from "../../utils/text-format";
import { useStoriesService } from "../../services/useStoriesService";
import { UserDetails } from "../../typings/User";
import { MLStateContext } from "../../services/stateProvider";

const useStories = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserDetails>();
  const { getAll, getByIdFiltered } = useStoriesService();
  const [allStories, setAllStories] = useState<Array<Story>>([]);
  const {
    state: { userDetails },
  } = useContext(MLStateContext);
  const { id = userDetails?.email } = useParams();

  useEffect(() => {
    loadInfo();
  }, [id]);

  useEffect(() => {
    if(selectedUser?.id) {
      getByIdFiltered(selectedUser?.id, true).then(r => {
        setAllStories(r || []);
      })
    }
  }, [selectedUser]);

  const loadInfo = () => {
    setIsLoading(true);
    getAll()
      .then((l) => {
        setIsLoading(false);
        if (userDetails) setSelectedUser(userDetails);
        setAllStories(
          l.map((s) => ({ ...s, shortText: trimText(s.shortText || "") }))
        );
      })
      .finally(() => setIsLoading(false));
  };

  return { allStories, isLoading, selectedUser };
};

export { useStories };
