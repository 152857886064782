import { FC } from "react";
import { Statement } from "../Statement";
import { ProgressBar } from "primereact/progressbar";
import { Chapter } from "../../typings/Chapter";
import { Link } from "react-router-dom";
import { TARGET_WORDS_PER_CHAPTER } from "../../constants/services";

interface ChapterTileProps {
  chapter: Chapter;
  isDisabled?: boolean;
}

const ChapterTile: FC<ChapterTileProps> = ({ chapter, isDisabled = false }) => {
  const { id, name, category, wordsCount, storiesCount } = chapter;
  const isTileDisabled = isDisabled ? "opacity-60" : "";

  return (
    <section
      className={`bg-tertiary px-4 py-4 rounded-lg flex flex-col md:flex-row items-stretch ${isTileDisabled}`}
    >
      <div className="flex-1">
        <div className="flex flex-row items-center justify-between md:flex-col md:items-start">
          <Statement
            text={name}
            extraStyles="mb-1 md:mb-3 text-xl md:text-3xl font-bold md:font-normal"
          />
          <Link
            to={`/chapter/${id}`}
            className="text-sm md:text-base underline inline-block"
          >
            {storiesCount > 0 ? (
              <p>Add more</p>
            ) : (
              <p className="text-secondary font-medium">Start this chapter</p>
            )}
          </Link>
        </div>
        <p className="text-xs md:text-base">
          Topics include:{" "}
          {category?.subcategories.map((s) => s.name).join(", ")}
        </p>
      </div>
      <div className="flex flex-col mt-2 md:mt-0 md:max-w-[300px] lg:max-w-[400px] w-full">
        <ProgressBar
          value={(100 / TARGET_WORDS_PER_CHAPTER) * wordsCount}
          color="#5DA4B0"
          displayValueTemplate={() => null}
          style={{ height: 14 }}
        ></ProgressBar>
        <p className="text-center mt-1 md:mt-0 text-xs md:text-base">
          <span className="font-bold">{storiesCount} Stories</span> totaling{" "}
          <span className="font-bold text-chapters-progress">
            {wordsCount} Words
          </span>
        </p>
      </div>
      <div className="ml-4 justify-center hidden md:flex">
        <img
          className="pointer-events-none"
          alt="ordering handle"
          src="/assets/images/sorting-icon.svg"
        />
      </div>
    </section>
  );
};

export { ChapterTile };
