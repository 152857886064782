import {
  FC,
  createRef,
  FocusEvent,
  ChangeEvent,
} from "react";


interface FormControlProps {
  name: string;
  value: string;
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: FocusEvent) => void;
  error?: string;
  touched?: boolean;
  accept?: string;
  nonMargins?: boolean;
  isSmall?: boolean;
}

const TermsConditionFormControl: FC<FormControlProps> = (props: FormControlProps) => {
  const {
    accept,
    name,
    error,
    touched,
    value,
    handleChange,
    handleBlur,
    nonMargins,
    isSmall
  } = props;

  const inputRef = createRef<HTMLInputElement>();
  const errorStyles = error ? "border-red-900" : '';
  const marginsStyles = nonMargins ? "mb-1" : "mb-10";
  const heightSize = isSmall ? "w-[20px] h-[20px]" : "w-[40px] h-[24px]";
  const onChangeValue = (e: ChangeEvent<HTMLInputElement>) => {
    handleChange(e);
  };
  return (
    <>
      <div className={`flex flex-row items-start ${marginsStyles}`}>
        <input type="checkbox"
          required
          ref={inputRef}
          id={name}
          name={name}
          onChange={onChangeValue}
          onBlur={handleBlur}
          accept={accept}
          value={value}
          className={`${heightSize} border ${errorStyles}`}/>
        <label className="ml-2" htmlFor="acceptTC">By checking this box, you agree to the <a href="https://www.memory-lane.ai/terms-and-conditions" target="blank" className="underline cursor-pointer">Terms and Conditions</a> and the <a href="https://www.memory-lane.ai/privacy-policy" target="blank" className="underline cursor-pointer">Memory Lane Privacy Policy</a>.</label>
      </div>
      {value}

      {touched && !value && (
        <p className="text-red-900 ml-4 -mt-9 mb-3">
          Accept terms and conditions is required.
        </p>
      )}
    </>
  );
};

export { TermsConditionFormControl };
