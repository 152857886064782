import { FollowUpQuestionType } from "../typings/FollowUpQuestion";
import { UserBase } from "../typings/User";

export enum QUICK_ACTIONS {
  ADD_NEW_STORYTELLER = "ADD_NEW_STORYTELLER",
  ADD_NEW_LISTENER = "ADD_NEW_LISTENER",
  ASK_QUESTION = "ASK_QUESTION",
}

export enum MAIN_MODAL_ACTIONS {
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  CUSTOM_MODAL = "CUSTOM_MODAL",
}

export enum USER_AI {
  ID = 0,
}

export const STORY_COMPLETITION_LEVEL = {
  OVERVIEW: {
    minLength: 1,
    label: "Overview",
    color: "#5DA4B0",
  },
  SHORT: {
    minLength: 20,
    label: "Short Story",
    color: "#5DA4B0",
  },
  GOOD: {
    minLength: 50,
    label: "Good Story",
    color: "#5DA4B0",
  },
  LONG: {
    minLength: 70,
    label: "Long Story",
    color: "#5DA4B0",
  },
  DETAILED: {
    minLength: 100,
    label: "Detailed Story",
    color: "#5DA4B0",
  },
};

export const MAX_FILE_SIZE = 10 * 1024 * 1024;
export const MAX_FILE_PER_STORY = 6;
export const TARGET_WORDS_PER_CHAPTER = 1000;

export const AI_AUTHOR = {
  id: -1,
  dateJoined: "",
  email: "Memory Lane",
  firstName: "Memory Lane",
  fullName: "Memory Lane",
  isActive: true,
  isStaff: true,
  isSuperuser: false,
  lastLogin: "",
  lastName: "Memory Lane",
  username: "Memory Lane",
  resource_uri: "Memory Lane",
  profile: {
    dateOfBirth: "",
    emailConfirmed: true,
    langs: ["EN", "ES"],
    photo: `/assets/images/ml-square-avatar.svg`,
    photoSm: `/assets/images/ml-square-avatar.svg`,
    sex: "AI",
    waitlisted: false,
  },
} as UserBase;

export const CUSTOM_QUESTION = {
  id: -1,
  name: "",
  text: "",
  description: "",
  status: "",
  isActive: true,
  createdAt: new Date(),
  updatedAt: new Date(),
  resourceUri: "",
  author: AI_AUTHOR,
  storyRef: undefined,
  storyName: undefined,
} as FollowUpQuestionType;

export const PARTNERSHIPS = {
  MITCHELL_MARTIN: "MITCHELL_MARTIN",
};
