import { useAPI } from "../utils/api-connection";
import { LoginResponse, UserDetailInfo } from "../typings/User";
import { MLStateContext } from "./stateProvider";
import { useContext } from "react";
import { NotificationDetail } from "../typings/Notifications";
import { AiPrompt } from "../typings/Prompts";
import { PARTNERSHIPS, USER_AI } from "../constants/services";
import {
  AI_PROMPTS,
  MITCHELL_MARTIN_AI_PROMPTS,
} from "../constants/initialPrompts";

const shuffleArray = <T>(array: Array<T>) => {
  return array.sort(() => Math.random() - 0.5);
};

const useUserService = () => {
  const {
    state: { partnership },
  } = useContext(MLStateContext);
  console.log(partnership);
  const preloadedQuestions =
    partnership === PARTNERSHIPS.MITCHELL_MARTIN
      ? MITCHELL_MARTIN_AI_PROMPTS
      : AI_PROMPTS;
  console.log(preloadedQuestions);
  const { getUserDetails, getStorytellerDetails } = useAPI();
  const {
    state: { userDetails },
    updateStoredState,
    updateStateItem,
  } = useContext(MLStateContext);

  const storeUserDetails = (detailResponse: LoginResponse) => {
    const { stories, following, followers, notifications } = detailResponse;
    const userDetails = new UserDetailInfo(detailResponse);
    updateStoredState({
      userDetails,
    });
    updateStateItem({
      userDetails,
      aiPrompts: shuffleArray<AiPrompt>(preloadedQuestions).slice(0, 15),
      stories: stories ? userDetails.stories : [],
      following: following ? following.map((f) => new UserDetailInfo(f)) : [],
      followers: followers ? followers.map((f) => new UserDetailInfo(f)) : [],
      notifications: notifications
        ? notifications
            .map((n) => new NotificationDetail(n))
            // prevent the display of AI questions under the notification section
            .filter((n) => n.author.id !== USER_AI.ID)
        : [],
    });
  };

  const refreshUserDetails = (callback?: () => void) => {
    if (!userDetails || !userDetails.email) {
      if (callback) callback();
      throw new Error("There is no user logged in.");
    }

    return getUserDetails(userDetails?.email).then(async (r) => {
      await storeUserDetails(r);
      if (callback) callback();
    });
  };

  const getSTDetails = (
    username: string,
    callback?: (r: UserDetailInfo) => void
  ) => {
    return getStorytellerDetails(username).then((r) => {
      const userDetails = new UserDetailInfo(r);
      if (callback) callback(userDetails);
      return userDetails;
    });
  };

  return { refreshUserDetails, getSTDetails };
};

export { useUserService };
