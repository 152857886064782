import { FC } from "react";
import { useTellStory } from "./useTellStory";
import { MaxWidthContainer } from "../../components/MaxWidthContainer";
import { LogoNavigation } from "../../components/LogoNavigation";
import { Statement, StatementSizes } from "../../components/Statement";
import { Button } from "../../components/Button";
import { Step1 } from "./Steps/Step1";
import { Step2 } from "./Steps/Step2";
import { ButtonTypes } from "../../typings/components";
import { Step3 } from "./Steps/Step3";
import { IconButton, IconButtonTypes } from "../../components/IconButton";
import { useNavigate } from "react-router-dom";

interface StepSettingProps {}

const TellStory: FC<StepSettingProps> = () => {
  const {
    stepBackNavigation,
    setDisplayHelp,
    animationClass,
    displayHelp,
    currentStep,
  } = useTellStory();
  const navigate = useNavigate();
  const steps = [
    {
      label: "How it works",
      component: <Step1 />,
    },
    {
      label: "Microphone Check",
      component: <Step2 />,
    },
    {
      label: "Microphone Check",
      component: <Step3 />,
    },
  ];

  return (
    <div className="fixed z-30 pt-8 bg-primary h-full w-full text-tertiary overflow-y-auto">
      <MaxWidthContainer extraStyles="h-full">
        <LogoNavigation isLightLogo noBackButton></LogoNavigation>
        <div>
          <div className={`flex flex-row justify-between relative`}>
            <>
              <IconButton
                icon={IconButtonTypes.BACK}
                onClick={() => stepBackNavigation()}
                extraStyles="w-5 h-5 mr-auto"
              />
            </>
          </div>
          <div className={`mb-8 pt-4 opacity-0 ${animationClass}`}>
            {!displayHelp &&
              steps.filter((a, index) => {
                if (index === currentStep) {
                  return a;
                }
              })[0].component}
            {displayHelp && (
              <div>
                <Statement
                  text={"Hmm, we're having troubles."}
                  size={StatementSizes.XL3}
                  extraStyles="text-center"
                />
                <h2 className="text-center pt-6 font-conversation text-2xl align-text-bottom">
                  Need help? Just click the{" "}
                  <span className="-mt-8 bg-[#D24B34] inline-block rounded-full w-[44px] h-[44px]">
                    <img
                      className="inline mt-2"
                      width={16}
                      height={16}
                      src="/assets/images/help-assist-log.svg"
                      alt="Sound wave"
                    />
                  </span>
                  <br />
                  in the lower right of your screen
                  <br />
                  and our Support Team will help you get going.
                </h2>
                <h2 className="text-center pt-6 font-conversation text-2xl">
                  You can also view this{" "}
                  <a
                    className="underline cursor-pointer underline-offset-2"
                    rel="noreferrer"
                    target="_blank"
                    href="https://intercom.help/memory-lane/en/articles/8520638-best-practices-for-recording"
                  >
                    help article
                  </a>{" "}
                  on recording setup and tips.
                </h2>
                <div className="flex max-w-[600px] mx-auto mt-[100px]">
                  <Button
                    extraStyles="mb-2 z-10 px-6 w-full md:w-[240px] md:mr-6"
                    style={ButtonTypes.TERTIARY}
                    label="Try again"
                    action={() => setDisplayHelp(false)}
                  />
                  <Button
                    isFullWidth
                    extraStyles="mb-2 z-10"
                    style={ButtonTypes.TERTIARY}
                    label="Let me take a look around"
                    action={() => navigate("/mybook/")}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </MaxWidthContainer>
    </div>
  );
};

export { TellStory };
