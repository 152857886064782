/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { Interval, Recorder, SetRecorder } from "../typings/audioRecorder";
import { MLStateContext } from "../services/stateProvider";
import { useInfoToastService } from "../services/useInfoToastService";
import { WSS_BASE_URL } from "../constants/endpoints";

const WS_IP = WSS_BASE_URL;
const KEY = `12345YmzeZ0IH4QmH1xGamQbNbvjcPaLuri60wMPr9sbCPTIwomcDC4Lt3SWCqaESQe7f1y0GCjozx8bbpZlhcc6t7TR0KwbXdp3ZkLIWGJIrGNT50BVyglhtsbvIcb8`;

const initialState: Recorder = {
  recordingTime: 0,
  isRecording: false,
  mediaStream: null,
  mediaRecorder: null,
  audio: null,
  file: null,
};

const startRecording = async (setRecorderState: SetRecorder) => {
  try {
    const stream: MediaStream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    });
    setRecorderState((prevState) => ({
      ...prevState,
      isRecording: true,
      mediaStream: stream,
    }));
  } catch (error) {
    console.log(error);
  }
};

const saveRecording = (recorder: MediaRecorder) => {
  if (recorder.state !== "inactive") recorder.stop();
};

interface UseAudioRecorderProps {
  handleTranscriptUpdate: (s: string) => void;
}

const useAudioRecorder = ({
  handleTranscriptUpdate,
}: UseAudioRecorderProps) => {
  const {
    state: { userDetails, currentStory, currentFollowupQuestion },
    updateStateItem,
  } = useContext(MLStateContext);
  const [recorderState, setRecorderState] = useState<Recorder>(initialState);
  const [socket, setSocket] = useState<WebSocket>();
  const { createErrorMsg } = useInfoToastService();

  const setupWSS = () => {
    const followUpString = currentFollowupQuestion
      ? `/${currentFollowupQuestion.id}`
      : "";
    try {
      setSocket(
        new WebSocket(
          `${WS_IP}/listen/${userDetails?.id}/${currentStory?.id}${followUpString}?api_key=${KEY}`
        )
      );
    } catch (error) {
      createErrorMsg({
        title: "Connection error.",
        message: "We are facing some problems with the connection.",
      });
      setRecorderState((prevState) => {
        return {
          ...prevState,
          isRecording: false,
        };
      });
    }
  };

  useEffect(() => {
    if (socket) {
      socket.onopen = () => {
        recorderState.mediaRecorder?.addEventListener("dataavailable", (e) => {
          if (e.data.size > 0 && socket.readyState === 1) socket.send(e.data);
        });
        if (recorderState.mediaRecorder?.state !== "recording") {
          recorderState.mediaRecorder?.start(250);
        }
      };

      socket.onmessage = (message) => {
        const transcript = message.data;
        if (transcript) handleTranscriptUpdate(transcript);
      };

      socket.onclose = () => {
        console.log("Closed...");
        recorderState.mediaStream?.getTracks().forEach((track) => track.stop());
        socket.removeEventListener("message", () => null);
      };
    }
  }, [socket]);

  useEffect(() => {
    let recordingInterval: Interval = null;

    if (recorderState.isRecording) {
      recordingInterval = setInterval(() => {
        setRecorderState((prevState) => {
          return {
            ...prevState,
            recordingTime: prevState.recordingTime + 1,
          };
        });
      }, 1000);
    } else {
      typeof recordingInterval === "number" && clearInterval(recordingInterval);
    }

    return () => {
      typeof recordingInterval === "number" && clearInterval(recordingInterval);
    };
  });

  useEffect(() => {
    setRecorderState((prevState) => {
      if (prevState.mediaStream) {
        return {
          ...prevState,
          mediaRecorder: new MediaRecorder(prevState.mediaStream),
        };
      } else {
        return prevState;
      }
    });
  }, [recorderState.mediaStream]);

  const stopRecording = () => {
    setTimeout(() => {
      socket?.close();
    }, 2000);
    setSocket(undefined);
    updateStateItem({
      currentFollowupQuestion: undefined,
      currentStory: undefined,
    });
    setRecorderState((prevState) => ({
      ...prevState,
      isRecording: false,
    }));
    if (recorderState.mediaRecorder) saveRecording(recorderState.mediaRecorder);
  };

  const startRecordingNow = () => {
    setupWSS();
  };

  const changeStateToRecording = () => {
    startRecording(setRecorderState);
  };

  return {
    recorderState,
    startRecording: startRecordingNow,
    cancelRecording: () => {
      stopRecording();
      setTimeout(() => setRecorderState(initialState),2050)
      
    },
    saveRecording: stopRecording,
    changeStateToRecording,
  };
};

export { useAudioRecorder, startRecording, saveRecording };
