import { useEffect, useState } from "react";
import { MediaFile } from "../../../typings/MediaLibrary";
// import { MLStateContext } from "../../../services/stateProvider";
import { useMediaService } from "../../../services/useMediaService";
import { useInfoToastService } from "../../../services/useInfoToastService";
// import { useNavigate } from "react-router-dom";

interface UseMediaLibraryProps {
  closeMenu: VoidFunction;
}

const useMediaLibrary = ({ closeMenu }: UseMediaLibraryProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const { getAllFiles, addMediaFile, removeFile } = useMediaService();
  const { createSuccessMsg } = useInfoToastService();
  const [mediaFiles, setMediaFiles] = useState<Array<MediaFile>>([]);

  useEffect(() => {
    reloadFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadFiles = () => {
    setIsLoading(true);
    getAllFiles().then((files) => {
      setMediaFiles(files.sort((a,b) => b.id - a.id));
      setIsLoading(false);
    });
  };

  const deleteFile = (f: MediaFile) => {
    setIsLoading(true);
    removeFile(f.id).then(() => {
      reloadFiles();
      createSuccessMsg({
        title: "Image deleted.",
        message: "Image was succesfully deleted.",
      });
    });
  };

  const addMediaFiles = (files: File[]) => {
    setIsLoading(true);
    files.forEach((f) =>
      addMediaFile(f, f.name).then(() => {
        createSuccessMsg({
          title: "Image uploaded.",
          message: "Image was succesfully uploaded.",
        });
      })
    );
    setTimeout(reloadFiles, 3000);
  };

  const options = [
    {
      label: "Delete image",
      action: (file: MediaFile) =>
        window.confirm("Are you sure you want to delete this file?")
          ? deleteFile(file)
          : null,
    },
  ];

  return {
    options,
    isLoading,
    mediaFiles,
    addMediaFiles,
    getAllFiles,
  };
};

export { useMediaLibrary };
