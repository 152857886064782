import { useContext, useEffect, useState } from "react";
import { MLStateContext } from "../../services/stateProvider";
import { useUserService } from "../../services/useUserService";
import { useStoriesService } from "../../services/useStoriesService";
import { Story } from "../../typings/Story";
import { useBookService } from "../../services/useBookService";
import { BookDetails } from "../../typings/Book";
import { Chapter } from "../../typings/Chapter";
import {
  areChaptersEquals,
  getIncompleteChapters,
  getOrderedChapters,
} from "../../utils/book-helper";
import { useMediaService } from "../../services/useMediaService";
import { useInfoToastService } from "../../services/useInfoToastService";
import { usePromptsService } from "../../services/usePromptsService";
import { useTellStoryService } from "../../services/useTellStoryService";
import { useNavigate } from "react-router-dom";

interface DndDivider {
  isDivider: true;
}

const useMyBook = () => {
  const { getByIdFiltered } = useStoriesService();
  const [isLoading, setIsLoading] = useState(false);
  const [stories, setStories] = useState<Array<Story>>([]);
  const [book, setBook] = useState<BookDetails>();
  const {
    state: { followers = [], following = [], userDetails },
  } = useContext(MLStateContext);
  const userId = userDetails?.id || 0;
  const { refreshUserDetails } = useUserService();
  const { getBookDetail, orderBookChapters } = useBookService();
  const { addMediaFile } = useMediaService();
  const { createSuccessMsg } = useInfoToastService();
  const { getAllPhotoOnes } = usePromptsService();
  const navigate = useNavigate();
  const [chapters, setChapters] = useState<Array<Chapter | DndDivider>>([]);
  const [pendingChapters, setPendingChapters] = useState<Array<Chapter>>([]);
  const dividerIndex = chapters.findIndex((i) => (i as DndDivider)?.isDivider);
  const [storiesCount, setStoriesCount] = useState<number>(0);
  const [categoryToRecord, setCategoryToRecord] = useState("Passions"); // this value could be generated based on some history
  const { setCategory } = useTellStoryService();
  useEffect(() => {
    setCategoryToRecord("Passions");
    setIsLoading(true);

    Promise.all([
      refreshUserDetails(),
      getBookDetail(),
      getByIdFiltered(userId, true),
    ])
      .then((results) => {
        setBook(results[1]);
        setStories(results[2]);
      })
      .finally(() => setIsLoading(false));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (book) {
      setChapters([
        ...book.chapters.filter((c) => c.isActive),
        {
          isDivider: true,
        },
        ...book.chapters.filter((c) => !c.isActive),
      ]);
      setPendingChapters(getIncompleteChapters(book.chapters));
    }
  }, [book]);

  useEffect(() => {
    if (
      book &&
      !areChaptersEquals(book.chapters, chapters as Chapter[], dividerIndex)
    ) {
      reorderChapters();
    }
  }, [chapters]);

  useEffect(() => {
    setStoriesCount(
      [0, ...pendingChapters.map((p) => p.storiesCount).flat(1)].reduce(
        (p, c) => p + c
      )
    );
  }, [pendingChapters]);

  const onChaptersDragged = async (cList: Array<Chapter | DndDivider>) => {
    setChapters(cList);
  };

  const reorderChapters = () => {
    const orderedChapters = getOrderedChapters(
      chapters as Chapter[],
      dividerIndex
    );

    orderBookChapters(orderedChapters).then((b) => setBook(b));
  };

  const recordCategory = (category: string) => {
    setCategory(category);
    navigate("/tellstory");
  };

  const numberOfActiveChapters =
    book?.chapters.filter((c) => c.isActive).length || 0;
  const bookProgress =
    (100 / numberOfActiveChapters) *
    (numberOfActiveChapters - pendingChapters.length);

  const addMediaFiles = (files: File[]) => {
    setIsLoading(true);

    files.forEach((f) =>
      addMediaFile(f, f.name).then((res) => {
        setTimeout(() => {
          createSuccessMsg({
            title: "Image uploaded.",
            message: "Image was succesfully uploaded.",
          });
          getAllPhotoOnes().then(() => {
            navigate("/tellstory");
          });
        }, 15000);
      })
    );
  };

  return {
    book,
    chapters,
    stories,
    isLoading,
    followers,
    following,
    userDetails,
    bookProgress,
    dividerIndex,
    storiesCount,
    pendingChapters,
    categoryToRecord,
    recordCategory,
    onChaptersDragged,
    addMediaFiles,
    setChapters,
  };
};

export { useMyBook };
