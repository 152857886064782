import { OptionedListItem } from "./OptionedListItem";
import { useOptionedItemsList } from "./useOptionedItemsList";

export type ListItem = {
  optionsType?: string;
};
export type OptionItem<T> = {
  types?: Array<string>;
  label: string;
  action: (item: T) => void;
};

export type OptionedItemsListProps<T> = {
  options: Array<OptionItem<T>>;
  items: Array<T>;
  displayItems: (data: T, index: number) => React.ReactNode;
  noItemsLabel: string;
};

const OptionedItemsList = <T extends ListItem>(
  props: OptionedItemsListProps<T>
) => {
  const { closeOptions, openedOptions, openOptions } = useOptionedItemsList();
  const { items, options, displayItems, noItemsLabel } = props;
  const collapseMenuBackdrop = `fixed left-0 bottom-0 w-full z-10 cursor-pointer ${
    openedOptions ? "h-full" : "h-0"
  }`;
  return (
    <div className="border-light-borders border-b border-border">
      <div className={collapseMenuBackdrop} onClick={closeOptions}></div>
      {items &&
        items.map((item, index) => (
          <OptionedListItem
            key={`optionedItem-${index}`}
            item={item}
            index={index}
            displayItems={displayItems}
            options={options}
            openOptions={openOptions}
            closeOptions={closeOptions}
            openedOptions={openedOptions}
          />
        ))}
      {!items ||
        (!items.length && (
          <p className="border-light-borders border-t border-border px-6 py-8">
            {noItemsLabel}
          </p>
        ))}
    </div>
  );
};

export { OptionedItemsList };
