import { useContext, useEffect, useState } from "react";
import { useAudioRecorder } from "../../../../hooks/useAudioRecorder";
import { MLStateContext } from "../../../../services/stateProvider";
import { usePromptsService } from "../../../../services/usePromptsService";
import { useStoriesService } from "../../../../services/useStoriesService";
import { useTellStoryService } from "../../../../services/useTellStoryService";
import { Prompt } from "../../../../typings/Prompts";
import { Story } from "../../../../typings/Story";
import { MediaFileDetail } from "../../../../typings/MediaLibrary";
import { PARTNERSHIPS } from "../../../../constants/services";
import { MITCHELL_MARTIN_AI_PROMPTS } from "../../../../constants/initialPrompts";

const useStep2 = () => {
  const {
    stepNextNavigation,
    stepBackNavigation,
    setDisplayHelp,
    tellStory,
    selectedCategory = "",
    cleanPromts,
  } = useTellStoryService();
  const { createNew, getById, updateMediaFiles } = useStoriesService();
  const { getAllByCategory } = usePromptsService();

  const {
    state: { currentStory, prompts, partnership },
    updateStateItem,
  } = useContext(MLStateContext);
  const [readyToStart, setReadyToStart] = useState<boolean>(false);
  const [isBuilding, setIsBuilding] = useState<boolean>(false);
  const [selectedQuestion, setSelectedQuestion] = useState<Prompt>();
  const [initialPrompts, setInitialPrompts] = useState<Prompt[]>();
  const [transcriptText, setTranscriptText] = useState<string>("");
  const [story, setStory] = useState<Story>();
  const [isLoading, setIsLoading] = useState(false);
  const handleTranscriptUpdate = (t: string) => {
    setTranscriptText((prev) => `${prev} ${t}`);
  };

  const {
    changeStateToRecording,
    startRecording,
    recorderState,
    saveRecording,
    cancelRecording,
  } = useAudioRecorder({
    handleTranscriptUpdate,
  });

  const { isRecording } = recorderState;
  const recordItem = useState<boolean>(false);

  useEffect(() => {
    if (partnership === PARTNERSHIPS.MITCHELL_MARTIN) {
      updateStateItem({ prompts: MITCHELL_MARTIN_AI_PROMPTS });
    } else {
      if (!prompts || prompts.length === 0) {
        setIsLoading(true);
        getAllByCategory(selectedCategory).then(() => {
          setIsLoading(false);
        });
      }
    }

    return () => cleanPromts();
  }, []);

  useEffect(() => {
    if (prompts?.length) {
      setSelectedQuestion(prompts[0]);
    }
  }, [prompts]);

  useEffect(() => {
    if (isRecording) {
      if (!story && !isLoading) {
        createNew((newStory) => {
          setStory(newStory);
          updateStateItem({ currentStory: newStory, isNewStory: true });
          if (selectedQuestion && selectedQuestion.mediafile)
            updateMediaFiles(newStory.id, [
              new MediaFileDetail(selectedQuestion.mediafile),
            ]);
        });
      }
    }
  }, [isRecording]);

  useEffect(() => {
    setInitialPrompts(prompts);
  }, [prompts]);

  useEffect(() => {
    if (currentStory && isRecording) {
      startRecording();
    }
  }, [currentStory, isRecording]);

  const toggleRecording = () => {
    if (isRecording) pauseRecording();
    else changeStateToRecording();
  };

  const goBack = () => {
    stepBackNavigation();
  };
  const setCurrentQuestion = (question: Prompt) => {
    setSelectedQuestion(question);
  };

  const buildingQuestion = () => {
    setIsBuilding(true);
    if (story?.id) {
      setTimeout(() => {
        getById(story?.id).then(
          (story) => {
            setIsBuilding(false);
            console.log("story with follow questions", story);
            setStory(story);
            updateStateItem({ currentStory: story, isNewStory: true });
            goToFollowUpQuestions();
          },
          (e) => {
            console.log("ERROR loading FollowUp questions", e);
          }
        );
      }, 13000);
    }
  };

  const goToFollowUpQuestions = () => {
    goForward();
  };

  const pauseRecording = () => {
    if (transcriptText.length > 0 && story) {
      saveRecording();
      setReadyToStart(true);
      buildingQuestion();
    } else {
      cancelRecording();
      setStory(undefined);
      setReadyToStart(false);
      setDisplayHelp(true);
    }
  };

  const goForward = () => {
    stepNextNavigation();
  };
  return {
    goBack,
    goForward,
    selectedCategory,
    selectedQuestion,
    initialPrompts: initialPrompts || [],
    setCurrentQuestion,
    isBuilding,
    displayHelp: tellStory?.displayHelp,
    transcriptText,
    isRecording,
    recordItem,
    story,
    readyToStart,
    isLoading,
    toggleRecording,
    setIsLoading,
    isMitchellMartin: partnership === PARTNERSHIPS.MITCHELL_MARTIN,
  };
};

export { useStep2 };
