import {
  FileUpload,
  FileUploadSelectEvent,
  FileUploadUploadEvent,
} from "primereact/fileupload";
import { useRef, useState } from "react";

const useMediaLibraryModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const fileUploadRef = useRef<FileUpload>(null);

  const onTemplateSelect = (e: FileUploadSelectEvent) => {
    setFiles(e.files);
  };

  const onTemplateUpload = (e: FileUploadUploadEvent) => {
    console.log(e.files);
  };

  const onTemplateRemove = (
    file: File,
    callback: (event?: unknown) => void
  ) => {
    setFiles((prev) => prev?.filter((f) => f.size !== file.size));
    callback();
  };

  const onTemplateClear = () => {
    setFiles([]);
  };

  const setInputName = (file: File, text: string) => {
    setFiles((prev) =>
      prev.map((f) => {
        return f.size === file.size ? { ...f, name: text } : f;
      })
    );
  };

  const closeMediaLibrary = () => {
    setFiles([]);
    setIsOpen(false);
  };
  const openMediaLibrary = () => setIsOpen(true);

  return {
    files,
    isOpen,
    fileUploadRef,
    closeMediaLibrary,
    openMediaLibrary,
    onTemplateSelect,
    onTemplateUpload,
    onTemplateRemove,
    onTemplateClear,
    setInputName,
  };
};

export { useMediaLibraryModal };
