import { FC } from "react";
import { Button } from "../../../../components/Button";
import { Statement, StatementSizes } from "../../../../components/Statement";
import { ButtonTypes } from "../../../../typings/components";
import { StepProps } from "../../useOnboarding";
import { useStep1 } from "./useStep1";

const Step1: FC<StepProps> = () => {
  const { goForward, isMitchellMartin } = useStep1();

  return (
    <>
      <div className="text-center">
        <Statement
          text={
            isMitchellMartin
              ? "Telling a story is easy - There are just 5 steps:"
              : "Give your family something they’ll always come back to."
          }
          size={StatementSizes.XL3}
          extraStyles="text-center"
        />
        {!isMitchellMartin && (
          <Statement
            text="There are just 5 steps:"
            size={StatementSizes.XL3}
            extraStyles="text-center mt-6"
          />
        )}
        <div className="relative mt-10">
          <span className="absolute top-0 left-[50%] -ml-1 text-xl underline">
            1
          </span>
          <Statement
            text="Choose a question that inspires you"
            size={StatementSizes.LG}
            extraStyles="text-center pt-6"
          />
        </div>
        <div className="relative mt-10">
          <span className="absolute top-0 left-[50%] -ml-1 text-xl underline">
            2
          </span>
          <Statement
            text="Record whatever comes into your mind"
            size={StatementSizes.LG}
            extraStyles="text-center pt-6"
          />
        </div>
        <div className="relative mt-10">
          <span className="absolute top-0 left-[50%] -ml-1 text-xl underline">
            3
          </span>
          <Statement
            text="We’ll write the first draft of your story"
            size={StatementSizes.LG}
            extraStyles="text-center pt-6"
          />
        </div>
        <div className="relative mt-10">
          <span className="absolute top-0 left-[50%] -ml-1 text-xl underline">
            4
          </span>
          <Statement
            text={
              isMitchellMartin
                ? "Your story becomes part of the collective journey."
                : "Your story becomes a chapter in the book of your life"
            }
            size={StatementSizes.LG}
            extraStyles="text-center pt-6"
          />
        </div>
        <div className="relative mt-10">
          <span className="absolute top-0 left-[50%] -ml-1 text-xl underline">
            5
          </span>
          <Statement
            text={
              isMitchellMartin
                ? "We share your stories with the people you choose."
                : "We print your stories in a book your family will cherish forever"
            }
            size={StatementSizes.LG}
            extraStyles="text-center pt-6"
          />
        </div>
        <div className=" max-w-[400px] mx-auto mt-[100px]">
          <Button
            isFullWidth
            extraStyles="mb-2 z-10"
            style={ButtonTypes.TERTIARY}
            label="Get started"
            action={() => goForward()}
          />
        </div>
      </div>
    </>
  );
};

export { Step1 };
