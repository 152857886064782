import {
  Chapter,
  ChapterDetail,
  ChapterRequest,
  ChapterResponse,
} from "./Chapter";

export interface BookResponse {
  id: number;
  name: string;
  chapters: ChapterResponse[];
  created_at: string;
  description: string;
  is_active: boolean;
  owner: string;
  resource_uri: string;
  updated_at: string;
}

export interface Book {
  id: number;
  name: string;
  chapters: Chapter[];
  description: string;
  isActive: boolean;
  owner: string;
  resourceUri: string;
  createdAt: string;
  updatedAt: string;
}

export class BookDetails implements Book {
  id: number;
  name: string;
  chapters: Chapter[];
  description: string;
  isActive: boolean;
  owner: string;
  resourceUri: string;
  createdAt: string;
  updatedAt: string;

  constructor(book: BookResponse) {
    this.id = book.id;
    this.name = book.name;
    this.chapters = book.chapters.map((c) => new ChapterDetail(c));
    this.description = book.description;
    this.isActive = book.is_active;
    this.owner = book.owner;
    this.resourceUri = book.resource_uri;
    this.createdAt = book.created_at;
    this.updatedAt = book.updated_at;
  }
}

export class BookRequest {
  id?: number;
  name?: string;
  chapters?: ChapterRequest[];
  created_at?: string;
  description?: string;
  is_active?: boolean;
  owner?: string;
  resource_uri?: string;
  updated_at?: string;

  constructor(book: Book) {
    this.id = book.id;
    this.name = book.name;
    this.chapters = book.chapters.map((c) => new ChapterRequest(c));
    this.description = book.description;
    this.is_active = book.isActive;
    this.owner = book.owner;
    this.resource_uri = book.resourceUri;
    this.created_at = book.createdAt;
    this.updated_at = book.updatedAt;
  }
}
