import { FC } from "react";
import { useEditStory } from "./useEditStory";
import { MaxWidthContainer } from "../../components/MaxWidthContainer";
import { Spinner } from "../../components/Spinner";
import { Button } from "../../components/Button";
import { ButtonTypes } from "../../typings/components";
import { EditStoryValues } from "../../typings/form-schemas";
import { EditFormControl } from "../../components/Forms/EditFormControl";
import { Dropdown } from "primereact/dropdown";

interface EditStoryProps {
  isDisabled?: boolean;
}

const EditStory: FC<EditStoryProps> = () => {
  const {
    story,
    isLoading,
    formikObject,
    chapters,
    categories,
    setProtectedSummary,
  } = useEditStory();
  const { touched, errors, values, submitForm, handleChange, handleBlur } =
    formikObject;

  const getFieldProps = (
    fieldName: keyof EditStoryValues,
    placeholder: string
  ) => {
    return {
      name: fieldName,
      placeholder,
      touched: touched[fieldName],
      error: errors[fieldName],
      handleChange,
      handleBlur,
    };
  };

  const chapterFieldAttr = getFieldProps("chapter", "Chapter");
  const categoryFieldAttr = getFieldProps("category", "Category");
  const summaryFieldAttr = getFieldProps("summary", "Summary");

  return (
    <>
      <div className="bg-tertiary">
        <Spinner isLoading={isLoading} withBackdrop />
        {story && (
          <MaxWidthContainer isPageLayout extraStyles="pb-8">
            <form
              noValidate
              onSubmit={(e) => {
                e.preventDefault();
                submitForm();
              }}
            >
              <label
                className="text-2xl font-conversation block mb-4"
                htmlFor="chapter"
              >
                Chapter
              </label>
              <Dropdown
                value={values.chapter || ""}
                {...chapterFieldAttr}
                options={chapters}
                onBlur={chapterFieldAttr.handleBlur}
                onChange={chapterFieldAttr.handleChange}
                optionLabel="name"
                optionValue="resourceUri"
                editable
                placeholder="Chapter"
                className="w-full md:w-14rem mb-10 rounded-xl border-2 border-inherit focus:outline-none"
              />

              <label
                className="text-2xl font-conversation block mb-4"
                htmlFor="category"
              >
                Category
              </label>
              <Dropdown
                value={values.category || ""}
                {...categoryFieldAttr}
                options={categories}
                onBlur={categoryFieldAttr.handleBlur}
                onChange={categoryFieldAttr.handleChange}
                optionLabel="name"
                optionValue="resourceUri"
                editable
                placeholder="Chapter"
                className="w-full md:w-14rem mb-10 rounded-xl border-2 border-inherit focus:outline-none"
              />

              <EditFormControl
                styles="text-left font-conversation text-4xl"
                {...getFieldProps("name", "Title")}
                value={values.name}
                rows={2}
              />
              <EditFormControl
                styles="text-left font-body"
                {...summaryFieldAttr}
                value={values.summary}
                rows={10}
                isMultiline
                handleChange={(e) => {
                  summaryFieldAttr.handleChange(e);
                  setProtectedSummary();
                }}
              />
              <div className="mb-8">
                <label htmlFor="isProtectedSummary">
                  <input
                    type="checkbox"
                    id="isProtectedSummary"
                    name="isProtectedSummary"
                    checked={values.isProtectedSummary}
                    onChange={handleChange}
                    className="mr-2"
                  />
                  Lock this story. This prevents AI from overwriting this story
                  when adding more recordings.
                </label>
              </div>

              <Button
                label="Update"
                type="submit"
                style={ButtonTypes.PRIMARY}
                isFullWidth
                action={() => null}
              />
            </form>
          </MaxWidthContainer>
        )}
      </div>
    </>
  );
};

export { EditStory };
