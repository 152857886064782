import { Spinner } from "@material-tailwind/react";
import { FC } from "react";
import { Button } from "../../../../components/Button";
import { QuestionsCarrousel } from "../../../../components/QuestionsCarrousel";
import { RecordingButton } from "../../../../components/RecordingButton";
import { Statement, StatementSizes } from "../../../../components/Statement";
import { ButtonTypes } from "../../../../typings/components";
import { StepProps } from "../../useTellStory";
import { useStep3 } from "./useStep3";

const Step3: FC<StepProps> = () => {
  const {
    questions,
    currentQuestion,
    displayHelp,
    isRecording,
    isRecordedCompleted,
    isBuilding,
    continueRecording,
    changeTopic,
    isAddingMore,
    toggleRecording,
    setCurrentQuestion,
    story,
    reviewStory,
    reviewMyBook,
    isFlowCompleted,
  } = useStep3();

  return (
    <>
      {!displayHelp && (
        <>
          {!isRecording && !isBuilding && !isRecordedCompleted && (
            <div className="text-center mx-auto max-w-[800px]">
              {questions.length > 0 && (
                <>
                  <Statement
                    text={story?.name}
                    size={StatementSizes.XL3}
                    extraStyles="text-center"
                  />
                  <Statement
                    text={story?.commentary}
                    size={StatementSizes.MD}
                    extraStyles="pt-6 mb-10"
                  />
                  <QuestionsCarrousel
                    displayAddMore={false}
                    hideCommentary={true}
                    questions={questions}
                    setCurrentQuestion={setCurrentQuestion}
                    questionTextExtraStyle="text-lg"
                    story={story}
                  >
                    <div>
                      <h2 className="text-left font-conversation text-base">
                        &quot;Not feeling inspired?&quot;
                      </h2>
                      <div className="actions-list flex">
                        <Button
                          extraStyles="mx-2 mt-4 text-tertiary border-tertiary text-sm flex-initia"
                          isSmall
                          style={ButtonTypes.TRANSPARENT}
                          label="I want to add something else to this story"
                          action={() => continueRecording()}
                        />
                        <Button
                          extraStyles="mt-4 text-tertiary border-tertiary text-sm flex-initia"
                          isSmall
                          style={ButtonTypes.TRANSPARENT}
                          label="Change the topic"
                          action={() => changeTopic()}
                        />
                      </div>
                    </div>
                  </QuestionsCarrousel>
                </>
              )}
            </div>
          )}
          {isBuilding && story && (
            <>
              <Statement
                text="Great job!"
                size={StatementSizes.XL2}
                extraStyles="text-center"
              />
              <Statement
                text={
                  isFlowCompleted
                    ? "Review your progress"
                    : "Now, we’re building some follow-up questions."
                }
                size={StatementSizes.MD}
                extraStyles="text-center mt-6"
              />
              {!isFlowCompleted && (
                <div className="flex items-center justify-center mt-6">
                  <Spinner className="h-16 w-16" color="purple" />
                </div>
              )}
              {isFlowCompleted && (
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 max-w-[600px] mx-auto mt-[50px]">
                  <Button
                    extraStyles="mb-2 z-10"
                    style={ButtonTypes.TERTIARY}
                    label="View my book"
                    action={() => {
                      reviewMyBook();
                    }}
                  />
                  <Button
                    extraStyles="mb-2 z-10"
                    style={ButtonTypes.TERTIARY}
                    label="Manage your story"
                    action={() => {
                      reviewStory();
                    }}
                  />
                </div>
              )}
            </>
          )}
          {isRecording && !isBuilding && (
            <>
              {currentQuestion?.description ? (
                <Statement
                  text={currentQuestion?.description}
                  size={StatementSizes.XL3}
                  extraStyles="text-center max-w-[600px] mx-auto"
                />
              ) : (
                <>
                  <Statement
                    text={story?.name}
                    size={StatementSizes.XL3}
                    extraStyles="text-center"
                  />
                  <Statement
                    text={story?.commentary}
                    size={StatementSizes.MD}
                    extraStyles="pt-6 mb-10 text-center"
                  />
                </>
              )}
              <Statement
                text="We're recording!"
                size={StatementSizes.MD}
                extraStyles="text-center mt-6"
              />
              <Statement
                text="Just speak normally, as you would to a friend."
                size={StatementSizes.MD}
                extraStyles="text-center"
              />
              <Statement
                text="Press “Stop” when you're finished."
                size={StatementSizes.MD}
                extraStyles="text-center"
              />
            </>
          )}
          {!isRecordedCompleted && !isBuilding && (
            <div className="flex justify-center mt-[30px]">
              {(currentQuestion || isAddingMore) && (
                <RecordingButton
                  isActive={isRecording}
                  action={toggleRecording}
                  inActiveLabel={story ? "Record" : "Start"}
                />
              )}
            </div>
          )}
          {!isRecording && !isBuilding && (
            <div className="max-w-[400px] mx-auto">
              <Button
                extraStyles="mb-2 mt-[60px] z-10 mx-auto"
                style={ButtonTypes.TERTIARY}
                label="Review my story"
                action={() => reviewStory()}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export { Step3 };
