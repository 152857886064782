import {
  GenericToastMessage as GTMsg,
  TOAST_TYPES,
  ToastAlertsService,
  ToastMessage,
  ToastMessageType,
} from "../typings/InfoToast";
import { MLStateContext } from "./stateProvider";
import { useContext } from "react";

const useInfoToastService = (): ToastAlertsService => {
  const {
    state: { toasts = [] },
    updateStateItem,
  } = useContext(MLStateContext);

  const clearMessage = (id: string) => {
    updateStateItem({ toasts: toasts.filter((t) => t.id !== id) });
  };

  const createMessage = (n: ToastMessageType) => {
    updateStateItem({ toasts: [...toasts, new ToastMessage(n)] });
  };

  return {
    createMessage,
    createSuccessMsg: (m: GTMsg) =>
      createMessage({ ...m, type: TOAST_TYPES.SUCCESS }),
    createWarningMsg: (m: GTMsg) =>
      createMessage({ ...m, type: TOAST_TYPES.WARNING }),
    createErrorMsg: (m: GTMsg) =>
      createMessage({ ...m, type: TOAST_TYPES.ERROR }),
    clearMessage,
  };
};

export { useInfoToastService };
