/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { RegisterValues } from "../../typings/form-schemas";
import { useAuthService } from "../../services/useAuthService";
import { useFormik } from "formik";
import { registerSchema } from "../../utils/form-validation/yup-schemas";
import { useInfoToastService } from "../../services/useInfoToastService";
import { SignupInvitation } from "../../typings/Invitations";
import { useAPI } from "../../utils/api-connection";

const useRegister = () => {
  const { invitation } = useParams();
  const { createSuccessMsg } = useInfoToastService();
  const [invitationDetail, setInvitationDetail] = useState<SignupInvitation>();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const navigate = useNavigate();
  const { registerUser, loadInvitationInfo } = useAuthService();
  const successMessage = {
    title: "Account created",
    message: "Your account was created succesfully.",
  };
  const [initialValues, setInitialValues] = useState<RegisterValues>({
    first_name: "",
    last_name: "",
    email: "",
    username: "",
    password: "",
    password_confirm: "",
    // profile: {
    // date_of_birth: "",
    // photo: "",
    // sex: "",
    // },
    acceptTC: ""
  });
  const { closeSession } = useAPI();
  const loadInvite = () => {
    if (invitation)
      loadInvitationInfo(invitation)
        .then((invDetail) => {
          if (invDetail.status === "active") {
            setInvitationDetail(invDetail);
            setInitialValues({
              ...initialValues,
              username: invDetail.userToPhonenumber,
              email: invDetail.userToEmail,
            });
          }
        })
        .catch((e) => setErrorMessage(e.error))
        .finally(() => setIsLoading(false));
  };

  const onSubmit = () => {
    if (!formikObject.isValid) return;
    setIsLoading(true);
    setErrorMessage(null);
    register();
  };

  const register = () => {
    registerUser(formikObject.values, invitationDetail?.refNumber)
      .then(() => {
        createSuccessMsg(successMessage);
        navigate(invitationDetail?.refNumber ? "/" : "/check-your-email");
      })
      .catch((e) => setErrorMessage(e.error))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    closeSession();
  }, []);

  useEffect(() => {
    if (invitation) loadInvite();
  }, [invitation]);

  const formikObject = useFormik({
    initialValues,
    validationSchema: registerSchema,
    validateOnChange: true,
    onSubmit,
  });

  useEffect(() => {
    formikObject.resetForm({ values: initialValues });
  }, [initialValues]);

  return { isLoading, errorMessage, formikObject, invitationDetail };
};

export { useRegister };
