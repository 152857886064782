import { FC } from "react";
import { useInfoToast } from "./useInfoToast";
import { InfoToastMessage } from "../InfoToastMessage";

interface InfoToastProps {}

const InfoToast: FC<InfoToastProps> = () => {
  const { toasts } = useInfoToast();

  return (
    <div
      className={`fixed bottom-14 transition-all max-w-sm w-[90%] right-[5%] z-40`}
    >
      {toasts &&
        toasts.map((t) => <InfoToastMessage key={t.id} toastMessage={t} />)}
    </div>
  );
};

export { InfoToast };
