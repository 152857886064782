import { useContext, useEffect, useState } from "react";
import { useAudioRecorder } from "../../../../hooks/useAudioRecorder";
import { MLStateContext } from "../../../../services/stateProvider";
import { usePromptsService } from "../../../../services/usePromptsService";
import { useStoriesService } from "../../../../services/useStoriesService";
import { useTellStoryService } from "../../../../services/useTellStoryService";
import { Prompt } from "../../../../typings/Prompts";
import { Story } from "../../../../typings/Story";
import { useBookService } from "../../../../services/useBookService";
import { BookDetails } from "../../../../typings/Book";

const useStep1 = () => {
  const {
    stepNextNavigation,
    stepBackNavigation,
    setDisplayHelp,
    setCategory,
    tellStory,
    selectedCategory
  } = useTellStoryService();
  const { createNew } = useStoriesService();
  const { getInitial } = usePromptsService();
  const [book, setBook] = useState<BookDetails>();
  const { getBookDetail } = useBookService();

  useEffect(() => {
    setIsLoading(true);
    getBookDetail()
      .then((b) => setBook(b))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const {
    state: { currentStory, initialPrompts },
    updateStateItem,
  } = useContext(MLStateContext);
  const [readyToStart, setReadyToStart] = useState<boolean>(false);
  const [selectedQuestion, setSelectedQuestion] = useState<Prompt>();
  const [transcriptText, setTranscriptText] = useState<string>("");
  const [story, setStory] = useState<Story>();
  const [isLoading, setIsLoading] = useState(false);
  const handleTranscriptUpdate = (t: string) => {
    setTranscriptText((prev) => `${prev} ${t}`);
  };

  const {
    changeStateToRecording,
    startRecording,
    recorderState,
    saveRecording,
    cancelRecording
  } = useAudioRecorder({
    handleTranscriptUpdate,
  });

  const { isRecording } = recorderState;
  const recordItem = useState<boolean>(false);

  useEffect(() => {
    if (isRecording) {
      if (!story && !isLoading) {
        createNew((newStory) => {
          setStory(newStory);
          updateStateItem({ currentStory: newStory, isNewStory: true });
        });
      }
    }
  }, [isRecording]);


  
  useEffect(() => {
    if (selectedCategory) {
      stepNextNavigation();
    }
  }, []);
  useEffect(() => {
    if (initialPrompts?.length === 0) {
      getInitial();
    }
  }, [initialPrompts]);

  useEffect(() => {
    if (currentStory && isRecording) {
      startRecording();
    }
  }, [currentStory, isRecording]);

  const toggleRecording = () => {
    if (isRecording) pauseRecording();
    else changeStateToRecording();
  };

  const goBack = () => {
    stepBackNavigation();
  };
  const setCurrentQuestion = (question: Prompt) => {
    setSelectedQuestion(question);
  };

  const setPromptCategory = (category: string) => {
    setCategory(category);
    setTimeout(() => {
      goForward();
    }, 1000);
  };

  const pauseRecording = () => {
    if (transcriptText.length > 0 && story) {
      saveRecording();
      setReadyToStart(true);
    } else {
      cancelRecording();
      setStory(undefined);
      setReadyToStart(false);
      setDisplayHelp(true);
    }
  };

  const goForward = () => {
    stepNextNavigation();
  };
  return {
    chapters: book?.chapters,
    goBack,
    goForward,
    setPromptCategory,
    selectedQuestion,
    initialPrompts: initialPrompts || [],
    setCurrentQuestion,
    displayHelp: tellStory?.displayHelp,
    transcriptText,
    isRecording,
    recordItem,
    story,
    readyToStart,
    isLoading,
    toggleRecording,
    setIsLoading,
  };
};

export { useStep1 };
