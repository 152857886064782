import { FC } from "react";
import { Button } from "../../../../components/Button";
import { QuestionsCarrousel } from "../../../../components/QuestionsCarrousel";
import { RecordingButton } from "../../../../components/RecordingButton";
import { Statement, StatementSizes } from "../../../../components/Statement";
import { ButtonTypes } from "../../../../typings/components";
import { StepProps } from "../../useOnboarding";
import { useStep5 } from "./useStep5";

const Step5: FC<StepProps>  = () => {
  const { questions, currentQuestion, displayHelp,
          isRecording, isRecordedCompleted,
          toggleRecording, setCurrentQuestion, recordFollowUpQuestion, story , readyToStart, goForward} = useStep5();

  return (
    <>
        {!displayHelp && <>
          {!readyToStart && <div className="text-center">
          <Statement
            text="Fantastic!"
            size={StatementSizes.XL3}
            extraStyles="text-center"
          />
          <Statement
            text="Let’s keep the story going with some follow-up questions 
            to help you flesh out the details that matter. "
            size={StatementSizes.XL}
            extraStyles="text-center pt-6 mx-auto max-w-[600px]"
          />
          <div className=" max-w-[300px] mx-auto mt-[100px] px-10">
            <Button
            isFullWidth
              extraStyles="z-10 mx-auto"
              style={ButtonTypes.TERTIARY}
              label="Continue"
              action={() => recordFollowUpQuestion()}
            />
          </div>
        </div>}

        {!isRecording && !isRecordedCompleted && readyToStart && <div className="text-center mx-auto max-w-[800px]">
          {questions.length > 0 && (
            <>
              <Statement
                text="Here are your follow-up questions"
                size={StatementSizes.XL3}
                extraStyles="text-center"
              />
              <Statement
                text="Select one of the below to keep the story going."
                size={StatementSizes.MD}
                extraStyles="pt-6 mb-10"
              />
              <QuestionsCarrousel
                displayAddMore={false}
                hideCommentary={true}
                questions={questions}
                setCurrentQuestion={setCurrentQuestion}
                questionTextExtraStyle="text-lg"
                story={story}
              />
            </>
          )}
          </div>
        }
        {
          isRecording && <>
          <Statement
                text={currentQuestion?.description}
                size={StatementSizes.XL3}
                extraStyles="text-center max-w-[600px] mx-auto"
              />
          <Statement
                text="We're recording!"
                size={StatementSizes.MD}
                extraStyles="text-center mt-6"
              />
          <Statement
                text="Just speak normally, as you would to a friend."
                size={StatementSizes.MD}
                extraStyles="text-center"
              />
          <Statement
                text="Press “Stop” when you're finished."
                size={StatementSizes.MD}
                extraStyles="text-center"
              />
          </>
        }
        {readyToStart && !isRecordedCompleted && <div className="flex justify-center mt-auto mt-[30px]">
            {currentQuestion && (
              <RecordingButton
                isActive={isRecording}
                action={toggleRecording}
                inActiveLabel={story ? "Record" : "Start"}
              />
            )}
          </div>}
        {isRecordedCompleted && <div className="max-w-[400px] mx-auto">
            <Statement
              text="Wow! A lot just happened!"
              size={StatementSizes.XL3}
              extraStyles="text-center"
            />
            <Statement
              text="You're a storytelling machine!"
              size={StatementSizes.XL}
              extraStyles="mt-4 text-center"
            />
            <Statement
              text="In the background, we've created a title
              and first draft for your story."
              size={StatementSizes.XL}
              extraStyles="mt-4 text-center"
            />
            <Statement
              text="Let's take a look at your story so far -- you can always come back and add more."
              size={StatementSizes.XL}
              extraStyles="mt-4 text-center"
            />
            <Button
                  extraStyles="mb-2 mt-[60px] z-10 mx-auto"
                  style={ButtonTypes.TERTIARY}
                  label="Preview your story"
                  action={() => goForward()}
                />
          </div>
        }
        </>}
    </>
  );
};

export { Step5 };
