import { useMainModalActionService } from "../../../services/useMainModalActionService";

interface SettingsProps {
  closeMenu: VoidFunction;
}

const useSettings = (_: SettingsProps) => {
  const { openMainModalAction } = useMainModalActionService();
  return { openMainModalAction };
};

export { useSettings };
