import { FC } from "react";
import { ButtonTypes } from "../../typings/components";
import classNames from "classnames";
// import { useButton } from "./useButton";

interface ButtonProps {
  animated?: boolean;
  type?: "button" | "submit" | "reset";
  style?: ButtonTypes;
  label: string;
  action: VoidFunction;
  isFullWidth?: boolean;
  isLoading?: boolean;
  isSmall?: boolean;
  disabled?: boolean;
  extraStyles?: string;
}

const Button: FC<ButtonProps> = ({
  label,
  action,
  isFullWidth = false,
  isLoading = false,
  type,
  isSmall= false,
  style = ButtonTypes.PRIMARY,
  extraStyles = "",
  disabled = false,
}) => {
  const styles = classNames(
    `flex justify-center items-center transition-all rounded-full text-md font-regular cursor-pointer`,
    isFullWidth ? "w-full" : "",
    disabled ? "opacity-50" : "",
    isSmall ? "px-4 py-2" : "px-8 min-h-[50px]",
    {
      [`text-tertiary bg-normal`]: style === ButtonTypes.PRIMARY,
      [`text-primary bg-secondary`]: style === ButtonTypes.SECONDARY,
      [`text-normal bg-tertiary`]: style === ButtonTypes.TERTIARY,
      [`text-tertiary bg-grayed`]: style === ButtonTypes.DISABLED,
      [`border bg-transparent`]:
        style === ButtonTypes.TRANSPARENT,
    },
    extraStyles
  );
  return (
    <button type={type} className={styles} onClick={action} disabled={disabled}>
      {isLoading && (
        <svg
          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}
      {label}
    </button>
  );
};

export { Button };
