import { FC } from "react";
import { Statement } from "../../../components/Statement";
import { FormControl } from "../../../components/Forms/FormControl";
import { useAddListeners } from "./useAddListeners";

interface AddListenersProps {
  addListener: (email: string) => void;
}

const AddListeners: FC<AddListenersProps> = (props) => {
  const { formikObject } = useAddListeners(props);
  const { submitForm, touched, errors, values, handleChange, handleBlur } =
    formikObject;

  return (
    <div className="flex flex-col content-between bg-quaternary rounded-xl p-6 mt-8">
      <div className="mb-2 mt-2">
        <Statement
          text="Enter the email of the listener to share this story."
          extraStyles="text-normal text-lg"
        />
      </div>
      <form
        className="flex text-normal items-start py-0 px-0.5"
        noValidate
        autoComplete="off"
        onSubmit={(e) => {
          e.preventDefault();
          submitForm();
        }}
      >
        <div className="w-full">
          <FormControl
            type="email"
            name="email"
            autocomplete="off"
            placeholder="Email"
            touched={touched.email}
            error={errors.email}
            value={values.email}
            {...{ handleChange, handleBlur }}
          />
        </div>
        <button
          className="px-2 text-normal font-normal mt-3 -ml-16"
          type="submit"
        >
          Add
        </button>
      </form>
    </div>
  );
};

export { AddListeners };
