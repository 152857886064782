import { useContext, useEffect, useState } from "react";
import { MLStateContext } from "../../services/stateProvider";
import { Story } from "../../typings/Story";
import { useChapterService } from "../../services/useChapterService";
import { useParams } from "react-router-dom";
import { Chapter } from "../../typings/Chapter";
import { areStorysEquals, getOrderedStorys } from "../../utils/book-helper";

interface DndDivider {
  isDivider: true;
}

const useChapterDetail = () => {
  const { getChapterDetail, orderChapterStories } = useChapterService();
  const [isLoading, setIsLoading] = useState(false);
  const [stories, setStories] = useState<Array<Story>>([]);
  const [activeStories, setActiveStories] = useState<Array<Story>>([]);
  const [chapter, setChapter] = useState<Chapter>();
  const { id } = useParams();
  const [storiesTiles, setStoriesTiles] = useState<Array<Story | DndDivider>>(
    []
  );
  const {
    state: { userDetails },
  } = useContext(MLStateContext);
  const dividerIndex = storiesTiles.findIndex(
    (i) => (i as DndDivider).isDivider
  );

  useEffect(() => {
    loadChapterDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setActiveStories(stories.filter((s) => s.isActive));
    setStoriesTiles([
      ...stories.filter((s) => s.isActive),
      {
        isDivider: true,
      },
      ...stories.filter((s) => !s.isActive),
    ]);
  }, [stories]);

  const loadChapterDetails = () => {
    if (id) {
      setIsLoading(true);
      getChapterDetail(id).then((c) => {
        setChapter(c);
        setStories(c.stories);
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    if (
      chapter &&
      !areStorysEquals(chapter.stories, storiesTiles as Story[], dividerIndex)
    ) {
      reorderStories();
    }
  }, [storiesTiles]);

  const onStoriesDragged = async (sTiles: Array<Story | DndDivider>) => {
    setStoriesTiles(sTiles);
  };

  const reorderStories = () => {
    const orderedStories = getOrderedStorys(
      storiesTiles as Story[],
      dividerIndex
    );
    if (id)
      orderChapterStories(id, orderedStories).then((c) => {
        setChapter(c);
        setStories(c.stories);
      });
  };

  return {
    chapter,
    stories,
    isLoading,
    userDetails,
    storiesTiles,
    activeStories,
    onStoriesDragged,
    setStoriesTiles,
    reorderStories,
  };
};

export { useChapterDetail };
