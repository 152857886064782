export const getTranscriptQueryParams = () => {
  const params = [
    { key: "model", value: "general" },
    { key: "tier", value: "base" },
    { key: "version", value: "latest" },
    { key: "language", value: "en-US" },
    { key: "punctuate", value: "true" },
    { key: "interim_results", value: "true" },
    { key: "smart_format", value: "true" },
    { key: "paragraphs", value: "true" },
    { key: "detect_topics", value: "true" },
    { key: "alternatives", value: "3" },
    { key: "numerals", value: "true" },
    { key: "keywords", value: "story" },
    { key: "keywords", value: "autobiography" },
  ];
  return params.map((p) => `${p.key}=${p.value}`).join("&");
};

export const getTranscriptQueryParamsLive = () => {
  const params = [
    { key: "model", value: "general" },
    { key: "tier", value: "base" },
    { key: "version", value: "latest" },
    { key: "language", value: "en-US" },
    { key: "punctuate", value: "true" },
    { key: "interim_results", value: "true" },
    { key: "smart_format", value: "true" },
    { key: "paragraphs", value: "true" },
    { key: "alternatives", value: "3" },
    { key: "numerals", value: "true" },
    { key: "keywords", value: "story" },
    { key: "keywords", value: "autobiography" },
  ];
  return params.map((p) => `${p.key}=${p.value}`).join("&");
};
