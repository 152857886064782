/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthService } from "../../services/useAuthService";
import { useInfoToastService } from "../../services/useInfoToastService";
import { useFormik } from "formik";
import { passwordReset } from "../../utils/form-validation/yup-schemas";
import { MLStateContext } from "../../services/stateProvider";
// import { useUserService } from "../../services/useUserService";

const useForgotPassword = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { requestResetPassword, logout } = useAuthService();
  const { createErrorMsg, createSuccessMsg } = useInfoToastService();
  const {
    state: { userDetails },
  } = useContext(MLStateContext);
  const userEmail = userDetails?.email || "";
  const navigate = useNavigate();

  useEffect(() => {
    formikObject.resetForm({ values: { email: userEmail } });
  }, [userEmail]);

  const onSubmit = () => {
    setIsLoading(true);
    requestResetPassword(values.email)
      .then(() => {
        createSuccessMsg({
          title: "Password reset was requested .",
          message: `To reset your password check your email and follow the instructions.`,
        });
        logout();
        navigate("/");
      })
      .catch((e) =>
        createErrorMsg({
          title: "Password was not reseted.",
          message: e.error,
        })
      )
      .finally(() => {
        setIsLoading(false);
      });
  };

  const formikObject = useFormik({
    initialValues: { email: userEmail },
    validationSchema: passwordReset,
    onSubmit,
  });
  const { values } = formikObject;

  return { isLoading, formikObject };
};

export { useForgotPassword };
