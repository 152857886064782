import { FC } from "react";
import { useCurrentUserProfile } from "./useCurrentUserProfile";
import { Card } from "../../components/Card";
import { RoundedImage } from "../../components/RoundedImage";
import { MaxWidthContainer } from "../../components/MaxWidthContainer";
import { useNavigate } from "react-router";
import { Grid } from "../../components/Grid";
import { Spinner } from "../../components/Spinner";
import { Button } from "../../components/Button";
import { UserAvatar } from "../../components/UserAvatar";
import { Statement, StatementSizes } from "../../components/Statement";

interface StoriesProps {
  isProfile?: boolean;
}

const CurrentUserProfile: FC<StoriesProps> = () => {
  const { allStories, isLoading, selectedUser } = useCurrentUserProfile();
  const navigate = useNavigate();

  return (
    <div>
      {isLoading && <Spinner isLoading withBackdrop />}
      <MaxWidthContainer isPageLayout>
        <div className="flex flex-col items-center w-full mb-10">
          <UserAvatar
            src={
              selectedUser?.profile.photo||
              `https://picsum.photos/seed/${selectedUser?.id}/200`
            }
            alt="Avatar"
            large
            extraStyles="mb-4"
          />
          <Statement
            text={selectedUser?.fullName}
            size={StatementSizes.XL4}
            extraStyles="text-primary"
          />
          <p className="text-center">
            {allStories.length || 0} Stories{" "}
            {selectedUser?.followers?.length || 0} Listeners{" "}
            {selectedUser?.following?.length || 0} Storytellers
          </p>
        </div>
        {!allStories ||
          (!allStories.length && (
            <div className="flex flex-col items-center">
              <p className="mb-4">There are no stories yet</p>
              <Button
                label="Go create your first story"
                action={() => navigate("/record")}
              />
            </div>
          ))}
        <Grid>
          {allStories &&
            allStories.length > 0 &&
            allStories.map((s) => (
              <Card
                key={`story-item-${s.id}`}
                action={() => navigate(`/stories/${s.id}`)}
                isRowDisplay
              >
                <div className="flex-1 mr-2">
                  {s.name && <h4 className="font-bold">{s.name}</h4>}
                  {s.shortText && <p>{s.shortText}</p>}
                </div>
                <RoundedImage
                  src={`https://picsum.photos/seed/${s.id}/200`}
                  alt="Recipe"
                  medium
                />
              </Card>
            ))}
        </Grid>
      </MaxWidthContainer>
    </div>
  );
};

export { CurrentUserProfile };
