import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../components/Button";
import { Statement, StatementSizes } from "../../../../components/Statement";
import { ButtonTypes } from "../../../../typings/components";
import { StepProps } from "../../useOnboarding";
import { useStep6 } from "./useStep6";


const Step6: FC<StepProps>  = () => {
  const {resetOnboarding, currentStory} = useStep6();
  const navigate = useNavigate();
  return (
    <>
        <div className="text-center  max-w-[600px] mx-auto">
            <Statement
              text={currentStory?.name}
              size={StatementSizes.XL3}
              extraStyles=""
            />
            <div className="mt-4">
              <div className="flex-1 overflow-y-scroll md:mt-10">
                <span className="h-20 w-full left-0 absolute bg-gradient-to-b from-primary z-10 pointer-events-none" />
                <div className="h-8" />
                {currentStory?.summary}
              </div>
            </div>
            <div className="relative mt-10">
              <Statement
                text="You’re off to a great start!"
                size={StatementSizes.LG}
                extraStyles=" pt-6"
              />
              <Statement
                text="From here, you can go to your story page where you can add photos, edit, and share. Or, you can check out your book progress."
                size={StatementSizes.LG}
                extraStyles=" pt-6"
              />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-10 max-w-[600px] mx-auto mt-[50px]">
              <Button
                extraStyles="mb-2 z-10"
                style={ButtonTypes.TERTIARY}
                label="View my book"
                action={() => {resetOnboarding();navigate(`/myBook`)}}
              />
              <Button
                extraStyles="mb-2 z-10"
                style={ButtonTypes.TERTIARY}
                label="Manage your story"
                action={() => {resetOnboarding();navigate(`/stories/${currentStory?.id}`)}}
              />
            </div>
        </div>
    </>

  );
};

export { Step6 };
