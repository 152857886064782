import { FollowUpQuestionDetails } from "../typings/FollowUpQuestion";
import { Story, StoryDetail } from "../typings/Story";

export const sortItemsByDate: (story: StoryDetail) => StoryDetail = (story: Story) => {
  const sortedItems = story.fullText?.sort((a, b) => a.id - b.id);
  return { ...story, fullText: sortedItems };
};

export const sortQuestionsByDate: (
  questions: FollowUpQuestionDetails[]
) => FollowUpQuestionDetails[] = (questions: FollowUpQuestionDetails[]) => {
  const sortedItems = questions.sort((a, b) => a.id - b.id);
  return sortedItems;
};

export const convertUTCDateToLocalDate = (date: Date) => {
  const newDate = new Date(date);
  newDate.setMinutes(date.getMinutes() - date.getTimezoneOffset());
  return newDate;
};

export const formatDate = (dateT: Date | string) => {
  const date = new Date(dateT);
  date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const year = date.getFullYear();

  return `${month}/${day}/${year}`;
};

