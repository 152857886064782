/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from "react";
import { Story } from "../../typings/Story";
import { trimText } from "../../utils/text-format";
import { useStoriesService } from "../../services/useStoriesService";
import { UserDetails } from "../../typings/User";
import { MLStateContext } from "../../services/stateProvider";

const useCurrentUserProfile = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserDetails>();
  const { getAll } = useStoriesService();
  const [allStories, setAllStories] = useState<Array<Story>>([]);
  const {
    state: { userDetails },
  } = useContext(MLStateContext);

  useEffect(() => {
    loadInfo();
  }, []);

  const loadInfo = () => {
    setIsLoading(true);
    getAll()
      .then((l) => {
        if (userDetails) setSelectedUser(userDetails);
        setAllStories(
          l
            .map((s) => ({ ...s, shortText: trimText(s.shortText || "") }))
            .filter((s) => s.author.id === userDetails?.id)
        );
      })
      .finally(() => setIsLoading(false));
  };

  return { allStories, isLoading, selectedUser };
};

export { useCurrentUserProfile };
