import { useAPI } from "../utils/api-connection";
import {
  FollowUpQuestion,
  FollowUpQuestionChunk,
} from "../typings/FollowUpQuestion";
import { MLStateContext } from "./stateProvider";
import { useContext } from "react";

const useFollowupQuestionsService = () => {
  const { createFollowUpQuestion, postChunkToFollowUpQuestion } = useAPI();
  const {
    state: { userDetails },
  } = useContext(MLStateContext);

  const createNewQuestion = (question: FollowUpQuestion) => {
    return createFollowUpQuestion(question);
  };

  const addChunkToFollowUpQuestion = (chunk: FollowUpQuestionChunk) => {
    return postChunkToFollowUpQuestion(chunk).catch((e) => console.log(e));
  };

  const getPendingFollowupQuestions = () => {
    if (
      !userDetails ||
      !userDetails.stories ||
      userDetails.stories.length === 0
    )
      return [];
    return userDetails.stories
      .map((s) => s.followupQuestions)
      .reduce((prev, curr) => prev.concat(curr), []);
  };

  return {
    createNewQuestion,
    addChunkToFollowUpQuestion,
    getPendingFollowupQuestions,
  };
};

export { useFollowupQuestionsService };
