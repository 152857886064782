export const API_BASE_URL: string = process.env.REACT_APP_API_ENV_URL as string;
export const WSS_BASE_URL: string = process.env.REACT_APP_WSS_ENV_URL as string;
export const ENDPOINTS = {
  USER: API_BASE_URL + `user/`,
  INVITATION: API_BASE_URL + `invitation/`,
  CONNECTION: API_BASE_URL + `connection/`,
  STORY: API_BASE_URL + `story/`,
  FOLLOW_UP_Q: API_BASE_URL + `followup_question/`,
  CHUNK: API_BASE_URL + `chunk/`,
  MEDIA: API_BASE_URL + `medialib/`,
  PROFILE: API_BASE_URL + `profile/`,
  PROMPT: API_BASE_URL + `prompt/`,
  BOOK: API_BASE_URL + `book/`,
  CHAPTER: API_BASE_URL + `chapter/`,
};

export const ALL_DEFAULT_STORY_FIELDS = [
  "created_at",
  "description",
  "followup_questions",
  "full_text",
  "id",
  "is_active",
  "keywords",
  "lang",
  "media",
  "name",
  "chapter",
  "category",
  "overview",
  "resource_uri",
  "sentiment",
  "short_text",
  "storyteller",
  "summary",
  "tags",
  "updated_at",
  "commentary",
  "protected_name",
  "protected_summary",
];

export const BASIC_STORY_FIELDS = ["id", "name", "short_text"];

export const LISTEN_STORY_FIELDS = [
  "id",
  "name",
  "short_text",
  "author",
  "media",
  "profile",
  "storyteller",
];
